import { Group, LoadingOverlay, Stack, Text, Title } from '@mantine/core';
import React from 'react';
import { Link, useParams } from 'react-router-dom';

import { ServerError } from '@portals/api';
import { useWelcomeDetails } from '@portals/api/ui';

import { useAppConfig } from '../../context';
import { FormWrapper } from './common';
import { WelcomeForm } from './WelcomeForm';

export function Welcome() {
  const { tenantType } = useAppConfig();
  const { token } = useParams<{ token: string }>();

  const welcomeDetails = useWelcomeDetails({ tenant: tenantType, token });

  if (welcomeDetails.isFetched && welcomeDetails.error) {
    return (
      <Stack>
        <Title order={3}>
          {(welcomeDetails.error as ServerError)?.error || 'User not found'}
        </Title>

        <Text color="dimmed" size="sm" align="center">
          <Group spacing={6}>
            Return to
            <Link to="/auth/sign-in">Sign-In</Link>
          </Group>
        </Text>
      </Stack>
    );
  }

  return (
    <FormWrapper id="welcome">
      <LoadingOverlay visible={!welcomeDetails.isFetched} />

      {welcomeDetails.data ? (
        <Stack>
          <Group position="apart" mb="lg" align="baseline">
            <Title order={1} className="auth-page-title">
              Welcome to {welcomeDetails?.data?.tenant_name}
            </Title>

            <Text color="dimmed" size="sm" align="center">
              <Group spacing={6}>
                <Link to="/auth/sign-in">Sign-In</Link>
              </Group>
            </Text>
          </Group>

          <WelcomeForm
            welcomeDetails={welcomeDetails.data}
            token={token}
            tenantType={tenantType}
          />
        </Stack>
      ) : null}
    </FormWrapper>
  );
}
