import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';

import { toastrError } from '@portals/redux/actions/toastr';
import { AuthType } from '@portals/types';

import { ORGANIZATIONS_API_URL } from './organizations.constants';
import { ServerError } from '../../types';
import { fetchApiRequest, useRequestOptions } from '../../utils';

export interface UseCreateOrganizationParams {
  org?: string;
  name: string;
  email: string;
  password: string;
  accepted_terms_and_conditions: boolean;
  afterAuthPath?: string;
  referral?: string;
}

export function useCreateOrganization() {
  const dispatch = useDispatch();
  const { url, options } = useRequestOptions({
    url: ORGANIZATIONS_API_URL,
    method: 'POST',
  });

  return useMutation<AuthType, ServerError, UseCreateOrganizationParams>({
    mutationFn: ({ afterAuthPath, ...params }) => {
      return fetchApiRequest(url, {
        ...options,
        body: JSON.stringify(params),
      });
    },
    onError: ({ error }) => {
      dispatch(toastrError(error));
    },
  });
}
