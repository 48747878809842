import { Box, createStyles } from '@mantine/core';
import { compact } from 'lodash/fp';
import React, { useMemo } from 'react';

import { useIsSidebarOpen } from '@portals/redux';
import { VerticalScrollBar } from '@portals/scrollbar';

import { SidebarFooter } from './SidebarFooter';
import { SidebarSection } from './SidebarSection';
import { TenantMenu } from './TenantMenu';
import { useAppConfig } from '../../../context';
import {
  useCommonClassification,
  useCommonDesign,
  useCommonFeatureFlags,
  useCommonPortalCapabilities,
  useCommonPricingPlanFeatures,
} from '../../../hooks/portal-config';
import { useHasSupportSeat } from '../../../hooks/support-seats';
import { usePermissionAccess } from '../../permission-access/use-permission-access';

export function Sidebar() {
  const isSidebarOpen = useIsSidebarOpen();
  const { routes, extraLayout } = useAppConfig();
  const { canView, canEdit, isAdmin, isSuperAdmin, isMissingAllPermissions } =
    usePermissionAccess();
  const hasSupportSeat = useHasSupportSeat();
  const { classes, cx } = useStyles();
  const featureFlags = useCommonFeatureFlags();
  const portalCapabilities = useCommonPortalCapabilities();
  const pricingPlanFeatures = useCommonPricingPlanFeatures();
  const design = useCommonDesign();

  const classification = useCommonClassification();

  const sidebarSections = useMemo(() => {
    const sections = routes?.dashboard?.map(
      ({ id, headerId, childRoutes, canAccessRoute }) => {
        if (
          canAccessRoute &&
          !canAccessRoute({
            featureFlags,
            portalCapabilities,
            pricingPlanFeatures,
            canView,
            canEdit,
            isAdmin,
            hasSupportSeat,
            classification,
          })
        ) {
          return undefined;
        }

        return (
          <SidebarSection
            key={id}
            headerId={headerId}
            childRoutes={childRoutes}
            isSidebarOpen={isSidebarOpen}
          />
        );
      }
    );

    return compact(sections);
  }, [
    routes?.dashboard,
    featureFlags,
    portalCapabilities,
    pricingPlanFeatures,
    canView,
    canEdit,
    isAdmin,
    hasSupportSeat,
    isSidebarOpen,
    classification,
  ]);

  return (
    <Box className={cx(classes.container, { open: isSidebarOpen })}>
      <Box
        className={cx('sidebar-content', classes.sidebarContent)}
        sx={(theme) => ({
          backgroundColor:
            design?.navigation_menu?.menu_background_color || theme.white,
        })}
      >
        <TenantMenu />

        <VerticalScrollBar
          hideTracksWhenNotNeeded
          renderView={(props) => <div className="sidebar-nav" {...props} />}
        >
          {isMissingAllPermissions &&
          !isSuperAdmin &&
          !hasSupportSeat ? null : (
            <>{sidebarSections}</>
          )}

          {extraLayout?.sidebarLayout
            ? extraLayout?.sidebarLayout({ featureFlags, portalCapabilities })
            : null}
        </VerticalScrollBar>

        <SidebarFooter />
      </Box>
    </Box>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    position: 'fixed',
    left: 0,
    top: 0,
    height: '100vh',
    zIndex: 1,
    width: theme.other.closedSidebarWidth,

    '.sidebar-content': {
      width: '100%',
    },

    '&.open': {
      width: theme.other.openedSidebarWidth,
    },
  },
  sidebarContent: {
    borderRight: `2px solid ${theme.colors.blue_gray[0]}`,
    zIndex: 100,
    height: '100%',
    display: 'grid',
    gridTemplateRows: 'auto 1fr 56px',

    '.sidebar-nav': {
      paddingLeft: 0,
      listStyle: 'none',
      paddingBottom: '15px',
    },
  },
}));
