import {
  createStyles,
  Divider,
  Group,
  Paper,
  Stack,
  Text,
} from '@mantine/core';
import React from 'react';

import { formatCurrency } from '@portals/utils';

import { OrderType } from './order.types';
import { useGenericContext } from '../../../components/GenericContext';

interface OrderSummaryCardProps {
  isTaxEnabled: boolean;
}

export function OrderSummaryCard<TOrder extends OrderType>({
  isTaxEnabled,
}: OrderSummaryCardProps) {
  const { classes } = useStyles();
  const order = useGenericContext<TOrder>();

  return (
    <Paper withBorder p="xl" radius="md">
      <Stack>
        <Text size="md" color="gray.8">
          Summary
        </Text>

        <Group position="apart">
          <Text className={classes.subtotalLabel}>Subtotal</Text>
          <Text className={classes.subtotalAmount}>
            {formatCurrency(order.subtotal_amount_in_scu, order.currency)}
          </Text>
        </Group>

        {isTaxEnabled && (
          <Group position="apart">
            <Text className={classes.taxLabel}>Tax</Text>
            <Text className={classes.taxAmount}>
              {formatCurrency(order.tax_in_scu, order.currency)}
            </Text>
          </Group>
        )}

        <Divider className={classes.divider} />

        <Group position="apart">
          <Text className={classes.totalLabel}>Total</Text>
          <Text className={classes.totalAmount}>
            {formatCurrency(order.total_amount_in_scu, order.currency)}
          </Text>
        </Group>
      </Stack>
    </Paper>
  );
}

const useStyles = createStyles((theme) => ({
  subtotalLabel: {
    fontSize: theme.fontSizes.sm,
    color: theme.colors.blue_gray[5],
    fontWeight: 400,
  },
  subtotalAmount: {
    fontSize: theme.fontSizes.sm,
    color: theme.colors.blue_gray[9],
    fontWeight: 500,
  },
  taxLabel: {
    fontSize: theme.fontSizes.sm,
    color: theme.colors.gray[5],
    fontWeight: 400,
  },
  taxAmount: {
    fontSize: theme.fontSizes.sm,
    color: theme.colors.blue_gray[9],
    fontWeight: 500,
  },
  divider: {
    borderColor: theme.colors.gray[2],
  },
  totalLabel: {
    fontSize: theme.fontSizes.md,
    color: theme.colors.gray[8],
    fontWeight: 500,
  },
  totalAmount: {
    fontSize: theme.fontSizes.xl,
    color: theme.colors.blue_gray[9],
    fontWeight: 500,
  },
}));
