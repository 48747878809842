import { Notifications } from '@mantine/notifications';
import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import ReduxToastr from 'react-redux-toastr';
import { Router } from 'react-router-dom';

import history from '@portals/browser-history';
import { ThemeProvider as MantineThemeProvider } from '@portals/core';
import { Localization } from '@portals/i18n';
import { store } from '@portals/redux';
import {
  AppProps,
  CommonFeatureFlagsType,
  CommonPortalCapabilities,
  CommonPricingPlanFeatures,
} from '@portals/types';
import { ThemeProvider } from '@portals/ui';

import DevTools from './components/DevTools';
import EnvName from './components/EnvName';
import { VersionMonitor } from './components/version-monitor/VersionMonitor';
import { AppConfigProvider } from './context';
import { HubspotConversationsProvider } from './external/HubspotConversationsProvider';
import { Routes } from './routes/Routes';
import QueryClientWrapper from './wrappers/QueryClientWrapper';

export function App<
  TFeatureFlags extends CommonFeatureFlagsType,
  TPortalCapabilities extends CommonPortalCapabilities,
  TPricingPlanFeatures extends CommonPricingPlanFeatures
>(props: AppProps<TFeatureFlags, TPortalCapabilities, TPricingPlanFeatures>) {
  return (
    <Router history={history}>
      <HelmetProvider>
        <ThemeProvider>
          <AppConfigProvider<
            TFeatureFlags,
            TPortalCapabilities,
            TPricingPlanFeatures
          >
            value={props}
          >
            <Provider store={store}>
              <QueryClientWrapper>
                <MantineThemeProvider>
                  <Notifications position="top-right" />

                  <EnvName />

                  {process.env.NX_ENV === 'production' ? (
                    <VersionMonitor />
                  ) : null}
                  {process.env.NX_ENV === 'production' ? null : <DevTools />}

                  <HubspotConversationsProvider>
                    <Localization>
                      <DndProvider backend={HTML5Backend}>
                        <Routes<
                          TFeatureFlags,
                          TPortalCapabilities,
                          TPricingPlanFeatures
                        > />
                        <ReduxToastr
                          timeOut={5000}
                          newestOnTop={true}
                          position="top-right"
                          transitionIn="fadeIn"
                          transitionOut="fadeOut"
                          progressBar
                          closeOnToastrClick
                          preventDuplicates
                        />
                      </DndProvider>
                    </Localization>
                  </HubspotConversationsProvider>
                </MantineThemeProvider>
              </QueryClientWrapper>
            </Provider>
          </AppConfigProvider>
        </ThemeProvider>
      </HelmetProvider>
    </Router>
  );
}
