import { Group } from '@mantine/core';
import React, { FC, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import { Col, ListGroup, ListGroupItem, Row } from 'reactstrap';

import { setRoute } from '@portals/redux/actions/routing';
import { ComponentRendererType, TabType } from '@portals/types';

type SideTabsProps = {
  tabs: Array<TabType>;
  urlParam: string;
  urlBase: string;
  titleWrap?: ComponentRendererType<any>;
  disabled?: boolean;
};

type SideTabsConnectedActions = {
  setRoute: (path: string) => void;
};

const SideTabs: FC<
  SideTabsProps &
    SideTabsConnectedActions &
    RouteComponentProps<Record<string, string>>
> = ({ tabs, urlParam, urlBase, match, setRoute, titleWrap, disabled }) => {
  const defaultId = tabs[0].id;
  const [stateSelected, setStateSelected] = useState(defaultId);
  const selected = urlParam
    ? match.params[urlParam] || defaultId
    : stateSelected;

  const setSelected = (id) =>
    urlParam ? setRoute(`${urlBase}/${id}`) : setStateSelected(id);

  return (
    <Row style={disabled ? { pointerEvents: 'none', opacity: 0.5 } : {}}>
      <Col md="3" className="border-right">
        <ListGroup flush>
          {tabs.map((tab) => (
            <ListGroupItem
              tag="a"
              key={tab.id}
              active={selected === tab.id}
              action
              className="mr-1"
              onClick={() => setSelected(tab.id)}
            >
              <Group position="apart" align="center">
                {titleWrap ? React.createElement(titleWrap, tab) : tab.title}

                {tab.badge || null}
              </Group>
            </ListGroupItem>
          ))}
        </ListGroup>
      </Col>
      <Col>
        {tabs.map(({ id, Component, props = {} }) =>
          id === selected ? <Component key={id} id={id} {...props} /> : null
        )}
      </Col>
    </Row>
  );
};

const ConnectedSideTabs = connect<null, SideTabsConnectedActions>(null, {
  setRoute,
})(SideTabs);

export default withRouter<
  RouteComponentProps & SideTabsProps,
  FC<SideTabsProps & SideTabsProps>
>(ConnectedSideTabs);
