import { ActionIcon, Tooltip, useMantineTheme } from '@mantine/core';
import React from 'react';

import { ReactComponent as New } from '@portals/icons/linear/new.svg';
import { OrganizationPortalCapabilities, TenantType } from '@portals/types';

import { useAppConfig } from '../../../context';
import LaunchNotes from '../../../external/LaunchNotes';
import {
  useCommonDesign,
  useCommonPortalCapabilities,
} from '../../../hooks/portal-config';

export const ProductUpdates = () => {
  const appConfig = useAppConfig();
  const design = useCommonDesign();
  const theme = useMantineTheme();
  const portalCapabilities = useCommonPortalCapabilities();

  if (appConfig.tenantType === TenantType.Organization) {
    if ((portalCapabilities as OrganizationPortalCapabilities).b2c_view)
      return null;
  }

  return (
    <LaunchNotes>
      {({ isActive, setIsActive }) => {
        let iconColor =
          design?.navigation_menu?.menu_text_color || theme.colors.blue_gray[6];

        if (isActive) {
          iconColor =
            design?.navigation_menu?.selected_section_background_color ||
            theme.colors.blue_accent[4];
        }

        return (
          <Tooltip label="What's new" withinPortal>
            <ActionIcon
              onClick={() => setIsActive(true)}
              variant="transparent"
              size="lg"
              id="launch-notes-toggle"
              sx={() => ({
                color: iconColor,
              })}
            >
              <New />
            </ActionIcon>
          </Tooltip>
        );
      }}
    </LaunchNotes>
  );
};
