import {
  Avatar,
  createStyles,
  Group,
  MantineColor,
  Paper,
  Stack,
  Text,
} from '@mantine/core';
import React, { FC, ReactNode } from 'react';

export interface KpiWidgetType {
  title: string;
  avatarColor: MantineColor;
  label: ReactNode;
  icon: ReactNode;
}

interface KPIWidgetProps {
  kpiWidget: KpiWidgetType;
}

const KpiWidget: FC<KPIWidgetProps> = ({ kpiWidget }) => {
  const { classes } = useStyles(kpiWidget?.avatarColor);

  return (
    <Paper radius="md" className={classes.container}>
      <Group spacing="md" noWrap className={classes.contentContainer}>
        <Avatar
          radius="lg"
          className={classes.avatar}
          styles={(theme) => ({
            placeholder: {
              backgroundColor: theme.fn.rgba(
                theme.colors[kpiWidget?.avatarColor]?.[7] ||
                  theme.colors.blue_gray[7],
                0.1
              ),
            },
          })}
        >
          {kpiWidget.icon}
        </Avatar>

        <Stack spacing={0}>
          <Text className={classes.title}>{kpiWidget.title}</Text>
          <Text className={classes.counter}>{kpiWidget.label}</Text>
        </Stack>
      </Group>
    </Paper>
  );
};

const useStyles = createStyles((theme, avatarColor: string) => ({
  container: {
    padding: theme.spacing.xl,
    border: `1px solid ${theme.colors.gray[2]}`,

    [theme.fn.largerThan(1600)]: {
      padding: `33px ${theme.spacing.xl}`,
    },
  },
  contentContainer: {
    [theme.fn.largerThan(1600)]: {
      gap: theme.spacing.xl,
    },
  },
  avatar: {
    width: 60,
    height: 60,
    minWidth: 60,

    svg: {
      color: theme.colors[avatarColor][4],
      width: 32,
      height: 32,
    },

    [theme.fn.largerThan(1600)]: {
      width: 80,
      height: 80,

      svg: {
        width: 36,
        height: 36,
      },
    },
  },
  counter: {
    fontSize: 32,
    fontWeight: 400,
    color: theme.colors.blue_gray[9],

    [theme.fn.largerThan(1600)]: {
      fontSize: 39,
    },
  },
  title: {
    color: theme.colors.blue_gray[4],
    fontSize: theme.fontSizes.sm,

    [theme.fn.largerThan(1600)]: {
      fontSize: theme.fontSizes.md,
    },
  },
}));

export default KpiWidget;
