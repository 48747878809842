import { Group, Stack, Text, Title } from '@mantine/core';
import { AnimatePresence, motion } from 'framer-motion';
import { get } from 'lodash/fp';
import React, { FC } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { AutoFormik } from '@portals/autoformik';
import { forgotPassword } from '@portals/redux/actions/auth';
import { FieldTypeEnum, StateType } from '@portals/types';
import { FADE_IN_OUT } from '@portals/ui';

import { useAppConfig } from '../../context';
import { FormWrapper } from './common';

const FIELDS = [
  {
    name: 'email',
    title: 'Email address',
    type: FieldTypeEnum.Email,
    required: true,
    placeholder: 'The email you signed up with',
  },
];

type ResetPasswordConnectedProps = {
  error: string | undefined;
  inProgress: boolean;
};

type ResetPasswordConnectedActions = {
  forgotPassword: (email: string, tenantType: string) => void;
};

const ResetPassword: FC<
  ResetPasswordConnectedProps & ResetPasswordConnectedActions
> = ({ error, inProgress, forgotPassword }) => {
  const { tenantType } = useAppConfig();

  return (
    <FormWrapper id="reset-password">
      <Stack>
        <Title order={1} mb="lg" className="auth-page-title">
          Reset Password
        </Title>

        <AutoFormik
          fields={FIELDS}
          inProgress={inProgress}
          handleSubmit={({ email }) => forgotPassword(email, tenantType)}
          submitTitle="Send reset link"
          buttonsProps={{
            size: 'sm',
            style: {
              width: '100%',
              marginTop: '20px',
            },
          }}
        />

        <AnimatePresence>
          {error ? (
            <motion.p
              {...FADE_IN_OUT}
              className="text-danger text-center mt-3 auth-page-error"
            >
              {error}
            </motion.p>
          ) : null}
        </AnimatePresence>

        <Text color="dimmed" size="sm" align="center">
          <Group spacing={6}>
            Return to
            <Link to="/auth/sign-in">Sign-In</Link>
          </Group>
        </Text>
      </Stack>
    </FormWrapper>
  );
};

const mapStateToProps = (state: StateType) => ({
  error: state.errors?.resetPasswordLink,
  inProgress: get('ui.network.resetPasswordLink', state),
});

export default connect(mapStateToProps, { forgotPassword })(ResetPassword);
