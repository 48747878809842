import {
  castArray,
  endsWith,
  find,
  findIndex,
  forEach,
  isNil,
  isNumber,
  isUndefined,
  map,
  size,
  startsWith,
  toNumber,
  toString,
} from 'lodash/fp';
import { FilterValue, IdType } from 'react-table';

import {
  RESULTS_PER_PAGE,
  TableColumn,
  TableFilterTypeEnum,
  TableState,
} from '@portals/types';

// Adjust filter value for special array types - TableFilterTypeEnum.Date &
// TableFilterTypeEnum.Number
// Example:
// - { id: 'date', value: { gte: January 1st, lte: January 5th } } =>
//   [{ id: 'q[date_gteq]', value: January 1st }, { id: 'q[date_lteq]', value: January 5th }]
//
// - { id: 'foo', value: 'a' } =>
//   { id: 'q[foo_i_cont]', value: 'a' }
export function getFilterSearchParams(
  filter: { id: IdType<any>; value: FilterValue },
  column?: TableColumn
) {
  let gte, lte, params;

  switch (column?.filter?.type) {
    case TableFilterTypeEnum.Date:
      gte = filter.value?.gte;
      lte = filter.value?.lte;

      if (!gte && !lte) return null;

      params = [
        {
          id: `q[${filter.id}_gteq]`,
          value: isNil(gte) ? '' : gte.toISOString(),
        },
        {
          id: `q[${filter.id}_lteq]`,
          value: isNil(lte) ? '' : lte.toISOString(),
        },
      ];

      return params;

    case TableFilterTypeEnum.Number:
      gte = filter.value?.gte;
      lte = filter.value?.lte;

      if (!isNumber(gte) && !isNumber(lte)) return null;

      params = [
        {
          id: `q[${filter.id}_gteq]`,
          value: isUndefined(gte) ? '' : gte,
        },
        {
          id: `q[${filter.id}_lteq]`,
          value: isUndefined(lte) ? '' : lte,
        },
      ];

      return params;

    case TableFilterTypeEnum.SingleSelect:
      if (!filter.value) return null;

      return {
        id: `q[${filter.id}_eq]`,
        value: filter.value[0],
      };

    case TableFilterTypeEnum.Select:
      if (!filter.value) return null;

      if (size(filter.value) > 1) {
        return map(
          (value) => ({
            id: `q[${filter.id}_in][]`,
            value,
          }),
          filter.value
        );
      } else {
        return {
          id: `q[${filter.id}_eq]`,
          value: filter.value[0],
        };
      }

    case TableFilterTypeEnum.Boolean:
      if (isNil(filter.value)) return null;

      return {
        id: `q[${filter.id}]`,
        value: filter.value,
      };

    case TableFilterTypeEnum.Text:
    default:
      return {
        id: `q[${filter.id}_i_cont]`,
        value: filter.value,
      };
  }
}

// Define an enumeration type for the filter types
enum FilterTypeEnum {
  GreaterThan = 'gteq', // Greater than or equal to
  LessThan = 'lteq', // Less than or equal to
  In = 'in', // In a list of values
  Contains = 'cont', // Contains a string
  Eq = 'eq', // Equals to a value
  Boolean = '', // Boolean filter of template q[<filterId>]=true/false
}

// Define an interface for the filter object
interface FilterType {
  key: string; // The ID of the filter
  type: FilterTypeEnum; // The type of the filter
}

/**
 * Extracts the filter ID and type from a URL parameter template.
 *
 * @param param The URL parameter template to extract the filter ID and type from
 * @returns An object containing the filter ID and type, or undefined if the filter could not be
 *   extracted
 */
function extractFilterKeyFromUrlParam(param: string): FilterType | undefined {
  // Boolean filters have no "_{filter}" at the search param (e.g. q[claimed]=true)
  // Thus we need to match both optional and defined filter types
  // Using optional only, will not return the filter type for non-boolean filters (e.g.
  // q[created_at_gteq] -> match[2] is undefined)
  const optionalFilterTypeRegex = /q\[([^[]+)(_(gteq|lteq|in|i_cont|eq))?\]/;
  const definedFilterTypeRegex = /q\[([^[]+)_(gteq|lteq|in|i_cont|eq)\]/;

  const optionalMatch = param.match(optionalFilterTypeRegex);
  const definedMatch = param.match(definedFilterTypeRegex);

  const match = definedMatch?.[2] ? definedMatch : optionalMatch;

  if (match) {
    // Return an object with the filterId and filterType
    return {
      key: match[1],
      type: match[2] as FilterTypeEnum,
    };
  }

  return undefined;
}

function extractSortByFilterFromURLParam(sortByValue: string): string {
  const match = sortByValue.match(/^([\s\S]+?)\s/);

  if (match) {
    return match[1];
  } else {
    return '';
  }
}

// Return adjusted table filter value from a given URL param value & its column type
const getFieldValueFromUrlParamValue = ({
  paramValue,
  fieldType,
}: {
  paramValue: string;
  fieldType?: TableFilterTypeEnum;
  fieldFilterType: FilterTypeEnum;
}) => {
  switch (fieldType) {
    case TableFilterTypeEnum.Date:
      return paramValue ? new Date(paramValue) : null;
    case TableFilterTypeEnum.Number:
      return Number(paramValue);
    case TableFilterTypeEnum.Select:
    default:
      return paramValue;
  }
};

// Helper util for turning a URL search param into a filter value
// Examples:
// - '?q[amount_gteq]=1&q[amount_lteq]=2' => { id: 'amount', value: { gte: 1, lte: 2 } }
// - '?q[name_i_cont][]=Speakers' => { id: 'name', value: 'speakers }
const pushFilterValueFromUrlParam = (
  urlParamKey: string,
  urlParamValue: string,
  filterValues: Array<{
    id: string;
    value: string | { gte: Date | number; lte?: Date | number } | Array<string>;
  }>,
  columns: Array<TableColumn>
) => {
  const fieldFilter = extractFilterKeyFromUrlParam(urlParamKey);
  const fieldType = find({ dataField: fieldFilter?.key }, columns)?.filter
    ?.type;
  const fieldValue = getFieldValueFromUrlParamValue({
    paramValue: urlParamValue,
    fieldType,
    fieldFilterType: fieldFilter?.type,
  });

  if (
    fieldFilter?.type === FilterTypeEnum.GreaterThan ||
    fieldFilter?.type === FilterTypeEnum.LessThan ||
    fieldFilter?.type === FilterTypeEnum.In ||
    fieldFilter?.type === FilterTypeEnum.Eq
  ) {
    const filterIndex = findIndex({ id: fieldFilter?.key }, filterValues);

    if (
      fieldType === TableFilterTypeEnum.Date ||
      fieldType === TableFilterTypeEnum.Number
    ) {
      if (filterIndex === -1) {
        filterValues.push({
          id: fieldFilter?.key,
          value: {
            gte: fieldValue as Date | number,
          },
        });
      } else {
        // @ts-ignore
        filterValues[filterIndex].value.lte = fieldValue;
      }
    } else if (fieldType === TableFilterTypeEnum.Select) {
      if (filterIndex === -1) {
        filterValues.push({
          id: fieldFilter?.key,
          value: [fieldValue as string],
        });
      } else {
        (filterValues[filterIndex].value as Array<string>).push(
          fieldValue as string
        );
      }
    }
  } else {
    filterValues.push({
      id: fieldFilter?.key,
      value: fieldValue as string,
    });
  }
};

// Returns table's filter values from URL params
export const getTableStateFromSearchParams = <TData extends object>({
  urlSearch,
  columns,
  pageSize = RESULTS_PER_PAGE,
}: {
  urlSearch: string;
  columns: Array<TableColumn<TData>>;
  pageSize: number;
}) => {
  let adjustedUrlSearchParams = urlSearch;

  if (startsWith('?', adjustedUrlSearchParams)) {
    adjustedUrlSearchParams = adjustedUrlSearchParams.slice(1);
  }

  const urlSearchParams = new URLSearchParams(adjustedUrlSearchParams);

  const initialTableState: Partial<
    Pick<TableState<TData>, 'sortBy' | 'filters' | 'pageIndex' | 'pageSize'>
  > = {
    pageIndex: 0,
    pageSize,
  };

  urlSearchParams.forEach((value, key) => {
    if (key === 'per_page') return;
    else if (key === 'q[s]') {
      initialTableState.sortBy = [
        {
          id: extractSortByFilterFromURLParam(value),
          desc: endsWith('desc', urlSearchParams.get('q[s]')),
        },
      ];
    } else if (key === 'page') {
      initialTableState.pageIndex = toNumber(urlSearchParams.get('page')) - 1;
    } else {
      initialTableState.filters ||= [];

      pushFilterValueFromUrlParam(
        key,
        value,
        initialTableState.filters,
        columns
      );
    }
  });

  return initialTableState;
};

// Builds a request URL from a table state
export const buildUrlFromTableState = <TData extends object>({
  url,
  columns,
  tableState,
}: {
  url: string;
  tableState: Partial<
    Pick<TableState<TData>, 'sortBy' | 'filters' | 'pageIndex' | 'pageSize'>
  >;
  columns?: Array<TableColumn<TData>>;
}) => {
  const requestUrl = new URL(url);

  const tableStateSearchParams = getSearchParamsFromTableState(
    tableState,
    columns
  );

  forEach(({ key, value }) => {
    requestUrl.searchParams.append(key, value);
  }, tableStateSearchParams);

  return requestUrl.toString();
};

// Returns URL search params for a given table state
// Columns are used to determine filter types - text, date, number...
export const getSearchParamsFromTableState = <TData extends object>(
  {
    filters,
    sortBy,
    pageIndex,
    pageSize,
  }: Partial<
    Pick<TableState<TData>, 'sortBy' | 'filters' | 'pageIndex' | 'pageSize'>
  >,
  columns?: Array<TableColumn>
) => {
  const searchParams: Array<{ key: string; value: string }> = [];

  // Column filters params
  forEach((filter) => {
    const column = find({ dataField: filter?.id }, columns);
    const filterSearchParams = castArray(getFilterSearchParams(filter, column));

    forEach((currFilter) => {
      if (currFilter) {
        searchParams.push({ key: currFilter.id, value: currFilter.value });
      }
    }, filterSearchParams);
  }, filters);

  // Columns sort by params
  forEach(({ id, desc }) => {
    searchParams.push({
      key: 'q[s]',
      value: `${id} ${desc ? 'desc' : 'asc'}`,
    });
  }, sortBy);

  searchParams.push({ key: 'page', value: toString(pageIndex + 1) });
  searchParams.push({ key: 'per_page', value: toString(pageSize) });

  return searchParams;
};
