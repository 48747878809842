import { Box, TextInput, Tooltip, useMantineTheme } from '@mantine/core';
import classNames from 'classnames';
import { contains, noop, toNumber } from 'lodash/fp';
import React, {
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { contextMenu } from 'react-contexify';
import { createPortal } from 'react-dom';
import styled from 'styled-components';
import 'react-contexify/dist/ReactContexify.css';

import {
  SpaceTreeNodeType,
  useCreateSpace,
  useSpace,
  useUpdateSpace,
} from '@portals/api/organizations';
import { IncidentsBySpaceIdTooltip } from '@portals/framework';
import { ReactComponent as Danger } from '@portals/icons/bulk/danger.svg';
import { ReactComponent as Maintenance } from '@portals/icons/linear/maintenance.svg';
import { ReactComponent as Menu } from '@portals/icons/linear/menu.svg';
import { ReactComponent as Snooze } from '@portals/icons/linear/snooze.svg';
import {
  generateUniqueDefaultSpaceNameInSiblings,
  getSpaceHighestIncidentPriority,
  getStyledThemeColor,
  suppressPropagation,
} from '@portals/utils';

import { noAccess, canEdit, canView } from '../../../../lib/access';
import { useTreeContext } from '../space-tree.context';
import { NodeContextMenu } from './NodeContextMenu';

export interface TreeNodeProps {
  handleSelected: (node: SpaceTreeNodeType) => void;
  node: SpaceTreeNodeType;
  contextMenuPortalRef: MutableRefObject<HTMLDivElement>;
  isInFocus: boolean;
  newSpaceId: number | null;
  setNewSpaceId: (id: number | null) => void;
  isEditable: boolean;
}

const UNSORTED_SPACE_NAME = 'Unsorted';

export function TreeNode({
  node,
  isInFocus,
  contextMenuPortalRef,
  newSpaceId,
  setNewSpaceId,
  handleSelected,
  isEditable,
}: TreeNodeProps) {
  const theme = useMantineTheme();
  const space = useSpace(node.id);
  const updateSpace = useUpdateSpace();
  const createSpace = useCreateSpace();

  const [isEditMode, setIsEditMode] = useState(false);
  const [isContextMenuOpen, setIsContextMenuOpen] = useState(false);

  const isSnoozed = space?.state?.snoozed;
  const isMaintenance = space?.state?.maintenance;

  const { expandedNodes } = useTreeContext();
  const isExpanded = contains(node.id, expandedNodes);

  const [nameInput, setNameInput] = useState(node.title);
  const isInputChanged = node.title !== nameInput;

  const saveUpdatedSpaceName = useCallback(async () => {
    if (nameInput && isInputChanged) {
      try {
        await updateSpace.mutateAsync({
          spaceId: node.id,
          updatedSpace: {
            name: nameInput,
          },
        });
      } catch (error) {
        setNameInput(node.title);
      }
    } else {
      setNameInput(node.title);
    }

    setIsEditMode(false);
  }, [isInputChanged, nameInput, node.title, node.id, updateSpace]);

  useEffect(
    function automaticallySetNewSpaceToEditMode() {
      if (toNumber(newSpaceId) === toNumber(node.id) && canEdit(node)) {
        const nodeName = node.title;

        if (nodeName === UNSORTED_SPACE_NAME) return;

        setIsEditMode(true);
        setNewSpaceId(null);
        handleSelected(node);
      }
    },
    [handleSelected, newSpaceId, node, setNewSpaceId]
  );

  const onCreateSpace = useCallback(() => {
    if (!canEdit(node)) return;

    createSpace.mutate({
      parentSpaceId: node.id,
      newSpace: {
        name: generateUniqueDefaultSpaceNameInSiblings(node.children),
      },
    });
  }, [createSpace, node]);

  const nodeTitle = useMemo(() => {
    const icons = [];

    if (!isMaintenance && isSnoozed) {
      icons.push(
        <Tooltip label="Snoozed" withArrow>
          <Snooze
            width={15}
            height={15}
            style={{ marginRight: theme.spacing.xs }}
            key="tree-node-bell-icon"
          />
        </Tooltip>
      );
    } else if (isMaintenance)
      icons.push(
        <Tooltip label="Maintenance" withArrow>
          <Maintenance
            width={15}
            height={15}
            style={{ marginRight: theme.spacing.xs }}
            key="tree-node-alert-icon"
          />
        </Tooltip>
      );

    return (
      <div
        className={classNames('tree-node-content', {
          'text-muted': isSnoozed || !canView(node),
        })}
      >
        {noAccess(node) ? icons : null}

        <div className="tree-node-label" title={node.title}>
          {/*
            Input value & space name will be different only if name was edited & saved via API,
            meaning we're waiting for server response to update the name in store. Displaying the
            INPUT value as space's name while waiting for update, to prevent flickering of old
            name to new name
          */}
          {nameInput !== node.title ? nameInput : node.title}
        </div>
      </div>
    );
  }, [isMaintenance, isSnoozed, nameInput, node, theme]);

  const onMenuToggle = useCallback(
    (event) => contextMenu.show({ event, id: node.id }),
    [node]
  );

  const onSearchInputKeyDown = useCallback((event) => {
    if (event.key === 'Enter' || event.key === 'Escape') {
      event.target.blur();
    }
  }, []);

  const onSearchInputChange = useCallback(
    (e) => setNameInput(e.target.value),
    []
  );

  // Select all text for easy replacement upon editing mode toggle
  const onRef = useCallback((inputRef: HTMLInputElement) => {
    if (!inputRef) return;

    inputRef.select();
  }, []);

  const shouldDisplayIncidentsAlert = useMemo(() => {
    if (!space || noAccess(space)) return false;

    const incidentsTotal = isExpanded
      ? space.state?.local_incidents?.total
      : space.state?.incidents?.total;

    if (!incidentsTotal || incidentsTotal < 1) return false;

    return incidentsTotal;
  }, [isExpanded, space]);

  if (!space) return null;

  return (
    <>
      <Container
        onDoubleClick={() =>
          isEditable && canEdit(node) ? setIsEditMode(true) : noop
        }
        className={classNames('tree-item selectable', {
          focused: isInFocus,
          'context-menu-open': !isInFocus && isContextMenuOpen,
        })}
      >
        <Title className="title">
          {!isEditMode || !canEdit(node) ? (
            nodeTitle
          ) : (
            <TextInput
              className="inline-space-name-input"
              autoFocus
              ref={onRef}
              value={nameInput}
              onChange={onSearchInputChange}
              onKeyDown={onSearchInputKeyDown}
              onBlur={saveUpdatedSpaceName}
              styles={{
                wrapper: {
                  input: {
                    border: 'none',
                    height: 24,
                    minHeight: 24,
                    padding: '0 5px',
                  },
                },
              }}
            />
          )}
        </Title>

        {shouldDisplayIncidentsAlert ? (
          <IncidentsBySpaceIdTooltip space={space} isLocal={isExpanded}>
            <Box
              sx={{
                svg: {
                  '*': {
                    fill: theme.colors[
                      getSpaceHighestIncidentPriority(
                        isExpanded
                          ? space.state.local_incidents
                          : space.state.incidents
                      )
                    ][4],
                  },
                },
              }}
            >
              <Danger width={17} height={17} />
            </Box>
          </IncidentsBySpaceIdTooltip>
        ) : null}

        <div className="context-menu-toggle">
          {isEditable && canEdit(node) ? (
            <Menu onClick={suppressPropagation(onMenuToggle)} />
          ) : null}
        </div>
      </Container>

      {contextMenuPortalRef.current
        ? createPortal(
            <NodeContextMenu
              setIsContextMenuOpen={setIsContextMenuOpen}
              nodeId={node.id}
              onEditToggle={() => setIsEditMode(true)}
              onCreateSpace={onCreateSpace}
            />,
            contextMenuPortalRef.current
          )
        : null}
    </>
  );
}

const Container = styled.div`
  width: 100%;
  z-index: 1;
  position: relative;
  display: grid;
  grid-template-columns: 1fr max-content max-content max-content;
  box-sizing: border-box;

  .context-menu-toggle {
    opacity: 0;
    padding: 0 5px;
    color: ${getStyledThemeColor('gray600')};
    transition: opacity 0.15s ease-in-out;

    svg {
      width: 12px;
      height: 12px;
    }
  }

  &:hover {
    .context-menu-toggle {
      opacity: 1;
    }
  }

  &.focused {
    .title {
      font-weight: bold;
      color: ${getStyledThemeColor('primary')};
    }
  }

  &.context-menu-open {
    &:before {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      height: 1px;
      width: 100%;
      background-color: ${getStyledThemeColor('gray400')};
    }
  }
`;

const Title = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  flex: 1;
  width: 100%;

  .tree-node-content {
    flex: 1;
    overflow: hidden;
    display: flex;
    align-items: center;
    max-width: 100%;

    .tree-node-label {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
`;
