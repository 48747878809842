import { Select } from '@mantine/core';
import React, { FC, useMemo } from 'react';

import { FieldRendererProps } from '@portals/types';

import { normalizeSelectFieldOptions } from './select-field.utils';

export const SelectField: FC<FieldRendererProps> = ({
  error,
  field,
  value,
  handleBlur,
  clearable,
  disabled,
  setFieldValue,
  readOnly,
  required,
}) => {
  const { name, title, options, placeholder, inputProps = {} } = field;
  const { startAdornment, endAdornment, ...restInputProps } = inputProps || {};

  const adjustedOptions = useMemo(() => {
    return normalizeSelectFieldOptions(options);
  }, [options]);

  return (
    <Select
      error={error}
      data-testid={'select-' + name}
      withinPortal={false}
      data={adjustedOptions}
      required={required}
      readOnly={readOnly}
      clearable={clearable}
      onChange={(value) => setFieldValue?.(field.name, value)}
      disabled={disabled || readOnly}
      placeholder={placeholder || title}
      label={title}
      id={name}
      value={value}
      onBlur={handleBlur}
      icon={startAdornment}
      rightSection={endAdornment}
      dropdownPosition="flip"
      searchable
      {...restInputProps}
    />
  );
};
