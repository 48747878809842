import { Button, Group, Modal } from '@mantine/core';
import React from 'react';

import {
  FileUploaderDropzone,
  FileUploaderDropzoneProps,
  useFileUploaderDropzone,
} from '@portals/core';

import { ModalProps } from '../../components/Modals';

export interface UploadFileProps
  extends ModalProps<{
    title: string;
    onSuccess?: (filesUrls: Array<string>) => void;
    dropzoneProps: FileUploaderDropzoneProps['dropzoneProps'];
  }> {}

export function UploadFile({ closeMe, data }: UploadFileProps) {
  const { files, setFiles, isLoading, isFilesEmpty } =
    useFileUploaderDropzone();
  const { title, dropzoneProps, onSuccess } = data;

  const onSubmit = () => {
    const fileUrls = files.map((file) => file.fileUrl as string);

    onSuccess?.(fileUrls);

    closeMe();
  };

  return (
    <Modal opened onClose={closeMe} title={title || 'Upload File'} size="xl">
      <FileUploaderDropzone
        dropzoneProps={dropzoneProps}
        files={files}
        setFiles={setFiles}
      />

      <Group position="right" mt="lg">
        <Button variant="default" onClick={closeMe}>
          Cancel
        </Button>

        <Button onClick={onSubmit} disabled={isFilesEmpty || isLoading}>
          Done
        </Button>
      </Group>
    </Modal>
  );
}
