import { createStyles, CSSObject } from '@mantine/core';
import { useClickOutside } from '@mantine/hooks';
import { motion, MotionProps } from 'framer-motion';
import { isEmpty } from 'lodash/fp';
import React, { ReactNode } from 'react';
import { useSelector } from 'react-redux';

import { StateType, TableDetailsPanelType } from '@portals/types';

const SLIDE_ANIMATION: MotionProps = {
  initial: { x: 400, opacity: 0 },
  animate: { x: 0, opacity: 1 },
  exit: { x: 400, opacity: 0 },
  transition: { duration: 0.3 },
};

interface TableDetailsPanelProps {
  onClose: () => void;
  children: ReactNode;
  type: TableDetailsPanelType['type'];
  width?: CSSObject['width'];
  className?: string;
}

export function TableDetailsPanel({
  type,
  width = 400,
  className,
  onClose,
  children,
}: TableDetailsPanelProps) {
  const { classes, cx } = useStyles({ type, width });
  const modals = useSelector<StateType>((state) => state.modals);

  const ref = useClickOutside(() => {
    // Don't close the details panel when a click has happened inside a modal.
    // when a modal was opened from within a details panel, we don't want a click event inside that modal will close the panel.
    if (!isEmpty(modals)) return;

    onClose();
  });

  return (
    <motion.div
      ref={ref}
      className={cx(
        'table-details-panel-container',
        classes.container,
        className
      )}
      {...SLIDE_ANIMATION}
    >
      {children}
    </motion.div>
  );
}

const useStyles = createStyles(
  (
    theme,
    {
      type,
      width,
    }: {
      type: TableDetailsPanelProps['type'];
      width: TableDetailsPanelProps['width'];
    }
  ) => {
    const innerTypeContainerStyles: CSSObject = {
      position: 'absolute',
      top: 0,
      height: '100%',
      border: `1px solid ${theme.colors.gray[3]}`,
    };

    const pageTypeContainerStyles: CSSObject = {
      position: 'fixed',
      top: `${theme.other.navbarHeight}px`,
      height: `calc(100% - ${theme.other.navbarHeight}px)`,
      borderLeft: `1px solid ${theme.colors.gray[3]}`,
    };

    return {
      container: {
        ...(type === 'inner'
          ? innerTypeContainerStyles
          : pageTypeContainerStyles),
        right: 0,
        zIndex: 6,
        width,
        backgroundColor: theme.white,
        boxShadow: '6px 0px 41px 0px rgba(0, 0, 0, 0.13)',
        // Using clip-path to show the box shadow on the left side of the panel.
        clipPath: 'inset(0 0 0 calc(-100vw + 100%))',
      },
    };
  }
);
