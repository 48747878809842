import { ActionIcon, createStyles, Tooltip } from '@mantine/core';
import React from 'react';

import { ReactComponent as Lifebuoy } from '@portals/icons/linear/lifebuoy.svg';

import {
  useCommonDesign,
  useCommonOrganizationConfig,
} from '../../../hooks/portal-config';

export function ContactSupport() {
  const design = useCommonDesign();
  const organizationConfig = useCommonOrganizationConfig();

  const { classes } = useStyles(design?.navigation_menu?.menu_text_color);

  const isCustomDomain = window.location.host.endsWith('.on-xyte.com');

  if (!isCustomDomain || !organizationConfig?.partner_support_email) {
    return null;
  }

  return (
    <Tooltip label="Contact support">
      <ActionIcon
        component="a"
        variant="transparent"
        size="lg"
        href={`mailto:${organizationConfig.partner_support_email}`}
        className={classes.actionIcon}
      >
        <Lifebuoy />
      </ActionIcon>
    </Tooltip>
  );
}

const useStyles = createStyles((theme, color: string | null | undefined) => ({
  actionIcon: {
    color: color || theme.colors.blue_gray[6],

    '&:hover': {
      color: color || theme.colors.blue_gray[6],
    },
  },
}));
