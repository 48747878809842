export const camelToPretty = (str) =>
  str
    .replace(/([A-Z])/g, ' $1') // insert a space before all caps
    .replace(/^./, (str) => str.toUpperCase()); // uppercase the first character

export const snakeToPretty = (str) => str.toLowerCase().replace('_', ' ');

export const upcaseFirst = (str) =>
  str.charAt(0).toUpperCase() + str.substring(1);

export const validEmail = (email) => {
  // noinspection RegExpRedundantEscape
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};
