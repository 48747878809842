import { Box, createStyles, Grid, Stack } from '@mantine/core';
import React, { useState } from 'react';

import { VerticalScrollBar } from '@portals/scrollbar';
import { camelToPretty } from '@portals/utils';

const ObjectFieldTemplate = ({ schema, properties }) => {
  const { classes, cx } = useStyles();
  const [selected, setSelected] = useState(0);
  const schemaProperties = schema.properties;
  const currProperty = properties[selected];
  const allObject = Object.keys(schemaProperties).every(
    (key) => typeof schemaProperties[key] === 'object'
  );

  if (!allObject) {
    return (
      <div>{properties.filter((prop) => prop).map((prop) => prop.content)}</div>
    );
  }

  return (
    <Grid columns={12} gutter={32} p={0} className={classes.container}>
      <Grid.Col span={4} pb={0}>
        <VerticalScrollBar
          renderView={(props) => <Stack spacing="xs" p={0} {...props} />}
        >
          {properties.map(({ name }, index) => (
            <Box
              key={name}
              onClick={() => setSelected(index)}
              className={cx(classes.category, { active: index === selected })}
            >
              {camelToPretty(name)}
            </Box>
          ))}
        </VerticalScrollBar>
      </Grid.Col>

      <Grid.Col span={8} pb={0}>
        <VerticalScrollBar renderView={(props) => <Stack p={0} {...props} />}>
          <Box sx={{ maxWidth: 470 }}>{currProperty.content}</Box>
        </VerticalScrollBar>
      </Grid.Col>
    </Grid>
  );
};

const useStyles = createStyles((theme) => ({
  container: {
    height: '100%',
  },
  category: {
    width: '100%',
    height: 44,
    padding: `${theme.spacing.md} ${theme.spacing.lg}`,
    color: theme.colors.blue_gray[7],
    fontSize: theme.fontSizes.sm,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: 114,
    cursor: 'pointer',
    textTransform: 'capitalize',
    transition: 'all 0.15s ease-in-out',

    '&.active': {
      backgroundColor: theme.fn.rgba(theme.colors.blue[0], 0.5),
      color: theme.colors.blue_accent[4],
    },

    '&:hover': {
      '&:not(.active)': {
        backgroundColor: theme.colors.gray[0],
      },
    },
  },
}));

export default ObjectFieldTemplate;
