import { SET_ROUTE, RELOAD_PAGE } from '../constants';

export const setRoute = (route, replace = false) => ({
  type: SET_ROUTE,
  payload: {
    route,
    replace,
  },
});

export const reloadPage = () => ({
  type: RELOAD_PAGE,
});
