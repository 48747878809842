import { Modal } from '@mantine/core';
import { useHotkeys } from '@mantine/hooks';
import React, { lazy, Suspense, useState } from 'react';

const ThemePreview = lazy(() => import('./ThemePreview'));

const LABELS = {
  ThemePreview: 'Theme Preview',
};

const DevTools = () => {
  const [openModal, setOpenModal] = useState(null);

  useHotkeys([['mod+U', () => setOpenModal('ThemePreview')]]);

  return (
    <Modal
      opened={!!openModal}
      onClose={() => setOpenModal(false)}
      title={LABELS[openModal]}
      styles={{
        content: {
          width: '60%',
        },
      }}
    >
      {openModal === 'ThemePreview' ? (
        <Suspense fallback={null}>
          <ThemePreview />
        </Suspense>
      ) : null}
    </Modal>
  );
};

export default DevTools;
