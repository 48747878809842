import {
  createStyles,
  Group,
  Image,
  NumberInput,
  NumberInputProps,
  Text,
} from '@mantine/core';
import React from 'react';

import { CURRENCIES_FLAGS } from '@portals/countries';
import { CurrencyCode } from '@portals/types';
import { formatNumber, getCurrencySign } from '@portals/utils';

import { useConvertedCurrencyInput } from '../hooks/currency-input';

const PRECISION = 2;

export interface CurrencyRowProps {
  value: NumberInputProps['value'];
  onChange: NumberInputProps['onChange'];
  error?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  currencyCode: CurrencyCode;
  isDefaultCurrency: boolean;
  className?: string;
}

export function CurrencyRow({
  value,
  onChange,
  error,
  disabled,
  readOnly,
  currencyCode,
  isDefaultCurrency,
  className,
}: CurrencyRowProps) {
  const { cx, classes } = useStyles();
  const currencySign = getCurrencySign(currencyCode);
  const [adjustedValue, onChangeHandler] = useConvertedCurrencyInput(
    currencyCode,
    value,
    onChange
  );

  return (
    <Group noWrap spacing="xs" className={cx(classes.container, className)}>
      <Image
        width={30}
        height={22}
        radius="sm"
        src={CURRENCIES_FLAGS[currencyCode]}
      />

      <Text size="sm">
        <span>{currencyCode}</span>
        {isDefaultCurrency && (
          <Text component="span" color="blue_gray.3">
            &nbsp;(default)
          </Text>
        )}
      </Text>

      <Group
        noWrap
        className={classes.inputWrapper}
        spacing={readOnly ? 0 : 'md'}
      >
        <Text color="gray.6">{currencySign}</Text>

        {readOnly ? (
          <Text data-testid="currency-row-price-value">
            {formatNumber({ value: adjustedValue || 0 })}
          </Text>
        ) : (
          <NumberInput
            hideControls
            size="xs"
            precision={PRECISION}
            error={error}
            disabled={disabled}
            value={adjustedValue}
            onChange={onChangeHandler}
            classNames={{ input: classes.input }}
            data-testid="currency-input"
          />
        )}
      </Group>
    </Group>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    paddingBlock: theme.spacing.lg,
    borderBottom: `1px solid ${theme.colors.gray[2]}`,
  },
  inputWrapper: {
    marginLeft: 'auto',
  },
  input: {
    width: 100,
    fontSize: theme.fontSizes.sm,
  },
}));
