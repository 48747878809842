import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { toastrError, toastrSuccess } from '@portals/redux/actions/toastr';
import { TableColumn, TableState } from '@portals/types';

import { useApiQuery } from '../../hooks';
import { fetchApiRequest, useRequestOptions } from '../../utils';
import { usePaginatedTableApiQuery } from '../../utils/paginated-table';
import { globalQueryKeys } from '../global-query-keys';
import { spacesQueryKeys } from '../spaces';
import {
  DEVICES_API_URL,
  devicesQueryKeys,
  getDeviceApiUrl,
} from './devices.constants';
import { DeviceDetailsType, DeviceType } from './devices.types';

interface UseMoveDeviceParams {
  space_id: number;
  device_id: string;
}

export function useDevices(
  tableState: Pick<
    TableState<DeviceType>,
    'sortBy' | 'filters' | 'pageIndex' | 'pageSize'
  >,
  columns: Array<TableColumn>,
  baseUrl: string = DEVICES_API_URL,
  queryKey = devicesQueryKeys.all()
) {
  return usePaginatedTableApiQuery<DeviceType>({
    baseUrl,
    queryKey: [...queryKey, baseUrl, tableState],
    tableState,
    columns,
  });
}

export function useDevice(deviceId: string, isEnabled = true) {
  return useApiQuery<DeviceDetailsType>(
    `${DEVICES_API_URL}/${deviceId}`,
    devicesQueryKeys.details(deviceId),
    {
      enabled: !!deviceId && isEnabled,
      cacheTime: 0,
    }
  );
}

export function useMoveDevice() {
  const queryClient = useQueryClient();
  const { url, options } = useRequestOptions({
    url: '',
    method: 'POST',
  });

  return useMutation<void, { error: any }, UseMoveDeviceParams>({
    mutationFn: ({ space_id, device_id }) =>
      fetchApiRequest(`${url}/${getDeviceApiUrl(device_id)}/move`, {
        ...options,
        body: JSON.stringify({
          space_id,
        }),
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(globalQueryKeys.devices);
      queryClient.invalidateQueries(globalQueryKeys.incidents);
      queryClient.invalidateQueries(spacesQueryKeys.base);
    },
  });
}

interface UseBulkDeleteDevicesParams {
  device_ids: Array<string>;
}

export function useBulkDeleteDevices() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { url, options } = useRequestOptions({
    url: '',
    method: 'POST',
  });

  return useMutation<void, { error: any }, UseBulkDeleteDevicesParams>({
    mutationFn: ({ device_ids: ids }) =>
      fetchApiRequest(`${url}/${DEVICES_API_URL}/bulk_destroy`, {
        ...options,
        body: JSON.stringify({ ids }),
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(globalQueryKeys.devices);
      queryClient.invalidateQueries(globalQueryKeys.incidents);
      queryClient.invalidateQueries(spacesQueryKeys.base);
    },
    onError: ({ error }: { error: string }) => {
      dispatch(toastrError(error));
    },
  });
}

export function useAddDevice() {
  const queryClient = useQueryClient();
  const { url, options } = useRequestOptions({
    url: '',
    method: 'POST',
  });

  return useMutation<DeviceType, { error: any }, Partial<DeviceType>>({
    mutationFn: (device) =>
      fetchApiRequest(`${url}/${DEVICES_API_URL}`, {
        ...options,
        body: JSON.stringify(device),
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(globalQueryKeys.devices);
      queryClient.invalidateQueries(globalQueryKeys.incidents);
      queryClient.invalidateQueries(spacesQueryKeys.base);
    },
  });
}

export function useSnoozeDevice(deviceId: string) {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { url, options } = useRequestOptions({
    url: getDeviceApiUrl(deviceId),
    method: 'PUT',
  });

  return useMutation({
    mutationFn: (minutes: number) => {
      return fetchApiRequest(`${url}/snooze`, {
        ...options,
        body: JSON.stringify({ minutes }),
      });
    },
    onSuccess: () => {
      dispatch(toastrSuccess('Device snoozed successfully'));

      queryClient.invalidateQueries(globalQueryKeys.devices);
    },
    onError: ({ error }: { error: string }) => {
      dispatch(toastrError(error));
    },
  });
}

export function useUnsnoozeDevice(deviceId: string) {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { url, options } = useRequestOptions({
    url: getDeviceApiUrl(deviceId),
    method: 'PUT',
  });

  return useMutation({
    mutationFn: () => {
      return fetchApiRequest(`${url}/unsnooze`, options);
    },
    onSuccess: () => {
      dispatch(toastrSuccess('Device unsnoozed successfully'));

      queryClient.invalidateQueries(globalQueryKeys.devices);
    },
    onError: ({ error }: { error: string }) => {
      dispatch(toastrError(error));
    },
  });
}
