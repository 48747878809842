import { Box, createStyles } from '@mantine/core';
import React from 'react';

import { AuthenticationGuard } from '../components/AuthenticationGuard';
import { GlobalSubscription } from '../components/GlobalSubscription';
import { Navbar } from '../components/layout/navbar/Navbar';
import { Sidebar } from '../components/layout/sidebar';
import Main from '../components/Main';
import Onboarded from '../components/Onboarded';
import { usePermissionAccess } from '../components/permission-access/use-permission-access';
import { QuickNavProvider } from '../components/QuickNav';
import { useAppConfig } from '../context';
import { useHasSupportSeat } from '../hooks/support-seats';
import { Page404 } from '../pages/auth/Page404';

interface DashboardProps {
  children: React.ReactNode;
}

export function DashboardLayout({ children }: DashboardProps) {
  const { classes, cx } = useStyles();
  const { extraLayout = {}, tenantType } = useAppConfig();
  const hasSupportSeat = useHasSupportSeat();
  const { isMissingAllPermissions, isSuperAdmin } = usePermissionAccess();

  const {
    dashboardDataLayout: DataLayout,
    globalLayout,
    options = {},
  } = extraLayout;

  return (
    <AuthenticationGuard>
      <QuickNavProvider>
        <Box className={cx(classes.wrapper, 'page-wrapper')}>
          {DataLayout ? (
            <DataLayout>
              <Onboarded>
                {options.isMobile ? null : <Sidebar />}

                <Main style={{ position: 'relative', zIndex: 1 }}>
                  <Navbar />
                  <div className={cx(classes.content, 'page-content')}>
                    {isMissingAllPermissions &&
                    !isSuperAdmin &&
                    !hasSupportSeat ? (
                      <Page404
                        hideActionButton
                        hideImage
                        title="Missing permissions"
                        description="We're sorry, it looks like you don't have the necessary permissions to access this page. Please reach out to your administrator for assistance."
                      />
                    ) : (
                      <>{children}</>
                    )}
                  </div>

                  {globalLayout ? globalLayout() : null}

                  <GlobalSubscription tenantType={tenantType} />
                </Main>
              </Onboarded>
            </DataLayout>
          ) : (
            <Onboarded>
              {options.isMobile ? null : <Sidebar />}

              <Main style={{ position: 'relative', zIndex: 1 }}>
                <Navbar />
                <div className={cx(classes.content, 'page-content')}>
                  {isMissingAllPermissions &&
                  !isSuperAdmin &&
                  !hasSupportSeat ? (
                    <Page404
                      hideActionButton
                      hideImage
                      title="Missing permissions"
                      description="We're sorry, it looks like you don't have the necessary permissions to access this page. Please reach out to your administrator for assistance."
                    />
                  ) : (
                    <>{children}</>
                  )}
                </div>

                {globalLayout ? globalLayout() : null}

                <GlobalSubscription tenantType={tenantType} />
              </Main>
            </Onboarded>
          )}
        </Box>
      </QuickNavProvider>
    </AuthenticationGuard>
  );
}

const useStyles = createStyles(() => ({
  wrapper: {
    height: '100vh',
    overflow: 'hidden',
  },
  content: {
    flex: 1,
    overflow: 'auto',
    position: 'relative',
  },
}));
