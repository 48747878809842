import {
  ActionIcon,
  Box,
  createStyles,
  Group,
  Stack,
  Tooltip,
} from '@mantine/core';
import React from 'react';
import { Link } from 'react-router-dom';

import { DeviceDetailsType } from '@portals/api/organizations';
import { TextWithTooltip } from '@portals/core';
import { ReactComponent as Snooze } from '@portals/icons/linear/snooze.svg';
import { DeviceType as CommonDeviceType } from '@portals/types';
import { prettyTime } from '@portals/utils';

import { DeviceAvatar } from '../../../../components/DeviceAvatar';
import { DeviceStatusBadge } from '../../../../components/DeviceStatusBadge';
import { useDeviceContext } from '../context';
import { DeviceName } from './DeviceName';

interface HeaderProps<TDevice extends DeviceDetailsType | CommonDeviceType> {
  device: TDevice;
}

export function Header<TDevice extends DeviceDetailsType | CommonDeviceType>({
  device,
}: HeaderProps<TDevice>) {
  const { classes } = useStyles();
  const deviceContext = useDeviceContext();

  return (
    <Box className={classes.container}>
      <DeviceAvatar
        className={classes.avatar}
        img={device.model_settings?.media?.img}
        icon={device.model_settings?.icon}
      />

      <Stack spacing={0} justify="space-between">
        <DeviceName device={device} />

        <TextWithTooltip className={classes.location}>
          <Link to={`/overview/${device.space_id}`}>
            {device.space_tree_path_name}
          </Link>
        </TextWithTooltip>

        <Group p={0} align="center" sx={{ height: 30 }}>
          <DeviceStatusBadge status={device.status} />

          {device.snoozed_until ? (
            <Tooltip
              position="right"
              label={
                <Stack spacing={0}>
                  <span>{`Incident tracking is off until ${prettyTime(
                    device.snoozed_until
                  )}`}</span>
                  <span>Click to turn it on</span>
                </Stack>
              }
            >
              <ActionIcon
                variant="transparent"
                onClick={() => deviceContext.onUnsnoozeDevice()}
                sx={(theme) => ({
                  svg: {
                    width: 17,
                    color: theme.colors.blue_gray[7],
                  },
                })}
              >
                <Snooze />
              </ActionIcon>
            </Tooltip>
          ) : null}
        </Group>
      </Stack>
    </Box>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: '77px 1fr',
    width: '100%',
    minHeight: 77,
    gap: 16,

    [theme.fn.largerThan(1600)]: {
      gridTemplateColumns: '100px 1fr',
      minHeight: 100,
    },
  },
  avatar: {
    width: '100%',
    height: '100%',
  },
  location: {
    fontSize: theme.fontSizes.xs,

    [theme.fn.largerThan(1600)]: {
      fontSize: theme.fontSizes.md,
    },
  },
  status: {
    fontWeight: 400,
    color: theme.colors.gray[9],
    fontSize: theme.fontSizes.xs,
  },
}));
