import {
  Badge,
  createStyles,
  Group,
  Stack,
  Text,
  Tooltip,
} from '@mantine/core';
import React from 'react';

import { OverflowGroup } from './OverflowGroup';

interface OverflowBadgeGroupProps {
  badges: string[];
}

export function OverflowBadgeGroup({ badges }: OverflowBadgeGroupProps) {
  const { classes } = useStyles();

  return (
    <OverflowGroup
      overflowRenderer={({ amount, hiddenIndexes }) => (
        <Tooltip
          withArrow
          withinPortal
          label={
            <Stack spacing="xs">
              {hiddenIndexes.map((hiddenIndex) => (
                <Text key={hiddenIndex}>{badges[hiddenIndex]}</Text>
              ))}
            </Stack>
          }
        >
          <Badge className={classes.badge}>+ {amount}</Badge>
        </Tooltip>
      )}
    >
      <Group noWrap sx={{ overflow: 'hidden' }} spacing="xs">
        {badges.map((item) => (
          <Badge key={item} className={classes.badge}>
            {item}
          </Badge>
        ))}
      </Group>
    </OverflowGroup>
  );
}

const useStyles = createStyles(() => ({
  badge: {
    overflow: 'unset',
    textOverflow: 'unset',
    textTransform: 'unset',
  },
}));
