import { Button, Group, Modal, Radio, Stack } from '@mantine/core';
import React, { PropsWithChildren, useState } from 'react';
import { FilterProps, UseFiltersColumnProps } from 'react-table';

interface BooleanFilterProps<TData extends object> {
  closeMe: () => void;
  data: {
    column: FilterProps<TData>['column'] & UseFiltersColumnProps<TData>;
    options: {
      all: string;
      true: string;
      false: string;
    };
    placeholder?: string;
  };
}

function BooleanFilter<TData extends object>({
  closeMe,
  data,
}: PropsWithChildren<BooleanFilterProps<TData>>) {
  const { column, options } = data;

  const [localFilterState, setLocalFilterState] = useState(
    column.filterValue || 'all'
  );

  const onClear = () => {
    column.setFilter(null);
    closeMe();
  };

  const onSubmit = () => {
    let value = localFilterState;

    if (localFilterState === 'all') value = null;

    column.setFilter(value);
    closeMe();
  };

  return (
    <Modal
      opened={true}
      onClose={closeMe}
      size="lg"
      padding={32}
      title={`Filter by '${data.column.Header}'`}
    >
      <Stack>
        <Radio.Group
          value={localFilterState}
          onChange={setLocalFilterState}
          data-testid="boolean-filter"
        >
          <Stack>
            <Radio
              value="all"
              label={options.all}
              data-testid={`boolean-filter-${options.all}`}
            />
            <Radio
              value="true"
              label={options.true}
              data-testid={`boolean-filter-${options.true}}`}
            />
            <Radio
              value="false"
              label={options.false}
              data-testid={`boolean-filter-${options.false}`}
            />
          </Stack>
        </Radio.Group>

        <Group position="right" mt="md">
          <Button
            variant="subtle"
            color="blue_gray"
            size="sm"
            onClick={closeMe}
            data-testid="boolean-filter-cancel-btn"
          >
            Cancel
          </Button>

          <Button
            variant="outline"
            color="blue_gray"
            size="sm"
            onClick={onClear}
            data-testid="boolean-filter-clear-btn"
          >
            Clear Filters
          </Button>

          <Button
            size="sm"
            onClick={onSubmit}
            data-testid="boolean-filter-apply-btn"
          >
            Apply Filters
          </Button>
        </Group>
      </Stack>
    </Modal>
  );
}

export default BooleanFilter;
