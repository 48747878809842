import {
  Box,
  createStyles,
  LoadingOverlay,
  Stack,
  StackProps,
  Switch,
  Text,
} from '@mantine/core';
import { find, noop } from 'lodash/fp';
import React from 'react';

import { WIDGET_ICONS } from '../../widgets.constants';
import { WidgetColorType, WidgetIconNameType } from '../../widgets.types';

export interface ToggleWidgetProps {
  title: string;
  iconName: WidgetIconNameType;
  color: WidgetColorType;

  isChecked: boolean;
  onToggle?: () => void;
  isLoading?: boolean;

  stackProps?: StackProps;
}

export function ToggleWidget({
  title,
  iconName,
  color,

  isChecked,
  isLoading = false,
  onToggle = noop,

  stackProps = {},
}: ToggleWidgetProps) {
  const { classes, theme } = useStyles();

  const widgetIcon = find({ iconName }, WIDGET_ICONS);

  const Icon = widgetIcon?.Icon || WIDGET_ICONS[0].Icon;
  const themeColor = theme.fn.themeColor(color);

  return (
    <Stack
      className={classes.container}
      p="xl"
      spacing="sm"
      pos="relative"
      h="100%"
      w="100%"
      bg="white"
      justify="center"
      {...stackProps}
    >
      <LoadingOverlay visible={isLoading} />

      <Box
        sx={{
          color: themeColor,
        }}
      >
        <Icon height={26} width={26} />
      </Box>

      <Text
        size="md"
        data-testid="dashboard-toggle-widget-name"
        color="gray.5"
        truncate
      >
        {title}
      </Text>

      <Switch size="lg" checked={isChecked} onChange={onToggle} color={color} />
    </Stack>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    borderRadius: theme.radius.lg,
  },
}));
