import React from 'react';
import { Button, ButtonProps, Spinner, SpinnerProps } from 'reactstrap';
import { Dispatch } from 'redux';
import styled from 'styled-components';

export type NetworkButtonProps = {
  readonly disabled?: boolean;
  readonly reloading?: boolean;
  readonly className?: string;
  readonly size?: string;
  readonly color?: string;
  readonly type?: 'button' | 'reset' | 'submit';
  readonly children: React.ReactNode;
  readonly spinnerProps?: SpinnerProps;
  readonly dispatch?: Dispatch;
} & ButtonProps;

export const NetworkButton: React.FC<NetworkButtonProps> = ({
  disabled = false,
  reloading = false,
  children,
  className,
  size = 'md',
  color = 'info',
  type = 'submit',
  dispatch,
  spinnerProps = {
    size: 'sm',
    color: 'light',
  },
  ...rest
}) => (
  <StyledButton
    size={size}
    color={color}
    type={type}
    disabled={reloading || disabled}
    className={className}
    $reloading={reloading}
    {...rest}
  >
    <span className="network-button-content-wrapper">{children}</span>

    {reloading ? (
      <SpinnerWrapper>
        <Spinner {...spinnerProps} className="network-button-spinner" />
      </SpinnerWrapper>
    ) : null}
  </StyledButton>
);

const StyledButton = styled(Button)<{ reloading: boolean } & ButtonProps>`
  position: relative;

  ${({ reloading }) =>
    reloading &&
    `
    .network-button-content-wrapper {
      opacity: 0;
    }
  `}
`;

const SpinnerWrapper = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;
