import {
  AvatarProps,
  Box,
  createStyles,
  Group,
  Indicator,
  IndicatorProps,
  Text,
  Tooltip,
} from '@mantine/core';
import React, { FC } from 'react';

import { RelatedDevice } from '@portals/api/organizations';

import { DeviceAvatar } from '../../../../../components/DeviceAvatar';
import { useOpenRouteModal } from '../../../../route-modals.hooks';
import {
  getDeviceName,
  getDeviceStatusColor,
  getDeviceStatusText,
} from '../../utils';

interface DeviceAvatarProps {
  device: RelatedDevice;
  avatarProps?: AvatarProps;
}

export const DeviceAvatarWithIndicator: FC<DeviceAvatarProps> = ({
  device,
  avatarProps = {},
}) => {
  const { classes } = useStyles();
  const openRouteModal = useOpenRouteModal();
  const statusColor = getDeviceStatusColor(device.state?.status);

  return (
    <Indicator
      inline
      withBorder
      size={20}
      styles={indicatorStyles}
      color={statusColor.indicator}
      onClick={() =>
        openRouteModal({ modalId: 'device', pathParams: [device.id] })
      }
    >
      <Tooltip
        withArrow
        arrowSize={5}
        position="bottom"
        label={
          <Group noWrap>
            <Text size="xs">{getDeviceName(device.name)}</Text>
            <Text size="xs" className={classes.statusText}>
              {getDeviceStatusText(device.state?.status)}
            </Text>
          </Group>
        }
      >
        <Box>
          <DeviceAvatar
            img={device.model_settings?.media?.img}
            icon={device.model_settings?.icon}
            {...avatarProps}
          />
        </Box>
      </Tooltip>
    </Indicator>
  );
};

const useStyles = createStyles((theme) => ({
  statusText: {
    fontSize: theme.fontSizes.xs,
    color: theme.colors.blue_gray[2],
    textTransform: 'capitalize',
  },
}));

const indicatorStyles: IndicatorProps['styles'] = () => ({
  root: { cursor: 'pointer', zIndex: 0 },
  indicator: { borderWidth: 5 },
});
