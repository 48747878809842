import { size } from 'lodash/fp';

import { OrganizationPortalCapabilities, TenantType } from '@portals/types';

import { useAppConfig } from '../../../../context';
import { useCommonPortalCapabilities } from '../../../../hooks/portal-config';
import { useCurrentUserAccessibleTenants } from '../../../../hooks/users';
import { usePermissionAccess } from '../../../permission-access/use-permission-access';

export const useShouldDisplayTenantMenu = () => {
  const { tenantType } = useAppConfig();
  const { isSuperAdmin } = usePermissionAccess();
  const accessibleTenants = useCurrentUserAccessibleTenants();
  const portalCapabilities = useCommonPortalCapabilities();

  const isPartner = tenantType === TenantType.Partner;

  if (!isPartner) {
    if ((portalCapabilities as OrganizationPortalCapabilities)?.b2c_view) {
      return false;
    }
  }

  const numOfAccessibleTenants = size(accessibleTenants);

  const hasMultipleTenants = numOfAccessibleTenants > 1;
  const canCreateTenant = isPartner && isSuperAdmin;

  return isSuperAdmin || hasMultipleTenants || canCreateTenant;
};
