import {
  Avatar,
  Badge,
  Box,
  createStyles,
  Text,
  useMantineTheme,
} from '@mantine/core';
import React from 'react';

import { DesignType } from '@portals/types';

import { useCommonDesign } from '../../../../hooks/portal-config';

interface UserAvatar {
  isActive?: boolean;
  logoSrc?: string;
}

export function UserAvatar({ isActive = false, logoSrc }: UserAvatar) {
  const theme = useMantineTheme();
  const design = useCommonDesign();

  let borderColor = 'transparent';

  if (isActive) {
    borderColor =
      design?.navigation_menu?.selected_section_background_color ||
      theme.colors.blue_accent[4];
  }

  return (
    <Avatar
      className="tenant-menu-avatar"
      radius="md"
      p={3}
      styles={() => ({
        root: {
          width: 44,
          height: 44,
          background: 'transparent',
          transition: 'border 0.1s ease-in-out',
          border: `2px solid ${borderColor}`,
        },
        image: {
          width: '100%',
          height: '100%',
          objectFit: 'contain',
        },
      })}
      src={logoSrc || 'logo_full_dark.svg'}
    />
  );
}

interface UserName {
  name: string;
  isLab?: boolean;
  partner?: string;
}

export function UserName({ name, isLab = false, partner }: UserName) {
  const design = useCommonDesign();
  const { cx, classes } = useNameStyles({
    isLab,
    partner,
    design,
  });

  return (
    <Box className={cx('tenant-user-menu', classes.container)}>
      <Text
        size="md"
        weight={700}
        title={name}
        sx={(theme) => ({
          color:
            design?.navigation_menu?.company_text_color ||
            theme.colors.blue_gray[8],
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        })}
      >
        {name}
      </Text>

      {isLab ? (
        <Badge
          size="xs"
          radius="sm"
          variant="filled"
          sx={{
            flexShrink: 0,
          }}
        >
          LAB
        </Badge>
      ) : null}
    </Box>
  );
}

export const useNameStyles = createStyles(
  (
    theme,
    {
      partner,
      isLab,
      design,
    }: {
      partner?: string;
      isLab?: boolean;
      design?: DesignType;
    }
  ) => ({
    container: {
      whiteSpace: 'nowrap',
      transition: 'color 0.1s ease-in-out',
      position: 'relative',
      display: 'grid',
      gridTemplateColumns: isLab ? 'auto max-content' : '1fr',
      gap: theme.spacing.xs,
      justifyContent: 'flex-start',
      alignItems: 'center',

      ...(partner
        ? {
            transform: 'translateY(-9px)',

            ':after': {
              content: `"by ${partner}"`,
              position: 'absolute',
              left: 0,
              width: '100%',
              transform: 'translateY(100%)',
              fontSize: theme.fontSizes.xs,
              color: theme.fn.lighten(
                design?.navigation_menu?.company_text_color ||
                  theme.colors.blue_gray[8],
                0.4
              ),
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            },
          }
        : {}),
    },
  })
);
