import { Box, createStyles, Divider, Group } from '@mantine/core';
import { motion } from 'framer-motion';
import { isFunction } from 'lodash/fp';
import React, { FC, useMemo, useRef } from 'react';
import { NavLink, useRouteMatch } from 'react-router-dom';
import { Badge } from 'reactstrap';

import { useTranslation } from '@portals/i18n';
import {
  CommonFeatureFlagsType,
  CommonPortalCapabilities,
  CommonPricingPlanFeatures,
  ComponentRendererType,
  DesignType,
  RouteType,
} from '@portals/types';

import {
  useCommonDesign,
  useCommonFeatureFlags,
  useCommonPortalCapabilities,
  useCommonPricingPlanFeatures,
} from '../../../hooks/portal-config';
import { usePermissionAccess } from '../../permission-access/use-permission-access';

type SidebarItemProps = Omit<
  RouteType<
    CommonPortalCapabilities,
    CommonFeatureFlagsType,
    CommonPricingPlanFeatures
  >,
  'component' | 'children'
> & {
  to: string;
  isSidebarOpen: boolean;
};

interface LinkBodyProps {
  id?: string;
  href?: string;
  allPaths?: string | Array<string>;
  icon?: ComponentRendererType;
  badgeComponent?: ComponentRendererType;
  indicatorComponent?: ComponentRendererType;
  badgeColor?: string;
  badgeText?: string;
  label?: string;
  isSidebarOpen: boolean;
}

const LinkBody: FC<LinkBodyProps> = ({
  icon: Icon,
  label,
  badgeComponent,
  indicatorComponent: Indicator,
  badgeColor,
  badgeText,
  isSidebarOpen,
}) => {
  const icon = useMemo(() => {
    if (Indicator && Icon && !isSidebarOpen) {
      return (
        <Indicator>
          <Icon size={18} />
        </Indicator>
      );
    }

    return Icon ? <Icon size={18} /> : null;
  }, [Icon, Indicator, isSidebarOpen]);

  if (!isSidebarOpen) {
    return icon;
  }

  return (
    <Group spacing={0} sx={{ width: '100%' }} position="apart">
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {icon}

        <Box ml="sm">{label}</Box>
      </Box>

      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {badgeComponent && React.createElement(badgeComponent)}

        {badgeColor && badgeText ? (
          <Badge color={badgeColor} size={18}>
            {badgeText}
          </Badge>
        ) : null}
      </Box>
    </Group>
  );
};

export const SidebarItem: FC<SidebarItemProps> = ({
  path,
  id,
  badgeComponent,
  indicatorComponent,
  badgeColor,
  badgeText,
  icon,
  to,
  hasBorder,
  isSidebarOpen,
}) => {
  const portalCapabilities = useCommonPortalCapabilities();
  const featureFlags = useCommonFeatureFlags();
  const pricingPlanFeatures = useCommonPricingPlanFeatures();
  const design = useCommonDesign();
  const { isAdmin } = usePermissionAccess();
  const { classes, cx } = useStyles({ design });

  const adjustedPath = isFunction(path)
    ? path({ featureFlags, portalCapabilities, pricingPlanFeatures, isAdmin })
    : path;

  const isMatch = useRouteMatch({ path: adjustedPath, exact: true });
  const t = useTranslation();
  const label = t(id || '');
  const containerRef = useRef(null);

  return (
    <motion.div
      layout="position"
      ref={containerRef}
      className={cx(classes.container, {
        active: !!isMatch,
        open: isSidebarOpen,
      })}
    >
      {hasBorder ? <Divider className={classes.divider} my="lg" /> : null}

      <NavLink
        to={to}
        className="sidebar-link"
        data-testid={`sidebar-item-${id}`}
      >
        <LinkBody
          icon={icon}
          label={label}
          badgeColor={badgeColor}
          badgeText={badgeText}
          badgeComponent={badgeComponent}
          indicatorComponent={indicatorComponent}
          isSidebarOpen={isSidebarOpen}
        />
      </NavLink>
    </motion.div>
  );
};

const useStyles = createStyles((theme, { design }: { design: DesignType }) => ({
  container: {
    transition: 'padding 0.1s linear',
    marginBottom: '5px',
    position: 'relative',
    padding: 0,

    '.sidebar-link': {
      fontSize: theme.fontSizes.sm,
      fontWeight: 600,
      alignItems: 'center',
      width: '100%',
      padding: '10px 16px',
      borderRadius: theme.radius.md,
      display: 'flex',
      textDecoration: 'none',
      cursor: 'pointer',
      transition:
        'background-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, color 0.15s ease-in-out',

      // Branding
      background: design?.navigation_menu?.menu_background_color || theme.white,
      color:
        design?.navigation_menu?.menu_text_color || theme.colors.blue_gray[6],

      'i, svg, path': {
        transition: 'stroke 0.15s ease-in-out',

        stroke:
          design?.navigation_menu?.menu_text_color || theme.colors.blue_gray[6],
      },

      svg: {
        height: 24,
        width: 24,
      },

      '&:focus': {
        outline: 'none',
      },

      '&:hover': {
        boxShadow: '0 0 1px rgba(0, 0, 0, 0.04)',
        color:
          design?.navigation_menu?.hovered_section_text_color ||
          theme.colors.blue_gray[6],
        background:
          design?.navigation_menu?.hovered_section_background_color ||
          theme.colors.gray[1],

        'i, svg, path': {
          stroke:
            design?.navigation_menu?.hovered_section_text_color ||
            theme.colors.gray[6],
        },
      },
    },

    '&.active': {
      fontWeight: 600,

      '.sidebar-link': {
        backgroundColor:
          design?.navigation_menu?.selected_section_background_color ||
          theme.colors.blue_accent[4],
        color:
          design?.navigation_menu?.selected_section_text_color || theme.white,

        'i, svg, path': {
          stroke:
            design?.navigation_menu?.selected_section_text_color || theme.white,
        },
      },
    },

    '&.open': {
      padding: '0 16px',
    },

    '&:not(.open)': {
      '.sidebar-link': {
        height: 44,
        width: 44,
        margin: '5px 0 5px 16px',
        padding: '12px 10px 10px',
      },
    },
  },
  divider: {
    borderColor: theme.colors.blue_gray[0],
  },
}));
