import {
  createStyles,
  Group,
  LoadingOverlay,
  Slider,
  Stack,
  StackProps,
  Text,
} from '@mantine/core';
import { isUndefined } from 'lodash';
import React, { useEffect, useState } from 'react';

import { WidgetColorType } from '../../widgets.types';

type OnChangeCallback = (value: number) => void | Promise<void>;

export interface BarControllerWidgetProps {
  title: string;
  value: number;
  unit?: string | null;
  min: number;
  max: number;
  gradient: { from: WidgetColorType; to: WidgetColorType };
  onChange: OnChangeCallback;
  isLoading: boolean;
  stackProps?: StackProps;
}

export function BarControllerWidget({
  title,
  value,
  unit,
  min,
  max,
  gradient,
  onChange,
  isLoading,
  stackProps = {},
}: BarControllerWidgetProps) {
  const { classes } = useStyles();
  const [localValue, setLocalValue] = useState(
    isUndefined(value) || isNaN(value) ? min : value
  );

  const getValue = () => {
    if (isUndefined(localValue) || isNaN(localValue)) {
      return min;
    }

    if (!unit) return localValue;

    return `${localValue}${unit}`;
  };

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  return (
    <Stack
      className={classes.container}
      p="xl"
      h="100%"
      w="100%"
      bg="white"
      justify="center"
      pos="relative"
      spacing="xl"
      {...stackProps}
    >
      <LoadingOverlay visible={isLoading} />

      <Group w="100%" position="apart" align="start">
        <Text
          size="md"
          data-testid="dashboard-bar-controller-widget-name"
          color="gray.5"
        >
          {title}
        </Text>

        <Text color="gray.8" sx={{ fontSize: 28 }}>
          {getValue()}
        </Text>
      </Group>

      <Slider
        value={max - localValue}
        onChange={setLocalValue}
        onChangeEnd={onChange}
        size="xl"
        label={null}
        min={min}
        max={max}
        thumbSize={28}
        styles={(theme) => ({
          // Had to do some hacking here, since the Slider component doesn't support
          // gradient background
          root: {
            transform: 'rotateY(180deg)',
          },
          track: {
            height: 20,

            '&:before': {
              background: theme.fn.linearGradient(
                90,
                theme.fn.themeColor(gradient.to),
                theme.fn.themeColor(gradient.from)
              ),
            },
          },
          bar: {
            background: theme.colors.gray[2],
          },
          thumb: {
            background: theme.white,
            boxShadow: '0px 1px 11px rgba(10, 8, 106, 0.16)',
            border: 'none',

            ':active': {
              boxShadow: '0px 1px 11px rgba(10, 8, 106, 0.25)',
            },
          },
        })}
      />
    </Stack>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    borderRadius: theme.radius.lg,
  },
}));
