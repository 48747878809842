import { ActionIcon } from '@mantine/core';
import { motion } from 'framer-motion';
import { noop } from 'lodash/fp';
import React, {
  FC,
  InputHTMLAttributes,
  ReactNode,
  useCallback,
  useState,
} from 'react';
import { Spinner } from 'reactstrap';
import styled from 'styled-components';

import { ReactComponent as CloseX } from '@portals/icons/linear/close-x.svg';
import { getStyledThemeColor } from '@portals/utils';

export enum LabelType {
  Fixed = 'fixed',
  Animated = 'animated',
}

export type InputProps = {
  id: string;
  label: string;
  value: string;
  height?: number;
  errorMessage?: string;
  onChange: (value: string, id: string) => void;
  isError?: boolean;
  isPending?: boolean;
  isDisabled?: boolean;
  className?: string;
  placeholder?: string;
  iconRenderer?: ({
    isPending,
    isError,
    value,
    isDisabled,
    isFocused,
  }: {
    isPending: boolean;
    isError: boolean;
    value: string;
    isDisabled: boolean;
    isFocused: boolean;
  }) => ReactNode;
  inputProps: InputHTMLAttributes<HTMLInputElement>;
  isClearable?: boolean;
  labelType: LabelType;
  onFocus?: (id: string) => void;
  onBlur?: (id: string) => void;
  onClear?: (id: string) => void;
};

const ICON_TRANSITION = {
  opacity: { duration: 0.1 },
  right: { duration: 0.15 },
};

const getIconAnimation = (isActive: boolean) => ({
  right: isActive ? 15 : -15,
  opacity: isActive ? 1 : 0,
});

export const Input: FC<InputProps> = ({
  value,
  placeholder,
  onChange,
  height = 45,
  isError,
  isPending,
  isDisabled,
  id,
  label,
  className = '',
  inputProps = {},
  isClearable,
  errorMessage,
  iconRenderer,
  labelType = LabelType.Fixed,
  onFocus = noop,
  onBlur = noop,
  onClear = noop,
}) => {
  const [isFocused, setIsFocused] = useState(false);

  const onChangeWrapper = useCallback(
    (event) => {
      if (isDisabled) return;

      onChange(event.target.value, id);
    },
    [isDisabled, onChange, id]
  );

  const onFocusWrapper = useCallback(() => {
    setIsFocused(true);
    onFocus(id);
  }, [id, setIsFocused, onFocus]);

  const onBlurWrapper = useCallback(() => {
    setIsFocused(false);
    onBlur(id);
  }, [id, setIsFocused, onBlur]);

  const onClearWrapper = useCallback(() => {
    onChange('', id);

    onClear(id);
  }, [onChange, id, onClear]);

  const isLabelPlaceholder =
    labelType !== LabelType.Fixed && !placeholder && !isFocused && !value;

  return (
    <Wrapper className={className}>
      {errorMessage && !isFocused ? (
        <ErrorMessage
          className="error-message"
          style={{ opacity: isError ? 1 : 0 }}
        >
          {errorMessage}
        </ErrorMessage>
      ) : null}

      <Container
        className="input-container"
        style={{ height }}
        isFocused={isFocused}
        isError={isError}
        isDisabled={isDisabled || isPending}
        isPending={isPending}
        isClearable={isClearable}
        isFixedLabel={labelType === LabelType.Fixed}
        hasValue={!!value}
      >
        <Label className="label" isPlaceholder={isLabelPlaceholder}>
          {label}
        </Label>

        {iconRenderer ? (
          <IconWrapper className="icon-wrapper">
            {iconRenderer({ isPending, isError, value, isDisabled, isFocused })}
          </IconWrapper>
        ) : null}

        {isPending ? (
          <AnimatedIconWrapper className="pending-wrapper" isActive>
            <Spinner color="primary" className="pending-icon" />
          </AnimatedIconWrapper>
        ) : null}

        <StyledInput
          {...inputProps}
          placeholder={placeholder}
          value={value}
          onChange={onChangeWrapper}
          className="input"
          onFocus={onFocusWrapper}
          onBlur={onBlurWrapper}
        />

        {isClearable && !isPending ? (
          <AnimatedIconWrapper
            isActive={!!value}
            style={{ cursor: 'pointer' }}
            className="clear-icon"
          >
            <ActionIcon
              size={15}
              color="gray"
              variant="transparent"
              onClick={onClearWrapper}
              id="search-remove-icon"
            >
              <CloseX />
            </ActionIcon>
          </AnimatedIconWrapper>
        ) : null}
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  padding: 10px 0;
  display: flex;
  align-items: center;
`;

const Container = styled.div<{
  isFocused: boolean;
  isError: boolean;
  isPending: boolean;
  isDisabled: boolean;
  isClearable: boolean;
  isFixedLabel: boolean;
  hasValue: boolean;
}>`
  border-radius: 6px;
  font-size: 16px;
  box-sizing: border-box !important;
  background: ${getStyledThemeColor('white')};
  box-shadow: inset 0 0 0 1px ${getStyledThemeColor('gray200')};
  transition: all 0.15s ease-in-out;
  display: flex;
  padding: 0
    ${({ isClearable, isPending }) => (isClearable || isPending ? 40 : 15)}px
    10px 15px;
  align-items: flex-end;
  position: relative;
  width: 100%;
  height: calc(100% - 20px);

  .icon-wrapper {
    margin-right: 10px;
    transition: all 0.15s ease-in-out;
  }

  ${({ isFixedLabel, theme, hasValue }) =>
    isFixedLabel &&
    `
    .label {
      top: 0;
      transform: translateY(-100%);
      padding-bottom: 5px;
      left: 1px;
      ${hasValue ? `color: ${theme.color.primary}` : ''}
    }
  `}

  ${({ isDisabled }) =>
    isDisabled &&
    `
    opacity: 0.75;
    pointer-events: none;
  `}

  ${({ isError, theme }) =>
    isError &&
    `
    box-shadow: inset 0 0 0 2px ${theme.color.danger};

    .label {
      color: ${theme.color.danger};
    }
  `}

  ${({ isFocused, theme }) =>
    isFocused &&
    `
    box-shadow: inset 0 0 0 2px ${theme.color.primary};

    .label {
      color: ${theme.color.primary};
    }
  `}
`;

const Label = styled.div<{ isPlaceholder: boolean }>`
  position: absolute;
  top: 10px;
  left: 15px;
  font-size: 13px;
  color: ${getStyledThemeColor('gray500')};
  transition: all 0.15s ease-in-out;
  transform: translateY(0%);

  ${({ isPlaceholder }) =>
    isPlaceholder &&
    `
    font-size: 15px;
    top: 50%;
    transform: translateY(-50%);
  `}
`;

const StyledInput = styled.input`
  outline: none;
  border: none;
  padding: 0;
  width: 100%;
  height: 25px;
  font-size: 16px;
  letter-spacing: 0.02em;
  color: ${getStyledThemeColor('dark')};
  background-color: transparent;

  ::placeholder {
    color: ${getStyledThemeColor('gray500')};
  }
`;

const IconWrapper = styled.div``;

const AnimatedIconWrapper = styled(motion.div).attrs(
  ({ isActive }: { isActive: boolean }) => ({
    transition: ICON_TRANSITION,
    initial: getIconAnimation(isActive),
    animate: getIconAnimation(isActive),
  })
)<{ isActive: boolean }>`
  position: absolute;
  display: flex;
  align-items: center;
  height: 100%;
  top: 0;

  .pending-icon {
    width: 1.25rem;
    height: 1.25rem;
  }
`;

const ErrorMessage = styled.div`
  padding-top: 5px;
  position: absolute;
  bottom: 0;
  left: 1px;
  opacity: 0;
  color: ${getStyledThemeColor('danger')};
  transition: opacity 0.15s ease-in-out;
  font-size: 13px;
`;
