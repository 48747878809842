import { Box, createStyles, Group } from '@mantine/core';
import { find, get, last, split } from 'lodash/fp';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Link, Redirect, useParams } from 'react-router-dom';

import {
  DeviceDetailsType,
  SpaceType,
  useDevice,
  useSpaces,
  useUpdateDevice,
} from '@portals/api/organizations';
import { PageBreadcrumbs } from '@portals/core';
import { ModalButton } from '@portals/framework';
import { getDeviceName } from '@portals/framework/route-modals';
import { ReactComponent as Location } from '@portals/icons/linear/location.svg';
import { toastrSuccess } from '@portals/redux/actions/toastr';
import { InfoTable } from '@portals/table';
import { ShortenedString } from '@portals/ui';
import { timeAgo } from '@portals/utils';

import { DeviceName } from '../../../../components/common/DeviceName';
import { PageContainer, PageSpinner } from '../../../../components/layout/Page';
import { canEdit } from '../../../../lib/access';

const getSpaceName = (space: SpaceType) => {
  if (!space) return;

  return last(split('/', space?.tree_path_name));
};

export const Device = () => {
  const dispatch = useDispatch();
  const spaces = useSpaces();
  const params = useParams<{ device_id: string }>();
  const device = useDevice(params.device_id);
  const updateDevice = useUpdateDevice();
  const { classes } = useStyles();

  const onUpdateDeviceName = useCallback(
    async (spaceId, deviceId, device: Partial<DeviceDetailsType>) => {
      await updateDevice.mutateAsync({
        spaceId: spaceId,
        deviceId: deviceId,
        updatedDevice: device,
      });

      dispatch(toastrSuccess('Device name updated'));
    },
    [dispatch, updateDevice]
  );

  if (!device.isFetched || !spaces.isFetched) return <PageSpinner />;

  const space = find({ id: device?.data?.space_id }, spaces.data);

  const spaceName = getSpaceName(space);

  if (!device) return <Redirect to="/devices" />;

  return (
    <PageContainer>
      <Box className={classes.content}>
        <Box py="lg" px="md">
          <PageBreadcrumbs
            crumbs={[
              {
                label: 'Devices',
                to: '/devices',
              },
              { label: getDeviceName(device.data?.name) },
            ]}
          />
        </Box>

        <Box className={classes.list} p="xs">
          <InfoTable.Table>
            {device.data?.parent_device ? (
              <InfoTable.Row
                label="Parent"
                value={
                  <div className="d-flex align-items-center">
                    <Link
                      to={`/overview/${device.data?.parent_device?.space_id}/devices/${device.data?.parent_device?.id}`}
                      className="mr-1"
                    >
                      {device.data?.parent_device?.name || 'Unknown'}
                    </Link>

                    {spaces.isFetched && (
                      <>
                        <span className="mr-1">{' in '}</span>

                        <Link
                          to={`/overview/${device.data?.parent_device?.space_id}`}
                        >
                          {find(
                            { id: device.data?.parent_device?.space_id },
                            spaces.data
                          )?.name || 'Unknown'}
                        </Link>
                      </>
                    )}
                  </div>
                }
              />
            ) : null}

            <InfoTable.Row
              label="Name"
              value={
                <DeviceName
                  device={device.data}
                  setDeviceDetails={onUpdateDeviceName}
                />
              }
            />

            <InfoTable.Row
              label="Vendor"
              value={device.data?.partner?.vendor || '--'}
            />
            <InfoTable.Row
              label="Model"
              value={device.data?.partner?.model || '--'}
            />

            <InfoTable.Row
              label={
                <Group position="apart">
                  <span>Space</span>

                  {canEdit(space) ? (
                    <ModalButton
                      modalName="MoveDevice"
                      data={{ device: device.data }}
                      label="Move"
                      leftIcon={<Location />}
                      variant="default"
                      sx={{
                        svg: {
                          width: 14,
                          height: 14,
                        },
                      }}
                    />
                  ) : null}
                </Group>
              }
              value={
                <Link to={`/claim/${device.data?.space_id}`} className="mr-1">
                  {spaceName}
                </Link>
              }
            />

            <InfoTable.Row label="Type" value={device.data?.type} />

            <InfoTable.Row
              label="Last Seen"
              value={
                device.data?.last_seen
                  ? timeAgo(Date.parse(device.data?.last_seen))
                  : 'Never'
              }
            />
            <InfoTable.Row
              label="Firmware Version"
              value={device.data?.firmware.version || '--'}
            />
            <InfoTable.Row
              label="MAC Address"
              value={device.data?.partner.mac || '--'}
            />
            <InfoTable.Row
              label="Serial Number"
              value={device.data?.partner.sn || '--'}
            />
            <InfoTable.Row
              label="Cloud ID"
              value={device.data?.partner.cloud_id || '--'}
            />

            <InfoTable.Row
              label="Config"
              value={
                !device.data?.config || !get('config.version', device)
                  ? 'Unknown'
                  : device.data?.config_version === device.data?.config.version
                  ? 'Up to date'
                  : `${
                      device.data?.config.version - device.data?.config_version
                    } versions behind`
              }
            />

            <InfoTable.Row label="UUID" value={device.data?.id || '--'} />

            {device.data?.connection_info ? (
              <>
                <InfoTable.Row
                  label="Access Key"
                  value={
                    <ShortenedString
                      str={device.data?.connection_info.access_key || '--'}
                      size={10}
                    />
                  }
                />
                <InfoTable.Row
                  label="Assigned server"
                  value={device.data?.connection_info.hub_url || '--'}
                />
                <InfoTable.Row
                  label="Assigned static cert server"
                  value={
                    device.data?.connection_info.hub_url_static_cert || '--'
                  }
                />
                <InfoTable.Row
                  label="Assigned mqtt server"
                  value={device.data?.connection_info.mqtt_hub_url || '--'}
                />
              </>
            ) : null}

            <InfoTable.Row label="Driver" value={device.data?.driver || '--'} />
          </InfoTable.Table>
        </Box>
      </Box>
    </PageContainer>
  );
};

const useStyles = createStyles((theme) => ({
  content: {
    height: '100%',
    display: 'grid',
    gridTemplateRows: 'min-content 1fr min-content',
    position: 'fixed',
  },
  list: {
    border: `1px solid ${theme.colors.gray[3]}`,
    overflow: 'auto',

    '.info-table': {
      '.info-table-row': {
        display: 'block',

        '.edit-device-name-container': {
          flexDirection: 'column',
          alignItems: 'start',

          '>div': {
            flex: 1,
            width: '100%',
          },
        },
      },
    },
  },
}));
