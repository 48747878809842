import { uiGlobalQueryKeys } from '../global-query-keys';

export const USERS_API_URL = 'ui/users';

export const usersQueryKeys = {
  global: [...uiGlobalQueryKeys.users],
  all: () => [...usersQueryKeys.global, USERS_API_URL],
  self: () => [...usersQueryKeys.all(), 'self'],
  welcomeDetails: () => ['welcome_details'],
};
