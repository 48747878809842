import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { useAuth } from '@portals/redux';
import { signedIn } from '@portals/redux/actions/auth';
import { setRoute } from '@portals/redux/actions/routing';
import { toastrError, toastrSuccess } from '@portals/redux/actions/toastr';
import { AuthType, TenantType } from '@portals/types';

import { useApiQuery } from '../../hooks';
import { ServerError } from '../../types';
import { fetchApiRequest, useRequestOptions } from '../../utils';
import { USERS_API_URL, usersQueryKeys } from './users.constants';
import {
  SelfUserResponseType,
  UserResponseType,
  WelcomeDetailsType,
} from './users.types';

export function useUsers() {
  return useApiQuery<UserResponseType[]>(USERS_API_URL, usersQueryKeys.all());
}

export function useCurrentUser() {
  const auth = useAuth();

  return useApiQuery<SelfUserResponseType>(
    `${USERS_API_URL}/self`,
    usersQueryKeys.self(),
    {
      enabled: Boolean(auth),
    }
  );
}

interface UseCreateUserParams {
  email: string;
  name?: string;
  addToLab?: boolean;
  groupIds?: string[];
}

export function useCreateUser() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { url, options } = useRequestOptions({
    url: USERS_API_URL,
    method: 'POST',
  });

  return useMutation<UserResponseType, ServerError, UseCreateUserParams>({
    mutationFn: ({ name, email, groupIds, addToLab }) => {
      return fetchApiRequest(url, {
        ...options,
        body: JSON.stringify({
          name,
          email,
          add_to_lab: addToLab,
          group_ids: groupIds,
        }),
      });
    },
    onSuccess: () => {
      dispatch(toastrSuccess('User created successfully'));

      queryClient.invalidateQueries(usersQueryKeys.all());
    },
    onError: ({ error }: { error: string }) => {
      dispatch(toastrError(error));
    },
  });
}

export function useResendWelcomeEmail() {
  const dispatch = useDispatch();

  const { url, options } = useRequestOptions({
    url: USERS_API_URL,
    method: 'POST',
  });

  return useMutation<void, ServerError, { userId: string }>({
    mutationFn: ({ userId }) => {
      return fetchApiRequest(`${url}/${userId}/resend_welcome_email`, options);
    },
    onSuccess: () => {
      dispatch(toastrSuccess('Welcome email sent successfully'));
    },
    onError: ({ error }: { error: string }) => {
      dispatch(toastrError(error));
    },
  });
}

export function useAssignSupportSeatToUser() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { url, options } = useRequestOptions({
    url: USERS_API_URL,
    method: 'POST',
  });

  return useMutation<any, ServerError, { userId: string }>({
    mutationFn: ({ userId }) => {
      return fetchApiRequest(`${url}/${userId}/add_support_seat`, options);
    },
    onSuccess: () => {
      dispatch(toastrSuccess('Support seat assigned successfully'));

      queryClient.invalidateQueries(usersQueryKeys.all());
    },
    onError: ({ error }) => {
      dispatch(toastrError(error));
    },
  });
}

export function useRevokeUserSupportSeat() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { url, options } = useRequestOptions({
    url: USERS_API_URL,
    method: 'POST',
  });

  return useMutation<any, ServerError, { userId: string }>({
    mutationFn: ({ userId }) => {
      return fetchApiRequest(`${url}/${userId}/remove_support_seat`, options);
    },
    onSuccess: () => {
      dispatch(toastrSuccess('Support seat revoked successfully'));

      queryClient.invalidateQueries(usersQueryKeys.all());
    },
    onError: ({ error }) => {
      dispatch(toastrError(error));
    },
  });
}

interface UseWelcomeDetailsParams {
  tenant: TenantType;
  token: string;
}

export function useWelcomeDetails({ tenant, token }: UseWelcomeDetailsParams) {
  return useApiQuery<WelcomeDetailsType>(
    `${USERS_API_URL}/get_user_details/${tenant}/${token}`,
    usersQueryKeys.welcomeDetails(),
    {
      enabled: Boolean(tenant) && Boolean(token),
    }
  );
}

interface UseUpdateUserDetailsParams {
  token: string;
  password: string;
  name: string;
  tenant: TenantType;
}

export function useUpdateUserDetails() {
  const dispatch = useDispatch();
  const { url, options } = useRequestOptions({
    url: USERS_API_URL,
    method: 'POST',
  });

  return useMutation<AuthType, ServerError, UseUpdateUserDetailsParams>({
    mutationFn: (params) => {
      return fetchApiRequest(`${url}/set_user_details`, {
        ...options,
        body: JSON.stringify(params),
      });
    },
    onSuccess: (response) => {
      dispatch(toastrSuccess('User details updated successfully'));

      dispatch(signedIn(response));
      dispatch(setRoute('/'));
    },
    onError: ({ error }) => {
      dispatch(toastrError(error));
    },
  });
}
