import {
  Badge,
  Box,
  Button,
  Checkbox,
  CloseButton,
  createStyles,
  Divider,
  Group,
  Stack,
  Text,
} from '@mantine/core';
import React, { useCallback, useState } from 'react';

import { GroupUserType } from '@portals/api/organizations';
import {
  EmptySearchResults,
  NameAbbreviationAvatar,
  SearchInput,
} from '@portals/core';
import { ReactComponent as Trash } from '@portals/icons/linear/trash.svg';
import { VerticalScrollBar } from '@portals/scrollbar';

import { GroupMembersListEmptyState } from './GroupMembersListEmptyState';
import { useConfirmationModal } from '../../../hooks/modals';

type GroupUserWithChecked = GroupUserType & { isChecked: boolean };

export interface GroupMembersListProps {
  usersList: Array<GroupUserWithChecked>;
  onRemoveUsers: (userIdsToRemove: string[]) => void;
  confirmBeforeRemovingUsers?: boolean;
  onAddUsersActionClick: () => void;
  onChangeCheckAllUsers: (isChecked: boolean) => void;
  onChangeCheckedUser: (userId: string, isChecked: boolean) => void;
}

export function GroupMembersList({
  usersList,
  onRemoveUsers,
  confirmBeforeRemovingUsers = false,
  onAddUsersActionClick,
  onChangeCheckAllUsers,
  onChangeCheckedUser,
}: GroupMembersListProps) {
  const { classes } = useStyles();
  const asyncConfirmation = useConfirmationModal();

  const [searchTerm, setSearchTerm] = useState('');
  const lowerCaseSearchTerm = searchTerm.toLowerCase();

  const checkedUserIds = usersList
    .filter((user) => user.isChecked)
    .map((user) => user.id);
  const isAllChecked = checkedUserIds.length === usersList.length;
  const indeterminate = checkedUserIds.length > 0 && !isAllChecked;

  const filteredUsersList = usersList.filter((user) => {
    const lowerCaseName = user.name.toLowerCase();
    const lowerCaseEmail = user.email.toLowerCase();

    return (
      lowerCaseName.includes(lowerCaseSearchTerm) ||
      lowerCaseEmail.includes(lowerCaseSearchTerm)
    );
  });

  const handleRemoveUsers = async (userIdsToRemove: string[]) => {
    if (!confirmBeforeRemovingUsers) {
      onRemoveUsers(userIdsToRemove);
      return;
    }

    const isConfirmed = await asyncConfirmation({
      description: `Are you sure you want to remove ${
        userIdsToRemove.length > 1 ? 'these users' : 'this user'
      } from the group?`,
    });

    if (isConfirmed) {
      onRemoveUsers(userIdsToRemove);
    }
  };

  return (
    <Stack spacing={32} h="100%">
      <Text size="lg" color="gray.8" weight={500} px={30}>
        Members
      </Text>

      {usersList.length === 0 ? (
        <GroupMembersListEmptyState onAddUsersClick={onAddUsersActionClick} />
      ) : (
        <Stack spacing="xs" sx={{ flexGrow: 1 }}>
          <Group sx={{ flexGrow: 1 }} px={30}>
            <Checkbox
              checked={isAllChecked}
              indeterminate={indeterminate}
              onChange={() => onChangeCheckAllUsers(!isAllChecked)}
            />

            <SearchInput
              size="sm"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              onClear={() => setSearchTerm('')}
            />

            {checkedUserIds.length > 0 ? (
              <Group>
                <Badge
                  h={36}
                  size="lg"
                  rightSection={
                    <CloseButton
                      w="auto"
                      miw="fit-content"
                      color="primary"
                      variant="transparent"
                      onClick={() => onChangeCheckAllUsers(false)}
                    />
                  }
                >
                  <Text weight={600}>{checkedUserIds.length} Selected</Text>
                </Badge>

                <Divider orientation="vertical" />

                <Button
                  variant="subtle"
                  color="blue_gray"
                  leftIcon={<Trash width={18} height={18} />}
                  onClick={() => handleRemoveUsers(Array.from(checkedUserIds))}
                >
                  Remove
                </Button>
              </Group>
            ) : null}

            <Button variant="default" ml="auto" onClick={onAddUsersActionClick}>
              Add Members
            </Button>
          </Group>

          {filteredUsersList.length === 0 ? (
            <EmptySearchResults searchValue={searchTerm} />
          ) : (
            <VerticalScrollBar
              renderView={(props) => <Box px={30} {...props} />}
            >
              {filteredUsersList.map((user) => (
                <Group
                  key={user.id}
                  position="apart"
                  className={classes.listItem}
                >
                  <Group spacing="xl">
                    <Checkbox
                      checked={user.isChecked}
                      onChange={(e) =>
                        onChangeCheckedUser(user.id, e.target.checked)
                      }
                      styles={{ body: { alignItems: 'center' } }}
                      label={
                        <Group>
                          <NameAbbreviationAvatar
                            name={user.name}
                            radius="xl"
                            size="md"
                          />

                          <div>
                            <Text color="gray.7">{user.name}</Text>
                            <Text
                              size="xs"
                              color="gray.5"
                              data-testid="group-members-list-item-email"
                            >
                              {user.email}
                            </Text>
                          </div>
                        </Group>
                      }
                    />

                    {user.is_external && (
                      <Badge color="purple" size="lg" radius="sm">
                        External User
                      </Badge>
                    )}
                  </Group>

                  <CloseButton
                    size="md"
                    color="blue_gray"
                    onClick={() => handleRemoveUsers([user.id])}
                  />
                </Group>
              ))}
            </VerticalScrollBar>
          )}
        </Stack>
      )}
    </Stack>
  );
}

const useStyles = createStyles((theme) => ({
  listItem: {
    paddingBlock: 32,

    '&:not(:last-child)': {
      borderBottom: `1px solid ${theme.colors.gray[2]}`,
    },
  },
}));

export function useGroupMembersList() {
  const [usersList, setUsersList] = useState<
    GroupMembersListProps['usersList']
  >([]);

  const onChangeCheckAllUsers = useCallback((isChecked: boolean) => {
    setUsersList((prevUsersList) =>
      prevUsersList.map((user) => ({ ...user, isChecked }))
    );
  }, []);

  const onChangeCheckedUser = (userId: string, isChecked: boolean) => {
    setUsersList((prevUsersList) =>
      prevUsersList.map((user) => {
        if (user.id === userId) {
          return { ...user, isChecked };
        }

        return user;
      })
    );
  };

  return {
    usersList,
    setUsersList,

    onChangeCheckAllUsers,
    onChangeCheckedUser,
  };
}
