import { MantineThemeOverride } from '@mantine/core';

import { Badge } from './badge';
import { Breadcrumbs } from './breadcrumbs';
import { Button } from './button';
import { Checkbox } from './checkbox';
import { Divider } from './divider';
import { Input, InputWrapper } from './inputs';
import { Menu } from './menu';
import { Modal } from './modal';
import { Notification } from './notification';
import { Prism } from './prism';
import { Radio, RadioGroup } from './radio';
import { SegmentedControl } from './segmented-control';
import { Select } from './select';
import { Stack } from './stack';
import { Stepper } from './stepper';
import { Switch } from './switch';
import { Tabs } from './tabs';
import { Tooltip } from './tooltip';

export const mantineComponentsOverride: MantineThemeOverride['components'] = {
  Modal,
  Menu,
  Stepper,
  Badge,
  Button,
  Breadcrumbs,
  Divider,
  InputWrapper,
  Input,
  Switch,
  RadioGroup,
  Radio,
  Checkbox,
  Notification,
  SegmentedControl,
  Tabs,
  Stack,
  Select,
  Prism,
  Tooltip,
};
