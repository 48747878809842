import { createStyles } from '@mantine/core';
import { motion, MotionProps, useDragControls } from 'framer-motion';
import React, { MutableRefObject, useCallback, useMemo, useState } from 'react';

import { Handle } from './Handle';
import { SegmentValue } from './SegmentValue';
import {
  NumberFormatType,
  OnAddCustomColorFn,
  WidgetColorType,
} from '../../../widgets.types';
import {
  computeDragLimit,
  maxValueToXPos,
  relativeToNumMax,
  xPosToMaxValue,
} from '../segment-range-selector.utils';
import { HANDLE_WIDTH } from '../segmented-range-selector.constants';

export interface SegmentHandleProps {
  containerRef: MutableRefObject<HTMLDivElement>;
  segmentMax: number;
  isDisabled: boolean;
  leftSideSegmentMax: number | undefined;
  rightSideSegmentMax: number | undefined;
  index: number;
  color: WidgetColorType;
  onChangeSegmentColor: (index: number, color: WidgetColorType) => void;
  onUpdateSegmentMax: (index: number, max: number) => void;
  onUpdateLocalSegmentMax: (index: number, max: number) => void;
  onRemoveSegment: (index: number) => void;
  containerWidth: number;
  globalRange: { min: number; max: number };
  numberFormat: NumberFormatType;
  numOfDecimals: number;
  colors: Array<WidgetColorType> | undefined;
  onAddCustomColor: OnAddCustomColorFn | undefined;
}

export function SegmentHandle({
  segmentMax,
  isDisabled,
  leftSideSegmentMax,
  rightSideSegmentMax,
  index,
  color,
  onRemoveSegment,
  onChangeSegmentColor,
  onUpdateSegmentMax,
  onUpdateLocalSegmentMax,
  containerWidth,
  containerRef,
  globalRange,
  numberFormat,
  numOfDecimals,
  colors,
  onAddCustomColor,
}: SegmentHandleProps) {
  const isOdd = index % 2 === 1;

  const dragControls = useDragControls();
  const { classes, cx } = useSegmentHandleStyles();

  const [isPristine, setIsPristine] = useState(true);
  const [isDragging, setIsDragging] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);

  const xPosition = maxValueToXPos(containerWidth, segmentMax);

  const dragConstraints = useMemo(() => {
    return computeDragLimit(
      containerWidth,
      leftSideSegmentMax || 0,
      rightSideSegmentMax || containerWidth
    );
  }, [containerWidth, rightSideSegmentMax, leftSideSegmentMax]);

  const onDrag: MotionProps['onDrag'] = useCallback(
    (event, info) => {
      if (!(event.target instanceof Element)) return;

      const adjustedXPosition =
        info.point.x - containerRef.current.getBoundingClientRect().x;

      const segmentMax = xPosToMaxValue(adjustedXPosition, containerWidth);
      const segmentValue = relativeToNumMax(segmentMax, globalRange);

      if (
        segmentValue <=
          relativeToNumMax(leftSideSegmentMax || 0, globalRange) ||
        segmentValue >=
          relativeToNumMax(rightSideSegmentMax || 100, globalRange)
      ) {
        return;
      }

      onUpdateLocalSegmentMax(index, segmentMax);
    },
    [
      containerRef,
      containerWidth,
      globalRange,
      index,
      leftSideSegmentMax,
      onUpdateLocalSegmentMax,
      rightSideSegmentMax,
    ]
  );

  const onDragStart: MotionProps['onDragEnd'] = useCallback(
    (event, info) => {
      if (isPristine) {
        setIsPristine(false);
      }

      setIsDragging(true);
      onDrag(event, info);
    },
    [isPristine, onDrag]
  );

  const onDragEnd: MotionProps['onDragEnd'] = useCallback(() => {
    setIsDragging(false);

    onUpdateSegmentMax(index, segmentMax);
  }, [index, segmentMax, onUpdateSegmentMax]);

  const snapHandleToInitialDragPosition = useCallback(
    (event: PointerEvent) => {
      if (isDisabled || !(event.target instanceof Element)) return;

      dragControls.start(event, {
        snapToCursor: true,
      });
    },
    [dragControls, isDisabled]
  );

  const onValueInputChange = useCallback(
    (max: number) => {
      onUpdateSegmentMax(index, max);

      // Simulate a drag start event, to update the handle position
      dragControls.start(
        new PointerEvent('pointerdown', {
          clientX:
            containerRef.current.getBoundingClientRect().x +
            maxValueToXPos(containerWidth, max),
        }),
        {
          snapToCursor: true,
        }
      );
    },
    [containerRef, containerWidth, dragControls, index, onUpdateSegmentMax]
  );

  const onRemove = useCallback(
    () => onRemoveSegment(index),
    [index, onRemoveSegment]
  );

  const onChangeColor = useCallback(
    (color: WidgetColorType) => onChangeSegmentColor(index, color),
    [index, onChangeSegmentColor]
  );

  const valueClassName = cx(classes.valueWrapper, {
    'is-odd': isOdd,
  });

  return (
    <>
      <motion.div
        className={cx(classes.container, {
          disabled: isDisabled,
        })}
        dragControls={dragControls}
        drag={isDisabled ? false : 'x'}
        dragConstraints={dragConstraints}
        dragElastic={false}
        dragMomentum={false}
        // @ts-ignore
        onPointerDown={snapHandleToInitialDragPosition}
        onDragStart={onDragStart}
        onDrag={onDrag}
        onDragEnd={onDragEnd}
        style={{
          x: xPosition - (isPristine ? HANDLE_WIDTH / 2 : 0),
          top: isOdd ? undefined : 30,
          bottom: isOdd ? 30 : undefined,
          zIndex: index + 2,
        }}
      >
        <Handle
          colors={colors}
          onAddCustomColor={onAddCustomColor}
          isDragging={isDragging}
          position={isOdd ? 'bottom' : 'top'}
          color={color}
          isDisabled={isDisabled}
          onChangeColor={onChangeColor}
          onRemove={onRemove}
        />
      </motion.div>

      <div
        className={valueClassName}
        style={{
          transform: `translateX(calc(${xPosition}px - 50%))`,
        }}
      >
        <SegmentValue
          segmentMax={segmentMax}
          isDisabled={Boolean(isDisabled)}
          globalRange={globalRange}
          leftSideSegmentMax={leftSideSegmentMax}
          rightSideSegmentMax={rightSideSegmentMax}
          numberFormat={numberFormat}
          numOfDecimals={numOfDecimals}
          onChange={onValueInputChange}
          isEditMode={isEditMode}
          setIsEditMode={setIsEditMode}
        />
      </div>
    </>
  );
}

const useSegmentHandleStyles = createStyles(() => ({
  container: {
    userSelect: 'none',
    zIndex: 2,
    position: 'absolute',
    cursor: 'grab',
    overflow: 'visible',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',

    '&:active': {
      cursor: 'grabbing',
    },

    '&.disabled': {
      cursor: 'default',

      '&:active': {
        cursor: 'default',
      },
    },
  },
  valueWrapper: {
    zIndex: 1,
    position: 'absolute',

    '&:not(.is-odd)': {
      top: -5,
    },

    '&.is-odd': {
      bottom: 0,
    },
  },
}));
