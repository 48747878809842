import React, { FC } from 'react';
import { Helmet, HelmetProps } from 'react-helmet-async';

import { useCSP } from '../hooks/csp';

type MetaProps = {
  title: HelmetProps['title'];
  links?: HelmetProps['link'];
  meta?: HelmetProps['meta'];
  gtmId?: string;
  hotJarId?: string;
};

const Meta: FC<MetaProps> = ({ title, links, meta = [], gtmId, hotJarId }) => {
  const cspContent = useCSP();

  return (
    <Helmet defer={false} title={title}>
      <meta
        key="CSP"
        httpEquiv="Content-Security-Policy"
        content={cspContent}
      />

      {/* Google Tag Manager*/}
      {gtmId ? (
        <script>{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${gtmId}');`}</script>
      ) : null}
      {/* Google Tag Manager*/}

      {/* Hotjar */}
      {hotJarId ? (
        <script>{`
        (function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:${hotJarId},hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
      })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`}</script>
      ) : null}
      {/* Hotjar*/}

      {/* Launchnotes-embed */}
      <script
        src="https://embed.launchnotes.io/latest/dist/esm/launchnotes-embed.js"
        async={false}
        type="module"
      />
      {/* Launchnotes-embed */}

      {meta.map((props, index) => (
        <meta {...props} key={index} />
      ))}

      {links ? (
        links.map((props, index) => <link key={index} {...props} />)
      ) : (
        <link href="favicon.ico" rel="icon" sizes="192x192" />
      )}
    </Helmet>
  );
};

export default Meta;
