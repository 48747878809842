import isMobile from 'is-mobile';
import React from 'react';

const ResponsiveContext = React.createContext<{
  isMobile: boolean;
}>({
  isMobile: false,
});

interface ResponsiveProps {
  children: React.ReactNode;
}

export function Responsive({ children }: ResponsiveProps) {
  const value = {
    isMobile: isMobile({ tablet: true }),
  };

  return (
    <ResponsiveContext.Provider value={value}>
      {children}
    </ResponsiveContext.Provider>
  );
}

function ResponsiveMobile({ children }: ResponsiveProps) {
  const context = React.useContext(ResponsiveContext);

  if (context.isMobile) {
    return <>{children}</>;
  }

  return null;
}

function ResponsiveDesktop({ children }: ResponsiveProps) {
  const context = React.useContext(ResponsiveContext);

  if (!context.isMobile) {
    return <>{children}</>;
  }

  return null;
}

Responsive.Mobile = ResponsiveMobile;
Responsive.Desktop = ResponsiveDesktop;
