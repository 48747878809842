import { SpaceTreeNodeType, SpaceType } from '@portals/api/organizations';
import {
  AccessLevelEnum,
  DeviceType,
  LicenseType,
  OrganizationIncidentType,
  TicketType,
} from '@portals/types';

type Entity = { access_level: AccessLevelEnum } & (
  | OrganizationIncidentType
  | TicketType
  | LicenseType
  | SpaceType
  | DeviceType
  | SpaceTreeNodeType
);

export const noAccess = (entity?: Entity) =>
  entity?.access_level === AccessLevelEnum.None;
export const canView = (entity?: Entity) =>
  Boolean(entity && entity.access_level >= AccessLevelEnum.View);
export const canEdit = (entity: Entity) =>
  entity.access_level >= AccessLevelEnum.Edit;
export const canAdmin = (entity: Entity) =>
  entity.access_level >= AccessLevelEnum.Admin;
