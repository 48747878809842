import { Box, createStyles, Group, MenuProps } from '@mantine/core';
import { useHover } from '@mantine/hooks';
import { map } from 'lodash/fp';
import React, { useMemo } from 'react';

import { ExpandRowType, RowType, SmartTableProps } from '@portals/types';

import { ROW_HEIGHT } from '../table.constants';
import ExpandRow from './ExpandRow';
import TCell from './TCell';
import { TRowMenuWrapper } from './TRowMenuWrapper';

interface TRowProps<TData extends object, TKeyField extends keyof TData> {
  row: RowType<TData>;
  expandRow: ExpandRowType<TData>;
  rowMenu?: SmartTableProps<TData, TKeyField>['rowMenu'];
  isCompact?: boolean;
  menuProps?: Omit<MenuProps, 'children'>;
  onRowClick?: (row: RowType<TData>) => void;
  readOnly: boolean;
  clickedRowOriginalId: TData[TKeyField] | undefined;
  keyField: TKeyField;
}

function TRow<TData extends object, TKeyField extends keyof TData>({
  row,
  expandRow,
  rowMenu,
  isCompact = false,
  menuProps = {},
  onRowClick,
  readOnly,
  clickedRowOriginalId,
  keyField,
}: TRowProps<TData, TKeyField>) {
  const { cx, classes } = useStyles({
    isSelected:
      row.isSelected || row.original[keyField] === clickedRowOriginalId,
    isExpanded: row.isExpanded,
    isCompact,
    isClickable: onRowClick !== undefined,
  });
  const { hovered, ref } = useHover();

  const rowProps = row.getRowProps();
  const hasExpandRow = useMemo(() => {
    if (!expandRow) return false;
    else if (expandRow.isEnabled) return expandRow.isEnabled(row);

    return true;
  }, [expandRow, row]);

  const cells = useMemo(
    () =>
      map(
        (cell) => <TCell key={cell.getCellProps().key} cell={cell} />,
        row?.cells
      ),
    [row?.cells]
  );

  return (
    <Box
      {...rowProps}
      onClick={() => onRowClick?.(row)}
      data-testid="table-row"
      className={cx('trow-wrapper', classes.rowWrapper, {
        expanded: hasExpandRow && row.isExpanded,
      })}
    >
      <Group
        noWrap
        ref={ref}
        spacing={0}
        className={cx('trow', classes.row)}
        title=""
        {...(hasExpandRow ? row?.getToggleRowExpandedProps() : {})}
      >
        {cells}

        {rowMenu && !readOnly ? (
          <TRowMenuWrapper
            rowMenu={rowMenu}
            row={row}
            isHovered={hovered}
            menuProps={menuProps}
          />
        ) : null}
      </Group>

      {hasExpandRow ? <ExpandRow expandRow={expandRow} row={row} /> : null}
    </Box>
  );
}

const useStyles = createStyles<
  'rowWrapper' | 'row',
  {
    isExpanded: boolean;
    isSelected: boolean;
    isCompact: boolean;
    isClickable: boolean;
  }
>((theme, { isExpanded, isSelected, isCompact, isClickable }) => ({
  rowWrapper: isExpanded
    ? {
        display: 'grid !important',
        gridTemplateRows: `${ROW_HEIGHT}px max-content`,
      }
    : {
        '&:not(:last-of-type)': {
          borderBottom: isCompact
            ? 'none'
            : `1px solid ${theme.colors.gray[3]}`,
        },
      },
  row: {
    width: '100%',
    height: ROW_HEIGHT,
    position: 'relative',
    cursor: isClickable ? 'pointer' : 'default',

    ...(isSelected
      ? {
          backgroundColor: theme.colors.primary[0],
          '.tcell.sticky': {
            backgroundColor: theme.colors.primary[0],
          },
        }
      : {
          backgroundColor: theme.white,

          ...theme.fn.hover({
            backgroundColor: theme.colors.gray[0],

            '.tcell.sticky': {
              backgroundColor: theme.colors.gray[0],
            },
          }),
        }),
  },
}));

export default TRow;
