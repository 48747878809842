import { MantineProvider, createEmotionCache } from '@mantine/core';
import React, { FC } from 'react';

import { THEME } from './theme';

const emotionCache = createEmotionCache({
  key: 'mantine',
  prefix: false,
  stylisPlugins: [],
});

export const ThemeProvider: FC = ({ children }) => {
  return (
    <MantineProvider emotionCache={emotionCache} theme={THEME}>
      {children}
    </MantineProvider>
  );
};
