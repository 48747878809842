import { isFunction } from 'lodash/fp';
import React, { ReactNode, Suspense } from 'react';
import { Route } from 'react-router-dom';

import {
  CommonFeatureFlagsType,
  CommonPortalCapabilities,
  CommonPricingPlanFeatures,
} from '@portals/types';
import { Loader } from '@portals/ui';

import { ChildRoutesParams, GetRouteComponentParams } from './routes.types';

export function getRouteComponent<
  TFeatureFlags extends CommonFeatureFlagsType,
  TPortalCapabilities extends CommonPortalCapabilities,
  TPricingPlanFeatures extends CommonPricingPlanFeatures
>(
  params: GetRouteComponentParams<
    TFeatureFlags,
    TPortalCapabilities,
    TPricingPlanFeatures
  >
) {
  const {
    route,
    featureFlags,
    portalCapabilities,
    pricingPlanFeatures,
    canEdit,
    canView,
    isAdmin,
    hasSupportSeat,
    classification,
  } = params;
  const { canAccessRoute, id, path, component: Component } = route;

  if (
    canAccessRoute &&
    !canAccessRoute({
      featureFlags,
      canView,
      canEdit,
      isAdmin,
      hasSupportSeat,
      portalCapabilities,
      pricingPlanFeatures,
      classification,
    })
  ) {
    return;
  }

  const adjustedPath = isFunction(path)
    ? path({
        featureFlags,
        portalCapabilities,
        pricingPlanFeatures,
        isAdmin,
      })
    : path;

  return (
    <Route
      key={id}
      path={adjustedPath}
      exact
      render={(props) => (
        <Suspense fallback={<Loader />}>
          <Component {...props} />
        </Suspense>
      )}
    />
  );
}

export function getChildRoutes<
  TFeatureFlags extends CommonFeatureFlagsType,
  TPortalCapabilities extends CommonPortalCapabilities,
  TPricingPlanFeatures extends CommonPricingPlanFeatures
>({
  routes,
  featureFlags,
  canEdit,
  canView,
  isAdmin,
  hasSupportSeat,
  portalCapabilities,
  pricingPlanFeatures,
  classification,
}: ChildRoutesParams<
  TFeatureFlags,
  TPortalCapabilities,
  TPricingPlanFeatures
>) {
  const result: ReactNode[] = [];

  routes.forEach((route) => {
    const { canAccessRoute, children, path } = route;

    if (
      canAccessRoute &&
      !canAccessRoute({
        featureFlags,
        portalCapabilities,
        pricingPlanFeatures,
        canView,
        canEdit,
        isAdmin,
        hasSupportSeat,
        classification,
      })
    ) {
      return;
    }

    // Route item with children
    if (children) {
      children.forEach((childRoute) => {
        result.push(
          getRouteComponent<
            TFeatureFlags,
            TPortalCapabilities,
            TPricingPlanFeatures
          >({
            route: childRoute,
            featureFlags,
            portalCapabilities,
            pricingPlanFeatures,
            canView,
            canEdit,
            isAdmin,
            hasSupportSeat,
            classification,
          })
        );
      });
    }

    if (path) {
      result.push(
        getRouteComponent<
          TFeatureFlags,
          TPortalCapabilities,
          TPricingPlanFeatures
        >({
          route,
          featureFlags,
          portalCapabilities,
          pricingPlanFeatures,
          canView,
          canEdit,
          isAdmin,
          hasSupportSeat,
          classification,
        })
      );
    }
  });

  return result.filter(Boolean);
}
