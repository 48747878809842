import { useMutation, useQuery, useQueryClient } from 'react-query';

import {
  PaginatedQueryFilterType,
  PaginatedQuerySortType,
  PaginationResponse,
} from '@portals/types';

import { QueryOptions } from '../../types/common';
import { fetchApiRequest, useRequestOptions } from '../../utils/common';
import { buildUrlFromFilters } from '../../utils/paginated-query';
import { DeviceDetailsType, DeviceType } from '../devices';
import { globalQueryKeys } from '../global-query-keys';
import { incidentsQueryKeys } from '../incidents/incidents.constants';
import { getSpaceApiUrl, spacesQueryKeys } from './spaces.constants';

function getApiUrl(spaceId: number) {
  return `${getSpaceApiUrl(spaceId)}/devices`;
}

function getDeviceApiUrl(spaceId: number, deviceId: string) {
  return `${getApiUrl(spaceId)}/${deviceId}`;
}

export function useDevicesBySpaceId({
  spaceId,
  queryOptions,
  filters,
  sorting,
  pageSize = 500,
}: {
  spaceId: number;
  queryOptions?: Pick<QueryOptions<DeviceType[]>, 'enabled' | 'staleTime'>;
  filters?: Array<PaginatedQueryFilterType<DeviceType>>;
  sorting?: Array<PaginatedQuerySortType<DeviceType>>;
  pageSize?: number;
}) {
  const { url, options } = useRequestOptions({ url: getApiUrl(spaceId) });

  const requestUrl = buildUrlFromFilters<DeviceType>({
    filters,
    sorting,
    url,
    pagination: {
      page: 0,
      pageSize,
    },
  });

  return useQuery<PaginationResponse<DeviceType>, any, DeviceType[]>({
    queryKey: [...spacesQueryKeys.devices.all(spaceId), requestUrl],
    queryFn: () => fetchApiRequest(requestUrl, options),
    select: (response) => response.data,
    ...(queryOptions || {}),
  });
}

export function useUpdateDevice() {
  const queryClient = useQueryClient();
  const { url: baseUrl, options } = useRequestOptions({
    url: '',
    method: 'PUT',
  });

  return useMutation<
    DeviceType,
    { error: string },
    {
      spaceId: number;
      deviceId: string;
      updatedDevice: Partial<DeviceDetailsType>;
    }
  >({
    mutationFn: ({ spaceId, deviceId, updatedDevice }) => {
      const finalUrl = `${baseUrl}/${getDeviceApiUrl(spaceId, deviceId)}`;

      return fetchApiRequest(finalUrl, {
        ...options,
        body: JSON.stringify(updatedDevice),
      });
    },
    onSuccess: (_, { spaceId }) => {
      queryClient.invalidateQueries(incidentsQueryKeys.base);
      queryClient.invalidateQueries(spacesQueryKeys.detail(spaceId));
      queryClient.invalidateQueries(globalQueryKeys.devices);

      setTimeout(() => {
        queryClient.invalidateQueries(spacesQueryKeys.base);
      }, 2500);
    },
  });
}
