import {
  Box,
  Button,
  ButtonProps,
  CloseButton,
  createStyles,
  Group,
  GroupProps,
  Stack,
  StackProps,
  Text,
  TextProps,
} from '@mantine/core';
import React, { ComponentProps, ReactNode } from 'react';

import { ScrollStateType, VerticalScrollBar } from '@portals/scrollbar';

import {
  DetailsPanelScrollContextProvider,
  useDetailsPanelScrollContext,
} from './DetailsPanelScrollContext';

interface DetailsPanelProps extends StackProps {
  enableScrollUiChanges?: boolean;
}
export function DetailsPanel({
  children,
  enableScrollUiChanges = true,
  ...stackProps
}: DetailsPanelProps) {
  return (
    <DetailsPanelScrollContextProvider
      enableScrollUiChanges={enableScrollUiChanges}
    >
      <Stack spacing={0} h="100%" {...stackProps}>
        {children}
      </Stack>
    </DetailsPanelScrollContextProvider>
  );
}

interface HeaderProps extends Omit<StackProps, 'title'> {
  title?: ReactNode;
  onClose?: () => void;
}
function Header({
  title,
  onClose,
  children,
  className,
  ...stackProps
}: HeaderProps) {
  const { classes, cx } = useHeaderStyles();
  const { isScrolled, isScrollUiChangesEnabled } =
    useDetailsPanelScrollContext();

  return (
    <Stack
      spacing="xl"
      className={cx(classes.header, className, {
        [classes.scrolled]: isScrollUiChangesEnabled && isScrolled,
      })}
      {...stackProps}
    >
      {title || onClose ? (
        <Group position="apart" noWrap>
          {title ? (
            <DetailsPanel.Title className={classes.title}>
              {title}
            </DetailsPanel.Title>
          ) : null}

          {onClose ? (
            <CloseButton
              onClick={onClose}
              size="md"
              className={classes.closeButton}
            />
          ) : null}
        </Group>
      ) : null}

      {children}
    </Stack>
  );
}

const useHeaderStyles = createStyles((theme) => ({
  header: {
    position: 'sticky',
    top: 0,
    paddingInline: 32,
    paddingBlock: 24,
  },
  title: {
    flexGrow: 1,
  },
  closeButton: {
    alignSelf: 'flex-start',
    marginLeft: 'auto',
  },
  scrolled: {
    borderBottom: `1px solid ${theme.colors.gray[3]}`,
  },
}));

interface TitleProps extends TextProps {}
function Title({ children, ...textProps }: TitleProps) {
  return (
    <Text size="lg" color="blue_gray.8" {...textProps}>
      {children}
    </Text>
  );
}

interface BodyProps extends StackProps {}
function Body({ children, ...stackProps }: BodyProps) {
  const { setIsScrolled, setIsScrollable } = useDetailsPanelScrollContext();

  const onScrollFrame = (scrollState: ScrollStateType) => {
    if (scrollState.top > 0) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  const onUpdate: ComponentProps<typeof VerticalScrollBar>['onUpdate'] = (
    positionValues
  ) => {
    if (positionValues.scrollHeight > positionValues.clientHeight) {
      setIsScrollable(true);
    }
  };

  return (
    <VerticalScrollBar
      onScrollFrame={onScrollFrame}
      onUpdate={onUpdate}
      renderView={(renderViewProps) => (
        <Stack
          spacing={32}
          px={32}
          pt="xl"
          pb="xl"
          {...stackProps}
          {...renderViewProps}
        />
      )}
    >
      {children}
    </VerticalScrollBar>
  );
}

interface FooterProps extends BodyProps {}
function Footer({ children, className, ...boxProps }: FooterProps) {
  const { classes, cx } = useFooterStyles();
  const { isScrollable, isScrollUiChangesEnabled } =
    useDetailsPanelScrollContext();

  return (
    <Box
      className={cx(classes.footer, className, {
        [classes.scrollable]: isScrollUiChangesEnabled && isScrollable,
      })}
      {...boxProps}
    >
      {children}
    </Box>
  );
}

const useFooterStyles = createStyles((theme) => ({
  footer: {
    position: 'sticky',
    bottom: 0,
    padding: 32,
  },
  scrollable: {
    borderTop: `1px solid ${theme.colors.gray[3]}`,
  },
}));

interface ActionsProps extends GroupProps {}
function Actions({ children, ...groupProps }: ActionsProps) {
  return (
    <Group spacing="xs" noWrap grow {...groupProps}>
      {children}
    </Group>
  );
}

function ActionButton(
  props: ButtonProps & React.ComponentPropsWithoutRef<'button'>
) {
  const { classes } = useActionButtonStyles();
  return (
    <Button
      variant="default"
      size="xs"
      classNames={{ leftIcon: classes.leftIcon }}
      px={0}
      {...props}
    />
  );
}

const useActionButtonStyles = createStyles(() => ({
  leftIcon: {
    width: 16,
    height: 16,
  },
}));

interface SectionProps extends Omit<StackProps, 'title'> {
  title?: ReactNode;
  content?: ReactNode;
}
function Section({ title, content, children, ...stackProps }: SectionProps) {
  return (
    <Stack {...stackProps}>
      {title && (
        <Text size="md" weight={600} color="gray.7">
          {title}
        </Text>
      )}
      {content && (
        <Text size="sm" color="blue_gray.9">
          {content}
        </Text>
      )}

      {children}
    </Stack>
  );
}

DetailsPanel.Header = Header;
DetailsPanel.Title = Title;
DetailsPanel.Body = Body;
DetailsPanel.Section = Section;
DetailsPanel.Footer = Footer;
DetailsPanel.Actions = Actions;
DetailsPanel.ActionButton = ActionButton;
