import { Button, Modal, Textarea } from '@mantine/core';
import React, { useState } from 'react';

import { ModalBody, ModalFooter } from '@portals/core';

import { ModalProps } from '../components/Modals';

export interface EditNoteModalProps
  extends ModalProps<{
    note: string | null;
    onSubmit: (newNotes: string) => Promise<unknown>;
  }> {}

export function EditNoteModal({
  closeMe,
  data: { note, onSubmit },
}: EditNoteModalProps) {
  const [value, setValue] = useState(note || '');
  const [isLoading, setIsLoading] = useState(false);

  const onSubmitHandler = async () => {
    setIsLoading(true);

    try {
      await onSubmit(value);
      closeMe();
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal opened onClose={closeMe} title="Edit note">
      <ModalBody>
        <Textarea
          data-autofocus
          disabled={isLoading}
          minRows={10}
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
      </ModalBody>

      <ModalFooter grow>
        <Button variant="default" onClick={closeMe}>
          Cancel
        </Button>
        <Button loading={isLoading} onClick={onSubmitHandler}>
          Save
        </Button>
      </ModalFooter>
    </Modal>
  );
}
