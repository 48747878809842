import { ActionIcon } from '@mantine/core';
import { motion } from 'framer-motion';
import React, { FC } from 'react';
import styled, { createGlobalStyle } from 'styled-components';

import { ReactComponent as CloseX } from '@portals/icons/linear/close-x.svg';
import { getStyledThemeColor } from '@portals/utils';

import Logo from '../header/Logo';
import SidebarItems from './SidebarItems';

type SidebarProps = {
  isOpen: boolean;
  onClose: () => void;
};

const Sidebar: FC<SidebarProps> = ({ isOpen, onClose }) => (
  <Container
    initial={{ x: '-100%' }}
    animate={{ x: isOpen ? '0%' : '-100%' }}
    transition={{ duration: 0.25 }}
  >
    {isOpen ? <SidebarGlobal /> : null}

    <SidebarContent>
      <ActionIcon
        size={30}
        color="white"
        variant="transparent"
        onClick={onClose}
        className="close-sidebar-icon"
      >
        <CloseX />
      </ActionIcon>

      <SidebarHeader>
        <StyledLogo />
      </SidebarHeader>

      <SidebarItems onClose={onClose} />
    </SidebarContent>
  </Container>
);

const Container = styled(motion.div)`
  height: 100%;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999;
`;

const SidebarGlobal = createGlobalStyle`
  body {
    background-color: ${getStyledThemeColor('dark')};
  }
`;

const SidebarContent = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  background-color: ${getStyledThemeColor('dark')};
  padding: 20px 40px;

  .close-sidebar-icon {
    position: absolute;
    top: 20px;
    right: 20px;
    color: ${getStyledThemeColor('white')};
  }
`;

const SidebarHeader = styled.div`
  width: calc(100% - 35px);
  display: flex;
  flex-direction: column;

  .organization-name {
    color: ${getStyledThemeColor('white')};
  }
`;

const StyledLogo = styled(Logo)`
  align-items: flex-start;

  img {
    max-width: 50px !important;
  }
`;

export default Sidebar;
