import { Breadcrumbs, Group, Text, useMantineTheme } from '@mantine/core';
import React, { FC } from 'react';
import { Link, LinkProps } from 'react-router-dom';

import { ReactComponent as ArrowRight1 } from '@portals/icons/linear/arrow-right-1.svg';

interface Crumb {
  label: string;
  to?: LinkProps['to'];
}

export interface PageBreadcrumbsProps {
  crumbs: Crumb[];
  className?: string;
  containerClassName?: string;
  withEndSeparator?: boolean;
}

export const PageBreadcrumbs: FC<PageBreadcrumbsProps> = ({
  crumbs,
  className,
  containerClassName,
  withEndSeparator,
}) => {
  const theme = useMantineTheme();

  return (
    <Group align="center" spacing="xs" className={containerClassName}>
      <Breadcrumbs
        separator={<ArrowRight1 width={16} height={16} />}
        className={className}
      >
        {crumbs.map(({ label, to }, index) => {
          if (to) {
            return (
              <Link key={index} to={to}>
                {label}
              </Link>
            );
          }

          return <Text key={index}>{label}</Text>;
        })}
      </Breadcrumbs>

      {withEndSeparator && (
        <ArrowRight1 color={theme.colors.gray[4]} width={16} height={16} />
      )}
    </Group>
  );
};
