import {
  Box,
  createStyles,
  Menu,
  MenuProps,
  Stack,
  Tooltip,
} from '@mantine/core';
import {
  compact,
  first,
  join,
  last,
  map,
  size,
  upperCase,
  words,
} from 'lodash/fp';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { ReactComponent as DocumentText } from '@portals/icons/linear/document-text.svg';
import { ReactComponent as Support } from '@portals/icons/linear/lifebuoy.svg';
import { ReactComponent as Logout1 } from '@portals/icons/linear/logout-1.svg';
import { ReactComponent as PasswordCheck } from '@portals/icons/linear/password-check.svg';
import { ReactComponent as Help } from '@portals/icons/linear/question-circle.svg';
import { ReactComponent as SecurityUser } from '@portals/icons/linear/security-user.svg';
import { getAuth, useActionDispatch } from '@portals/redux';
import { signOut } from '@portals/redux/actions/auth';
import { openModal } from '@portals/redux/actions/modal';
import { DesignType, TenantType } from '@portals/types';
import { getStyledThemeColor } from '@portals/utils';

import { useAppConfig } from '../../../context';
import { useHubspotConversations } from '../../../external/HubspotConversationsProvider';
import { useCommonConfig, useCommonDesign } from '../../../hooks/portal-config';

export const UserSettings = () => {
  const signOutAction = useActionDispatch(signOut);
  const openModalAction = useActionDispatch(openModal);
  const { extraLayout = {} } = useAppConfig();
  const hubspotConversations = useHubspotConversations();
  const design = useCommonDesign();
  const { classes, theme } = useStyles({ design });

  const { name, uid: email, tenant } = useSelector(getAuth) || {};
  const isPartner = tenant.type === TenantType.Partner;
  const isOrganization = tenant.type === TenantType.Organization;

  const config = useCommonConfig();
  const organizationConfig = config?.data?.[TenantType.Organization];

  const settingsItems = compact([
    {
      Icon: Help,
      label: 'Help',
      type: 'link',
      href: isPartner ? 'https://dev.xyte.io/' : 'https://docs.xyte.io/',
    },
    isOrganization && organizationConfig?.partner_support_email
      ? {
          Icon: Support,
          label: 'Contact support',
          type: 'link',
          href: `mailto:${organizationConfig?.partner_support_email}?subject="Support request from ${organizationConfig?.name}"`,
        }
      : null,
    {
      Icon: PasswordCheck,
      label: 'Change password',
      type: 'action',
      onClick: () => openModalAction('ChangePasswordModal'),
    },
    {
      Icon: DocumentText,
      label: 'Terms of service',
      type: 'link',
      href: 'https://xyte.io/terms-of-service',
    },
    {
      Icon: SecurityUser,
      label: 'Privacy',
      type: 'link',
      href: 'https://xyte.io/privacy-policy',
    },
    {
      Icon: Logout1,
      label: 'Sign out',
      type: 'action',
      onClick: () => {
        hubspotConversations.removeWidget();
        signOutAction();
      },
    },
  ]);

  const nameAbbreviation = useMemo(() => {
    const nameWords = words(name);

    if (size(nameWords) === 1) {
      return upperCase(first(nameWords[0]));
    }

    return join(
      '',
      map((word) => upperCase(first(word)), [first(nameWords), last(nameWords)])
    );
  }, [name]);

  return (
    <Menu width={300} styles={menuStyles(design)}>
      <Menu.Target>
        <Box className={classes.control}>
          <Tooltip
            withinPortal
            label="User settings"
            disabled={extraLayout?.options?.isMobile}
          >
            <User className="user-name">{nameAbbreviation}</User>
          </Tooltip>
        </Box>
      </Menu.Target>
      <Menu.Dropdown px={theme.spacing.xs} py={theme.spacing.md}>
        <Stack spacing="xs">
          <Box className={classes.header}>
            <HeaderUser>{nameAbbreviation}</HeaderUser>
            <div className="user-data">
              <UserName className="user-name">{name}</UserName>
              <Email className="user-email">{email}</Email>
            </div>
          </Box>

          <Menu.Divider />

          {settingsItems.map(({ Icon, label, type, href, onClick }) => {
            if (type === 'link') {
              return (
                <Menu.Item
                  icon={<Icon />}
                  onClick={() => window.open(href, '_blank')}
                  key={label}
                >
                  {label}
                </Menu.Item>
              );
            }

            if (type === 'action') {
              return (
                <Menu.Item icon={<Icon />} onClick={onClick} key={label}>
                  {label}
                </Menu.Item>
              );
            }

            return null;
          })}
        </Stack>
      </Menu.Dropdown>
    </Menu>
  );
};

const useStyles = createStyles(
  (theme, { design }: { design?: DesignType }) => ({
    header: {
      display: 'grid',
      gridTemplateColumns: '44px 1fr',
      gap: theme.spacing.md,
      alignItems: 'center',
      height: 44,
      padding: `0 ${theme.spacing.sm}`,

      '.user-data': {
        display: 'grid',

        '.user-name': {
          color:
            design?.navigation_menu?.company_text_color ||
            theme.colors.blue_gray[8],
        },

        '.user-email': {
          color: theme.fn.rgba(
            design?.navigation_menu?.company_text_color ||
              theme.colors.blue_gray[8],
            0.5
          ),
        },
      },
    },
    control: {
      height: '100%',
      cursor: 'pointer',
      borderLeft: `1px solid ${theme.fn.rgba(
        design?.navigation_menu?.menu_text_color || theme.colors.blue_gray[6],
        0.5
      )}`,

      '.nav-link': {
        padding: '0 !important',
        display: 'flex',
        height: '100%',
        alignItems: 'flex-end !important',
        justifyContent: 'space-between',
      },
    },
  })
);

const menuStyles =
  (design?: DesignType): MenuProps['styles'] =>
  (theme) => ({
    dropdown: {
      background: design?.navigation_menu?.menu_background_color || theme.white,
    },
    item: {
      borderRadius: theme.radius.md,

      '&[data-hovered]': {
        '.mantine-Menu-itemIcon, .mantine-Menu-itemLabel': {
          color: 'inherit',
        },
        color:
          design?.navigation_menu?.hovered_section_text_color ||
          theme.colors.blue_gray[6],
        backgroundColor:
          design?.navigation_menu?.hovered_section_background_color ||
          theme.colors.gray[1],
      },
    },
    itemIcon: {
      color:
        design?.navigation_menu?.menu_text_color || theme.colors.blue_gray[6],
    },
    itemLabel: {
      color:
        design?.navigation_menu?.menu_text_color || theme.colors.blue_gray[6],
    },
  });

const User = styled.div`
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: #263238;
  color: ${getStyledThemeColor('white')};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 600;
  margin-left: 10px;
  transition: background-color 0.15s ease-in-out, color 0.15s ease-in-out;
  padding-top: 2px;
  border: 2px solid ${getStyledThemeColor('white')};
`;

const HeaderUser = styled(User)`
  height: 44px;
  width: 44px;
  margin-left: 0;
`;

const Email = styled.div`
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.0015em;
  transition: color 0.15s ease-in-out;
  position: relative;
  text-transform: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const UserName = styled.div`
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.0015em;
  text-transform: capitalize;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;
