import { createStyles } from '@mantine/core';
import { motion } from 'framer-motion';
import React, { FC, ReactNode } from 'react';
import { CSSObject } from 'styled-components';

import { useIsSidebarOpen } from '@portals/redux';

import { useAppConfig } from '../context';

type MainProps = {
  className?: string;
  children: ReactNode;
  style?: CSSObject;
};

const getAnimationConfig = (isOpen: boolean) => ({
  transition: { duration: 0.15 },
  initial: {
    marginLeft: isOpen ? 300 : 76,
  },
  animate: {
    marginLeft: isOpen ? 300 : 76,
  },
});

const Main: FC<MainProps> = ({ children }) => {
  const { classes, cx } = useStyles();
  const isSidebarOpen = useIsSidebarOpen();
  const { extraLayout = {} } = useAppConfig();

  return (
    <motion.div
      className={cx(classes.main, 'page-main')}
      {...(extraLayout.options?.isMobile
        ? {}
        : getAnimationConfig(isSidebarOpen))}
    >
      {children}
    </motion.div>
  );
};

const useStyles = createStyles((theme) => ({
  main: {
    position: 'relative',
    zIndex: 2,
    display: 'grid',
    gridTemplateRows: `${theme.other.navbarHeight}px 1fr`,
    height: '100vh',
    backgroundColor: theme.white,
    letterSpacing: '0.02em',
  },
}));

export default Main;
