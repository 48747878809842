import React, { useState } from 'react';

import { SignUp } from '@portals/framework';

import { AuthWrapper } from './common';
import Notification from '../../components/layout/Notification';

export function SignUpPage() {
  const [error, setError] = useState<string | null>(null);

  return (
    <AuthWrapper>
      <SignUp onError={setError} />

      <Notification error={error} onClear={() => setError(null)} />
    </AuthWrapper>
  );
}
