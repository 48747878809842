import React, { ReactNode, useContext } from 'react';

import {
  CommonFeatureFlagsType,
  CommonPortalCapabilities,
  CommonPricingPlanFeatures,
  ExtraLayoutType,
  ModalsType,
  RoutesMap,
  TenantType,
} from '@portals/types';

interface ContextType<
  TFeatureFlags extends CommonFeatureFlagsType,
  TPortalCapabilities extends CommonPortalCapabilities,
  TPricingPlanFeatures extends CommonPricingPlanFeatures
> {
  routes: RoutesMap<TFeatureFlags, TPortalCapabilities, TPricingPlanFeatures>;
  modals: ModalsType;
  tenantType: TenantType;
  extraLayout: ExtraLayoutType<TFeatureFlags>;
}

const AppConfigContext =
  React.createContext<
    Partial<
      ContextType<
        CommonFeatureFlagsType,
        CommonPortalCapabilities,
        CommonPricingPlanFeatures
      >
    >
  >(null);

interface AppConfigProviderProps<
  TFeatureFlags extends CommonFeatureFlagsType,
  TPortalCapabilities extends CommonPortalCapabilities,
  TPricingPlanFeatures extends CommonPricingPlanFeatures
> {
  value: Partial<
    ContextType<TFeatureFlags, TPortalCapabilities, TPricingPlanFeatures>
  >;
  children: ReactNode;
}

function AppConfigProvider<
  TFeatureFlags extends CommonFeatureFlagsType,
  TPortalCapabilities extends CommonPortalCapabilities,
  TPricingPlanFeatures extends CommonPricingPlanFeatures
>({
  children,
  value,
}: AppConfigProviderProps<
  TFeatureFlags,
  TPortalCapabilities,
  TPricingPlanFeatures
>) {
  return (
    <AppConfigContext.Provider value={value}>
      {children}
    </AppConfigContext.Provider>
  );
}

function useAppConfig<
  TFeatureFlags extends CommonFeatureFlagsType,
  TPortalCapabilities extends CommonPortalCapabilities,
  TPricingPlanFeatures extends CommonPricingPlanFeatures
>() {
  const context =
    useContext<
      Partial<
        ContextType<TFeatureFlags, TPortalCapabilities, TPricingPlanFeatures>
      >
    >(AppConfigContext);

  if (context === undefined) {
    throw new Error('useAppConfig must be used within a AppConfigProvider');
  }

  return context;
}

export { AppConfigProvider, useAppConfig, AppConfigContext };
