import { Box, createStyles, Menu, MenuProps } from '@mantine/core';
import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useKey } from 'react-use';

import { getTenant, useIsSidebarOpen } from '@portals/redux';
import { TenantType } from '@portals/types';
import { useOnClickOutside } from '@portals/utils';

import { useShouldDisplayTenantMenu } from './hooks';
import {
  OrganizationMenuContent,
  PartnerMenuContent,
} from './TenantMenuContent';
import { OrganizationUser, PartnerUser } from './TenantUser';

export function TenantMenu() {
  const isSidebarOpen = useIsSidebarOpen();
  const { classes } = useStyles(isSidebarOpen);
  const [isOpen, setIsOpen] = useState(false);
  const currentTenantType = useSelector(getTenant)?.type;

  const MenuContentWrapper =
    currentTenantType === TenantType.Organization
      ? OrganizationMenuContent
      : PartnerMenuContent;

  const contentRef = useRef<HTMLDivElement>(null);

  const onEscape = () => {
    if (isOpen) {
      setIsOpen(false);
    }
  };

  useOnClickOutside(contentRef, () => setIsOpen(false), isOpen);
  useKey('Escape', onEscape, { event: 'keydown' }, [isOpen]);

  const shouldDisplayTenantMenu = useShouldDisplayTenantMenu();

  if (!shouldDisplayTenantMenu) {
    return (
      <Box sx={{ height: 76 }}>
        {currentTenantType === TenantType.Organization ? (
          <OrganizationUser isActive={isOpen} isDisabled />
        ) : (
          <PartnerUser isActive={isOpen} isDisabled />
        )}
      </Box>
    );
  }

  return (
    <Menu
      offset={0}
      withinPortal
      opened={isOpen}
      closeOnItemClick={false}
      position="right"
      width={300}
      styles={menuStyles}
    >
      <Menu.Target>
        <Box
          className={classes.menuTarget}
          onClick={() => setIsOpen((curr) => !curr)}
        >
          {currentTenantType === TenantType.Organization ? (
            <OrganizationUser isActive={isOpen} />
          ) : (
            <PartnerUser isActive={isOpen} />
          )}
        </Box>
      </Menu.Target>

      <Menu.Dropdown>
        <MenuContentWrapper ref={contentRef} onClose={() => setIsOpen(false)} />
      </Menu.Dropdown>
    </Menu>
  );
}

const menuStyles: MenuProps['styles'] = {
  dropdown: {
    padding: `0 !important`,
    borderBottomLeftRadius: 0,
    borderTopLeftRadius: 0,
  },
};

const useStyles = createStyles((theme, isSidebarOpen: boolean) => ({
  menuTarget: {
    height: 76,
    width: isSidebarOpen ? 'auto' : theme.other.closedSidebarWidth,
  },
}));
