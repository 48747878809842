import {
  Box,
  createStyles,
  Flex,
  Group,
  Input,
  Stack,
  Text,
  TextInput,
} from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { Identifier } from 'dnd-core';
import React, { MutableRefObject } from 'react';

import { ReactComponent as Drag } from '@portals/icons/linear/drag.svg';

import { PopoverIconSelector } from '../../../common/PopoverIconSelector';
import {
  StateControllerOptionCommandType,
  StateControllerWidgetFormType,
} from '../state-controller-form.types';

export interface StateControllerOptionFormProps {
  option: StateControllerOptionCommandType;
  index: number;
  form: UseFormReturnType<StateControllerWidgetFormType>;
  handlerId: Identifier | null;
  innerRef: MutableRefObject<HTMLDivElement>;
}

export function StateControllerOptionForm({
  option,
  index,
  form,
  handlerId,
  innerRef,
}: StateControllerOptionFormProps) {
  const numOfRows = form.values.options.length;
  const { classes } = useStyles({
    numOfRows: numOfRows || 0,
  });

  return (
    <Box
      className={classes.commandWrapper}
      w="100%"
      data-handler-id={handlerId}
      ref={innerRef}
      p="sm"
    >
      <Flex h="100%" align="center" justify="center">
        <Box className={classes.dragWrapper}>
          <Drag />
        </Box>
      </Flex>

      <Stack w="100%">
        <Group noWrap align="center">
          <Input.Wrapper
            label="Icon"
            required={form.values.display_icons}
            sx={{ flex: 0 }}
          >
            <PopoverIconSelector
              selectedIconName={option.icon_name}
              onChange={(iconName) =>
                form.setFieldValue(`options.${index}.icon_name`, iconName)
              }
              color={form.values.color}
            />
          </Input.Wrapper>

          <TextInput
            w="100%"
            {...form.getInputProps(`options.${index}.label`)}
            label="Label"
            required
            placeholder={`Option ${index + 1}`}
          />

          <Input.Wrapper label="Option Value" w="100%">
            <Text size="sm" color="gray.6">
              {form.values.options[index].value}
            </Text>
          </Input.Wrapper>
        </Group>
      </Stack>
    </Box>
  );
}

const useStyles = createStyles(
  (theme, { numOfRows }: { numOfRows: number }) => ({
    commandWrapper: {
      display: 'grid',
      gridTemplateColumns: 'min-content 1fr',
      radius: theme.radius.md,
      gap: theme.spacing.md,
      border: `1px solid ${theme.colors.gray[2]}`,
    },
    dragWrapper: {
      transform: 'rotate(90deg)',
      color: theme.colors.gray[4],
      cursor: 'grab',

      '&:active': {
        cursor: 'grabbing',
      },
    },
  })
);
