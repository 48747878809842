import { MantineColor } from '@mantine/core';
import { compact, join } from 'lodash/fp';

import { DeviceType, SpaceType } from '@portals/api/organizations';
import {
  DeviceType as CommonDeviceType,
  IncidentPriorityLevel,
} from '@portals/types';

export interface Priority {
  id: IncidentPriorityLevel;
  name: string;
  color: MantineColor;
}

export const INCIDENT_PRIORITY_LEVEL: Record<IncidentPriorityLevel, Priority> =
  {
    1: { id: 1, name: 'Critical', color: 'red' },
    2: { id: 2, name: 'High', color: 'orange_accent' },
    3: { id: 3, name: 'Moderate', color: 'amber' },
    4: { id: 4, name: 'Low', color: 'blue_gray' },
    5: { id: 5, name: 'Planning', color: 'indigo' },
  };

export function getPrioritiesList() {
  return Object.values(INCIDENT_PRIORITY_LEVEL);
}

export function getIncidentPriorityLevelName(
  priorityLevel: IncidentPriorityLevel
) {
  return INCIDENT_PRIORITY_LEVEL[priorityLevel].name;
}

export function getIncidentPriorityLevelColor(
  priorityLevel: IncidentPriorityLevel
) {
  return INCIDENT_PRIORITY_LEVEL[priorityLevel].color;
}

export function getSpaceHighestIncidentPriority(
  incidents: SpaceType['state']['incidents']
) {
  if (incidents?.critical > 0) return INCIDENT_PRIORITY_LEVEL[1].color;
  else if (incidents?.high > 0) return INCIDENT_PRIORITY_LEVEL[2].color;
  else if (incidents?.moderate > 0) return INCIDENT_PRIORITY_LEVEL[3].color;
  else if (incidents?.low > 0) return INCIDENT_PRIORITY_LEVEL[4].color;
  else if (incidents?.planning > 0) return INCIDENT_PRIORITY_LEVEL[5].color;
  else return 'gray';
}

export function getDeviceHighestIncidentPriority(
  incidents: DeviceType['incidents'] | CommonDeviceType['incidents']
) {
  if (incidents?.[0] > 0) return INCIDENT_PRIORITY_LEVEL[1].color;
  else if (incidents?.[1] > 0) return INCIDENT_PRIORITY_LEVEL[2].color;
  else if (incidents?.[2] > 0) return INCIDENT_PRIORITY_LEVEL[3].color;
  else if (incidents?.[3] > 0) return INCIDENT_PRIORITY_LEVEL[4].color;
  else if (incidents?.[4] > 0) return INCIDENT_PRIORITY_LEVEL[5].color;
  else return 'gray';
}

export const getIncidentsBySpaceIdTooltipContent = (
  incidents: SpaceType['state']['incidents']
) => {
  const { critical, high, moderate, low, planning } = incidents;

  const count = compact([
    critical > 0 ? `${critical} critical` : null,
    high > 0 ? `${high} high` : null,
    moderate > 0 ? `${moderate} moderate` : null,
    low > 0 ? `${low} low` : null,
    planning > 0 ? `${planning} planning` : null,
  ]);

  return `Incidents: ${count?.length ? join(', ', count) : 'none'}`;
};

export const getIncidentsByDeviceIdTooltipContent = (
  incidents: Array<number>
) => {
  const [critical, high, moderate, low, planning] = incidents;

  const count = compact([
    critical > 0 ? `${critical} critical` : null,
    high > 0 ? `${high} high` : null,
    moderate > 0 ? `${moderate} moderate` : null,
    low > 0 ? `${low} low` : null,
    planning > 0 ? `${planning} planning` : null,
  ]);

  return `Incidents: ${count?.length ? join(', ', count) : 'None'}`;
};
