import { Box, createStyles, Stack, Text } from '@mantine/core';
import { isEmpty } from 'lodash/fp';
import React from 'react';

import {
  DeviceModelType as OrgDeviceModelType,
  DeviceType,
} from '@portals/api/organizations';
import { DeviceModelType as PartnerDeviceModelType } from '@portals/api/partners';
import {
  AccessLevelEnum,
  DeviceType as CommonDeviceType,
  SupportedCommandType,
} from '@portals/types';

import { ModalButton } from '../../../../components/ModalButton';
import { EntityAccess } from '../../../../EntityAccess';

interface CommandsProps<
  TDevice extends DeviceType | CommonDeviceType,
  TDeviceModel extends OrgDeviceModelType | PartnerDeviceModelType
> {
  device: TDevice;
  model: TDeviceModel;
}

export function Commands<
  TDevice extends DeviceType | CommonDeviceType,
  TDeviceModel extends OrgDeviceModelType | PartnerDeviceModelType
>({ device, model }: CommandsProps<TDevice, TDeviceModel>) {
  const { classes } = useStyles();

  if (isEmpty(model?.supported_commands)) return null;

  return (
    <Stack>
      <Text className={classes.title}>Commands</Text>

      <Box className={classes.container}>
        {model?.supported_commands.map((command: SupportedCommandType) => (
          <EntityAccess
            key={command.id}
            id={command.id}
            minLevel={AccessLevelEnum.Edit}
            entity={device}
          >
            <ModalButton
              modalName="RunCommand"
              size="sm"
              label={command.friendly_name}
              data={{
                deviceType: device.partner.type_id,
                command,
                deviceIds: [device.id],
              }}
            />
          </EntityAccess>
        ))}
      </Box>
    </Stack>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    display: 'flex',
    gap: theme.spacing.md,
    flexWrap: 'wrap',
  },
  title: {
    fontSize: theme.fontSizes.md,
    color: theme.colors.blue_gray[7],
    fontWeight: 500,
  },
}));
