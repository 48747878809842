// Create the script tag, set the appropriate attributes
if (process.env.NX_GOOGLE_KEY) {
  const script = document.createElement('script');
  script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.NX_GOOGLE_KEY}&libraries=places&callback=initMap`;
  script.defer = true;

  // Attach your callback function to the `window` object
  // @ts-ignore
  window.initMap = () => {
    // TODO: Enable map api usage
  };

  // Append the 'script' element to 'head'
  document.head.appendChild(script);
}
