import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';

const Referral = ({ location }) => {
  useEffect(() => {
    const useQuery = new URLSearchParams(location.search);
    const referral = useQuery.get('referral');

    if (referral) {
      localStorage.setItem('referral', referral);
    }
  }, [location.search]);

  return null;
};

export default withRouter(Referral);
