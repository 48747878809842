import { useMantineTheme } from '@mantine/core';
import { useNetwork } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import React, { useEffect } from 'react';

import { ReactComponent as Wifi } from '@portals/icons/linear/wifi.svg';

export function useNetworkStatus() {
  const theme = useMantineTheme();
  const network = useNetwork();

  useEffect(
    function notifyOnNetworkStateChange() {
      if (network.online) {
        notifications.hide('network-notification');
      } else {
        notifications.show({
          id: 'network-notification',
          autoClose: false,
          icon: <Wifi color={theme.fn.themeColor('red')} />,
          title: "You're currently offline",
          message:
            'Your internet connection is having an issue reaching our server',
        });
      }
    },
    [network.online, theme.fn]
  );
}
