import {
  OrganizationFeatureFlagsType,
  OrganizationPortalCapabilities,
  OrganizationPricingPlanFeatures,
  RoutesMap,
  RoutesType,
} from '@portals/types';

import { ChangePasswordPage, ResetPassword } from './pages/auth/Password';
import { SignIn } from './pages/auth/SignIn';
import { SignUpPage } from './pages/auth/SignUp';
import { WelcomeWrapper } from './pages/auth/Welcome';
import { Claim } from './pages/dashboard/claim';
import { Scan } from './pages/dashboard/claim/scan';
import { Space } from './pages/dashboard/claim/space';
import { Devices } from './pages/dashboard/devices';
import { Device } from './pages/dashboard/devices/device';

const authRoutes: RoutesType<
  OrganizationPortalCapabilities,
  OrganizationFeatureFlagsType,
  OrganizationPricingPlanFeatures
> = [
  {
    path: ['/auth/sign-in'],
    id: 'Sign In',
    component: SignIn,
  },
  {
    path: ['/auth/sign-up'],
    id: 'Sign Up',
    component: SignUpPage,
  },
  {
    path: ['/auth/welcome/:token'],
    id: 'Welcome',
    component: WelcomeWrapper,
  },
  {
    path: ['/auth/reset-password'],
    id: 'Reset Password',
    component: ResetPassword,
  },
  {
    path: ['/auth/change-password/:token'],
    id: 'Change Password',
    component: ChangePasswordPage,
  },
];

const dashboardChildRoutes: RoutesType<
  OrganizationPortalCapabilities,
  OrganizationFeatureFlagsType,
  OrganizationPricingPlanFeatures
> = [
  {
    path: ['/', '/claim'],
    id: 'claim',
    component: Claim,
  },
  {
    path: ['/claim/:space_id'],
    id: 'space',
    component: Space,
  },
  {
    path: ['/claim/:space_id/scan'],
    id: 'scan',
    component: Scan,
  },
  {
    path: ['/devices'],
    id: 'devices',
    component: Devices,
  },
  {
    path: ['/devices/:device_id'],
    id: 'device',
    component: Device,
  },
];

export const routes: RoutesMap<
  OrganizationFeatureFlagsType,
  OrganizationPortalCapabilities,
  OrganizationPricingPlanFeatures
> = {
  dashboard: [
    {
      id: 'dashboard',
      headerId: '',
      childRoutes: dashboardChildRoutes,
    },
  ],
  auth: authRoutes,
};
