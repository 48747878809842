import { MantineThemeOverride, rem } from '@mantine/core';

import { mantineComponentsOverride } from './mantine-components-override';
import { COLORS } from './theme-colors';
import { THEME_OTHER } from './theme-other';

export const THEME: MantineThemeOverride = {
  globalStyles: (theme) => ({
    body: {
      color: theme.colors.blue_gray[9],
    },
  }),
  components: mantineComponentsOverride,
  primaryColor: 'blue_accent',
  primaryShade: 4,
  other: THEME_OTHER,
  fontFamily:
    'Nunito, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, sans-serif',
  colors: COLORS,
  white: '#FFFFFF',
  black: '#000000',
  spacing: {
    xs: rem(8),
    xxl: rem(32),
  },
  lineHeight: 'normal',
  headings: {
    fontFamily:
      'Nunito, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, sans-serif',
    sizes: {
      h1: { fontSize: rem(36), lineHeight: 'normal' },
      h2: { fontSize: rem(32), lineHeight: 'normal' },
      h3: { fontSize: rem(28), lineHeight: 'normal' },
      h4: { fontSize: rem(24), lineHeight: 'normal' },
      h5: { fontSize: rem(20), lineHeight: 'normal' },
      h6: { fontSize: rem(18), lineHeight: 'normal' },
    },
  },
};
