import React, { FC } from 'react';
import styled from 'styled-components';

import { useOrganizationConfig } from '@portals/api/organizations';
import { useAuth } from '@portals/redux';
import { getStyledThemeColor } from '@portals/utils';

type LogoProps = {
  className?: string;
  isLight?: boolean;
};

export const Logo: FC<LogoProps> = ({ className }) => {
  const authentication = useAuth();
  const organization = useOrganizationConfig();

  if (authentication) {
    return (
      <OrgName className={className}>
        <div className="organization-name">{organization?.name}</div>

        {organization?.partner_display_name ? (
          <div className="partner-name">{`by ${organization.partner_display_name}`}</div>
        ) : null}
      </OrgName>
    );
  }

  return <LogoAsset className="logo-asset" src="logo_full_dark.svg" alt="" />;
};

export const LogoAsset = styled.img`
  max-height: 50px;
  max-width: 50px;
`;

const OrgName = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: ${getStyledThemeColor('dark')};
  font-size: 25px;
  letter-spacing: 0.1em;
  overflow: hidden;

  .organization-name {
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    overflow: hidden;
  }

  .partner-name {
    font-size: 18px;
    color: ${getStyledThemeColor('gray600')};
  }
`;

export default Logo;
