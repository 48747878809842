import { Alert, createStyles, Group, Loader, Stack, Text } from '@mantine/core';
import React from 'react';

import {
  DeviceDetailsType,
  DeviceModelType as OrgDeviceModelType,
} from '@portals/api/organizations';
import { DeviceModelType as PartnerDeviceModelType } from '@portals/api/partners';
import { VerticalScrollBar } from '@portals/scrollbar';
import { DeviceType as CommonDeviceType } from '@portals/types';

import { Actions } from './Actions';
import { Commands } from './Commands';
import { Details } from './Details';
import { Header } from './Header';
import { ParentAndChildDevices } from './ParentAndChildDevices';
import { ReplaceDeviceAlert } from './ReplaceDeviceAlert';

interface DeviceInfoProps<
  TDevice extends DeviceDetailsType | CommonDeviceType,
  TDeviceModel extends OrgDeviceModelType | PartnerDeviceModelType
> {
  device: TDevice;
  model: TDeviceModel;
}

export function DeviceInfo<
  TDevice extends DeviceDetailsType | CommonDeviceType,
  TDeviceModel extends OrgDeviceModelType | PartnerDeviceModelType
>({ device, model }: DeviceInfoProps<TDevice, TDeviceModel>) {
  const { classes } = useStyles();

  return (
    <Stack className={classes.container} spacing="xl">
      <Header<TDevice> device={device} />

      <Actions<TDevice> device={device} />

      {device.registration_failed_at ? (
        <ReplaceDeviceAlert device={device} />
      ) : null}

      {device.awaiting_replacement ? (
        <Alert color="blue_accent.0" p="xl" radius="md">
          <Group spacing="md">
            <Loader size="xs" />

            <Text size="sm" color="blue_gray.7">
              Waiting for device replacement....
            </Text>
          </Group>
        </Alert>
      ) : null}

      <VerticalScrollBar>
        <Stack p={0} spacing={32}>
          <Details<TDevice> device={device} />

          <Commands<TDevice, TDeviceModel> device={device} model={model} />

          {/* When new device view is added in Partners portal, its type will be updated and
           included missing fields for child & parent devices */}
          <ParentAndChildDevices device={device as DeviceDetailsType} />
        </Stack>
      </VerticalScrollBar>
    </Stack>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    height: '100%',
    width: '100%',
    borderRight: `1px solid ${theme.colors.gray[2]}`,
    background: theme.white,
    padding: 30,
  },
}));
