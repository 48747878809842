import { Box, createStyles, Group, Text } from '@mantine/core';
import { filter, groupBy, includes, keys, map, toLower } from 'lodash/fp';
import React, { useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router';

import { ReactComponent as ArrowLeft } from '@portals/icons/linear/arrow-left.svg';

import { RouteModal } from '../../RouteModal';
import { CATEGORIES, SERVICES } from './integrations.constants';
import {
  ServiceCategoryEnum,
  ServiceCategoryType,
  ServicesGroupType,
} from './integrations.types';
import { Payment } from './Payment';
import { ServicePreview } from './ServicePreview';
import { ServicesList } from './ServicesList';
import { ServicesSidebar } from './ServicesSidebar';

export const IntegrationsRouteModal = () => {
  const { classes } = useStyles();
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategory, setSelectedCategory] =
    useState<ServiceCategoryType | null>(null);
  const history = useHistory();
  const params = useParams<{ integration?: string }>();
  const stepParam = new URLSearchParams(history.location.search).get('step');

  const servicesGroups = useMemo<Array<ServicesGroupType>>(() => {
    const lowerCaseSearchTerm = toLower(searchTerm);

    const filteredServices = filter(({ name, category }) => {
      const isSearchFiltered =
        !lowerCaseSearchTerm || includes(lowerCaseSearchTerm, toLower(name));
      const isCategoryFiltered =
        !selectedCategory || selectedCategory.id === category;

      return isSearchFiltered && isCategoryFiltered;
    }, SERVICES);

    const groupedServices = groupBy('category', filteredServices);

    return map(
      (category: ServiceCategoryEnum) => ({
        category,
        services: groupedServices[category],
      }),
      keys(groupedServices)
    );
  }, [searchTerm, selectedCategory]);

  const content = useMemo(() => {
    if (!params.integration && stepParam !== 'payment') {
      return (
        <Box className={classes.content}>
          <ServicesSidebar
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            categories={CATEGORIES}
            selectedCategory={selectedCategory}
            setSelectedCategory={setSelectedCategory}
          />

          <ServicesList
            servicesGroups={servicesGroups}
            toggleSelectedService={(serviceId: string) =>
              history.push(`/m/integrations/${serviceId}`)
            }
          />
        </Box>
      );
    }

    if (stepParam === 'payment') {
      return <Payment />;
    }

    return (
      <ServicePreview onDisplayPayment={() => history.push('?step=payment')} />
    );
  }, [
    classes.content,
    history,
    params.integration,
    searchTerm,
    selectedCategory,
    servicesGroups,
    stepParam,
  ]);

  const header = useMemo(() => {
    if (!params.integration && stepParam !== 'payment')
      return <div>Explore Integrations</div>;

    if (stepParam === 'payment') {
      return (
        <Group
          align="center"
          onClick={() => history.goBack()}
          sx={{
            cursor: 'pointer',
            svg: {
              width: 17,
              height: 17,
            },
          }}
        >
          <ArrowLeft />
          <Text size="xl">Back</Text>
        </Group>
      );
    }

    return (
      <Group
        align="center"
        onClick={() => history.push('/m/integrations')}
        sx={{
          cursor: 'pointer',
          svg: {
            width: 17,
            height: 17,
          },
        }}
      >
        <ArrowLeft />
        <Text size="xl">Browse Integrations</Text>
      </Group>
    );
  }, [history, stepParam, params.integration]);

  return (
    <RouteModal modalId="order">
      <Box className={classes.container}>
        <Group className={classes.header}>{header}</Group>

        {content}
      </Box>
    </RouteModal>
  );
};

const useStyles = createStyles((theme) => ({
  container: {
    width: '100%',
    height: '100%',
    display: 'grid',
    gridTemplateRows: 'min-content 1fr',
  },
  header: {
    padding: theme.spacing.xl,
    fontSize: theme.fontSizes.xl,
    alignItems: 'center',
    borderBottom: `1px solid ${theme.colors.gray[2]}`,
    height: 79,

    svg: {
      width: 34,
      height: 34,
    },
  },
  content: {
    height: '100%',
    display: 'grid',
    gridTemplateColumns: '237px 1fr',
    overflow: 'hidden',
  },
}));
