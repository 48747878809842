import { kebabCase } from 'lodash/fp';
import React, { FC } from 'react';
import { UncontrolledTooltip } from 'reactstrap';

type DeviceNameProps = {
  name?: string;
  fallbackName?: string;
};

const DeviceName: FC<DeviceNameProps> = ({ name = 'Unnamed' }) => (
  <>
    <div className="name" id={`device-name-${kebabCase(name)}`}>
      {name}
    </div>

    <UncontrolledTooltip
      placement="bottom"
      target={`device-name-${kebabCase(name)}`}
    >
      {name}
    </UncontrolledTooltip>
  </>
);

export default DeviceName;
