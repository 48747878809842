import 'rc-tree/assets/index.css';
import {
  contains,
  difference,
  find,
  first,
  includes,
  isEmpty,
  noop,
  toLower,
  uniq,
} from 'lodash/fp';
import { default as RcTree, TreeNodeProps, TreeProps } from 'rc-tree';
import { DraggableFn } from 'rc-tree/es/Tree';
import { IconType } from 'rc-tree/lib/interface';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useContextMenu } from 'react-contexify';
import { useDragDropManager } from 'react-dnd';
import { useEffectOnce, useLocalStorage, useUpdateEffect } from 'react-use';
import { AutoSizer } from 'react-virtualized';
import styled from 'styled-components';

import { SpaceTreeNodeType, SpaceType } from '@portals/api/organizations';
import { usePermissionAccess } from '@portals/framework';
import { EmptyState } from '@portals/table';
import {
  filterTreeNodes,
  getAllNodesIds,
  getStyledThemeColor,
} from '@portals/utils';

import { canView, canEdit, canAdmin } from '../../../../lib/access';
import { TreeContextProvider } from '../space-tree.context';
import { DroppableTreeNode } from './DroppableTreeNode';
import Toggler from './Toggler';
import TreeHeader from './TreeHeader';

interface TreeComponentProps {
  handleSelected: (nodeId: number) => void;
  handleMoveSpace: (spaceId: number, targetSpaceId: number) => void;
  selected?: number;
  treeData: Array<SpaceTreeNodeType>;
  readonly?: boolean;
  draggable?: boolean;
  hideSearch?: boolean;
  spaces: SpaceType[];
  initialExpandedNodeIds: Array<number>;
}

const ITEM_HEIGHT = 30;

const FIELD_NAMES = {
  key: 'id',
} as const;

export function Tree(props: TreeComponentProps) {
  const {
    initialExpandedNodeIds,
    handleSelected,
    handleMoveSpace,
    selected,
    treeData,
    draggable,
    readonly,
    spaces,
  } = props;

  const { show } = useContextMenu();

  const { isAdmin } = usePermissionAccess();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [expandedNodes, setExpandedNodes] = useState(() =>
    uniq([
      ...initialExpandedNodeIds,
      ...(find({ id: props.selected }, spaces)?.path || []),
    ])
  );

  // Persist expanded nodes ids in local storage on every 'expandedNodes' update
  const [, setStoredExpandedNodeIds] = useLocalStorage(
    'spacesTree.expandedNodes'
  );

  useUpdateEffect(
    function persistExpandedNodeIds() {
      setStoredExpandedNodeIds(expandedNodes);
    },
    [expandedNodes]
  );

  // Toggled to `true` when a device is dragged over the tree, which in return sets the Tree's
  // `draggable` prop to false, so we could drop devices on nodes
  const [isDraggingOutsideOfTree, setIsDraggingOutsideOfTree] = useState(false);

  // Passed to all nodes, used as portal ref for rendering each node's context menu on
  // right-click (had to move them all under 1 element, instead of independently rendering on
  // each node, bc of virtualization)
  const contextMenuPortalRef = useRef(null);

  // Passed to rc-tree as ref, used to access inner methods like `scrollTo`
  const treeRef = useRef(null);

  const allIds = useMemo(
    () => getAllNodesIds(props.treeData),
    [props.treeData]
  );

  // Copy of all ids list. Used for diff with current list of all ids, to
  // find which ids were ADDED, so we could automatically set them to edit mode
  const prevAllIds = useRef(allIds);
  // Ref object which holds newly created space id, so we'd know to set a node to edit mode by
  // default for name change
  const newSpaceId = useRef(null);
  // Passed to tree nodes. When a node is rendered, and its id === newSpaceId, we set it by
  // default to edit mode & set `newSpaceId` back to `null` so it happens only once
  const setNewSpaceId = useCallback(
    (spaceId: number | null) => (newSpaceId.current = spaceId),
    []
  );

  useUpdateEffect(() => {
    // When 'allIds' is updated - meaning we have added / removed a node from tree.
    const spaceIdsDifference = difference(allIds, prevAllIds.current);

    // If there's a new ID that was not present in previous `allIds`, we update
    // `newSpaceId.current` to hold the newly added node id
    // It then passed to every node to know which one should be set to edit mode by default
    if (!isEmpty(spaceIdsDifference)) {
      setNewSpaceId(first(spaceIdsDifference));
    }

    prevAllIds.current = allIds;
  }, [allIds]);

  const onHandleSelected = useCallback(
    (node: SpaceTreeNodeType) => {
      if (handleSelected && canView(node)) {
        handleSelected(node.id);
      }
    },
    [handleSelected]
  );

  const onMoveNode = useCallback<TreeProps<SpaceTreeNodeType>['onDrop']>(
    (params) => {
      const draggedNode = params?.dragNode;
      const targetNode = params?.node;

      if (
        isNaN(draggedNode.id) ||
        isNaN(targetNode.id) ||
        !canEdit(targetNode) ||
        !canEdit(draggedNode)
      ) {
        return;
      }

      handleMoveSpace(draggedNode.id, targetNode.id);
    },
    [handleMoveSpace]
  );

  // Monitor react-dnd dragging state, to disable tree's `draggable` prop, so devices could be
  // dropped on nodes. Otherwise, if dragging a device while Tree's `draggable` set to true, the
  // hover & drop will not be recognized
  const monitor = useDragDropManager().getMonitor();
  useEffectOnce(function subscribeToDndStateChange() {
    monitor.subscribeToStateChange(() => {
      const isDragging = monitor.isDragging();

      setIsDraggingOutsideOfTree(isDragging);
    });
  });

  const filteredTreeData = useMemo<Array<SpaceTreeNodeType>>(() => {
    if (!searchTerm) return treeData;

    return filterTreeNodes(treeData, searchTerm);
  }, [searchTerm, treeData]);

  const isEditable = useCallback(
    (node) => !readonly && (isAdmin || canAdmin(node)),
    [isAdmin, readonly]
  );

  const onRightClick = useCallback<
    TreeProps<SpaceTreeNodeType>['onRightClick']
  >(
    ({ event, node }) => {
      if (!isEditable(node)) return;

      show(event, { id: node.id });
    },
    [isEditable, show]
  );

  const getIsDraggable = useCallback<DraggableFn>(
    (node: SpaceTreeNodeType) => {
      if (isDraggingOutsideOfTree || !isEditable(node) || !draggable) {
        return false;
      }

      const currentSpace = find({ id: node.id }, spaces);

      return currentSpace?.parent_id !== null;
    },
    [draggable, isDraggingOutsideOfTree, isEditable, spaces]
  );

  const titleRenderer = (node) => {
    // Highlights node's title styling when search is active
    const isInFocus =
      !!searchTerm &&
      includes(toLower(searchTerm), toLower((node as SpaceTreeNodeType).title));

    return (
      <DroppableTreeNode
        key={`${node.key}-${node.title}`}
        node={node}
        newSpaceId={newSpaceId.current}
        setNewSpaceId={setNewSpaceId}
        handleSelected={onHandleSelected}
        contextMenuPortalRef={contextMenuPortalRef}
        isInFocus={isInFocus}
        isEditable={isEditable(node)}
      />
    );
  };

  const onSelect = useCallback<TreeProps<SpaceTreeNodeType>['onSelect']>(
    (_, { node }) => {
      if (handleSelected) {
        handleSelected(node.id);
      }

      const nodeNotExpanded = !contains(node.id, expandedNodes);

      // Expand selected node if not expanded
      if (nodeNotExpanded) {
        setExpandedNodes([...expandedNodes, node.id]);
      }
    },
    [expandedNodes, handleSelected]
  );

  const onExpand = useCallback<TreeProps<SpaceTreeNodeType>['onExpand']>(
    (updatedExpandedNodes: Array<number>) =>
      setExpandedNodes(updatedExpandedNodes),
    []
  );

  // Don't render toggle caret for rooms
  const switcherIconRenderer = useCallback<
    (node: TreeNodeProps<SpaceTreeNodeType>) => IconType
  >(
    (node) =>
      isEmpty(node.data.children) ? null : (
        <Toggler expanded={node.expanded} handleToggle={noop} />
      ),
    []
  );

  const onCollapseAll = useCallback(
    // Setting expanded node to root only
    () => setExpandedNodes([treeData[0].id]),
    [treeData]
  );
  const onExpandAll = useCallback(() => setExpandedNodes(allIds), [allIds]);
  const onJumpToSelected = useCallback(() => {
    const space = find({ id: selected }, spaces);
    const path = space.path;

    // Expand selected node's path & scroll node into view
    setExpandedNodes(uniq([...expandedNodes, ...path]));

    if (treeRef?.current?.scrollTo) {
      treeRef.current.scrollTo({ key: selected, align: 'top' });
    }
  }, [expandedNodes, selected, spaces]);

  return (
    <TreeContextProvider expandedNodes={expandedNodes}>
      <Container className="w-100 tree-container">
        <div className="content-menus-portal" ref={contextMenuPortalRef} />

        <TreeHeader
          searchString={searchTerm}
          handleSearchOnChange={setSearchTerm}
          onCollapseAll={onCollapseAll}
          onExpandAll={onExpandAll}
          onJumpToSelected={onJumpToSelected}
        />

        {isEmpty(filteredTreeData) ? (
          <EmptyState label="No spaces found" src="empty-state-location" />
        ) : (
          <div>
            <AutoSizer disableWidth>
              {({ height }) => (
                <RcTree<SpaceTreeNodeType>
                  ref={treeRef}
                  treeData={filteredTreeData}
                  height={height}
                  onRightClick={onRightClick}
                  itemHeight={ITEM_HEIGHT}
                  fieldNames={FIELD_NAMES}
                  // Expand
                  defaultExpandedKeys={expandedNodes}
                  expandedKeys={searchTerm ? allIds : expandedNodes}
                  onExpand={onExpand}
                  // Select
                  defaultSelectedKeys={[selected]}
                  selectedKeys={[selected]}
                  onSelect={onSelect}
                  // Drag & drop
                  draggable={getIsDraggable}
                  dropIndicatorRender={() => <div />}
                  onDrop={onMoveNode}
                  allowDrop={() => true}
                  // Renderers
                  titleRender={titleRenderer}
                  switcherIcon={switcherIconRenderer}
                  icon={() => null}
                />
              )}
            </AutoSizer>
          </div>
        )}
      </Container>
    </TreeContextProvider>
  );
}

const Container = styled.div`
  margin-bottom: 0;
  padding: 0 0 15px;
  background-color: ${getStyledThemeColor('gray150')};
  display: grid;
  grid-template-rows: 0 max-content 1fr;
  position: relative;
  height: 100%;

  .rc-tree-list {
    .rc-tree-list-holder {
      .rc-tree-list-holder-inner {
        padding: 5px !important;

        .rc-tree-indent-unit {
          width: 10px;
        }

        .rc-tree-node.drop-container ~ .rc-tree-node {
          border-left-color: ${getStyledThemeColor('gray500')};
        }

        .rc-tree-treenode {
          height: ${ITEM_HEIGHT}px;
          display: grid;
          grid-template-columns: max-content min-content 1fr;
          align-items: center;
          border-left: none !important;

          &.drag-over,
          &.drop-target {
            background-color: ${getStyledThemeColor('primary')};
            color: ${getStyledThemeColor('white')};
            box-shadow: 0 16px 24px rgba(0, 0, 0, 0.05),
              0 2px 6px rgba(0, 0, 0, 0.05), 0 0 1px rgba(0, 0, 0, 0.05);

            .toggler {
              background-color: ${getStyledThemeColor('primary')} !important;
            }
          }

          &:not(.dragging) {
            .rc-tree-node-content-wrapper {
              &.rc-tree-node-selected {
                opacity: 1;
                background-color: ${getStyledThemeColor('gray300')};
                border: 1px dashed ${getStyledThemeColor('primary')};
              }
            }
          }

          &.dragging {
            background-color: transparent;
          }

          .toggler {
            height: 100%;
            display: flex;
            align-items: center;
          }

          .rc-tree-node-content-wrapper {
            display: grid !important;
            grid-template-columns: max-content 1fr;
            height: 100%;
            align-items: center;
            padding-left: 5px;
            border: 1px dashed transparent;
            box-shadow: none;
            transition: all 0.15s ease-in-out;

            .drop-indicator {
              position: absolute;
              bottom: 0;
              height: 1px;
              width: 100%;
              background-color: ${getStyledThemeColor('gray500')};
            }

            &:hover {
              background-color: ${getStyledThemeColor('gray200')};
            }

            .rc-tree-icon__customize {
              width: 0;
            }
          }
        }
      }
    }
  }
`;
