import {
  ActionIcon,
  Box,
  Button,
  ButtonProps,
  createStyles,
  Group,
  Stack,
  Text,
  TextInput,
  TextInputProps,
} from '@mantine/core';
import { useInterval } from '@mantine/hooks';
import { isEmpty } from 'lodash/fp';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';

import { ReactComponent as Send2 } from '@portals/icons/bold/send-2.svg';
import { ReactComponent as CollapseRight } from '@portals/icons/linear/collapse-right.svg';
import { ReactComponent as MessageNotif } from '@portals/icons/linear/message-notif.svg';
import { ReactComponent as MessageTick } from '@portals/icons/linear/message-tick.svg';
import { VerticalScrollBar } from '@portals/scrollbar';
import { ChatMessageType } from '@portals/types';

import chatEmptyStateSrc from './chat-empty-state.svg';
import { ChatMessage } from './ChatMessage';

const HALF_MINUTE = 30000;

interface ChatProps {
  title?: string;
  messages: ChatMessageType[];
  pollingFn: () => void;
  seen: boolean;
  updateSeen: (seen: boolean) => void;
  chatOwner: string;
  disabled?: boolean;
  sendMessage: (message: string) => Promise<void>;
  onClose?: () => void;
}

export function Chat({
  title,
  pollingFn,
  messages,
  seen,
  updateSeen,
  chatOwner,
  disabled = false,
  sendMessage,
  onClose,
}: ChatProps) {
  const { classes } = useStyles();

  const scrollRef = useRef<Scrollbars>();
  const pollingInterval = useInterval(pollingFn, HALF_MINUTE);

  const [inputValue, setInputValue] = useState('');

  const orderedMessages = useMemo(
    () =>
      messages.sort(
        (a, b) =>
          new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
      ),
    [messages]
  );

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      await sendMessage(inputValue);
      setInputValue('');
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(
    function handlePolling() {
      pollingInterval.start();

      return () => {
        pollingInterval.stop();
      };
    },
    [pollingInterval]
  );

  useEffect(
    function autoScrollToNewestMessage() {
      if (scrollRef.current && !isEmpty(orderedMessages)) {
        scrollRef.current.scrollToBottom();
      }
    },
    [orderedMessages]
  );

  return (
    <Box className={classes.container}>
      <Group position="apart" p={32} className={classes.header}>
        <Text size="lg" color="gray.8">
          {title ?? 'Chat'}
        </Text>

        <Group spacing={0}>
          <Button
            disabled={disabled}
            variant="white"
            color="blue_gray"
            onClick={() => updateSeen(!seen)}
            styles={buttonStyles}
            leftIcon={seen ? <MessageNotif /> : <MessageTick />}
          >
            Mark as {seen ? 'Unread' : 'Read'}
          </Button>

          {onClose && (
            <Button
              variant="white"
              color="blue_gray"
              styles={buttonStyles}
              leftIcon={<CollapseRight />}
              onClick={() => onClose?.()}
            >
              Close Chat
            </Button>
          )}
        </Group>
      </Group>

      {isEmpty(orderedMessages) ? (
        <Stack align="center" justify="center" h="100%" p={32}>
          <Box maw={270}>
            <img src={chatEmptyStateSrc} alt="" width="100%" height="auto" />
          </Box>
          <Text weight={600} color="gray.8">
            No messages yet
          </Text>
        </Stack>
      ) : (
        <VerticalScrollBar innerRef={scrollRef}>
          <Stack spacing={32} p={32}>
            {orderedMessages.map((message) => (
              <ChatMessage
                key={message.id}
                message={message}
                chatOwner={chatOwner}
              />
            ))}
          </Stack>
        </VerticalScrollBar>
      )}

      <form onSubmit={handleSubmit} className={classes.footer}>
        <Group>
          <TextInput
            radius="xl"
            disabled={disabled}
            placeholder="Type your message..."
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            styles={inputStyles}
          />
          <ActionIcon
            color="primary"
            variant="transparent"
            size="lg"
            disabled={!inputValue || disabled}
            type="submit"
          >
            <Send2 />
          </ActionIcon>
        </Group>
      </form>
    </Box>
  );
}

const inputStyles: TextInputProps['styles'] = (theme) => ({
  root: {
    flexGrow: 1,
  },
  input: {
    paddingInline: theme.spacing.lg,
    borderColor: theme.colors.gray[3],
  },
});

const buttonStyles: ButtonProps['styles'] = (theme) => ({
  icon: {
    width: 20,
    height: 20,
  },
});

const useStyles = createStyles((theme) => ({
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    borderBottom: `1px solid ${theme.colors.gray[3]}`,
  },
  footer: {
    paddingInline: 32,
    paddingBottom: 32,
    paddingTop: theme.spacing.xl,
    borderTop: `1px solid ${theme.colors.gray[3]}`,
  },
}));
