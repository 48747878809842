import { forEach } from 'lodash/fp';
import { useCallback } from 'react';
import { InvalidateQueryFilters, useQueryClient } from 'react-query';

export const useClearDataCache = () => {
  const queryClient = useQueryClient();

  return useCallback(
    (queriesKeys: Array<Array<string>>, filters?: InvalidateQueryFilters) => {
      forEach(
        (queryKey) => queryClient.invalidateQueries(queryKey, filters),
        queriesKeys
      );
    },
    [queryClient]
  );
};
