import React from 'react';

import { upcaseFirst } from '@portals/utils';

const EnvName = () => {
  const env = process.env.NX_ENV || 'development';

  if (env === 'production' || env === 'demo') {
    return null;
  }

  const color = env === 'staging' ? 'orange' : '#90EE90';

  return (
    <div className="env-info">
      <div className="name" style={{ backgroundColor: color }}>
        {upcaseFirst(env)}
      </div>
    </div>
  );
};

export default EnvName;
