import { useApiQuery } from '../hooks';
import { AUTH_BASE_URL, authQueryKeys } from './auth.constants';

export function useGoogleSSOEnabled() {
  return useApiQuery<boolean>(
    `${AUTH_BASE_URL}/google/enabled`,
    authQueryKeys.google.enabled(),
    {
      staleTime: 0,
    }
  );
}
