import { RichTextEditor, RichTextEditorProps } from '@mantine/tiptap';
import { Link } from '@tiptap/extension-link';
import { Underline as UnderlineExtension } from '@tiptap/extension-underline';
import { EditorOptions, useEditor } from '@tiptap/react';
import { StarterKit } from '@tiptap/starter-kit';
import React from 'react';

import {
  ItalicIcon,
  BoldIcon,
  UnderlineIcon,
  StrikethroughIcon,
  BulletListIcon,
  LinkIcon,
  OrderedListIcon,
} from './icons';

export interface TextEditorProps
  extends Omit<RichTextEditorProps, 'editor' | 'children'> {
  editorParams: Omit<Partial<EditorOptions>, 'extensions'>;
}

export function TextEditor({
  editorParams,
  ...richTextEditorProps
}: TextEditorProps) {
  const editor = useEditor({
    extensions: [StarterKit, UnderlineExtension, Link],
    ...editorParams,
  });

  return (
    <RichTextEditor editor={editor} {...richTextEditorProps}>
      {editorParams?.editable !== false && (
        <RichTextEditor.Toolbar sticky stickyOffset={0}>
          <RichTextEditor.ControlsGroup>
            <RichTextEditor.Italic icon={ItalicIcon} />
            <RichTextEditor.Bold icon={BoldIcon} />
            <RichTextEditor.Underline icon={UnderlineIcon} />
            <RichTextEditor.Strikethrough icon={StrikethroughIcon} />
          </RichTextEditor.ControlsGroup>

          <RichTextEditor.ControlsGroup>
            <RichTextEditor.Link icon={LinkIcon} />
          </RichTextEditor.ControlsGroup>

          <RichTextEditor.ControlsGroup>
            <RichTextEditor.BulletList icon={BulletListIcon} />
            <RichTextEditor.OrderedList icon={OrderedListIcon} />
          </RichTextEditor.ControlsGroup>
        </RichTextEditor.Toolbar>
      )}

      <RichTextEditor.Content />
    </RichTextEditor>
  );
}
