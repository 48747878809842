import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';
import { FC } from 'react';
import styled from 'styled-components';

import { FADE_IN_OUT } from '@portals/ui';

export const FormWrapper: FC<{ id: string }> = ({ children, id }) => (
  <AnimatePresence>
    <Container {...FADE_IN_OUT} key={id}>
      {children}
    </Container>
  </AnimatePresence>
);

const Container = styled(motion.div)`
  .modal-body {
    margin: 0;
    padding: 0;
  }

  .modal-footer {
    padding: 0;
    border: none;

    * {
      margin: 0;
    }
  }
`;
