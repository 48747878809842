import { Box, createStyles } from '@mantine/core';
import { AnimatePresence } from 'framer-motion';
import { filter, isEmpty, keys, size } from 'lodash/fp';
import React, { useMemo, useRef } from 'react';
import { useUpdateEffect } from 'react-use';

import { SmartTableProps } from '@portals/types';

import {
  NoDataState,
  NoFiltersResultsState,
  NoSearchResultsState,
} from '../components/data-states';
import Footer from '../components/Footer';
import { Header } from '../components/Header';
import { TableDetailsPanel } from '../components/TableDetailsPanel';
import TableScrollWrapper from '../components/TableScrollWrapper';
import TBody from '../components/TBody';
import { TGrid } from '../components/TGrid';
import THead from '../components/THead';
import { useTableInstance } from '../context';
import { useOnRowClick } from '../table.hooks';
import { hasStickyColumn } from '../table.utils';

export function SmartTable<
  TData extends object,
  TKeyField extends keyof TData
>({
  onSelected,
  name,
  exportParams = { isEnabled: true },
  noDataIndication,
  noSort = false,
  noFilters,
  noColumnsSelection = false,
  noHeader = false,
  isCompact = false,
  readOnly = false,
  additionalActions,
  selectedItemsActions,
  expandRow,
  rowMenu,
  menuProps = {},
  detailsPanel,
  viewType,
  gridView,
  onRowClick,
  keyField,
  columns,
}: Omit<SmartTableProps<TData, TKeyField>, 'data'>) {
  const { classes, cx } = useStyles(noHeader);
  const instance = useTableInstance<TData>();
  const { data, rows, state, page } = instance;

  const tableRef = useRef<HTMLDivElement>();

  const {
    clickedRowOriginalId,
    clickedRow,
    onRowClickHandler,
    onCloseDetailsPanel,
  } = useOnRowClick<TData, TKeyField>({
    keyField,
    rows,
    detailsPanel,
    onRowClick,
  });

  useUpdateEffect(() => {
    if (!onSelected) return;

    const toggledIndexes = filter(
      (index) => instance.state.selectedRowIds[index],
      keys(instance.state.selectedRowIds)
    );

    onSelected(toggledIndexes as string[]);
  }, [instance.state.selectedRowIds]);

  useUpdateEffect(
    function resetSelectedRowsOnDataUpdate() {
      if (!rows || !instance?.toggleAllRowsSelected) return;

      instance.toggleAllRowsSelected(false);
    },
    [rows, instance?.toggleAllRowsSelected]
  );

  const content = useMemo(() => {
    if (isEmpty(data)) {
      return (
        <>
          <TableScrollWrapper
            isCompact={isCompact}
            hasStickyColumn={hasStickyColumn(columns)}
          >
            <div />
            <div />
          </TableScrollWrapper>

          <NoDataState {...noDataIndication} />

          <Footer isCompact={isCompact} totalCount={0} />
        </>
      );
    }

    if (isEmpty(rows) && !!state.globalFilter) {
      return (
        <>
          <TableScrollWrapper
            isCompact={isCompact}
            hasStickyColumn={hasStickyColumn(columns)}
          >
            <THead<TData, TKeyField>
              noSort={noSort}
              noFilters={noFilters}
              isCompact={isCompact}
            />

            <div />
          </TableScrollWrapper>

          <NoSearchResultsState />

          <Footer isCompact={isCompact} totalCount={size(rows)} />
        </>
      );
    }

    if (isEmpty(rows)) {
      return (
        <>
          <TableScrollWrapper
            isCompact={isCompact}
            hasStickyColumn={hasStickyColumn(columns)}
          >
            <THead<TData, TKeyField>
              noSort={noSort}
              noFilters={noFilters}
              isCompact={isCompact}
            />

            <div />
          </TableScrollWrapper>

          <NoFiltersResultsState />

          <Footer isCompact={isCompact} totalCount={size(rows)} />
        </>
      );
    }

    return (
      <>
        <TableScrollWrapper
          isCompact={isCompact}
          hasStickyColumn={hasStickyColumn(columns)}
        >
          <THead<TData, TKeyField>
            noSort={noSort}
            noFilters={noFilters}
            isCompact={isCompact}
            viewType={viewType}
          />

          {viewType === 'grid' ? (
            <TGrid<TData, TKeyField>
              data={page}
              gridView={gridView}
              onRowClick={onRowClickHandler}
            />
          ) : (
            <TBody<TData, TKeyField>
              expandRow={expandRow}
              rowMenu={rowMenu}
              isCompact={isCompact}
              data={page}
              menuProps={menuProps}
              onRowClick={onRowClickHandler}
              readOnly={readOnly}
              keyField={keyField}
              clickedRowOriginalId={clickedRowOriginalId}
            />
          )}
        </TableScrollWrapper>

        {detailsPanel && (
          <AnimatePresence>
            {clickedRow?.id && (
              <TableDetailsPanel
                key={clickedRow?.id}
                type={detailsPanel.type}
                onClose={onCloseDetailsPanel}
                className={detailsPanel.className}
                width={detailsPanel.width}
              >
                {detailsPanel.renderer({
                  row: clickedRow,
                  onClose: onCloseDetailsPanel,
                  tableRef,
                })}
              </TableDetailsPanel>
            )}
          </AnimatePresence>
        )}

        <Footer isCompact={isCompact} totalCount={size(rows)} />
      </>
    );
  }, [
    columns,
    keyField,
    clickedRowOriginalId,
    data,
    rows,
    state,
    gridView,
    viewType,
    isCompact,
    noSort,
    noFilters,
    expandRow,
    rowMenu,
    page,
    menuProps,
    onRowClickHandler,
    detailsPanel,
    clickedRow,
    onCloseDetailsPanel,
    noDataIndication,
    readOnly,
  ]);

  return (
    <Box
      ref={tableRef}
      className={cx('smart-table-container', classes.container)}
    >
      {noHeader ? null : (
        <Box className="header-container" mb="md">
          <Header<TData, TKeyField>
            name={name}
            exportParams={exportParams}
            noColumnsSelection={noColumnsSelection}
            additionalActions={additionalActions}
            selectedItemsActions={selectedItemsActions}
            withGlobalSearch
            isCompact={isCompact}
          />
        </Box>
      )}

      {content}
    </Box>
  );
}

const useStyles = createStyles((theme, noHeader: boolean) => ({
  container: {
    height: '100%',
    width: '100%',
    display: 'grid',
    gridTemplateRows: noHeader ? '1fr' : 'max-content 1fr',
    position: 'relative',
  },
}));
