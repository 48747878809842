import { Button, ButtonProps, Group, Modal, Stack, Text } from '@mantine/core';
import React, { ReactNode } from 'react';

import { ModalProps } from '../components/Modals';

export interface ConfirmationModalProps
  extends ModalProps<{
    onApprove: () => void;
    onCancel: () => void;
    description: ReactNode;
    title?: ReactNode;
    confirmationLabel?: string;
    cancelLabel?: string;
    confirmButtonProps?: Omit<ButtonProps, 'children'>;
    cancelButtonProps?: Omit<ButtonProps, 'children'>;
  }> {}

export function ConfirmationModal({ data, closeMe }: ConfirmationModalProps) {
  const {
    onApprove,
    onCancel,
    description,
    title = 'Please confirm',
    confirmationLabel = 'Confirm',
    cancelLabel = 'Cancel',
    confirmButtonProps,
    cancelButtonProps,
  } = data;

  const onToggle = () => {
    onCancel();
    closeMe();
  };

  return (
    <Modal
      opened
      onClose={onToggle}
      title={title}
      padding={32}
      radius="md"
      size={560}
    >
      <Stack>
        <Text size="sm">{description}</Text>

        <Group position="right" pt={32}>
          <Button variant="default" onClick={onToggle} {...cancelButtonProps}>
            {cancelLabel}
          </Button>

          <Button color="red" onClick={onApprove} {...confirmButtonProps}>
            {confirmationLabel}
          </Button>
        </Group>
      </Stack>
    </Modal>
  );
}
