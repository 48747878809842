import {
  Box,
  createStyles,
  Group,
  Stack,
  StackProps,
  Text,
  Tooltip,
} from '@mantine/core';
import GoogleMapReact from 'google-map-react';
import React from 'react';

import { ReactComponent as LocationIcon } from '@portals/icons/bold/location.svg';

import { WidgetColorType } from '../../widgets.types';
import { MAP_STYLE } from './constants';

export interface DeviceLocationWidgetProps {
  title: string;
  coordinates: { lat: number; lng: number } | null;
  showLocationName: boolean;
  color: WidgetColorType;

  stackProps?: StackProps;
}

function formatLabel(spaceName: string) {
  const segments = spaceName.split('/');

  return segments.length === 1 ? segments[0] : segments.slice(1).join(' / ');
}

const MAX_ZOOM = 17;

export function DeviceLocationWidget({
  title,
  coordinates,
  showLocationName,
  color,

  stackProps = {},
}: DeviceLocationWidgetProps) {
  const { classes, theme } = useStyles();

  const marker = (
    <Tooltip
      disabled={!showLocationName}
      label={<Text size="xs">{formatLabel(title)}</Text>}
      px="sm"
      opened={true}
      withArrow
      withinPortal={false}
      bg="blue_gray.9"
      arrowSize={8}
    >
      <Group
        bg={color}
        className={classes.marker}
        grow
        sx={{ position: 'absolute', transform: 'translate(-50%, -50%)' }}
      >
        <LocationIcon color="white" />
      </Group>
    </Tooltip>
  );

  if (!coordinates) {
    return (
      <Stack
        className={classes.container}
        p="xl"
        spacing="lg"
        pos="relative"
        h="100%"
        w="100%"
        bg="white"
        justify="center"
        {...stackProps}
      >
        <Group
          bg="gray.2"
          grow
          sx={{
            width: '100%',
            height: '100%',
          }}
        >
          <Tooltip
            label={<Text size="xs">Unknown location</Text>}
            px="sm"
            opened={true}
            withArrow
            bg="blue_gray.9"
            arrowSize={8}
          >
            <Box mt="xl" />
          </Tooltip>
        </Group>
      </Stack>
    );
  }

  return (
    <Stack
      className={classes.container}
      p="xl"
      spacing="lg"
      pos="relative"
      h="100%"
      w="100%"
      bg="white"
      justify="center"
      {...stackProps}
    >
      <Box
        w="100%"
        h="100%"
        sx={{
          borderRadius: theme.radius.md,
          overflow: 'hidden',
        }}
      >
        <GoogleMapReact
          bootstrapURLKeys={{
            key: process.env.NX_GOOGLE_KEY || 'MISSING_GOOGLE_MAPS_API_KEY',
          }}
          defaultZoom={MAX_ZOOM - 2}
          center={coordinates}
          draggable={false}
          options={{
            maxZoom: MAX_ZOOM,
            styles: MAP_STYLE,
            disableDefaultUI: true,
            keyboardShortcuts: false,
          }}
        >
          {marker}
        </GoogleMapReact>
      </Box>
    </Stack>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    borderRadius: theme.radius.lg,
  },
  marker: {
    width: 32,
    height: 32,
    borderRadius: 16,
  },
}));
