import { SegmentedControl } from '@mantine/core';
import { sortBy, uniq, compact, values, filter } from 'lodash/fp';
import React, { useState, useMemo, FC } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';

import { setRoute } from '@portals/redux/actions/routing';
import { ProductType, SetRoute } from '@portals/types';

import ProductCard from './ProductCard';

type ProductsListProps = {
  products: Record<string, ProductType>;
};

type ProductsListConnectedActions = {
  setRoute: SetRoute;
};

const ProductsList: FC<ProductsListProps & ProductsListConnectedActions> = ({
  products,
  setRoute,
}) => {
  const [selectedCategory, setSelectedCategory] = useState('All');
  const categories = useMemo(
    () => [
      'All',
      ...compact(
        uniq(Object.values(products).map((product) => product.category))
      ),
    ],
    [products]
  );

  const visibleProducts = useMemo(() => {
    const productArray = sortBy('comingSoon', values(products));

    if (selectedCategory === 'All') {
      return productArray;
    }

    return filter(
      (product) => product.category === selectedCategory,
      productArray
    );
  }, [products, selectedCategory]);

  return (
    <div>
      <div className="text-center mb-4">
        <SegmentedControl
          data={categories}
          value={selectedCategory}
          onChange={setSelectedCategory}
          styles={{
            label: {
              textTransform: 'uppercase',
            },
          }}
        />
      </div>

      <Row>
        {visibleProducts.map((product) => (
          <Col
            md="4"
            key={product.id}
            onClick={() =>
              !product.disabled && setRoute(`/integrations/${product.id}`)
            }
          >
            <ProductCard {...product} />
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default connect<null, ProductsListConnectedActions>(null, { setRoute })(
  ProductsList
);
