import { Box, createStyles } from '@mantine/core';
import React, { FC, useCallback, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';

interface TableScrollWrapperProps {
  isCompact?: boolean;
  hasStickyColumn: boolean;
}

const TableScrollWrapper: FC<TableScrollWrapperProps> = ({
  children,
  isCompact,
  hasStickyColumn,
}) => {
  const { cx, classes } = useStyles({
    isCompact,
    hasStickyColumn,
  });
  const [isScrolled, setIsScrolled] = useState(false);

  const renderView = useCallback(
    (props) => (
      <Box
        {...props}
        className={cx('table-scroll-container', classes.container, {
          'with-shadow': isScrolled,
        })}
      />
    ),
    [classes.container, cx, isScrolled]
  );

  const onScrollFrame = useCallback(
    (scrollState) => {
      if (scrollState.scrollLeft > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    },
    [setIsScrolled]
  );

  return (
    <Scrollbars
      onScrollFrame={onScrollFrame}
      key="table-scroll-wrapper"
      renderView={renderView}
    >
      {children}
    </Scrollbars>
  );
};

const useStyles = createStyles(
  (
    theme,
    {
      isCompact,
      hasStickyColumn,
    }: { isCompact: boolean; hasStickyColumn: boolean }
  ) => ({
    container: {
      display: 'grid',
      gridTemplateRows: 'min-content 1fr',
      border: isCompact ? 'none' : `1px solid ${theme.colors.gray[3]}`,
      borderBottom: 'none',
      backgroundColor: theme.white,
      padding: isCompact || hasStickyColumn ? '0' : `0 ${theme.spacing.md}`,

      '&.with-shadow': {
        '.sticky': {
          boxShadow: `8px 0px 9px rgba(0, 0, 0, 0.07)`,
        },
      },
    },
  })
);

export default TableScrollWrapper;
