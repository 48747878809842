import { ActionIcon } from '@mantine/core';
import React, { FC } from 'react';

import { ReactComponent as ArrowLeft } from '@portals/icons/linear/arrow-left.svg';

import { useCommonDesign } from '../../../hooks/portal-config';

interface SidebarToggleButtonProps {
  isOpened: boolean;
  toggleSidebar: () => void;
}

export const SidebarToggleButton: FC<SidebarToggleButtonProps> = ({
  toggleSidebar,
  isOpened,
}) => {
  const design = useCommonDesign();

  return (
    <ActionIcon
      onClick={toggleSidebar}
      variant="transparent"
      sx={(theme) => ({
        cursor: 'pointer',
        backgroundColor:
          design?.navigation_menu?.hovered_section_background_color ||
          theme.colors.gray[1],
        color:
          design?.navigation_menu?.hovered_section_text_color ||
          theme.colors.blue_gray[6],

        svg: {
          width: 17,
          height: 17,
          transition: 'transform 0.15s ease-in-out',
          transform: `rotate(${isOpened ? 0 : 180}deg)`,
        },
      })}
    >
      <ArrowLeft />
    </ActionIcon>
  );
};
