import React, { FC, ReactChild, useCallback } from 'react';
import { ListGroupItem, Row, Col } from 'reactstrap';
import styled, { css } from 'styled-components';

import { useActionDispatch } from '@portals/redux';
import { setRoute as setRouteAction } from '@portals/redux/actions/routing';

type TableProps = {
  icon: ReactChild;
  title: string;
  message: string;
  link?: string;
  spacing?: boolean;
};
const NavbarDropdownItem: FC<TableProps> = ({
  icon,
  title,
  message,
  link,
  spacing,
}) => {
  const setRoute = useActionDispatch(setRouteAction);

  const navigateToRoute = useCallback(() => {
    if (link) {
      setRoute(link);
    }
  }, [link, setRoute]);

  return (
    <StyledListGroupItem $link={link}>
      <Row noGutters className="align-items-center">
        <Col xs={2}>{icon}</Col>
        <Col xs={10} className={spacing ? 'pl-2' : null}>
          <div className="text-dark">{title}</div>
          <div className="text-muted small mt-1" onClick={navigateToRoute}>
            {message}
          </div>
        </Col>
      </Row>
    </StyledListGroupItem>
  );
};

const StyledListGroupItem = styled(ListGroupItem)<{ $link: string }>`
  ${({ $link }) =>
    $link &&
    css`
      cursor: pointer;
    `}
`;

export default NavbarDropdownItem;
