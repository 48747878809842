import { castArray, every, some } from 'lodash/fp';
import { useCallback, useMemo } from 'react';

import { useCurrentUser } from '@portals/api/ui';
import {
  AccessLevelEnum,
  TenantType,
  UserPermissionNames,
  UserPermissions,
  UserPermissionsCallback,
} from '@portals/types';

import { useAppConfig } from '../../context';
import { useCommonConfig } from '../../hooks/portal-config';

export type CanViewFn = UserPermissionsCallback;
export type CanEditFn = UserPermissionsCallback;

interface UsePermissionAccessReturnType {
  canView: UserPermissionsCallback;
  canEdit: UserPermissionsCallback;
  isAdmin: boolean;
  isSuperAdmin: boolean;
  isMissingAllPermissions: boolean;
}

export function usePermissionAccess(): UsePermissionAccessReturnType {
  const { tenantType } = useAppConfig();
  const config = useCommonConfig();
  const currentUser = useCurrentUser();

  const isRoleManagementEnabled = useMemo(() => {
    if (!tenantType) return false;

    return (
      config.data?.[tenantType]?.pricing_plan?.features
        ?.role_based_permissions === 1
    );
  }, [config, tenantType]);

  const isAdmin = useMemo(() => {
    if (!currentUser.isFetched || !currentUser.data) return false;

    const { super_admin, local_admin } = currentUser.data;

    return super_admin || local_admin;
  }, [currentUser]);

  const isMissingAllPermissions = useMemo(() => {
    // currently in organizations portal permissions are not used
    if (tenantType === TenantType.Organization || isAdmin) return false;

    if (!currentUser.isFetched || !currentUser.data?.permissions) return true;

    return every(
      (accessLevel) => accessLevel === AccessLevelEnum.None,
      currentUser.data.permissions
    );
  }, [currentUser, tenantType, isAdmin]);

  const canView = useCallback<UserPermissionsCallback>(
    (permissionKeys) => {
      if (!currentUser.isFetched || !currentUser.data) return false;

      if (!isRoleManagementEnabled) return true;

      if (isAdmin) return true;

      const keysArray = castArray(permissionKeys);
      const { permissions } = currentUser.data;

      return !permissions
        ? false
        : hasRequiredAccess(permissions, keysArray, AccessLevelEnum.View);
    },
    [currentUser, isAdmin, isRoleManagementEnabled]
  );

  const canEdit = useCallback<UserPermissionsCallback>(
    (permissionKeys) => {
      if (!currentUser.isFetched || !currentUser.data) return false;

      if (!isRoleManagementEnabled) return true;

      if (isAdmin) return true;

      const keysArray = castArray(permissionKeys);
      const { permissions } = currentUser.data;

      return !permissions
        ? false
        : hasRequiredAccess(permissions, keysArray, AccessLevelEnum.Edit);
    },
    [currentUser, isAdmin, isRoleManagementEnabled]
  );

  return {
    canView,
    canEdit,
    isAdmin,
    isSuperAdmin: Boolean(currentUser.data?.super_admin),
    isMissingAllPermissions,
  };
}

function hasRequiredAccess(
  permissions: UserPermissions,
  permissionKeys: Array<UserPermissionNames>,
  accessLevel: AccessLevelEnum
) {
  return some(
    (permissionKey) => permissions[permissionKey] >= accessLevel,
    permissionKeys
  );
}
