import { createStyles, LoadingOverlay, Stack, Text } from '@mantine/core';
import { isEmpty } from 'lodash/fp';
import React, { FC, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { useDevicesBySpaceId } from '@portals/api/organizations';
import { EmptyState } from '@portals/table';

import { useFilteredDevicesList } from '../../../../components/common/DevicesList/devices-list.hooks';

interface DevicesListProps {
  spaceName: string;
}

export const DevicesList: FC<DevicesListProps> = ({ spaceName }) => {
  const { classes } = useStyles();
  const history = useHistory();
  const params = useParams<{ space_id: string }>();
  const devices = useDevicesBySpaceId({ spaceId: Number(params.space_id) });

  const { devicesList } = useFilteredDevicesList(devices?.data, (device) => {
    history.push(`/devices/${device.id}`);
  });

  const content = useMemo(() => {
    if (!devices.isFetched) return <LoadingOverlay visible />;

    return isEmpty(devicesList) ? (
      <Stack
        align="center"
        justify="center"
        p="lg"
        sx={{ height: '100%', textAlign: 'center' }}
      >
        <Stack spacing="xs">
          <EmptyState label="" />

          <Text weight={600} size="md" color="dark">
            {spaceName}
          </Text>
          <Text size="sm" color="blue_gray">
            has no assigned devices
          </Text>
        </Stack>
      </Stack>
    ) : (
      devicesList
    );
  }, [devices.isFetched, devicesList, spaceName]);

  return <Stack className={classes.container}>{content}</Stack>;
};

const useStyles = createStyles((theme) => ({
  container: {
    height: '100%',
    overflow: 'auto',
    gap: 0,

    '.devices-group-container': {
      marginTop: 0,
      marginBottom: 0,

      '.devices-group-label': {
        width: '100%',
        transform: 'none',
      },

      '.devices-wrapper': {
        padding: `${theme.spacing.md} ${theme.spacing.md}`,
        gridTemplateColumns: 'repeat(auto-fill, 100%)',
        marginBottom: 0,

        '.device-card-wrapper': {
          width: '100%',

          '.drag-icon': {
            display: 'none',
          },
        },
      },
    },
  },
}));
