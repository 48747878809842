import {
  ButtonProps,
  ButtonStylesNames,
  ButtonStylesParams,
} from '@mantine/core';

import { ThemeComponentOverrides } from '../common-types';

export const Button: ThemeComponentOverrides<
  ButtonProps,
  ButtonStylesNames,
  ButtonStylesParams
> = {
  styles: {
    root: {
      fontWeight: 400,
    },
    label: {
      fontWeight: 400,
    },
  },
  sizes: {
    xs: () => ({
      root: {
        height: 30,
      },
    }),
    sm: (theme) => ({
      root: {
        height: 34,
      },
      label: {
        fontSize: theme.fontSizes.sm,
      },
    }),
    md: (theme) => ({
      root: {
        height: 40,
      },
      label: {
        fontSize: theme.fontSizes.sm,
      },
    }),
  },
};
