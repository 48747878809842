import {
  SwitchProps,
  SwitchStylesNames,
  SwitchStylesParams,
} from '@mantine/core';

import { ThemeComponentOverrides } from '../common-types';

export const Switch: ThemeComponentOverrides<
  SwitchProps,
  SwitchStylesNames,
  SwitchStylesParams
> = {
  styles: (theme) => ({
    label: {
      marginBottom: 0,
      color: theme.colors.gray[7],
      fontWeight: 400,
      fontSize: theme.fontSizes.sm,
    },
    input: {
      backgroundColor: theme.colors.blue_gray[1],

      '&:disabled': {
        backgroundColor: theme.colors.blue_gray[1],

        '&:before': {
          backgroundColor: theme.colors.gray[4],
        },
      },
    },
    description: {
      fontSize: theme.fontSizes.sm,
    },
  }),
};
