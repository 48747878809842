import {
  Box,
  createStyles,
  Paper,
  PaperProps,
  StackProps,
  Text,
} from '@mantine/core';
import React, { FC, ReactNode } from 'react';

interface CheckoutCardProps {
  title: ReactNode;
  paperProps?: PaperProps;
  stackProps?: StackProps;
}

export const CardContainer: FC<CheckoutCardProps> = ({
  title,
  paperProps = {},
  stackProps = {},
  children,
}) => {
  const { classes } = useStyles();

  return (
    <Paper p={0} radius="md" className={classes.container} {...paperProps}>
      <Box p="xl" {...stackProps}>
        <Text className="title" size="md" color="gray.8">
          {title}
        </Text>

        {children}
      </Box>
    </Paper>
  );
};

const useStyles = createStyles((theme) => ({
  container: {
    border: `1px solid ${theme.colors.gray[2]}`,
    display: 'grid',
    overflow: 'hidden',
    whiteSpace: 'nowrap',

    '>div': {
      overflow: 'hidden',
      display: 'grid',
      gridTemplateRows: 'min-content minmax(0px, 1fr)',
      gap: theme.spacing.lg,

      '.title': {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
  },
}));
