import { Box, createStyles, Group, Text } from '@mantine/core';
import React from 'react';

import { DeviceStatusType } from '@portals/types';

import { getDeviceStatusColor, getDeviceStatusText } from '../RouteModals';

interface DeviceStatusBadgeProps {
  status: DeviceStatusType;
}

export function DeviceStatusBadge({ status }: DeviceStatusBadgeProps) {
  const color = getDeviceStatusColor(status);
  const { classes } = useStyles();

  return (
    <Group
      bg={color.bg}
      className={classes.badge}
      py={6}
      px={12}
      spacing={4}
      noWrap
    >
      <Box bg={color.indicator} className={classes.dot} />

      <Text c="gray.8" size="xs">
        {getDeviceStatusText(status)}
      </Text>
    </Group>
  );
}

const useStyles = createStyles(() => ({
  badge: {
    borderRadius: 21,
  },
  dot: {
    display: 'inline-block',
    width: 8,
    height: 8,
    borderRadius: '50%',
  },
}));
