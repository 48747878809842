import {
  ActionIcon,
  Box,
  createStyles,
  Group,
  Menu,
  Stack,
  Text,
  TextInput,
} from '@mantine/core';
import { isEmpty, keys, map, size, toLower } from 'lodash/fp';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { useCurrentUser } from '@portals/api/ui';
import { ReactComponent as ArrowRight1 } from '@portals/icons/linear/arrow-right-1.svg';
import { ReactComponent as TickSimple } from '@portals/icons/linear/tick-simple.svg';
import { switchTenant } from '@portals/redux/actions/auth';
import { VerticalScrollBar } from '@portals/scrollbar';

import { useAppConfig } from '../../../../context';
import { useCommonConfig } from '../../../../hooks/portal-config';
import { useCurrentUserAccessibleTenants } from '../../../../hooks/users';

interface TenantSwitchProps {
  toggleTenantMenu: () => void;
  toggleTenantSwitch: () => void;
  isStandalone?: boolean;
}

export const TenantSwitch: FC<TenantSwitchProps> = ({
  toggleTenantSwitch,
  toggleTenantMenu,
  isStandalone = false,
}) => {
  const { classes, cx, theme } = useStyles({ isStandalone });

  const [searchValue, setSearchValue] = useState('');

  const dispatch = useDispatch();
  const { tenantType } = useAppConfig();
  const currentUser = useCurrentUser();
  const config = useCommonConfig();

  const accessibleTenants = useCurrentUserAccessibleTenants();

  const options = useMemo(() => {
    if (!accessibleTenants) return [];

    const groups = map(
      (tenantId) => ({
        value: tenantId,
        id: accessibleTenants[tenantId].id,
        label: accessibleTenants[tenantId].display_name,
        name: accessibleTenants[tenantId].name,
      }),
      keys(accessibleTenants)
    );

    if (!isEmpty(searchValue)) {
      const lowerCaseSearchValue = toLower(searchValue);

      return groups.filter(
        ({ label, name }) =>
          toLower(label).includes(lowerCaseSearchValue) ||
          toLower(name).includes(lowerCaseSearchValue)
      );
    }

    return groups;
  }, [accessibleTenants, searchValue]);

  const changeTenant = useCallback(
    (newTenantId) => {
      toggleTenantMenu();

      if (newTenantId === currentUser?.data?.id) {
        return;
      }

      dispatch(
        switchTenant(
          { id: newTenantId, ...accessibleTenants?.[newTenantId] },
          tenantType,
          true
        )
      );
    },
    [accessibleTenants, currentUser, dispatch, tenantType, toggleTenantMenu]
  );

  if (!currentUser.data || size(accessibleTenants) < 2) {
    return null;
  }

  return (
    <Box className={cx('tenant-switch-container', classes.container)}>
      <Box p="md" className={cx('tenant-switch-header', classes.header)}>
        {isStandalone ? null : (
          <ActionIcon
            onClick={toggleTenantSwitch}
            className={cx(classes.toggle, 'tenant-menu-toggle')}
          >
            <ArrowRight1 />
          </ActionIcon>
        )}

        <TextInput
          value={searchValue}
          className={classes.input}
          placeholder="Find tenant..."
          onChange={(event) => setSearchValue(event.target.value)}
          autoFocus
          data-autofocus
        />
      </Box>

      <VerticalScrollBar
        renderView={(props) => (
          <Stack {...props} spacing="xs" py="xs" px="sm" />
        )}
      >
        {options.map(({ label, value, id, name }, index) => {
          const isActive = id === config?.data?.[tenantType]?.id;

          return (
            <Menu.Item
              key={value}
              tabIndex={index + 1}
              onClick={() => !isActive && changeTenant(value)}
              className={cx(classes.tenant, {
                'current-tenant': isActive,
              })}
            >
              <Group align="center" position="apart">
                <Stack spacing={4}>
                  <Text size="sm" color="blue_gray.9" weight={500}>
                    {label}
                  </Text>

                  <Text size="xs" color="blue_gray.6">
                    {name}
                  </Text>
                </Stack>

                {isActive ? (
                  <TickSimple
                    color={theme.fn.primaryColor()}
                    width={16}
                    height={16}
                  />
                ) : null}
              </Group>
            </Menu.Item>
          );
        })}
      </VerticalScrollBar>
    </Box>
  );
};

const useStyles = createStyles((theme, params: { isStandalone: boolean }) => ({
  container: {
    height: 390,
    width: 300,
    display: 'grid',
    gridTemplateRows: 'max-content 1fr',
  },
  header: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: params.isStandalone ? '1fr' : '18px 1fr',
    gap: theme.spacing.md,
    borderBottom: `1px solid ${theme.colors.gray[2]}`,
  },
  input: {
    fontSize: theme.fontSizes.sm,

    input: {
      paddingLeft: 0,
      border: 'none',

      '::placeholder': {
        fontWeight: 400,
        fontSize: theme.fontSizes.md,
      },
    },
  },
  toggle: {
    transform: 'rotate(180deg)',
    color: theme.colors.blue_gray[2],
    transition: 'color 0.1s ease-in-out',
    width: 18,
    height: 18,
    svg: {
      width: 18,
      height: 18,
    },

    '&:hover': {
      color: theme.colors.blue_accent[4],
    },
  },
  tenant: {
    padding: theme.spacing.sm,
    flexShrink: 0,
    transition: 'all 0.1s ease-in-out',
    cursor: 'pointer',
    display: 'grid',
    alignItems: 'center',
    borderRadius: theme.radius.md,
    gridTemplateColumns: '1fr',
    backgroundColor: theme.white,

    '&:hover': {
      backgroundColor: theme.colors.gray[0],
    },

    '&.current-tenant': {
      gridTemplateColumns: '1fr max-content',
      backgroundColor: `${theme.colors.blue[0]} !important`,
      pointerEvents: 'none',
    },
  },
}));
