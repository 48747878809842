import { FormikErrors, FormikProps } from 'formik';
import {
  ChangeEvent,
  FocusEventHandler,
  FunctionComponent,
  MutableRefObject,
  ReactNode,
} from 'react';
import { AnySchema } from 'yup';
import { AnyObject } from 'yup/lib/types';

export type InputType =
  | 'text'
  | 'email'
  | 'select'
  | 'file'
  | 'radio'
  | 'checkbox'
  | 'textarea'
  | 'button'
  | 'reset'
  | 'submit'
  | 'date'
  | 'datetime-local'
  | 'hidden'
  | 'image'
  | 'month'
  | 'number'
  | 'range'
  | 'search'
  | 'tel'
  | 'url'
  | 'week'
  | 'password'
  | 'datetime'
  | 'time'
  | 'color';

export enum FieldTypeEnum {
  Custom = 'custom',
  File = 'file',
  Group = 'group',
  VirtualizedSelect = 'virtualized_select',
  Password = 'password',
  Select = 'select',
  Multiselect = 'multiselect',
  ImageSelector = 'image_selector',
  Email = 'email',
  Number = 'number',
  Textarea = 'textarea',
  Text = 'text',
  Checkbox = 'checkbox',
  Datetime = 'datetime',
  Color = 'color',
  String = 'string',
  JSON = 'JSON',
}

export type FieldTypeType = FieldTypeEnum;

export interface FieldType<TOptionType = any> {
  name: string;
  title?: ReactNode;
  type?: FieldTypeType | InputType;
  children?: Array<FieldType>;
  inputProps?: {
    [key: string]: any;
    startAdornment?: ReactNode;
    endAdornment?: ReactNode;
  };
  options?: Record<string, TOptionType>;
  validations?: AnySchema<any, AnyObject, any>;
  default?: any;
  clearable?: boolean;
  required?: boolean | ((...params: any) => boolean);
  disabled?: boolean | ((...params: any) => boolean);
  readOnly?: boolean | ((...params: any) => boolean);
  hidden?: boolean | ((...params: any) => boolean);
  horizontal?: boolean;
  placeholder?: any;
  component?: FunctionComponent<any>;
  parse?: (valueToParse: any) => any;
}

export type AutoFormikProps = {
  fields: Array<FieldType>;
  handleSubmit?: (updatedForm: Record<string, any>) => void;
  title?: ReactNode;
  subTitle?: string;
  initialValues?: Record<string, any>;
  serverError?: string;
  onChange?: (updatedForm: Record<string, any>) => void;
  inputProps?: Record<string, any>;
  readOnly?: boolean | ((...params: any) => boolean);
  bodyWrap?: ({ children }: { children: ReactNode }) => ReactNode;
  actionWrap?: ({ children }: { children: ReactNode }) => ReactNode;
  buttonsProps?: Record<string, any>;
  inProgress: boolean;
  submitTitle?: string;
  dismissTitle?: string;
  wrapped?: (...params: any) => ReactNode;
  modal?: () => void;
  requireChange?: boolean;
  innerRef?: MutableRefObject<any>;
  formikProps?: Partial<FormikProps<any>>;
};

export type FieldRendererProps = AutoFormikProps['inputProps'] &
  FieldType['inputProps'] & {
    field: FieldType;
    type?: FieldTypeType | InputType;
    disabled?: boolean;
    readOnly?: boolean;
    required?: boolean;
    value: any;
    handleChange?: (event: ChangeEvent<any>) => void;
    setFieldValue?: (name: string, value: any) => void;
    error?:
      | FormikErrors<any>
      | Array<FormikErrors<any>>
      | string
      | Array<string>;
    handleBlur?: FocusEventHandler<HTMLElement>;
    className?: string;
  };

export enum ImageTypeEnum {
  Logo = 'logo',
  Favicon = 'favicon',
  Hero = 'hero',
}

export type ImageTypeType = ImageTypeEnum;

export type SetFieldValueType<T = any> = (
  path: string | Array<string>,
  value: T | Record<string, never>
) => void;
