import React, { createContext, FC, useContext } from 'react';

const TreeContext = createContext<{
  expandedNodes: Array<number>;
}>({
  expandedNodes: [],
});

interface TreeContextProviderProps {
  expandedNodes: Array<number>;
}

export const TreeContextProvider: FC<TreeContextProviderProps> = ({
  children,
  expandedNodes,
}) => {
  return (
    <TreeContext.Provider value={{ expandedNodes }}>
      {children}
    </TreeContext.Provider>
  );
};

export const useTreeContext = () => useContext(TreeContext);
