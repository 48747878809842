import {
  Box,
  createStyles,
  Group,
  Stack,
  Image,
  Text,
  Button,
  Avatar,
  Paper,
  Divider,
  SimpleGrid,
  Badge,
} from '@mantine/core';
import { find } from 'lodash/fp';
import React, { FC } from 'react';
import { useParams } from 'react-router';

import { ReactComponent as Global } from '@portals/icons/linear/global.svg';
import { ReactComponent as MessageQuestion } from '@portals/icons/linear/message-question.svg';
import { ReactComponent as Shield } from '@portals/icons/linear/shield.svg';

import previewSrc from './assets/preview.png';
import { CATEGORY, SERVICES } from './integrations.constants';

interface ServicePreviewProps {
  onDisplayPayment: () => void;
}

export const ServicePreview: FC<ServicePreviewProps> = ({
  onDisplayPayment,
}) => {
  const params = useParams<{ integration: string }>();
  const service = find({ id: params.integration }, SERVICES);
  const { classes } = useStyles();

  return (
    <Box className={classes.container}>
      <Stack className={classes.content} spacing={32}>
        <Group position="apart">
          <Group>
            <Avatar
              radius="md"
              src={service.logo}
              size={64}
              sx={(theme) => ({
                border: `1px solid ${theme.colors.blue_gray[0]}`,
                padding: 4,
              })}
              imageProps={{
                style: {
                  objectFit: 'contain',
                },
              }}
            />

            <Stack spacing={0}>
              <Text
                sx={(theme) => ({
                  fontSize: 32,
                  fontWeight: 600,
                  color: theme.colors.gray[9],
                })}
              >
                {service.name}
              </Text>

              <Text
                sx={(theme) => ({
                  fontSize: 14,
                  fontWeight: 500,
                  color: theme.colors.gray[5],
                })}
              >
                {`by ${service.owner}`}
              </Text>
            </Stack>
          </Group>

          <Button onClick={onDisplayPayment}>Connect to XYTE</Button>
        </Group>

        <Image src={previewSrc} />

        <Box className={classes.info}>
          <Stack>
            <Text
              size="sm"
              sx={(theme) => ({ color: theme.colors.gray[7] })}
              mb="xl"
            >
              {service.description}
            </Text>

            <Stack>
              <Text size="md" sx={(theme) => ({ color: theme.colors.gray[8] })}>
                Pricing
              </Text>

              <SimpleGrid cols={2}>
                <Paper withBorder radius="md" p="lg">
                  <Stack>
                    <Text size="lg">Starter</Text>

                    <Group spacing="xs" align="end">
                      <Text size="xl" weight={600} inline>
                        $12
                      </Text>
                      <Text
                        size="xl"
                        weight={600}
                        inline
                        sx={(theme) => ({
                          color: theme.colors.gray[4],
                        })}
                      >
                        /
                      </Text>
                      <Text
                        size="sm"
                        weight={600}
                        inline
                        sx={(theme) => ({
                          color: theme.colors.gray[4],
                        })}
                      >
                        month
                      </Text>
                    </Group>

                    <Stack spacing={0}>
                      <Text size="sm" color="dimmed">
                        Free for up to 2 team members.
                      </Text>
                      <Text size="sm" color="dimmed">
                        This plan comes without support.
                      </Text>
                    </Stack>
                  </Stack>
                </Paper>

                <Paper withBorder radius="md" p="lg">
                  <Stack>
                    <Text size="lg">Premium</Text>

                    <Group spacing="xs" align="end">
                      <Text size="xl" weight={600} inline>
                        $25
                      </Text>
                      <Text
                        size="xl"
                        weight={600}
                        inline
                        sx={(theme) => ({
                          color: theme.colors.gray[4],
                        })}
                      >
                        /
                      </Text>
                      <Text
                        size="sm"
                        weight={600}
                        inline
                        sx={(theme) => ({
                          color: theme.colors.gray[4],
                        })}
                      >
                        month
                      </Text>
                    </Group>

                    <Stack spacing={0}>
                      <Text size="sm" color="dimmed">
                        Free for all team members.
                      </Text>
                      <Text size="sm" color="dimmed">
                        Support included.
                      </Text>
                    </Stack>
                  </Stack>
                </Paper>
              </SimpleGrid>

              <Box sx={{ height: 30, width: '100%' }} />
            </Stack>
          </Stack>

          <Stack>
            <Paper
              radius="md"
              withBorder
              sx={{ background: 'white', height: 'fit-content' }}
              p="lg"
            >
              <Stack
                sx={(theme) => ({
                  color: theme.colors.gray[8],
                  fontSize: theme.fontSizes.sm,
                })}
              >
                <Group align="center">
                  <Global />
                  Developer website
                </Group>

                <Divider />

                <Group align="center">
                  <Shield />
                  Terms and privacy
                </Group>

                <Divider />

                <Group align="center">
                  <MessageQuestion />
                  Support
                </Group>
              </Stack>
            </Paper>

            <Stack>
              <Text size="md" sx={(theme) => ({ color: theme.colors.gray[8] })}>
                Category
              </Text>

              <Group>
                <Badge size="lg">{CATEGORY[service.category]}</Badge>
              </Group>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Box>
  );
};

const useStyles = createStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
    overflow: 'auto',
  },
  content: {
    maxWidth: 888,
    height: '100%',
    width: '100%',
    paddingTop: 30,
  },
  info: {
    display: 'grid',
    gridTemplateColumns: '1fr 280px',
    gap: 46,
  },
}));
