import { LoadingOverlay } from '@mantine/core';
import React, { FC, ReactNode, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components';

import { usePortalConfig } from '@portals/api/organizations';
import { AuthenticationGuard } from '@portals/framework';
import { useAppConfig } from '@portals/framework/context';
import { useAuth } from '@portals/redux';
import { setRoute } from '@portals/redux/actions/routing';
import {
  OrganizationFeatureFlagsType,
  OrganizationPortalCapabilities,
  OrganizationPricingPlanFeatures,
} from '@portals/types';
import { getStyledThemeColor } from '@portals/utils';

import Header from '../components/layout/header';
import Sidebar from '../components/layout/sidebar';

type MobileLayoutProps = {
  children: ReactNode;
  isAuth?: boolean;
};

const MobileLayout: FC<MobileLayoutProps> = ({ children, isAuth = false }) => {
  const { extraLayout } = useAppConfig<
    OrganizationFeatureFlagsType,
    OrganizationPortalCapabilities,
    OrganizationPricingPlanFeatures
  >();
  const [isOpen, setIsOpen] = useState(false);
  const portalConfig = usePortalConfig({ enabled: isAuth });

  if (isAuth && !portalConfig.isFetched) return <LoadingOverlay visible />;

  return (
    <Container>
      {extraLayout?.globalLayout ? extraLayout.globalLayout() : null}

      <Header onOpen={() => setIsOpen(true)} isAuth={isAuth} />

      {isAuth ? null : (
        <Sidebar isOpen={isOpen} onClose={() => setIsOpen(false)} />
      )}

      <Content className="mobile-layout-container">{children}</Content>
    </Container>
  );
};

export const AuthMobileLayout: FC<MobileLayoutProps> = ({ children }) => {
  const authentication = useAuth();

  if (authentication) return <Redirect to="/" />;

  return <MobileLayout isAuth>{children}</MobileLayout>;
};

export const DashboardMobileLayout: FC<MobileLayoutProps> = ({ children }) => {
  const auth = useAuth();
  const dispatch = useDispatch();
  const { extraLayout } = useAppConfig();
  const { dashboardDataLayout: DataLayout } = extraLayout;

  useEffect(
    function redirectedAuthenticatedUser() {
      if (!auth?.token) return;

      const afterAuth = localStorage.getItem('afterAuth');

      if (!afterAuth || afterAuth === '/') return;

      localStorage.removeItem('afterAuth');

      dispatch(setRoute(afterAuth, true));
    },
    [auth?.token, dispatch]
  );

  if (DataLayout) {
    return (
      <AuthenticationGuard>
        <DataLayout>
          <MobileLayout>{children}</MobileLayout>
        </DataLayout>
      </AuthenticationGuard>
    );
  }

  return (
    <AuthenticationGuard>
      <MobileLayout>{children}</MobileLayout>
    </AuthenticationGuard>
  );
};

const Container = styled.div`
  position: fixed;
  height: 100vh;
  width: 100%;
  padding-bottom: env(safe-area-inset-bottom);
  padding-top: env(safe-area-inset-top);
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

const Content = styled.div`
  flex: 1;
  background-color: ${getStyledThemeColor('white')};
  overflow: auto;
  height: 100vh;
`;
