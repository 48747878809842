import { hexToPalette } from '@portals/utils';

const BASE_COLOR = {
  blue: '#5b7dff',
  red: '#f44455',
  yellow: '#fcc100',
  orange: '#fd7e14',
  green: '#22AA8F',
  lightGreen: '#1DE9B6',
  cyan: '#2979FF',
  pink: '#a180da',
  dark: '#354052',
  brown: '#99681E',
  white: '#ffffff',
  black: '#000000',

  green100: '#F3F5F8',

  gray100: '#f8f9fa',
  gray150: '#F3F4F6',
  gray200: '#e9ecef',
  gray300: '#dee2e6',
  gray400: '#ced4da',
  gray500: '#adb5bd',
  gray550: '#90a4ae',
  gray600: '#6c757d',
  gray700: '#495057',
  gray750: '#546e7a',
  gray800: '#343a40',
  gray900: '#212529',
};

const THEME = {
  color: {
    ...BASE_COLOR,

    primary: BASE_COLOR.cyan,
    ...hexToPalette({ hex: BASE_COLOR.cyan, prefix: 'primary', isDark: false }),
    secondary: BASE_COLOR.pink,
    ...hexToPalette({
      hex: BASE_COLOR.pink,
      prefix: 'secondary',
      isDark: false,
    }),
    tertiary: BASE_COLOR.pink,
    success: BASE_COLOR.green,
    info: BASE_COLOR.blue,
    warning: BASE_COLOR.yellow,
    danger: BASE_COLOR.red,
    light: BASE_COLOR.gray100,
  },
  layout: {
    sidebarWidthClosed: '76px',
    sidebarWidthOpened: '300px',
  },
};

export default THEME;
