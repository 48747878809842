import { find } from 'lodash/fp';
import React from 'react';
import { Route, useLocation } from 'react-router-dom';

import { useAppConfig } from '../context';
import {
  RouteOverlayLocationState,
  RouteOverlayType,
} from './route-overlays.types';

export function RouteOverlayWrapper({
  overlayType,
}: {
  overlayType: RouteOverlayType;
}) {
  const { routes } = useAppConfig();
  const location = useLocation<RouteOverlayLocationState>();

  const routeOverlay = location?.state?.routeOverlay;

  if (!routeOverlay) return null;

  const { path, entityId } = routeOverlay;

  const routesCollection =
    overlayType === 'modal' ? routes?.routeModals : routes?.routePanels;

  if (!routesCollection) return null;

  const Component = find({ id: entityId }, routesCollection)?.component;

  if (!Component) return null;

  return <Route path={path} exact render={() => <Component />} />;
}
