import { Divider, Group, Stack } from '@mantine/core';
import React, { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { useGoogleSSOEnabled } from '@portals/api/auth';
import { signInWithGoogle } from '@portals/redux/actions/auth';
import { TenantType } from '@portals/types';

import { useGoogleSSOButton } from '../../hooks/sso';
import { CredentialResponse } from '../../types/google-auth';

interface SSOProps {
  tenantId: string;
  tenantType: TenantType;
}

const SSO: FC<SSOProps> = ({ tenantId, tenantType }) => {
  const dispatch = useDispatch();
  const setGoogleToken = useCallback(
    ({ credential }: CredentialResponse) => {
      dispatch(signInWithGoogle(credential, tenantId, tenantType));
    },
    [dispatch, tenantId, tenantType]
  );
  const googleBtnRef = useGoogleSSOButton(setGoogleToken);

  return (
    <Stack mb="md">
      <Divider label="OR" labelPosition="center" mb={10} />

      <Group position="center" align="center">
        <div ref={googleBtnRef} style={{ height: 44 }} />
      </Group>
    </Stack>
  );
};

interface SSOWrapperProps {
  tenantId: string;
  tenantType: TenantType;
}

const SSOWrapper: FC<SSOWrapperProps> = ({ tenantId, tenantType }) => {
  const googleSSOEnabled = useGoogleSSOEnabled();

  return googleSSOEnabled.data ? (
    <SSO tenantId={tenantId} tenantType={tenantType} />
  ) : null;
};

export default SSOWrapper;
