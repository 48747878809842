import { LoadingOverlay } from '@mantine/core';
import React, { ReactNode } from 'react';

import { useConfig, useSpaces } from '@portals/api/organizations';
import { useCurrentUser } from '@portals/api/ui';

interface OrganizationDataLayoutProps {
  children: ReactNode;
}

export function OrganizationDataLayout({
  children,
}: OrganizationDataLayoutProps) {
  const currentUser = useCurrentUser();
  const spaces = useSpaces();
  const config = useConfig();

  const isFetched =
    currentUser.isFetched && spaces.isFetched && config.isFetched;

  if (!isFetched) {
    return <LoadingOverlay visible />;
  }

  return <>{children}</>;
}
