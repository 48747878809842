import { Button, Checkbox, Stack, Text, TextInput } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import React from 'react';
import { boolean, InferType, object, string } from 'yup';

import { useUpdateUserDetails, WelcomeDetailsType } from '@portals/api/ui';
import { TenantType } from '@portals/types';

import { TermsAndPolicy } from '../../components/TermsAndPolicy';
import {
  PasswordInputWithRequirements,
  yupPasswordConfirmValidator,
  yupPasswordValidator,
} from './PasswordInputWithRequirements';

interface WelcomeFormProps {
  welcomeDetails: WelcomeDetailsType;
  token: string;
  tenantType: TenantType | undefined;
}

const schema = object({
  email: string().required(),
  name: string().required(),
  password: yupPasswordValidator,
  confirm: yupPasswordConfirmValidator('password'),
  terms: boolean()
    .required()
    .oneOf([true], 'You must accept our terms and conditions'),
});

export function WelcomeForm({
  welcomeDetails,
  token,
  tenantType,
}: WelcomeFormProps) {
  const updateUserDetails = useUpdateUserDetails();

  const onSubmit = async (values: typeof form.values) => {
    const { password, name } = values;

    try {
      await updateUserDetails.mutateAsync({
        token,
        password,
        name,
        tenant: tenantType,
      });
    } catch (error) {
      console.error(error?.error);
    }
  };

  const form = useForm<InferType<typeof schema>>({
    initialValues: {
      email: welcomeDetails.email,
      name: welcomeDetails.name,
      password: '',
      confirm: '',
      terms: false,
    },
    validate: yupResolver(schema),
  });

  return (
    <form onSubmit={form.onSubmit(onSubmit)} noValidate>
      <Stack>
        <TextInput
          required
          disabled
          type="email"
          label="Email"
          placeholder="Email"
          data-testid="email-input"
          {...form.getInputProps('email')}
        />

        <TextInput
          required
          label="Your name"
          placeholder="Your name"
          data-testid="name-input"
          {...form.getInputProps('name')}
        />

        <PasswordInputWithRequirements
          label="New password"
          placeholder="New password"
          value={form.values.password}
          onChange={(e) => form.setFieldValue('password', e.target.value)}
          error={form.errors.password}
        />

        <PasswordInputWithRequirements
          label="Confirm password"
          placeholder="The same password again"
          value={form.values.confirm}
          onChange={(e) => form.setFieldValue('confirm', e.target.value)}
          error={form.errors.confirm}
        />

        <Checkbox
          data-testid="terms-checkbox"
          label={<TermsAndPolicy />}
          {...form.getInputProps('terms')}
        />

        <Button
          type="submit"
          mt="md"
          data-testid="join-btn"
          loading={updateUserDetails.isLoading}
        >
          Join
        </Button>

        {updateUserDetails.error ? (
          <Text color="red" align="center" className="auth-page-error">
            {updateUserDetails.error?.error || 'Oops! Something went wrong'}
          </Text>
        ) : null}
      </Stack>
    </form>
  );
}
