import { actions } from 'react-redux-toastr';

export const toastrSuccess = (title: string, message?: string) =>
  actions.add({ title, message, type: 'success' });

export const toastrWarning = (title: string, message?: string) =>
  actions.add({ title, message, type: 'warning' });

export const toastrError = (title: string, message?: string) =>
  actions.add({ title, message, type: 'error' });
