import { Box, createStyles, Text, Tooltip } from '@mantine/core';
import { motion } from 'framer-motion';
import { castArray, first, isFunction } from 'lodash/fp';
import React from 'react';

import { useTranslation } from '@portals/i18n';
import {
  CategoryRouteItem,
  CommonFeatureFlagsType,
  CommonPortalCapabilities,
  CommonPricingPlanFeatures,
  DesignType,
} from '@portals/types';

import { SidebarItem } from './SidebarItem';
import {
  useCommonClassification,
  useCommonDesign,
  useCommonFeatureFlags,
  useCommonPortalCapabilities,
  useCommonPricingPlanFeatures,
} from '../../../hooks/portal-config';
import { useHasSupportSeat } from '../../../hooks/support-seats';
import { usePermissionAccess } from '../../permission-access/use-permission-access';

interface SidebarSectionProps<
  TPortalCapabilities extends CommonPortalCapabilities = CommonPortalCapabilities,
  TFeatureFlags extends CommonFeatureFlagsType = CommonFeatureFlagsType,
  TPricingPlanFeatures extends CommonPricingPlanFeatures = CommonPricingPlanFeatures
> {
  headerId: CategoryRouteItem<
    TPortalCapabilities,
    TFeatureFlags,
    TPricingPlanFeatures
  >['headerId'];
  childRoutes: CategoryRouteItem<
    TPortalCapabilities,
    TFeatureFlags,
    TPricingPlanFeatures
  >['childRoutes'];
  isSidebarOpen: boolean;
}

export function SidebarSection({
  headerId,
  childRoutes,
  isSidebarOpen,
}: SidebarSectionProps) {
  const t = useTranslation();
  const { canView, canEdit, isAdmin } = usePermissionAccess();
  const hasSupportSeat = useHasSupportSeat();
  const featureFlags = useCommonFeatureFlags();
  const portalCapabilities = useCommonPortalCapabilities();
  const pricingPlanFeatures = useCommonPricingPlanFeatures();
  const design = useCommonDesign();
  const { cx, classes } = useStyles({ design });

  const classification = useCommonClassification();

  // check that there is at least one child route that is visible and accessible
  const shouldRenderSection = childRoutes.some(
    ({ canAccessRoute, isVisibleInSidebar }) => {
      const canAccess =
        canAccessRoute?.({
          featureFlags,
          portalCapabilities,
          pricingPlanFeatures,
          canView,
          canEdit,
          isAdmin,
          hasSupportSeat,
          classification,
        }) ?? true;

      const isVisible =
        isVisibleInSidebar?.({
          featureFlags,
          portalCapabilities,
          classification,
        }) ?? true;

      return canAccess && isVisible;
    }
  );

  if (!shouldRenderSection) {
    return null;
  }

  return (
    <Box className={cx(classes.container, { open: isSidebarOpen })}>
      {headerId ? (
        <motion.div layout="position">
          <Box
            className="sidebar-header"
            sx={(theme) => ({
              color: theme.fn.rgba(
                design?.navigation_menu?.menu_text_color ||
                  theme.colors.blue_gray[6],
                0.8
              ),
            })}
          >
            {t(headerId)}
          </Box>

          <div className="sidebar-divider" />
        </motion.div>
      ) : null}

      {childRoutes.map((childRoute) => {
        const { canAccessRoute, isVisibleInSidebar } = childRoute;

        const canAccess =
          canAccessRoute?.({
            featureFlags,
            portalCapabilities,
            pricingPlanFeatures,
            canView,
            canEdit,
            isAdmin,
            hasSupportSeat,
            classification,
          }) ?? true;
        const isVisible =
          isVisibleInSidebar?.({
            featureFlags,
            portalCapabilities,
            classification,
          }) ?? true;

        if (!canAccess || !isVisible) {
          return null;
        }

        return (
          <Tooltip
            key={childRoute.id}
            sx={{ display: 'block' }}
            label={<Text size="xs">{t(childRoute.id)}</Text>}
            disabled={isSidebarOpen}
            withinPortal
            position="right"
            offset={-5}
          >
            <Box>
              <SidebarItem
                {...childRoute}
                to={first(
                  castArray(
                    isFunction(childRoute.path)
                      ? childRoute.path({
                          featureFlags,
                          portalCapabilities,
                          pricingPlanFeatures,
                          isAdmin,
                        })
                      : childRoute.path
                  )
                )}
                isSidebarOpen={isSidebarOpen}
              />
            </Box>
          </Tooltip>
        );
      })}
    </Box>
  );
}

const useStyles = createStyles(
  (theme, { design }: { design?: DesignType }) => ({
    container: {
      '.sidebar-header': {
        padding: '15px 32px',
        letterSpacing: '0.04em',
        fontSize: theme.fontSizes.xs,
        fontWeight: 600,
        lineHeight: 1.33,
        transition: 'opacity 0.15s ease-in-out',
        textTransform: 'none',
        display: 'none',
      },

      '&.open': {
        '.sidebar-header': {
          display: 'list-item',
        },
      },

      '&:not(&.open)': {
        '.sidebar-divider': {
          borderBottom: `1px solid ${theme.fn.rgba(
            design?.navigation_menu?.menu_text_color ||
              theme.colors.blue_gray[6],
            0.3
          )}`,
          margin: '28px 0 28px 16px',
          width: '44px',
        },
      },
    },
  })
);
