import { Box, Button, createStyles } from '@mantine/core';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useEffectOnce } from 'react-use';

import { useSpace } from '@portals/api/organizations';
import { PageBreadcrumbs } from '@portals/core';
import { ReactComponent as Camera } from '@portals/icons/linear/camera.svg';

import DevicesFiltersProvider from '../../../components/common/DevicesList/devices-list.context';
import { PageContainer } from '../../../components/layout/Page';
import { noAccess, canEdit } from '../../../lib/access';
import { DevicesList } from './devices-list';

export function Space() {
  const { classes } = useStyles();
  const history = useHistory();
  const params = useParams<{ space_id: string }>();
  const space = useSpace(Number(params?.space_id));

  useEffectOnce(function redirectIfNoSAccess() {
    if (noAccess(space)) {
      history.replace(`/claim`);
    }
  });

  const onScan = () => history.push(`/claim/${params.space_id}/scan`);

  return (
    <PageContainer>
      <Box py="lg" px="md" className={classes.content}>
        <Box sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
          <PageBreadcrumbs
            crumbs={[
              {
                label: 'Spaces',
                to: '/',
              },
              {
                label: space?.name || 'Unknown',
              },
            ]}
          />
        </Box>

        <Box className={classes.list}>
          <DevicesFiltersProvider>
            <DevicesList spaceName={space?.name} />
          </DevicesFiltersProvider>
        </Box>

        {canEdit(space) ? (
          <Button size="lg" leftIcon={<Camera />} onClick={onScan}>
            Scan QR Code
          </Button>
        ) : null}
      </Box>
    </PageContainer>
  );
}

const useStyles = createStyles((theme) => ({
  content: {
    height: '100%',
    display: 'grid',
    gridTemplateRows: 'min-content 1fr min-content',
    gridRowGap: theme.spacing.md,
  },
  list: {
    border: `1px solid ${theme.colors.gray[3]}`,
    overflow: 'hidden',
  },
}));
