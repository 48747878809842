import { createStyles, Stack, StackProps, Text } from '@mantine/core';
import { isUndefined } from 'lodash/fp';
import React, { useCallback } from 'react';

import { FORMATTERS } from '../../../utils/formatters';
import { NumberFormatType } from '../../widgets.types';

export interface NumericMetricWidgetProps {
  title: string;
  value: number;
  unit?: string | null;
  stackProps?: StackProps;
  numberFormat?: NumberFormatType;
  numberOfDecimals?: number;
}

export function NumericMetricWidget({
  title,
  value,
  unit,
  stackProps = {},
  numberFormat = 'none',
  numberOfDecimals = 0,
}: NumericMetricWidgetProps) {
  const { classes } = useStyles();

  const formatter = useCallback(
    (value: number | undefined) => {
      if (isUndefined(value)) return 'N/A';

      const adjustedValue = FORMATTERS[numberFormat](value, numberOfDecimals);

      if (isUndefined(adjustedValue)) return 'N/A';

      if (unit) {
        return `${adjustedValue} ${unit}`;
      }

      return adjustedValue;
    },
    [numberFormat, numberOfDecimals, unit]
  );

  return (
    <Stack
      className={classes.container}
      p="xl"
      h="100%"
      w="100%"
      bg="white"
      justify="center"
      spacing={4}
      {...stackProps}
    >
      <Text
        size="md"
        data-testid="dashboard-numeric-widget-name"
        color="gray.5"
      >
        {title}
      </Text>

      <Text size={28} color="gray.8">
        {formatter(value)}
      </Text>
    </Stack>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    borderRadius: theme.radius.lg,
  },
}));
