import {
  Box,
  createStyles,
  Group,
  Menu,
  Paper,
  Stack,
  Text,
} from '@mantine/core';
import { size } from 'lodash/fp';
import React, { forwardRef, useMemo, useState } from 'react';

import { useUsers } from '@portals/api/ui';
import { ReactComponent as CloudChange } from '@portals/icons/linear/cloud-change.svg';
import { ReactComponent as CloudPlus } from '@portals/icons/linear/cloud-plus.svg';
import { ReactComponent as Flag } from '@portals/icons/linear/flag.svg';
import { ReactComponent as Star } from '@portals/icons/linear/star.svg';
import { useOpenModal } from '@portals/redux';
import { ComponentRendererType, DesignType, TenantType } from '@portals/types';

import { UserAvatar, UserName } from './common';
import { TenantSwitch } from './TenantSwitch';
import { useAppConfig } from '../../../../context';
import {
  useCommonDesign,
  useCommonOrganizationConfig,
  useCommonPartnerConfig,
} from '../../../../hooks/portal-config';
import { useCurrentUserAccessibleTenants } from '../../../../hooks/users';
import { usePermissionAccess } from '../../../permission-access/use-permission-access';

interface TenantMenuContentProps {
  logoSrc?: string;
  label: string;
  onClose: () => void;
}

const TenantMenuContent = forwardRef<HTMLDivElement, TenantMenuContentProps>(
  ({ logoSrc, label, onClose }, ref) => {
    const design = useCommonDesign();
    const { classes } = useStyles({ design });
    const [isTenantSwitchActive, setIsTenantSwitchActive] = useState(false);
    const accessibleTenants = useCurrentUserAccessibleTenants();

    const users = useUsers();
    const { tenantType } = useAppConfig();
    const { isSuperAdmin, isAdmin } = usePermissionAccess();
    const openModal = useOpenModal();

    const membersCount = useMemo(() => size(users?.data), [users]);
    const numOfAccessibleTenants = useMemo(
      () => size(accessibleTenants),
      [accessibleTenants]
    );

    const items = useMemo(() => {
      const itemsList: Array<{
        Icon: ComponentRendererType;
        label: string;
        onClick: () => void;
        shouldClose?: boolean;
      }> = [];

      if (isAdmin) {
        itemsList.push({
          Icon: Star,
          label: 'Your Success Journey',
          onClick: () => window.open('https://success.xyte.io', '_blank'),
        });
      }

      if (isSuperAdmin) {
        itemsList.push({
          Icon: Flag,
          label: 'Feature Flags',
          onClick: () => openModal('FeatureFlags'),
        });
      }

      if (users && numOfAccessibleTenants > 1) {
        itemsList.push({
          Icon: CloudChange,
          label: 'Switch Tenant',
          onClick: () => setIsTenantSwitchActive(true),
          shouldClose: false,
        });
      }

      if (isSuperAdmin && tenantType === TenantType.Partner) {
        itemsList.push({
          Icon: CloudPlus,
          label: 'Create New Tenant',
          onClick: () => openModal('CreatePartner'),
        });
      }

      return itemsList;
    }, [
      numOfAccessibleTenants,
      isSuperAdmin,
      openModal,
      tenantType,
      users,
      isAdmin,
    ]);

    if (isTenantSwitchActive)
      return (
        <Paper
          ref={ref}
          sx={{
            width: '100%',
          }}
        >
          <TenantSwitch
            toggleTenantSwitch={() => setIsTenantSwitchActive(false)}
            toggleTenantMenu={onClose}
          />
        </Paper>
      );

    return (
      <Paper
        ref={ref}
        p="md"
        sx={(theme) => ({
          width: '100%',
          background:
            design?.navigation_menu?.menu_background_color || theme.white,
          boxShadow: '4px 4px 6px rgba(0, 0, 0, 0.08)',
          borderBottomLeftRadius: 0,
          borderTopLeftRadius: 0,
        })}
      >
        <Box mb="md" className={classes.header}>
          <UserAvatar logoSrc={logoSrc} isActive />

          <Stack spacing={0}>
            <UserName name={label} />
            <Group position="apart">
              <Text
                size="xs"
                weight={400}
                sx={(theme) => ({
                  color: theme.fn.lighten(
                    design?.navigation_menu?.company_text_color ||
                      theme.colors.blue_gray[8],
                    0.4
                  ),
                })}
              >
                {`${membersCount} ${membersCount > 1 ? 'Members' : 'Member'}`}
              </Text>

              {tenantType === TenantType.Partner ? (
                <Text
                  size="xs"
                  color="primary.4"
                  sx={{ cursor: 'pointer' }}
                  onClick={() => {
                    openModal('EditPartnerLogo');

                    onClose();
                  }}
                >
                  Change logo
                </Text>
              ) : null}
            </Group>
          </Stack>
        </Box>

        <Menu.Divider />

        <Stack mt="md" spacing="xs" px={0}>
          {items.map(({ Icon, label, onClick, shouldClose = true }) => (
            <Menu.Item
              key={label}
              onClick={() => {
                onClick();

                if (shouldClose) {
                  onClose();
                }
              }}
              className={classes.menuItemWrapper}
            >
              <Box className={classes.menuItem}>
                <Icon />

                <Text size="sm">{label}</Text>
              </Box>
            </Menu.Item>
          ))}
        </Stack>
      </Paper>
    );
  }
);

const useStyles = createStyles(
  (theme, { design }: { design?: DesignType }) => ({
    header: {
      display: 'grid',
      gridTemplateColumns: '44px 1fr',
      gap: theme.spacing.md,
      alignItems: 'center',
      height: 44,
    },
    menuItemWrapper: {
      height: 44,
      padding: `0 ${theme.spacing.md}`,
      transition: 'background 0.1s ease-in-out',
      borderRadius: theme.radius.md,

      '&:hover': {
        backgroundColor:
          design?.navigation_menu?.hovered_section_background_color ||
          theme.colors.gray[1],
      },
    },
    menuItem: {
      display: 'grid',
      gridTemplateColumns: '24px 1fr',
      gap: theme.spacing.md,
      color:
        design?.navigation_menu?.menu_text_color || theme.colors.blue_gray[6],
      alignItems: 'center',
    },
  })
);

export const OrganizationMenuContent = forwardRef<
  HTMLDivElement,
  { onClose: () => void }
>(({ onClose }, ref) => {
  const design = useCommonDesign();
  const organization = useCommonOrganizationConfig();

  return (
    <TenantMenuContent
      ref={ref}
      onClose={onClose}
      label={organization?.name || ''}
      logoSrc={design?.navigation_menu?.logo ?? undefined}
    />
  );
});

export const PartnerMenuContent = forwardRef<
  HTMLDivElement,
  { onClose: () => void }
>(({ onClose }, ref) => {
  const partnerConfig = useCommonPartnerConfig();

  return (
    <TenantMenuContent
      ref={ref}
      onClose={onClose}
      label={partnerConfig?.display_name || ''}
      logoSrc={partnerConfig?.logo_url ?? undefined}
    />
  );
});
