import { Box, createStyles, Stack } from '@mantine/core';
import React, { Dispatch, FC, SetStateAction } from 'react';

import { SearchInput } from '@portals/core';

import { ServiceCategoryType } from './integrations.types';

interface ConnectSidebarProps {
  searchTerm: string;
  setSearchTerm: Dispatch<SetStateAction<string>>;
  categories: Array<ServiceCategoryType>;
  selectedCategory: ServiceCategoryType | null;
  setSelectedCategory: Dispatch<SetStateAction<ServiceCategoryType | null>>;
}

export const ServicesSidebar: FC<ConnectSidebarProps> = ({
  searchTerm,
  setSearchTerm,
  categories = [],
  selectedCategory,
  setSelectedCategory,
}) => {
  const { classes, cx } = useStyles();

  return (
    <Stack className={classes.container}>
      <SearchInput
        data-autofocus={true}
        onClear={() => setSearchTerm('')}
        value={searchTerm}
        onChange={(event) => setSearchTerm(event.target.value)}
        className={classes.searchInput}
        size="md"
      />

      <Stack spacing={4} p={0}>
        <Box
          className={cx(classes.category, { selected: !selectedCategory })}
          onClick={() => setSelectedCategory(null)}
        >
          All Integrations
        </Box>

        {categories.map((category) => (
          <Box
            key={category.id}
            className={cx(classes.category, {
              selected: category.id === selectedCategory?.id,
            })}
            onClick={() => setSelectedCategory(category)}
          >
            {category.label}
          </Box>
        ))}
      </Stack>
    </Stack>
  );
};

const useStyles = createStyles((theme) => ({
  container: {
    height: '100%',
    borderRight: `1px solid ${theme.colors.gray[2]}`,
    padding: `${theme.spacing.xl} ${theme.spacing.md}`,
    gap: theme.spacing.xl,
  },
  searchInput: {
    width: '100%',
  },
  title: {
    alignItems: 'center',
    fontSize: theme.fontSizes.xs,
    color: theme.colors.blue_gray[3],
    gap: 4,

    svg: {
      width: 14,
      height: 14,
    },
  },
  category: {
    height: 41,
    padding: `0 ${theme.spacing.md}`,
    borderRadius: theme.radius.xl,
    cursor: 'pointer',
    color: theme.colors.blue_gray[7],
    fontSize: theme.fontSizes.sm,
    fontWeight: 300,
    display: 'flex',
    alignItems: 'center',
    transition: 'all 0.15s ease-in-out',

    '&.selected': {
      backgroundColor: theme.fn.rgba(theme.colors.blue_accent[1], 0.1),
      color: theme.colors.blue_accent[4],
    },

    '&:hover': {
      backgroundColor: theme.fn.rgba(theme.colors.blue_accent[1], 0.1),
    },
  },
}));
