import React, { ReactNode } from 'react';

import { AuthenticationGuard } from '../components/AuthenticationGuard';
import Onboarded from '../components/Onboarded';
import { useAppConfig } from '../context';

interface OnboardingProps {
  children: ReactNode;
}

function Onboarding({ children }: OnboardingProps) {
  const { extraLayout } = useAppConfig();
  const { dashboardDataLayout: DataLayout } = extraLayout;

  const globalLayout = extraLayout?.globalLayout
    ? extraLayout.globalLayout()
    : null;

  if (DataLayout) {
    return (
      <AuthenticationGuard>
        <DataLayout>
          <Onboarded>{children}</Onboarded>
        </DataLayout>

        {globalLayout}
      </AuthenticationGuard>
    );
  }

  return (
    <AuthenticationGuard>
      <Onboarded>{children}</Onboarded>

      {globalLayout}
    </AuthenticationGuard>
  );
}

export default Onboarding;
