import React from 'react';

export function TermsAndPolicy() {
  return (
    <span>
      Agree to{' '}
      <a
        href="https://xyte.io/terms-of-service"
        target="_blank"
        rel="noopener noreferrer"
      >
        Terms of Use
      </a>{' '}
      and{' '}
      <a
        href="https://xyte.io/privacy-policy"
        target="_blank"
        rel="noopener noreferrer"
      >
        Privacy Policy
      </a>
    </span>
  );
}
