import { ActionIcon, Indicator, Tooltip, useMantineTheme } from '@mantine/core';
import React from 'react';

import { ReactComponent as MessageQuestion } from '@portals/icons/linear/message-question.svg';

import { useHubspotConversations } from '../../../external/HubspotConversationsProvider';
import { useCommonDesign } from '../../../hooks/portal-config';

export function HubspotConversationsTrigger() {
  const { isEnabled, toggleWidget, isOpen, unreadMessagesCount } =
    useHubspotConversations();
  const theme = useMantineTheme();
  const design = useCommonDesign();

  let iconColor =
    design?.navigation_menu?.menu_text_color || theme.colors.blue_gray[6];

  if (isOpen) {
    iconColor =
      design?.navigation_menu?.selected_section_background_color ||
      theme.colors.blue_accent[4];
  }

  const tooltipText = isOpen ? 'Hide chat' : 'Chat with us';

  if (!isEnabled) {
    return null;
  }

  return (
    <Indicator
      color="red_accent"
      disabled={unreadMessagesCount === 0}
      withBorder
      offset={6}
      size={12}
    >
      <Tooltip label={tooltipText} withinPortal>
        <ActionIcon
          onClick={toggleWidget}
          variant="transparent"
          size="lg"
          sx={() => ({
            color: iconColor,
          })}
        >
          <MessageQuestion />
        </ActionIcon>
      </Tooltip>
    </Indicator>
  );
}
