import {
  Box,
  createStyles,
  LoadingOverlay,
  SegmentedControl,
  Stack,
  StackProps,
  Text,
} from '@mantine/core';
import { isEmpty } from 'lodash/fp';
import React from 'react';

import { WIDGET_ICONS } from '../../widgets.constants';
import { WidgetColorType } from '../../widgets.types';

type Option = { label: string; iconName: string; value: string };

export interface StateControllerWidgetProps {
  color: WidgetColorType;
  title: string;
  value: string;
  options: Array<Option>;
  displayIcons: boolean;
  displayTitle: boolean;
  onToggle: (option: string) => void;
  isLoading: boolean;

  stackProps?: StackProps;
}

interface LabelProps {
  option: Option;
  index: number;
  withIcon: boolean;
}

function Label({ index, option, withIcon }: LabelProps) {
  const widgetIcon =
    withIcon &&
    WIDGET_ICONS.find((widgetIcon) => widgetIcon.iconName === option.iconName);
  const Icon = widgetIcon ? widgetIcon?.Icon : null;
  const { classes } = useLabelStyles(withIcon);

  return (
    <Box h={45} className={classes.labelWrapper}>
      {Icon ? (
        <Box>
          <Icon height={24} width={24} />
        </Box>
      ) : null}
      <Text size="sm" inherit truncate title={option.label}>
        {option.label}
      </Text>
    </Box>
  );
}

export function StateControllerWidget({
  onToggle,
  color,
  title,
  value,
  options,
  displayIcons,
  displayTitle,
  isLoading,

  stackProps = {},
}: StateControllerWidgetProps) {
  const { classes } = useWidgetStyles(color);

  const uniqueKey = options.map((option) => option.value).join('-');

  return (
    <Stack
      className={classes.container}
      p="xl"
      spacing="lg"
      pos="relative"
      h="100%"
      w="100%"
      bg="white"
      justify="center"
      {...stackProps}
    >
      <LoadingOverlay visible={isLoading} />

      {displayTitle ? (
        <Text
          size="md"
          data-testid="dashboard-state-controller-widget-name"
          color="gray.5"
        >
          {title}
        </Text>
      ) : null}

      {!isEmpty(options) ? (
        <SegmentedControl
          disabled={isLoading}
          key={uniqueKey}
          data={options.map((option, index) => ({
            label: (
              <Label option={option} index={index} withIcon={displayIcons} />
            ),
            value: option.value,
          }))}
          value={value}
          onChange={onToggle}
          size="xl"
          classNames={{
            indicator: classes.active,
            label: classes.label,
          }}
        />
      ) : (
        <Stack h={81} justify="center">
          <Text size="sm" color="gray.5" w="100%" align="center">
            Select command & param
          </Text>
        </Stack>
      )}
    </Stack>
  );
}

const useWidgetStyles = createStyles((theme, color: WidgetColorType) => ({
  container: {
    borderRadius: theme.radius.lg,
  },
  label: {
    fontWeight: 400,
    fontSize: theme.fontSizes.sm,

    '&[data-active]': {
      color: 'white',
    },
  },
  active: {
    backgroundColor: theme.fn.themeColor(color),
    borderRadius: theme.radius.md,
  },
}));

const useLabelStyles = createStyles((theme, withIcon: boolean) => ({
  labelWrapper: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridTemplateRows: withIcon ? 'min-content 1fr' : '1fr',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    justifyContent: 'center',
    alignItems: 'center',
    gap: theme.spacing.xs,
  },
}));
