import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';

const Page500: FC<Record<string, never>> = () => (
  <div className="text-center error-page">
    <h1 className="display-1 font-weight-bold">500</h1>

    <p className="h1">Internal server error.</p>

    <p className="h2 font-weight-normal mt-3 mb-4">
      The server encountered something unexpected that didn't allow it to
      complete the request.
    </p>

    <Link to="/">
      <Button color="primary" size="lg" className="btn-branding pr-5 pl-5">
        Return to website
      </Button>
    </Link>
  </div>
);

export default Page500;
