import { includes } from 'lodash/fp';
import React, { FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { ReactComponent as DirectInbox } from '@portals/icons/linear/direct-inbox.svg';
import { ReactComponent as Logout } from '@portals/icons/linear/logout.svg';
import { ReactComponent as MonitorMobbile } from '@portals/icons/linear/monitor-mobbile.svg';
import { ReactComponent as ShoppingCart } from '@portals/icons/linear/shopping-cart.svg';
import { ReactComponent as UserIcon } from '@portals/icons/linear/user.svg';
import { getAuth } from '@portals/redux';
import { signOut } from '@portals/redux/actions/auth';
import { StateType } from '@portals/types';
import { getStyledThemeColor } from '@portals/utils';

const useSidebarItems = (onClose: () => void) => {
  const { location, push } = useHistory();
  const authentication = useSelector(getAuth);

  const onNavigate = useCallback(
    (pathToNavigate: string) => {
      onClose();
      push(pathToNavigate);
    },
    [push, onClose]
  );

  return {
    pathname: location.pathname,
    onNavigate,
    authentication,
  };
};

type SidebarItemsProps = {
  onNavigate: (path: string) => void;
  pathname: string;
};

export const AuthSidebarItems: FC<SidebarItemsProps> = ({
  pathname,
  onNavigate,
}) => (
  <Container>
    <SidebarItem
      active={includes('/auth', pathname)}
      onClick={() => onNavigate('/auth/sign-in')}
    >
      <UserIcon />
      SIGN IN
    </SidebarItem>

    <SidebarItem
      active={pathname === '/shop'}
      onClick={() => onNavigate('/shop')}
    >
      <ShoppingCart />
      SHOP
    </SidebarItem>
  </Container>
);

export const DashboardSidebarItems: FC<
  SidebarItemsProps & { authentication: StateType['ui']['auth'] }
> = ({ pathname, onNavigate, authentication }) => {
  const dispatch = useDispatch();

  return (
    <Container className="justify-content-between pt-5">
      <div className="d-flex flex-column">
        <SidebarCategory>CONFIGURATION</SidebarCategory>

        <SidebarItem
          active={includes('/claim', pathname) || pathname === '/'}
          onClick={() => onNavigate('/claim')}
        >
          <DirectInbox />
          CLAIM DEVICE
        </SidebarItem>

        <SidebarItem
          active={includes('/devices', pathname)}
          onClick={() => onNavigate('/devices')}
        >
          <MonitorMobbile />
          DEVICES
        </SidebarItem>
      </div>

      <Settings>
        <User>
          <div>{authentication.name}</div>
          <div className="text-muted">{authentication.uid}</div>

          <div
            className="d-flex align-items-center mt-3"
            onClick={() => dispatch(signOut())}
          >
            <Logout color="white" />

            <div className="text-white ml-2 font-size-sm">SIGN OUT</div>
          </div>
        </User>
      </Settings>
    </Container>
  );
};

const SidebarItems: FC<{ onClose: () => void }> = ({ onClose }) => {
  const { onNavigate, authentication, pathname } = useSidebarItems(onClose);

  return authentication ? (
    <DashboardSidebarItems
      onNavigate={onNavigate}
      pathname={pathname}
      authentication={authentication}
    />
  ) : (
    <AuthSidebarItems onNavigate={onNavigate} pathname={pathname} />
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding-bottom: 60px;
`;

const SidebarCategory = styled.div`
  padding: 10px 0;
  font-size: 17px;
  color: ${getStyledThemeColor('gray600')};
`;

const SidebarItem = styled.div.attrs(() => ({
  className: 'sidebar-item',
}))<{ active?: boolean }>`
  font-size: 20px;
  color: ${getStyledThemeColor('white')};
  width: calc(100% + 80px);
  transform: translateX(-40px);
  padding: 10px 40px;
  letter-spacing: 0.1em;
  display: flex;
  align-items: center;

  svg {
    margin-right: 15px;
    width: 18px;
    height: 18px;
  }

  .badge {
    font-size: 12px;
    letter-spacing: normal;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
  }

  ${({ active, theme }) =>
    active &&
    `
    background-color: ${theme.color.gray100};
    color: ${theme.color.dark};
  `}

  &:active {
    background-color: ${getStyledThemeColor('gray100')};
    color: ${getStyledThemeColor('dark')};
  }
`;

const Settings = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
`;

const User = styled.div`
  flex-shrink: 0;
  color: ${getStyledThemeColor('white')};
  font-size: 16px;
  max-width: calc(100% - 70px);
`;

export default SidebarItems;
