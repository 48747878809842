import { Box, BoxProps, createStyles, Text, Title } from '@mantine/core';
import React, { FC, ReactNode } from 'react';

import { PageBreadcrumbs, PageBreadcrumbsProps } from '@portals/core';

export interface DashboardContentLayoutProps extends BoxProps {
  pageTitle?: ReactNode;
  subtitle?: ReactNode;
  breadcrumbs?: PageBreadcrumbsProps['crumbs'];
  withBreadcrumbsEndSeparator?: boolean;
  footer?: ReactNode;
}

export const DashboardContentLayout: FC<DashboardContentLayoutProps> = ({
  children,
  pageTitle,
  subtitle,
  breadcrumbs,
  className,
  withBreadcrumbsEndSeparator,
  footer,
  ...boxProps
}) => {
  const { classes, cx } = useStyles({ hasBreadcrumbs: Boolean(breadcrumbs) });
  const shouldRenderHeader = pageTitle || subtitle || breadcrumbs;
  const titleTestId =
    typeof pageTitle === 'object' ? 'inner-page-title' : pageTitle + '-title';

  return (
    <Box className={classes.content}>
      <Box className={cx(classes.container, className)} {...boxProps}>
        {shouldRenderHeader ? (
          <header className={classes.header}>
            {breadcrumbs ? (
              <PageBreadcrumbs
                crumbs={breadcrumbs}
                withEndSeparator={withBreadcrumbsEndSeparator}
                containerClassName={classes.breadcrumbsContainer}
              />
            ) : null}

            {pageTitle ? (
              <Title
                order={3}
                className={cx('layout-title', classes.pageTitle)}
                data-testid={titleTestId}
              >
                {pageTitle}
              </Title>
            ) : null}

            {subtitle ? (
              <Text size="md" mt="xs" className={classes.subtitle}>
                {subtitle}
              </Text>
            ) : null}
          </header>
        ) : null}

        {children}
      </Box>

      {footer}
    </Box>
  );
};

const useStyles = createStyles(
  (theme, params: { hasBreadcrumbs: boolean }) => ({
    content: {
      height: '100%',
      display: 'grid',
      gridTemplateRows: '1fr max-content',
    },
    container: {
      overflowY: 'auto',
      display: 'grid',
      gridTemplateRows: 'max-content 1fr',
      height: '100%',
      paddingTop: params.hasBreadcrumbs ? theme.spacing.xl : 48,
      paddingBottom: 30,
      paddingInline: 30,
    },
    header: {
      marginBottom: theme.spacing.xl,
    },
    breadcrumbsContainer: {
      marginBottom: theme.spacing.md,
    },
    pageTitle: {
      fontWeight: 500,
      color: theme.colors.blue_gray[9],
    },
    subtitle: {
      marginTop: theme.spacing.sm,
      color: theme.colors.blue_gray[6],
    },
  })
);
