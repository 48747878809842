import { createStyles, Stack, Text, Title } from '@mantine/core';
import React, { FC } from 'react';

import { PageContainer } from '../../components/layout/Page';

export const AuthWrapper: FC = ({ children }) => {
  const { classes } = useStyles();

  return (
    <PageContainer className="sign-in-page-container">
      <Stack className={classes.container} spacing="xl">
        <Title order={3}>Xyte's FieldOps</Title>
        <Text size="sm" color="blue_gray">
          Use your desktop for the full experience
        </Text>

        {children}
      </Stack>
    </PageContainer>
  );
};

const useStyles = createStyles((theme) => ({
  container: {
    height: '100%',
    width: '100%',
    overflow: 'auto',
    padding: `50px ${theme.spacing.xl}`,

    '.auth-page-error, .auth-page-title': {
      display: 'none',
    },
  },
}));
