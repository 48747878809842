import { useMantineTheme } from '@mantine/core';
import React, { FC, useState } from 'react';
import { AutoSizer } from 'react-virtualized';
import {
  CartesianGrid,
  Dot,
  Line,
  LineChart,
  ReferenceLine,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import styled from 'styled-components';

import { getStyledThemeColor } from '@portals/utils';

import { ButtonGroup } from '../../ButtonGroup';
import { ChartCard } from '../ChartCard';
import {
  ChartLabelRenderer,
  ReferenceLineLabelRenderer,
  TooltipRenderer,
} from './common';

interface FinanceLineChartProps {
  title: string;
  withForecast?: boolean;
  dataKeys: Array<{ key: string; label: string; color: string }>;
  data: Array<Record<string, number | string>>;
  options?: Record<string, number>;
}

const LineChartCard: FC<FinanceLineChartProps> = ({
  title,
  withForecast = false,
  dataKeys,
  options = {},
  data = [],
}) => {
  const [displayForecast, setDisplayForecast] = useState(withForecast);
  const theme = useMantineTheme();

  return (
    <StyledChartCard
      title={
        <div className="d-flex justify-content-between align-content-center">
          <span>{title}</span>

          {withForecast ? (
            <ButtonGroup
              height={35}
              options={[
                { value: true, label: 'Forecast ON' },
                { value: false, label: 'Forecast OFF' },
              ]}
              onChange={setDisplayForecast}
              value={displayForecast}
            />
          ) : null}
        </div>
      }
      emptyTitle={`No ${title} data`}
    >
      <AutoSizer>
        {({ height, width }) => (
          <LineChart
            height={height}
            width={width}
            data={data}
            margin={{ top: 50, left: 5, bottom: 0, right: 5 }}
          >
            <CartesianGrid horizontal={false} stroke={theme.colors.gray[3]} />

            <Tooltip content={<TooltipRenderer />} />

            <ReferenceLine
              x="August"
              stroke={theme.colors.red_accent[4]}
              isFront
              label={<ReferenceLineLabelRenderer />}
            />

            <YAxis
              domain={[0, options.maxY || 4000]}
              axisLine={false}
              width={50}
              tickMargin={20}
              tickSize={0}
              allowDecimals={false}
            />

            <XAxis
              dataKey="name"
              interval="preserveStartEnd"
              tick={<ChartLabelRenderer />}
              tickMargin={20}
              tickSize={0}
              axisLine={false}
            />

            {dataKeys.map(({ key, color }) => (
              <Line
                key={key}
                type="natural"
                strokeWidth={3}
                dataKey={key}
                isAnimationActive={false}
                stroke={color}
                dot={(props) => (
                  <Dot
                    {...props}
                    fill={color}
                    r={6}
                    stroke="white"
                    strokeWidth={2}
                  />
                )}
                activeDot={(props) => (
                  <Dot
                    {...props}
                    fill={color}
                    r={6}
                    stroke="white"
                    strokeWidth={0}
                  />
                )}
              />
            ))}

            {displayForecast
              ? dataKeys.map(({ key, color }) => (
                  <Line
                    key={`${key}_forecast`}
                    type="natural"
                    strokeDasharray="3 3"
                    strokeWidth={3}
                    dot={false}
                    isAnimationActive={false}
                    stroke={color}
                    dataKey={`${key}_forecast`}
                  />
                ))
              : null}
          </LineChart>
        )}
      </AutoSizer>

      <LegendWrapper>
        {dataKeys.map(({ label, color }) => (
          <Legend color={color} key={label}>
            {label}
          </Legend>
        ))}
      </LegendWrapper>
    </StyledChartCard>
  );
};

const StyledChartCard = styled(ChartCard)`
  .chart-wrapper {
    background-color: ${getStyledThemeColor('white')};
  }
`;

const LegendWrapper = styled.div`
  position: absolute;
  right: 0;
  top: -10px;
  display: flex;
  align-items: center;
  filter: drop-shadow(0px 2px 2px ${getStyledThemeColor('gray400')});
`;

const Legend = styled.div<{ color: string }>`
  padding: 5px 15px;
  background-color: ${({ color }) => color};
  color: ${getStyledThemeColor('white')};
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 11px;
  margin-right: 7px;
`;

export default LineChartCard;
