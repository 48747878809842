import {
  Box,
  Button,
  createStyles,
  Group,
  Input,
  Stack,
  Text,
  Tooltip,
} from '@mantine/core';
import React from 'react';

import { ReactComponent as DecimalAdd } from '../../assets/decimal-add.svg';
import { ReactComponent as DecimalSubtract } from '../../assets/decimal-subtract.svg';
import { NumberFormatType } from '../widgets.types';

export interface NumberFormatSelectorProps {
  format: NumberFormatType;
  numOfDecimals: number;
  onFormatChange: (format: 'none' | 'decimal' | 'scientific') => void;
  onNumOfDecimalsChange: (numOfDecimals: number) => void;
}

const FORMAT_OPTIONS = [
  {
    label: 'None',
    value: 'none',
  },
  {
    label: 'Number',
    value: 'decimal',
    withNumOfDecimals: true,
  },
  {
    label: 'Scientific',
    value: 'scientific',
    withNumOfDecimals: true,
  },
];

// Write a function the dynamically generates the formatting preview based on format & on
// numOfDecimals
const getFormattingPreview = (
  format: NumberFormatType,
  numOfDecimals: number
) => {
  if (!numOfDecimals) return '100';

  switch (format) {
    case 'none':
      return '1234567890';
    case 'decimal':
      return `100.${'0'.repeat(numOfDecimals - 1)}1`;
    case 'scientific':
      return `1.${'0'.repeat(numOfDecimals - 1)}1e+10`;
  }
};

export function NumberFormatSelector({
  format,
  numOfDecimals,
  onFormatChange,
  onNumOfDecimalsChange,
}: NumberFormatSelectorProps) {
  const { classes, cx } = useStyles();

  return (
    <Input.Wrapper>
      <Input.Label>Number Format</Input.Label>

      <Group grow align="start">
        {FORMAT_OPTIONS.map((option) => {
          const isActive = option.value === format;

          return (
            <Stack key={option.value}>
              <Box
                data-testid={`number-format-btn-${option.value}`}
                onClick={() => onFormatChange(option.value as NumberFormatType)}
                className={cx(classes.formatButton, {
                  active: isActive,
                })}
              >
                <Stack spacing={0} align="center">
                  <Text size="xs" color={isActive ? 'blue_accent.4' : 'gray.9'}>
                    {option.label}
                  </Text>

                  {isActive && option.withNumOfDecimals ? (
                    <Text size="xs" weight={300} color="gray.6">
                      {getFormattingPreview(format, numOfDecimals)}
                    </Text>
                  ) : null}
                </Stack>
              </Box>

              {option.withNumOfDecimals && isActive ? (
                <Group align="center" position="center" grow>
                  {numOfDecimals > 1 ? (
                    <Tooltip label="Decrease decimal places" withinPortal>
                      <Button
                        color="gray.1"
                        data-tesid="number-format-btn-decimals-subtract"
                        onClick={() => onNumOfDecimalsChange(numOfDecimals - 1)}
                      >
                        <DecimalSubtract />
                      </Button>
                    </Tooltip>
                  ) : (
                    <div />
                  )}

                  {numOfDecimals < 4 ? (
                    <Tooltip label="Increase decimal places" withinPortal>
                      <Button
                        color="gray.1"
                        data-tesid="number-format-btn-decimals-add"
                        onClick={() => onNumOfDecimalsChange(numOfDecimals + 1)}
                      >
                        <DecimalAdd />
                      </Button>
                    </Tooltip>
                  ) : (
                    <div />
                  )}
                </Group>
              ) : null}
            </Stack>
          );
        })}
      </Group>
    </Input.Wrapper>
  );
}

const useStyles = createStyles((theme) => ({
  formatButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: `1px solid ${theme.colors.gray[3]}`,
    borderRadius: theme.radius.md,
    height: 70,
    userSelect: 'none',

    '&.active': {
      // @ts-ignore
      borderColor: theme.colors.blue_accent[4],
    },

    '&:not(.active)': {
      cursor: 'pointer',
    },
  },
}));
