import * as Sentry from '@sentry/react';
import { startsWith } from 'lodash/fp';
import React, { ReactNode, useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { Redirect, useLocation } from 'react-router-dom';

import { useCurrentUser, usersQueryKeys } from '@portals/api/ui';
import { useAuth } from '@portals/redux';

import { useConnection } from '../hooks/websocket';

interface AuthenticationGuardProps {
  children: ReactNode;
}

export function AuthenticationGuard({ children }: AuthenticationGuardProps) {
  const dispatch = useDispatch();

  const auth = useAuth();
  const currentUser = useCurrentUser();
  const queryClient = useQueryClient();

  const { disconnect } = useConnection();

  useEffect(
    // In some edge-cases, an already authenticated user might try accessing another account.
    // In this case, we want to reset the authentication state and remove any data related to
    // the current user - thus the "mismatch" between the auth ID & the currentUser ID.
    function resetAuthenticationOnMismatch() {
      const isStaleUser = !auth && currentUser.data;
      const isMismatchBetweenAuthAndUser =
        auth && currentUser.data && auth.id !== currentUser.data.id;

      if (isStaleUser || isMismatchBetweenAuthAndUser) {
        queryClient.removeQueries({
          queryKey: usersQueryKeys.all(),
        });
      }
    },
    [currentUser.data, auth, queryClient, dispatch]
  );

  if (auth) {
    Sentry.configureScope((scope) => scope.setUser({ id: auth.uid }));
    return <>{children}</>;
  } else {
    queryClient.clear();
    disconnect();
    return <AuthenticationRedirect />;
  }
}

const AuthenticationRedirect = () => {
  const { pathname, search } = useLocation();

  // Save prev path and search, so we can redirect back after login
  if (pathname && !startsWith('?org=', search)) {
    localStorage.setItem('afterAuth', pathname + search);
  }

  return <Redirect to="/auth/sign-in" />;
};
