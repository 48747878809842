import { MantineTheme } from '@mantine/core';

import { generateRelatedColors } from '@portals/utils';

import {
  PORTAL_CONFIG_BASE_URL,
  portalConfigQueryKeys,
} from './portal-config.constants';
import { PortalConfigType } from './portal-config.types';
import { useApiQuery } from '../../hooks';
import { QueryOptions } from '../../types';

export function usePortalConfig(
  queryOptions: QueryOptions<PortalConfigType | Record<string, never>> = {}
) {
  return useApiQuery<PortalConfigType | Record<string, never>>(
    PORTAL_CONFIG_BASE_URL,
    portalConfigQueryKeys.portalConfig,
    {
      // @ts-ignore
      placeholderData: {},
      ...queryOptions,
    }
  );
}

export function useWidgetColors() {
  const portalConfig = usePortalConfig();

  const result: Record<string, MantineTheme['colors']['string']> = {};
  const design = portalConfig.data?.design;

  if (design && design.custom_colors) {
    const customColors = design.custom_colors;

    for (let i = 0; i < customColors.length; i++) {
      const colorHex = customColors[i];

      result[colorHex] = generateRelatedColors(colorHex);
    }
  }

  return result;
}
