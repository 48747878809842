import React, { createContext, FC, ReactNode, useContext } from 'react';

const EntityAccessContext = createContext({ isDisabled: false });

export const useEntityAccessContext = () => {
  const accessContext = useContext(EntityAccessContext);

  return accessContext || { isDisabled: false };
};

interface EntityAccessProviderProps {
  isDisabled: boolean;
  children: ReactNode;
}

export const EntityAccessProvider: FC<EntityAccessProviderProps> = ({
  isDisabled = false,
  children,
}) => (
  <EntityAccessContext.Provider value={{ isDisabled }}>
    {children}
  </EntityAccessContext.Provider>
);
