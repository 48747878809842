import { Avatar, createStyles, Stack, Text } from '@mantine/core';
import React, { FC } from 'react';

import { ServiceType } from './integrations.types';

interface ServiceCardProps extends ServiceType {
  onClick: () => void;
}

export const ServiceCard: FC<ServiceCardProps> = ({
  name,
  logo,
  owner,
  shortDescription,
  onClick,
  price,
}) => {
  const { classes } = useStyles();

  return (
    <Stack
      className={classes.container}
      justify="space-between"
      onClick={onClick}
    >
      <Stack spacing="md">
        <Avatar
          src={logo}
          size={49}
          sx={(theme) => ({
            border: `1px solid ${theme.colors.blue_gray[0]}`,
            padding: 4,
          })}
          imageProps={{
            style: {
              objectFit: 'contain',
            },
          }}
        />

        <Stack spacing={4}>
          <Text className={classes.title}>{name}</Text>
          <Text className={classes.subtitle}>{`by ${owner}`}</Text>
        </Stack>

        <Text className={classes.description}>{shortDescription}</Text>
      </Stack>

      <Text className={classes.price}>{price}</Text>
    </Stack>
  );
};

const useStyles = createStyles((theme) => ({
  container: {
    padding: 32,
    borderRadius: theme.radius.md,
    border: `1px solid ${theme.colors.gray[1]}`,
    background: theme.white,
    cursor: 'pointer',
    transition: 'box-shadow 0.15s ease-in-out',
    boxShadow: `0px 11px 18px rgba(38, 50, 56, 0)`,

    '&:hover': {
      boxShadow: `0px 11px 18px rgba(38, 50, 56, 0.1)`,
    },
  },
  title: {
    fontSize: theme.fontSizes.lg,
    color: theme.colors.gray[9],
    fontWeight: 600,
  },
  subtitle: {
    fontSize: theme.fontSizes.sm,
    color: theme.colors.gray[5],
    fontWeight: 300,
  },
  description: {
    fontSize: theme.fontSizes.sm,
    color: theme.colors.gray[7],
    fontWeight: 300,
  },
  price: {
    fontSize: theme.fontSizes.sm,
    color: theme.colors.gray[5],
    fontWeight: 300,
  },
  category: {
    fontSize: `${theme.fontSizes.xs} !important`,
    color: theme.colors.gray[7],
    textTransform: 'none',
    fontWeight: 300,
    alignItems: 'center',

    svg: {
      width: 15,
      height: 15,
      marginRight: theme.spacing.xs,
    },
  },
}));
