import {
  Box,
  createStyles,
  Group,
  Stack,
  StackProps,
  Text,
} from '@mantine/core';
import React from 'react';

export interface StatusWidgetProps {
  title: string;
  color: string;
  value: string;

  stackProps?: StackProps;
}

export function StatusWidget({
  title,
  color,
  value,

  stackProps = {},
}: StatusWidgetProps) {
  const { classes } = useStyles();

  return (
    <Stack
      className={classes.container}
      p="xl"
      h="100%"
      w="100%"
      bg="white"
      spacing={4}
      justify="center"
      {...stackProps}
    >
      <Text
        size="md"
        data-testid="dashboard-status-widget-name"
        color="gray.5"
        truncate
      >
        {title}
      </Text>

      <Group align="center" spacing="md">
        <Box>
          <Box w={12} h={12} bg={color} sx={{ borderRadius: '50%' }} />
        </Box>

        <Text size={28} color="gray.8" truncate>
          {value}
        </Text>
      </Group>
    </Stack>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    borderRadius: theme.radius.lg,
  },
}));
