import React, { FC } from 'react';
import AceEditor from 'react-ace';

import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-github';

type JsonBlobProps = {
  json: Record<string, unknown> | JSON;
  height?: string;
};

const JsonBlob: FC<JsonBlobProps> = ({ json, height }) => {
  const pretty = JSON.stringify(json, null, 4);

  return (
    <AceEditor
      mode="json"
      theme="tomorrow"
      width="100%"
      height={height || '100%'}
      maxLines={Infinity}
      fontSize={14}
      tabSize={2}
      showGutter={true}
      highlightActiveLine={true}
      value={pretty}
      editorProps={{ $blockScrolling: true }}
      readOnly
    />
  );
};

export default JsonBlob;
