import { Box, createStyles, Image, UnstyledButton } from '@mantine/core';
import Result from '@zxing/library/esm/core/Result';
import { replace } from 'lodash/fp';
import React, { useCallback, useRef, useState } from 'react';
import { QrReader } from 'react-qr-reader';
import { useHistory, useParams } from 'react-router-dom';

import { useClaimDeviceByQrCode } from '@portals/api/organizations';
import { ReactComponent as CloseX } from '@portals/icons/linear/close-x.svg';
import { useOpenModal } from '@portals/redux';

import scanSrc from '../../../assets/scan.svg';
import Notification from '../../../components/layout/Notification';

export const Scan = () => {
  const { classes, theme } = useStyles();
  const params = useParams<{ space_id: string }>();
  const history = useHistory();
  const claimDevice = useClaimDeviceByQrCode();
  const openModal = useOpenModal();

  const [error, setError] = useState(null);

  const currentQrCode = useRef(null);

  const onClaim = useCallback(
    async (cloudId) => {
      try {
        const claimedDevice = await claimDevice.mutateAsync({
          qr: cloudId,
          space_id: params.space_id,
        });

        openModal('UpdateDeviceName', { device: claimedDevice });
      } catch (error) {
        setError(error?.error || 'Failed to claim device. Please try again');

        currentQrCode.current = null;
      }
    },
    [claimDevice, openModal, params.space_id]
  );

  const handleScan = (scanResult: Result, error) => {
    if (error && error.message) {
      setError(error.message);
      return;
    }

    if (!scanResult) return;

    const resultText = scanResult.getText();
    const cloudId = replace('https://xyte.io/c/', '', resultText);

    if (
      !cloudId ||
      claimDevice.isLoading ||
      cloudId === currentQrCode.current
    ) {
      return;
    } else {
      currentQrCode.current = cloudId;

      onClaim(currentQrCode.current);
    }
  };

  return (
    <>
      <Box className={classes.container}>
        <QrReader
          onResult={handleScan}
          constraints={{ facingMode: 'environment' }}
          videoContainerStyle={{
            backgroundColor: theme.colors.blue_gray[8],
            top: 0,
            position: 'fixed',
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            zIndex: 10,
          }}
          videoStyle={{
            top: 0,
            position: 'fixed',
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            zIndex: 10,
          }}
        />

        <Box className={classes.button}>
          <UnstyledButton
            onClick={() => history.push(`/claim/${params.space_id}`)}
          >
            <CloseX color="white" />
          </UnstyledButton>
        </Box>

        <Image src={scanSrc} className={classes.image} />
      </Box>

      <Notification error={error} onClear={() => setError(null)} />
    </>
  );
};

const useStyles = createStyles((theme) => ({
  container: {
    position: 'relative',
    display: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  button: {
    position: 'fixed',
    top: 20,
    left: 20,
    display: 'flex',
    justifyContent: 'center',
    zIndex: 11,
  },
  image: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    width: '80%',
    objectFit: 'contain',
    transform: 'translateX(-50%) translateY(-50%)',
    zIndex: 11,
  },
}));
