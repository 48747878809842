import {
  Box,
  Menu,
  MenuProps,
  Modal,
  ModalProps as MantineModalProps,
} from '@mantine/core';
import { noop } from 'lodash/fp';
import React from 'react';

import { TenantSwitch } from '../components/layout/sidebar/TenantMenu/TenantSwitch';

import type { ModalProps } from '../components/Modals';

export function SwitchTenant({ closeMe }: ModalProps) {
  return (
    <Modal
      opened={true}
      onClose={closeMe}
      padding={0}
      title="Switch Tenant"
      size={340}
      styles={modalStyles}
    >
      <Box
        pos="relative"
        h={390}
        w="100%"
        sx={(theme) => ({
          overflow: 'hidden',
          borderBottomLeftRadius: theme.radius.md,
          borderBottomRightRadius: theme.radius.md,
        })}
      >
        {/*
        Wrapping w/ <Menu /> to not break the original implementation used inside a Menu - the
        items are <Menu.Item />
      */}
        <Menu
          opened
          offset={0}
          closeOnItemClick={false}
          position="right"
          width={340}
          styles={menuStyles}
        >
          <Menu.Dropdown p={0}>
            <TenantSwitch
              toggleTenantSwitch={noop}
              toggleTenantMenu={closeMe}
              isStandalone
            />
          </Menu.Dropdown>
        </Menu>
      </Box>
    </Modal>
  );
}

const menuStyles: MenuProps['styles'] = {
  dropdown: {
    borderBottomLeftRadius: 0,
    borderTopLeftRadius: 0,
  },
};

const modalStyles: MantineModalProps['styles'] = (theme) => ({
  content: {
    borderRadius: theme.radius.md,
  },
  header: {
    borderBottom: `1px solid ${theme.colors.gray[3]}`,
    margin: 0,
    padding: theme.spacing.md,
  },
  title: {
    fontSize: theme.fontSizes.xl,
  },
  root: {
    '.tenant-switch-container': {
      width: 340,
      height: 390,

      '.tenant-switch-header': {
        height: 55,
        paddingBlock: 0,
        borderBottom: `1px solid ${theme.colors.gray[2]}`,
      },

      '.tenant-switch-list': {
        padding: 0,
      },
    },
  },
});
