import { Global, useMantineTheme } from '@mantine/core';
import React, {
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useKey } from 'react-use';

import { useAuth } from '@portals/redux';
import { TenantType } from '@portals/types';
import { useOnClickOutside } from '@portals/utils';

import { useAppConfig } from '../context';

interface LaunchNotesProps {
  children: (params: {
    isActive: boolean;
    setIsActive: Dispatch<SetStateAction<boolean>>;
  }) => ReactNode;
}

const LAUNCH_NOTES_CONFIG = {
  [TenantType.Partner]: {
    token: 'public_NV4EYiJRGtIi13zsGqhThMpZ',
    projectID: 'pro_bPPJzPf3j5crK',
  },
  [TenantType.Organization]: {
    token: 'public_CrrrMIUFlaSvBjI1gztt2Wu5',
    projectID: 'pro_zFGTZ1bx0fpTZ',
  },
};

const LaunchNotes: FC<LaunchNotesProps> = ({ children }) => {
  const auth = useAuth();
  const launchNotesRef = useRef<HTMLDivElement>(null);
  const closeButtonRef = useRef<HTMLDivElement>(null);
  const { tenantType } = useAppConfig();

  const [isActive, setIsActive] = useState(false);
  const theme = useMantineTheme();

  const toggleActiveState = useCallback(() => setIsActive(false), []);

  const onRef = useCallback((node) => {
    if (!node) return null;

    launchNotesRef.current = node;
  }, []);

  // LaunchNotes widget closes only on 'x' click or outside click. Simulating 'x' click if it's
  // open and pressed 'esc'
  const onEscape = useCallback(() => {
    if (!isActive) return;

    // LaunchNotes modal doesn't close on 'esc' as well. If it's currently open - closing it,
    // instead of the widget by simulating outside click
    const launchNotesModal = launchNotesRef.current.getElementsByTagName(
      'content-modal'
    )[0] as HTMLDivElement;

    if (launchNotesModal) {
      launchNotesModal.click();
    } else if (closeButtonRef.current) {
      closeButtonRef.current.click();
    }
  }, [isActive]);

  // Need to update 'isActive' state when clicking on 'x' button, since there's no 'onClose'
  // callback in LaunchNote's widget
  useEffect(() => {
    if (!launchNotesRef.current) return;

    if (isActive) {
      closeButtonRef.current = launchNotesRef.current.getElementsByClassName(
        'close-button'
      )[0] as HTMLDivElement;

      closeButtonRef.current.addEventListener('click', toggleActiveState);
    }

    return () => {
      if (closeButtonRef.current) {
        closeButtonRef.current.removeEventListener('click', toggleActiveState);
      }
    };
  }, [isActive, toggleActiveState]);

  useOnClickOutside(launchNotesRef, () => setIsActive(false), isActive);
  useKey('Escape', () => onEscape(), { event: 'keydown' }, [isActive]);

  return (
    <>
      <Global
        styles={(theme) => ({
          '.launchnotes-embed': {
            '*': {
              fontFamily: `Nunito, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, sans-serif !important`,
            },

            '.launchnotes-jumbotron': {
              borderBottom: `1px solid ${theme.colors.blue_gray[0]}`,

              h1: {
                fontSize: `${theme.fontSizes.md} !important`,
              },
              p: {
                fontSize: `${theme.fontSizes.xs} !important`,
              },
            },

            '.scrolling-container': {
              height: 600,

              '.announcement-card-wrapper': {
                padding: 0,
                borderBottom: `1px solid ${theme.colors.blue_gray[0]}`,

                '&:hover': {
                  boxShadow: 'none',
                },

                '.announcement-card': {
                  borderRadius: 0,

                  '&:hover': {
                    boxShadow: 'none',
                  },

                  '.categories': {
                    '.category': {
                      color: theme.colors.blue_gray[2],
                      textTransform: 'uppercase',
                      fontWeight: 600,
                    },
                  },

                  '.name': {
                    fontSize: theme.fontSizes.sm,
                    color: theme.colors.blue_gray[9],
                  },

                  '.timestamp': {
                    color: theme.colors.blue_gray[2],
                    fontWeight: 600,
                  },
                },
              },
            },
          },
        })}
      />

      {/* @ts-ignore */}
      <launchnotes-embed
        ref={onRef}
        token={LAUNCH_NOTES_CONFIG[tenantType]?.token}
        project={LAUNCH_NOTES_CONFIG[tenantType]?.projectID}
        // Widget placement
        toggle-selector="#launch-notes-toggle"
        widget-placement="bottom-end"
        widget-offset-skidding="100" // y-offset from toggle
        widget-offset-distance="30" // x-offset from toggle
        // Design
        primary-color={theme.white}
        heading="What's New"
        heading-color={theme.colors.blue_gray[9]}
        subheading="See the latest feature releases, product improvements and bug fixes"
        subheading-color={theme.colors.blue_gray[4]}
        // Settings
        show-unread="true"
        anonymous-user-id={auth.id}
        unread-placement="right-start"
        unread-offset-skidding="-2"
        unread-offset-distance="-10"
        unread-background-color="#0080ff"
        unread-text-color="#ffffff"
      >
        {children({ isActive, setIsActive })}
        {/* @ts-ignore */}
      </launchnotes-embed>
    </>
  );
};

export default LaunchNotes;
