import { Box, Button, createStyles, Title } from '@mantine/core';
import { find } from 'lodash/fp';
import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useEffectOnce } from 'react-use';

import {
  SpaceType,
  useClaimDeviceByQrCode,
  useSpaces,
} from '@portals/api/organizations';
import { useOpenModal } from '@portals/redux';
import { setRoute } from '@portals/redux/actions/routing';

import { SpaceTree } from '../../../components/common/SpaceTree/SpaceTree';
import { PageContainer } from '../../../components/layout/Page';
import { canView, noAccess } from '../../../lib/access';

export function Claim() {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const spaces = useSpaces();
  const history = useHistory();
  const openModal = useOpenModal();
  const { mutateAsync: claimDevice } = useClaimDeviceByQrCode();

  const [selectedSpace, setSelectedSpace] = useState<SpaceType>(null);

  const location = useLocation();
  // When a user scans QR code outside our app, and gets straight to the claiming page
  const qrCodeSearchParam = useMemo(
    () => new URLSearchParams(location.search).get('qr'),
    [location.search]
  );

  const onClaimDeviceByQRSearchParam = useCallback(
    async (qrCode) => {
      try {
        const claimedDevice = await claimDevice({ qr: qrCode });

        dispatch(setRoute(`/devices/${claimedDevice.id}`, true));
      } catch (err) {
        console.error(err);
        dispatch(setRoute('/', true));
      }
    },
    [claimDevice, dispatch]
  );

  useEffectOnce(function claimDeviceOnQrParam() {
    if (qrCodeSearchParam) {
      onClaimDeviceByQRSearchParam(qrCodeSearchParam);
    }
  });

  const onSelect = (spaceId: SpaceType['id']) => {
    if (spaceId === selectedSpace?.id) {
      setSelectedSpace(null);

      return;
    }

    const space = find({ id: spaceId }, spaces.data);

    if (space && canView(space)) setSelectedSpace(space);
  };

  const onNext = () => {
    if (!selectedSpace?.id || noAccess(selectedSpace)) return;

    history.push(`/claim/${selectedSpace.id}`);
  };

  return (
    <PageContainer>
      <Box py="lg" px="md" className={classes.content}>
        <Title order={4}>Spaces</Title>

        <Box className={classes.tree}>
          <SpaceTree
            spaces={spaces.data}
            draggable={false}
            openModal={openModal}
            handleSelected={onSelect}
            selected={selectedSpace}
          />
        </Box>

        <Button disabled={!selectedSpace} size="lg" onClick={onNext}>
          {!selectedSpace ? 'Select a space to continue' : 'Continue'}
        </Button>
      </Box>
    </PageContainer>
  );
}

const useStyles = createStyles((theme) => ({
  content: {
    display: 'grid',
    gridTemplateRows: 'min-content 1fr min-content',
    gridRowGap: theme.spacing.md,
  },

  tree: {
    border: `1px solid ${theme.colors.gray[3]}`,

    '.tree-container': {
      gridTemplateRows: '0 70px 1fr',

      '.tree-header': {
        gridTemplateColumns: '1fr',

        '.tree-actions': {
          display: 'none',
        },

        input: {
          minHeight: 45,
        },
      },

      '.rc-tree-list': {
        '.rst_toggleButtonIcon': {
          width: 20,
          height: 20,
        },

        '.rc-tree-treenode': {
          height: '40px !important',

          '.inline-space-name-input': {
            fontSize: `${theme.fontSizes.md} !important`,
          },

          '.rc-tree-iconEle': {
            width: 20,
            height: 20,

            svg: {
              width: '20px !important',
              height: '20px !important',
            },
          },

          '.rc-tree-title': {
            paddingLeft: theme.spacing.md,
            fontSize: theme.fontSizes.md,
          },
        },
      },
    },
  },

  action: {
    backgroundColor: theme.colors.gray[2],
  },
}));
