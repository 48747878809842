import {
  ActionIcon,
  Box,
  Button,
  createStyles,
  Group,
  Input,
  Stack,
  Text,
  TextInput,
} from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import React from 'react';
import { v4 as uuid } from 'uuid';

import { ReactComponent as CloseX } from '@portals/icons/linear/close-x.svg';

import { StatusWidgetFormType } from './status-form.types';
import { PopoverColorSelector } from '../../common/PopoverColorSelector';
import { OnAddCustomColorFn, WidgetColorType } from '../../widgets.types';

export interface StatusWidgetFormProps {
  form: UseFormReturnType<StatusWidgetFormType>;
  onAddCustomColor: OnAddCustomColorFn | undefined;
  colors: Array<WidgetColorType> | undefined;
}

export function StatusWidgetForm({
  form,
  onAddCustomColor,
  colors,
}: StatusWidgetFormProps) {
  const { classes, theme } = useStyles();

  return (
    <Stack spacing="xl" pr={30}>
      <Stack>
        <Text size="sm" color="gray.9">
          General
        </Text>

        <TextInput
          {...form.getInputProps('name')}
          autoFocus
          data-autofocus
          label="Title"
          data-testid="title-name-input"
          required
        />

        <TextInput
          {...form.getInputProps('telemetry')}
          label="Telemetry"
          data-testid="telemetry-input"
          required
        />
      </Stack>

      <Stack>
        <Text size="sm" color="gray.9">
          Status
        </Text>

        <Stack spacing="xl">
          {form.values.status.map((status, index) => (
            <Stack key={index}>
              <Group w="100%">
                <Input.Wrapper pt={4}>
                  <Input.Label />

                  <PopoverColorSelector
                    colors={colors}
                    onAddCustomColor={onAddCustomColor}
                    selectedColor={form.values.status[index].color}
                    onChange={(color) =>
                      form.setFieldValue(`status.${index}.color`, color)
                    }
                  >
                    {({ color }) => (
                      <Box className={classes.indicator} bg={color} />
                    )}
                  </PopoverColorSelector>
                </Input.Wrapper>

                <TextInput
                  {...form.getInputProps(`status.${index}.value`)}
                  label="Value"
                  data-testid="value-input"
                  required
                  sx={{ flex: 1 }}
                />

                <TextInput
                  {...form.getInputProps(`status.${index}.display_name`)}
                  label="Display Name"
                  data-testid="display-name-input"
                  required
                  sx={{ flex: 1 }}
                />

                {form.values.status.length > 1 ? (
                  <Input.Wrapper sx={{ flexGrow: 0 }} pt="xs">
                    <Input.Label></Input.Label>

                    <ActionIcon
                      size="xs"
                      color="gray.4"
                      onClick={() => form.removeListItem('status', index)}
                    >
                      <CloseX />
                    </ActionIcon>
                  </Input.Wrapper>
                ) : null}
              </Group>
            </Stack>
          ))}
        </Stack>
      </Stack>

      <Group grow>
        <Button
          variant="default"
          onClick={() =>
            form.insertListItem('status', {
              color: theme.colors['teal_accent'][7],
              display_name: '',
              value: '',
              key: uuid(),
            })
          }
          data-testid="add-status-btn"
        >
          Add Status
        </Button>
      </Group>
    </Stack>
  );
}

const useStyles = createStyles((theme) => ({
  indicator: {
    width: 19,
    height: 19,
    borderRadius: theme.radius.md,
    cursor: 'pointer',
  },
}));
