export enum ServiceCategoryEnum {
  CRM = 'crm',
  Ticketing = 'ticketing',
  Connectivity = 'connectivity',
  Analytics = 'analytics',
}

export interface ServiceCategoryType {
  id: ServiceCategoryEnum;
  label: string;
}

export interface ServiceType {
  id: string;
  name: string;
  logo: string;
  owner: string;
  price: string;
  category: ServiceCategoryEnum;
  shortDescription: string;
  description: string;
}

export interface ServicesGroupType {
  category: ServiceCategoryEnum;
  services: Array<ServiceType>;
}
