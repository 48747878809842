import {
  ColorPicker,
  Tooltip,
  Group,
  Stack,
  Button,
  TooltipProps,
  NumberInput,
  TextInput,
  createStyles,
  Box,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import hexRgb from 'hex-rgb';
import React, { useState } from 'react';
import rgbHex from 'rgb-hex';

import { ReactComponent as Plus } from '@portals/icons/linear/add-circle.svg';

import { OnAddCustomColorFn } from '../widgets.types';

const DEFAULT_COLOR = '#000000';

const { red, green, blue } = hexRgb(DEFAULT_COLOR);

const initialValues = {
  r: red,
  g: green,
  b: blue,
  hex: DEFAULT_COLOR,
};

export interface ColorPickerHexProps {
  onAddCustomColor: OnAddCustomColorFn | undefined;
}

export function ColorPickerHex({ onAddCustomColor }: ColorPickerHexProps) {
  const [isOpened, setIsOpened] = useState(false);
  const { classes } = useStyles();

  return (
    <Tooltip label="Add a custom color">
      <Tooltip
        opened={isOpened}
        width={315}
        label={
          <ColorPickerHexContent
            onAddCustomColor={onAddCustomColor}
            onClose={() => setIsOpened(false)}
          />
        }
        styles={tooltipStyles}
      >
        <Box
          c="gray.4"
          onClick={() => setIsOpened(true)}
          className={classes.actionIcon}
        >
          <Plus width={22} height={22} />
        </Box>
      </Tooltip>
    </Tooltip>
  );
}

interface ColorPickerHexContentProps {
  onAddCustomColor: OnAddCustomColorFn | undefined;
  onClose: () => void;
}

function ColorPickerHexContent({
  onAddCustomColor,
  onClose,
}: ColorPickerHexContentProps) {
  const { classes } = useStyles();

  const form = useForm({
    initialValues,
  });

  const handleAddCustomColor = () => {
    onAddCustomColor?.({
      colorHex: form.values.hex,
    });

    onClose();
  };

  const onChangeColor = (color: string) => {
    const { red, green, blue } = hexRgb(color);

    form.setValues({ r: red, g: green, b: blue, hex: color });
  };

  const onChangeRGB = ({ r, g, b }: { r: number; g: number; b: number }) => {
    try {
      const hexValue = `#${rgbHex(r, g, b)}`;

      form.setValues({ r, g, b, hex: hexValue });
    } catch (e) {
      return;
    }
  };

  const onChangeHex = (hex: string) => {
    try {
      form.setFieldValue('hex', hex);
      onChangeColor(hex);
    } catch (e) {
      return;
    }
  };

  const handleHexBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      hexRgb(form.values.hex);
    } catch (e) {
      form.setFieldValue('hex', DEFAULT_COLOR);
    }
  };

  return (
    <Stack>
      <ColorPicker
        {...form.getInputProps('hex')}
        onChange={(e) => {
          onChangeColor(e);
        }}
        size="lg"
      />

      <div className={classes.inputs}>
        <TextInput
          size="xs"
          label="Hex"
          id="hex"
          {...form.getInputProps('hex')}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onChangeHex(e.target.value)
          }
          onBlur={handleHexBlur}
        />
        <NumberInput
          size="xs"
          label="R"
          min={0}
          max={255}
          hideControls
          {...form.getInputProps('r')}
          onChange={(value: number) =>
            onChangeRGB({ r: value ?? 0, g: form.values.g, b: form.values.b })
          }
        />
        <NumberInput
          size="xs"
          label="G"
          min={0}
          max={255}
          hideControls
          {...form.getInputProps('g')}
          onChange={(value: number) =>
            onChangeRGB({ r: form.values.r, g: value ?? 0, b: form.values.b })
          }
        />
        <NumberInput
          size="xs"
          label="B"
          min={0}
          max={255}
          hideControls
          {...form.getInputProps('b')}
          onChange={(value: number) =>
            onChangeRGB({ r: form.values.r, g: form.values.g, b: value ?? 0 })
          }
        />
      </div>

      <Group position="right">
        <Button variant="default" onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={handleAddCustomColor}>Add</Button>
      </Group>
    </Stack>
  );
}

const tooltipStyles: TooltipProps['styles'] = (theme) => ({
  tooltip: {
    pointerEvents: 'unset',
    border: `1px solid ${theme.colors.gray[2]}`,
    padding: theme.spacing.md,
    backgroundColor: theme.white,
  },
});

const useStyles = createStyles((theme) => ({
  inputs: {
    display: 'grid',
    gridTemplateColumns: '2fr 1fr 1fr 1fr',
    gap: theme.spacing.sm,
  },
  actionIcon: {
    cursor: 'pointer',
    '&:hover': {
      transform: 'scale(1.25)',
    },
  },
}));
