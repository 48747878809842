import { find, flow, last, split } from 'lodash/fp';
import { useMutation } from 'react-query';
import { useSelector } from 'react-redux';
import { validate } from 'uuid';

import { getRequestOptions } from '@portals/api';
import { getAuth } from '@portals/redux';

export const downloadFile = (fileUrl: string, fileName: string) => {
  fetch(fileUrl)
    .then((res) => res.blob())
    .then((data) => {
      const a = document.createElement('a');
      a.href = window.URL.createObjectURL(data);

      const fileType = last(split('.', fileUrl));

      a.download = `${fileName}.${fileType}`;
      a.click();
    });
};

export const getFileTypeFromUrl = (fileUrl: string): string =>
  flow([split('.'), last])(fileUrl);

export const scrubFileName = (fileName: string) =>
  fileName?.replace(/[^\w\d_\-.]+/gi, '');

export function useDownloadFile() {
  return useMutation({
    mutationFn: async ({ fileUrl }: { fileUrl: string; fileName: string }) => {
      const response = await fetch(fileUrl);
      return await response.blob();
    },
    onSuccess: (blob, { fileUrl, fileName }) => {
      const a = document.createElement('a');
      a.href = window.URL.createObjectURL(blob);

      const fileType = last(split('.', fileUrl));

      a.download = `${fileName}.${fileType}`;
      a.click();
    },
  });
}

export function useAuthDownloadFile() {
  const auth = useSelector(getAuth);

  return useMutation({
    mutationFn: async ({ fileUrl }: { fileUrl: string; fileName: string }) => {
      const { options } = getRequestOptions(
        {
          url: '',
          method: 'GET',
        },
        auth
      );

      const response = await fetch(fileUrl, options);
      return await response.blob();
    },
    onSuccess: (blob, { fileName }) => {
      const a = document.createElement('a');
      a.href = window.URL.createObjectURL(blob);

      a.download = fileName;
      a.click();
    },
  });
}

export function extractFilenamePrefixedWithUuidV4(filename: string): string {
  const uuid = filename.match(
    /[a-f0-9]{8}-[a-f0-9]{4}-4[a-f0-9]{3}-[89ab][a-f0-9]{3}-[a-f0-9]{12}/i
  );

  if (uuid && uuid[0] && validate(uuid[0])) {
    return filename.replace(`${uuid[0]}-`, '');
  }

  return filename;
}

export function generateExportFilename(
  fileNamePrefix: string,
  fileExtension = 'csv'
) {
  const formatter = new Intl.DateTimeFormat(undefined, {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: 'numeric',
    minute: '2-digit',
    second: '2-digit',
    hour12: false,
  });

  const parts = formatter.formatToParts(new Date());

  const year = find({ type: 'year' }, parts)?.value;
  const month = find({ type: 'month' }, parts)?.value;
  const day = find({ type: 'day' }, parts)?.value;
  const hours = find({ type: 'hour' }, parts)?.value;
  const minutes = find({ type: 'minute' }, parts)?.value;
  const seconds = find({ type: 'second' }, parts)?.value;

  return `${fileNamePrefix}_${year}-${month}-${day}T${hours}-${minutes}-${seconds}.${fileExtension}`;
}
