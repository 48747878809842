import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';

import start from '@portals/framework';
import {
  OrganizationFeatureFlagsType,
  OrganizationPortalCapabilities,
  OrganizationPricingPlanFeatures,
  TenantType,
} from '@portals/types';

import { OrganizationDataLayout } from './components/layout/DashboardDataLayout';
import GlobalStyle from './components/layout/GlobalStyle';
import { MobileOrganizationMeta } from './components/layout/MobileOrganizationMeta';
import {
  AuthMobileLayout,
  DashboardMobileLayout,
} from './layouts/MobileLayout';
import modals from './modals';
import { routes } from './routes';

start<
  OrganizationFeatureFlagsType,
  OrganizationPortalCapabilities,
  OrganizationPricingPlanFeatures
>(routes, modals, TenantType.Organization, {
  globalLayout: () => (
    <>
      <GlobalStyle />
      <MobileOrganizationMeta />
    </>
  ),
  authLayout: AuthMobileLayout,
  dashboardLayout: DashboardMobileLayout,
  dashboardDataLayout: OrganizationDataLayout,
  options: {
    isMobile: true,
  },
});
