import { ActionIcon, Tooltip } from '@mantine/core';
import { compact, values } from 'lodash/fp';
import React, { useMemo } from 'react';
import { CSVLink } from 'react-csv';
import { ColumnInstance } from 'react-table';

import { ExportParams, TableInstanceType } from '@portals/types';
import { useAuthDownloadFile } from '@portals/utils';

import { ReactComponent as CsvIcon } from '../../assets/csv.svg';

interface ExportProps<TData extends object> {
  instance: TableInstanceType<TData>;
  remoteUrl?: ExportParams['remoteUrl'];
  fileName?: ExportParams['fileName'];
  name: string;
}

export function Export<TData extends object>({
  instance,
  name,
  remoteUrl = '',
  fileName,
}: ExportProps<TData>) {
  const localFileName = fileName || `${name || 'xyte'}.csv`;
  const { flatHeaders, rows } = instance;
  const downloadFile = useAuthDownloadFile();

  const exportData = useMemo(() => {
    const headers = compact(
      flatHeaders.map(
        ({
          Header,
          canExport = true,
        }: ColumnInstance<TData> & { canExport: boolean }) =>
          !canExport ? null : Header
      )
    );
    const dataRows = rows.map(({ values: rowValues }) => values(rowValues));

    return [headers, ...dataRows];
  }, [flatHeaders, rows]);

  return (
    <Tooltip label="Export" withinPortal>
      {remoteUrl ? (
        <ActionIcon
          data-testid="table-export-btn"
          loading={downloadFile.isLoading}
          onClick={() =>
            downloadFile.mutate({ fileUrl: remoteUrl, fileName: localFileName })
          }
        >
          <CsvIcon />
        </ActionIcon>
      ) : (
        <ActionIcon
          loading={downloadFile.isLoading}
          data-testid="table-export-btn"
        >
          <CSVLink filename={fileName} data={exportData}>
            <CsvIcon />
          </CSVLink>
        </ActionIcon>
      )}
    </Tooltip>
  );
}
