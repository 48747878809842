import { createConsumer } from '@rails/actioncable';
import { noop } from 'lodash/fp';
import { useCallback, useEffect } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';

import { getAuth } from '@portals/redux';
import { Channel } from '@portals/types';
import { WS_URL } from '@portals/utils';

type MessageHandler = (oldData: any, newData: any) => any;

interface ChannelHookParams {
  channelName: Channel;
  subscriptionParams: any;
  messageHandler: MessageHandler;
}

let consumer;

const useConnection = () => {
  const auth = useSelector(getAuth);

  const disconnect = useCallback(() => {
    if (!consumer) {
      return;
    }

    consumer.connection.close();

    // stopping, otherwise the monitor reopens a closed connection
    consumer.connection.monitor.stop();

    consumer = null;
  }, []);

  if (!auth) {
    return { disconnect };
  }

  consumer ||= createConsumer(
    `${WS_URL}?access-token=${auth.token}&client=${auth.client}&uid=${auth.uid}&tenant-id=${auth.tenant.id}&tenant-type=${auth.tenant.type}`
  );

  return { connection: consumer, disconnect };
};

const useChannel = ({
  channelName,
  subscriptionParams,
  messageHandler,
}: ChannelHookParams) => {
  const queryClient = useQueryClient();
  const { connection } = useConnection();

  useEffect(() => {
    const subscription = connection.subscriptions.create(
      { channel: channelName, ...subscriptionParams },
      {
        received(newData) {
          // Save in react-query's cache
          queryClient.setQueryData(
            [channelName, subscriptionParams],
            (oldData) => messageHandler(oldData, newData)
          );
        },
      }
    );

    return () => {
      subscription.unsubscribe();
      queryClient.resetQueries([channelName, subscriptionParams]);
    };
  }, [
    channelName,
    connection.subscriptions,
    messageHandler,
    queryClient,
    subscriptionParams,
  ]);

  // Return the data from cache
  return useQuery([channelName, subscriptionParams], {
    // Always disable, so that `queryFn` won't be called since we're updating the cache manually
    // on websocket's callback
    enabled: false,
    queryFn: noop,
  });
};

const useDeviceCommandsChannel = (args: {
  subscriptionParams: { device_id: string };
  messageHandler: MessageHandler;
}) => useChannel({ ...args, channelName: Channel.DeviceCommandsChannel });

const useOrgIncidentsChannel = (args: {
  subscriptionParams: { org_id: string };
  messageHandler: MessageHandler;
}) => useChannel({ ...args, channelName: Channel.OrgIncidents });

const useOrgDeviceStateChannel = (args: {
  subscriptionParams: { org_id: string; device_id: string };
  messageHandler: MessageHandler;
}) => useChannel({ ...args, channelName: Channel.OrgDeviceState });

export {
  useConnection,
  useOrgIncidentsChannel,
  useOrgDeviceStateChannel,
  useDeviceCommandsChannel,
};
