import {
  Box,
  createStyles,
  LoadingOverlay,
  Modal,
  ModalProps,
  Stack,
  Text,
} from '@mantine/core';
import React from 'react';

interface ModalCenteredMediaLayoutProps extends ModalProps {
  media: React.ReactNode;
  mediaSize?: number | string;
  description?: React.ReactNode;
  footer?: React.ReactNode;
  isLoading?: boolean;
}

export function ModalCenteredMediaLayout({
  children,
  title,
  media,
  mediaSize = 96,
  description,
  footer,
  withCloseButton = true,
  closeButtonProps,
  isLoading = false,
  ...modalProps
}: ModalCenteredMediaLayoutProps) {
  const { classes } = useStyles(mediaSize);

  return (
    <Modal.Root centered size="lg" padding="xxl" {...modalProps}>
      <Modal.Overlay />

      <Modal.Content>
        <Modal.Header className={classes.header}>
          <Stack className={classes.headerContent}>
            <div className={classes.media}>{media}</div>

            {title ? (
              <Modal.Title data-testid="modal-title">{title}</Modal.Title>
            ) : null}

            {description ? <Text size="sm">{description}</Text> : null}
          </Stack>

          {withCloseButton && <Modal.CloseButton size="md" />}
        </Modal.Header>

        <Modal.Body pos="relative">
          {children}

          {footer ? <Box mt="xxl">{footer}</Box> : null}

          <LoadingOverlay visible={isLoading} />
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
}

const useStyles = createStyles((_, mediaSize?: number | string) => ({
  header: {
    alignItems: 'flex-start',
  },
  headerContent: {
    flexGrow: 1,
    textAlign: 'center',
  },
  media: {
    display: 'flex',
    justifyContent: 'center',

    '> *': {
      width: mediaSize,
      height: mediaSize,
    },
  },
}));
