import { Button, Group, LoadingOverlay, Stack, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import React, { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useUpdateDevice } from '@portals/api/organizations';
import { ModalCenteredMediaLayout } from '@portals/core';
import { ModalProps } from '@portals/framework';
import { toastrSuccess } from '@portals/redux/actions/toastr';
import { DeviceType } from '@portals/types';

import roomSrc from '../assets/room.svg';

interface UpdateDeviceNameProps {
  device: DeviceType;
}

export const UpdateDeviceName: FC<ModalProps<UpdateDeviceNameProps>> = ({
  closeMe,
  data,
}) => {
  const dispatch = useDispatch();
  const { device } = data;
  const history = useHistory();
  const updateDevice = useUpdateDevice();

  const form = useForm<{ name: string }>({
    initialValues: {
      name: '',
    },
  });

  const onSubmit = useCallback(
    async (values) => {
      await updateDevice.mutateAsync({
        deviceId: device.id,
        spaceId: device.space_id,
        updatedDevice: {
          name: values.name,
        },
      });

      closeMe();

      dispatch(toastrSuccess('Device claimed'));
      history.push(`/claim/${device.space_id}`);
    },
    [closeMe, device.id, device.space_id, dispatch, history, updateDevice]
  );

  return (
    <ModalCenteredMediaLayout
      opened
      title="Name your device"
      onClose={closeMe}
      media={<img src={roomSrc} />}
    >
      <LoadingOverlay visible={updateDevice.isLoading} />

      <form onSubmit={form.onSubmit(onSubmit)}>
        <Stack>
          <Group grow mb="md">
            <TextInput
              data-autofocus
              placeholder="Device Name"
              required
              {...form.getInputProps('name')}
            />
          </Group>

          <Group grow>
            <Button
              type="submit"
              disabled={!form.isValid || !form.values.name}
              loading={updateDevice.isLoading}
            >
              Submit
            </Button>
          </Group>
        </Stack>
      </form>
    </ModalCenteredMediaLayout>
  );
};
