import { createStyles, Group, Stack, Text } from '@mantine/core';
import { size } from 'lodash/fp';
import React from 'react';

import { DeviceDetailsType } from '@portals/api/organizations';

import { ChildDevices } from './ChildDevices';
import { ParentDevice } from './ParentDevice';

interface ParentAndChildDevicesProps {
  device: DeviceDetailsType;
}

export function ParentAndChildDevices({ device }: ParentAndChildDevicesProps) {
  const { classes } = useStyles();

  const hasParent = !!device.parent_device;
  const hasChildren = size(device.child_devices) > 0;

  if (!hasParent && !hasChildren) {
    return null;
  }

  const title = hasParent ? 'Parent Device' : 'Child Devices';

  return (
    <Stack spacing="lg">
      <Text className={classes.title}>{title}</Text>
      <Group>
        {hasParent ? (
          <ParentDevice device={device.parent_device} />
        ) : (
          <ChildDevices devices={device.child_devices} />
        )}
      </Group>
    </Stack>
  );
}

const useStyles = createStyles((theme) => ({
  title: {
    fontSize: theme.fontSizes.md,
    color: theme.colors.blue_gray[7],
    fontWeight: 500,
  },
}));
