import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { usePortalConfig } from '@portals/api/organizations';
import { SignIn as BaseSignIn } from '@portals/framework';
import { getAuthError } from '@portals/redux';
import { resetAuthError } from '@portals/redux/actions/ui';

import Notification from '../../components/layout/Notification';
import { AuthWrapper } from './common';

export const SignIn = () => {
  const dispatch = useDispatch();
  const authError = useSelector(getAuthError);
  const portalConfig = usePortalConfig();

  return (
    <AuthWrapper>
      <BaseSignIn afterAuthPath="/" config={portalConfig.data} />

      <Notification
        error={authError}
        onClear={() => dispatch(resetAuthError())}
      />
    </AuthWrapper>
  );
};
