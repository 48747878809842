import { createStyles, Group } from '@mantine/core';
import React, { FC } from 'react';

import { useActionDispatch, useIsSidebarOpen } from '@portals/redux';
import { toggleSidebar as toggleSidebarAction } from '@portals/redux/actions/sidebarActions';
import { DesignType } from '@portals/types';

import { SidebarToggleButton } from './SidebarToggleButton';
import { XyteLogo } from '../../../assets';
import { useCommonDesign } from '../../../hooks/portal-config';

export const SidebarFooter: FC = () => {
  const design = useCommonDesign();
  const { classes, theme } = useStyles({ design });
  const isSidebarOpen = useIsSidebarOpen();
  const toggleSidebar = useActionDispatch(toggleSidebarAction);

  const showXyteCredits =
    isSidebarOpen && design?.sign_in_page?.powered_by_xyte_label !== false;

  return (
    <Group
      align="center"
      position="apart"
      className={classes.container}
      w="100%"
      p="md"
      c={design?.navigation_menu?.menu_text_color || theme.colors.blue_gray[6]}
    >
      {showXyteCredits ? (
        <Group align="center" spacing="xs">
          <>
            <span>Powered by</span>
            <XyteLogo height={13} />
          </>
        </Group>
      ) : null}

      <SidebarToggleButton
        isOpened={isSidebarOpen}
        toggleSidebar={toggleSidebar}
      />
    </Group>
  );
};

const useStyles = createStyles((theme, { design }: { design: DesignType }) => ({
  container: {
    borderTop: `1px solid ${theme.fn.rgba(
      design?.navigation_menu?.menu_text_color || theme.colors.blue_gray[6],
      0.2
    )}`,
  },
}));
