import { ActionIcon, Indicator, useMantineTheme } from '@mantine/core';
import React from 'react';
import { useSelector } from 'react-redux';

import { ReactComponent as InfoCircle } from '@portals/icons/linear/info-circle.svg';
import { ReactComponent as Notification } from '@portals/icons/linear/notification.svg';
import { getNotifications } from '@portals/redux';
import { OrganizationPortalCapabilities, TenantType } from '@portals/types';

import { NavbarDropdown } from './NavbarDropdown';
import { useAppConfig } from '../../../context';
import {
  useCommonDesign,
  useCommonPortalCapabilities,
} from '../../../hooks/portal-config';
import NavbarDropdownItem from '../../NavbarDropdownItem';

export const Notifications = () => {
  const theme = useMantineTheme();
  const appConfig = useAppConfig();
  const notifications = useSelector(getNotifications);
  const numOfNotifications = notifications?.length;
  const design = useCommonDesign();

  const portalCapabilities = useCommonPortalCapabilities();

  if (appConfig.tenantType === TenantType.Organization) {
    if ((portalCapabilities as OrganizationPortalCapabilities).b2c_view)
      return null;
  }

  return (
    <NavbarDropdown
      header="System notifications"
      count={numOfNotifications}
      icon={() => (
        <Indicator
          size={16}
          label={numOfNotifications}
          disabled={!numOfNotifications}
          color="red_accent"
          offset={6}
        >
          <ActionIcon
            variant="transparent"
            size="lg"
            sx={{
              color:
                design?.navigation_menu?.menu_text_color ||
                theme.colors.blue_gray[6],
            }}
          >
            <Notification />
          </ActionIcon>
        </Indicator>
      )}
    >
      {notifications.map(({ id, title, message, link }) => (
        <NavbarDropdownItem
          key={id}
          title={title}
          icon={
            <InfoCircle width={18} height={18} color={theme.colors.red[4]} />
          }
          message={message}
          link={link}
          spacing
        />
      ))}
    </NavbarDropdown>
  );
};
