import { Box, Button, createStyles, Group } from '@mantine/core';
import { Prism } from '@mantine/prism';
import React, { useEffect, useState } from 'react';
import { AutoSizer } from 'react-virtualized';

import { DeviceType } from '@portals/api/organizations';
import { VerticalScrollBar } from '@portals/scrollbar';
import { DeviceType as CommonDeviceType } from '@portals/types';
import { JsonEditor } from '@portals/ui';

const pretty = (json: object) => JSON.stringify(json, null, '\t');

interface NoSchemaProps {
  device: DeviceType | CommonDeviceType;
  updateConfig: (device_id: string, configuration: string) => void;
}

const NoSchema = ({ device, updateConfig }: NoSchemaProps) => {
  const { classes } = useStyles();
  const isReadOnly = device.is_config_schema_readonly;

  // If the data from the server changed, we update the editor
  useEffect(() => {
    setUpdatedConfig(pretty(device.config));
  }, [device]);

  const [updatedConfig, setUpdatedConfig] = useState(
    pretty(device.config || {})
  );
  const [isValid, setIsValid] = useState(true);

  const isPristine = updatedConfig === pretty(device.config);

  return (
    <Box className={classes.container}>
      <Box sx={{ flex: 1 }}>
        {isReadOnly ? (
          <VerticalScrollBar>
            <Prism
              styles={{ code: { whiteSpace: 'pre-wrap' } }}
              language="json"
              withLineNumbers
            >
              {JSON.stringify(device.config, null, 2)}
            </Prism>
          </VerticalScrollBar>
        ) : (
          <AutoSizer disableWidth>
            {({ height }) => (
              <JsonEditor
                data={updatedConfig}
                onChange={setUpdatedConfig}
                onValid={setIsValid}
                aceEditorProps={{ height: `${height}px` }}
              />
            )}
          </AutoSizer>
        )}
      </Box>

      {isReadOnly ? (
        <div />
      ) : (
        <Group position="right" py="md">
          <Button
            variant="default"
            disabled={isPristine}
            onClick={() => setUpdatedConfig(pretty(device.config))}
          >
            Revert Changes
          </Button>

          <Button
            disabled={isPristine || !isValid}
            onClick={() => updateConfig(device.id, updatedConfig)}
          >
            Save
          </Button>
        </Group>
      )}
    </Box>
  );
};

const useStyles = createStyles((theme) => ({
  container: {
    height: '100%',
    display: 'grid',
    gridTemplateRows: '1fr min-content',
    gap: theme.spacing.md,
    minHeight: 900,
    padding: theme.spacing.sm,
  },
}));

export default NoSchema;
