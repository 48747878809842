import {
  Box,
  createStyles,
  Group,
  LoadingOverlay,
  Stack,
  Text,
  Title,
} from '@mantine/core';
import lottie, { AnimationItem } from 'lottie-web';
import React, { useRef } from 'react';

import { VerticalScrollBar } from '@portals/scrollbar';

import { XyteLogo } from '../assets';
import animation from '../assets/animations/mobile_support.json';
import { useAppConfig } from '../context';
import { useCommonPortalConfig } from '../hooks/portal-config';

export function MobileSupport() {
  const { extraLayout } = useAppConfig();
  const portalConfig = useCommonPortalConfig();

  const { classes } = useStyles();
  const animationRef = useRef<AnimationItem>();
  const onRef = (node: HTMLDivElement) => {
    if (!node || !!animationRef.current) return;

    animationRef.current = lottie.loadAnimation({
      container: node,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: animation,
    });
  };

  if (!portalConfig.isFetched) return <LoadingOverlay visible />;

  return (
    <>
      {extraLayout?.globalLayout ? extraLayout?.globalLayout?.() : null}

      <VerticalScrollBar>
        <Stack spacing="xl" h="100%" py={40} className={classes.content}>
          <Group position="center">
            <XyteLogo height={25} />
          </Group>

          <Stack
            h="100%"
            align="center"
            justify="center"
            className={classes.content}
            spacing="xl"
          >
            <Title order={3} align="center" mb={45} w={320} maw="100%">
              Oops! Looks like you're using a mobile device
            </Title>

            <Box ref={onRef} className={classes.animationContainer} />

            <Text color="gray.7" align="center" size="md" w={320} maw="100%">
              Our platform is optimized for desktop use. For the best experience
              possible, please use our platform on a desktop computer.
            </Text>
          </Stack>
        </Stack>
      </VerticalScrollBar>
    </>
  );
}

const useStyles = createStyles((theme) => ({
  content: {
    position: 'relative',
  },
  animationContainer: {
    width: '100%',
    maxWidth: 400,

    svg: {
      width: '100%',
    },
  },
}));
