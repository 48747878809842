import { createStyles, Group } from '@mantine/core';
import React from 'react';

import { useAppConfig } from '../../../context';
import { useCommonDesign } from '../../../hooks/portal-config';
import { ContactSupport } from './ContactSupport';
import { HubspotConversationsTrigger } from './HubspotConversationsTrigger';
import { Notifications } from './Notifications';
import { ProductUpdates } from './ProductUpdates';
import { QuickSearch } from './QuickSearch';
import { UserSettings } from './UserSettings';

export const Navbar = () => {
  const { classes } = useStyles();
  const { extraLayout = {} } = useAppConfig();
  const { navbarLayout, options = {} } = extraLayout;
  const design = useCommonDesign();

  return (
    <Group
      position="right"
      align="center"
      spacing="xs"
      className={classes.container}
      sx={(theme) => ({
        backgroundColor:
          design?.navigation_menu?.menu_background_color || theme.white,
      })}
    >
      {options.isMobile ? null : <QuickSearch />}

      {navbarLayout ? navbarLayout() : null}

      <ContactSupport />

      <HubspotConversationsTrigger />

      <ProductUpdates />

      <Notifications />

      <UserSettings />
    </Group>
  );
};

const useStyles = createStyles((theme) => ({
  container: {
    height: '100%',
    padding: '10px 12px',
    borderBottom: `2px solid ${theme.colors.gray[2]}`,

    li: {
      listStyle: 'none',
    },
  },
}));
