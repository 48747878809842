import React, { useCallback, useMemo, useState } from 'react';
import { useDebounce } from 'react-use';

import { useClearDataCache } from '@portals/api';
import {
  devicesQueryKeys,
  incidentsQueryKeys,
  spacesQueryKeys,
  useOrganizationConfig,
} from '@portals/api/organizations';
import { TenantType } from '@portals/types';

import { useOrgIncidentsChannel } from '../hooks/websocket';

const DATA_REFETCH_DEBOUNCE_IN_MS = 2500;

function OrganizationGlobalSubscription() {
  const config = useOrganizationConfig();
  const [lastUpdated, setLastUpdated] = useState(null);
  const clearDataCache = useClearDataCache();

  const [, cancelDebounce] = useDebounce(
    () => {
      if (!lastUpdated) return;

      clearDataCache([
        // Refetch spaces data
        spacesQueryKeys.base,
        // Refetch stats - we're getting spaces incidents data from stats in some widgets
        spacesQueryKeys.stats.base,
        // Refetch spaces devices data - in Overview, when displaying devices, the num of
        // incidents there is taken from 'spaces/:id/devices' data
        spacesQueryKeys.devices.base,
        // Refetch specific device details - its data used in Device view modal (used to display
        // incidents counter in Device's Incidents tab)
        [...devicesQueryKeys.all(), 'details'],
        // Refetch specific device incidents data - displayed in Device view modal > Incidents
        // tab (data used to display specific device incidents via `useAllIncidents` hook)
        [...incidentsQueryKeys.base, 'device-details'],
        incidentsQueryKeys.count(),
      ]);
    },
    DATA_REFETCH_DEBOUNCE_IN_MS,
    [lastUpdated]
  );

  const messageHandler = useCallback(() => {
    cancelDebounce();
    setLastUpdated(new Date().toISOString());
  }, [cancelDebounce]);

  const subscriptionParams = useMemo(
    () => ({ org_id: config?.id }),
    [config?.id]
  );

  useOrgIncidentsChannel({
    subscriptionParams,
    messageHandler,
  });

  return null;
}

export function GlobalSubscription({ tenantType }: { tenantType: TenantType }) {
  switch (tenantType) {
    case TenantType.Organization:
      return <OrganizationGlobalSubscription />;
    default:
      return null;
  }
}
