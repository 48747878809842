import { createStyles, Paper, Stepper, StepperProps } from '@mantine/core';
import React from 'react';

import { FulfillmentStatusType as OrgsFulfillmentStatusType } from '@portals/api/organizations';
import { FulfillmentStatusType as PartnersFulfillmentStatusType } from '@portals/api/partners';

type FulfillmentStatusType =
  | PartnersFulfillmentStatusType
  | OrgsFulfillmentStatusType;

export const STATUS_TO_INDEX_MAP: Record<FulfillmentStatusType, number> = {
  order_placed: 0,
  missing_information: 0,

  processing: 1,

  ready: 2,

  shipped: 3,

  delivered: 5,
  available: 5,
};

export interface OrderShipmentProgressProps {
  value: PartnersFulfillmentStatusType | OrgsFulfillmentStatusType;
}

export function OrderShipmentProgress({ value }: OrderShipmentProgressProps) {
  const { classes } = useStyles();

  return (
    <Paper className={classes.container} withBorder radius="md">
      <Stepper
        styles={stepperStyles}
        active={STATUS_TO_INDEX_MAP[value]}
        w="100%"
        h={108}
      >
        <Stepper.Step
          label="Order placed"
          data-testid="order-placed-progress-processing-step"
        />
        <Stepper.Step
          label="Processing"
          data-testid="order-shipment-progress-processing-step"
        />
        <Stepper.Step
          label="Shipment Ready"
          data-testid="order-shipment-progress-ready-for-shipment-step"
        />
        <Stepper.Step
          label="Shipped"
          data-testid="order-shipment-progress-ready-shipped-step"
        />
        <Stepper.Step
          label="Delivered"
          data-testid="order-shipment-progress-delivered-step"
        />
      </Stepper>
    </Paper>
  );
}

const stepperStyles: StepperProps['styles'] = (theme) => ({
  root: {
    paddingBlock: theme.spacing.xl,
    paddingInline: 100,
  },
  step: {
    position: 'relative',
    flexDirection: 'column',
    wordBreak: 'normal',
    cursor: 'default',
  },
  separator: {
    height: '1px',
    margin: 0,
  },
  stepIcon: {
    cursor: 'default',
  },
  stepBody: {
    position: 'absolute',
    bottom: -10,
    margin: 0,
    transform: 'translateY(100%)',
    display: 'flex',
    alignItems: 'center',
  },
  stepLabel: {
    width: 'max-content',
    cursor: 'default',
  },
});

const useStyles = createStyles((theme) => ({
  container: {
    background: theme.white,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));
