import brightSignSrc from './assets/brightsign.svg';
import connectWiseSrc from './assets/connectwise.svg';
import crestronXiOSrc from './assets/crestron-xio.svg';
import freshServiceSrc from './assets/freshservice.svg';
import googleChatSrc from './assets/google-chat.svg';
import hubspotSrc from './assets/hubspot.svg';
import jiraSrc from './assets/jira.png';
import microsoftTeamsRoomsSrc from './assets/microsoft-teams-rooms.svg';
import msdynamicsSrc from './assets/msdynamics.png';
import oracleSrc from './assets/oracle.png';
import qscReflectSrc from './assets/qsc-reflect.svg';
import salesforceSrc from './assets/salesforce.svg';
import servicenowSrc from './assets/servicenow2.png';
import slackSrc from './assets/slack.svg';
import webExTeamsSrc from './assets/webex-teams.png';
import zohoSrc from './assets/zoho.png';
import zoomRoomsSrc from './assets/zoom-rooms.svg';
import {
  ServiceCategoryEnum,
  ServiceCategoryType,
  ServiceType,
} from './integrations.types';

export const CATEGORIES: Array<ServiceCategoryType> = [
  {
    id: ServiceCategoryEnum.CRM,
    label: 'CRM',
  },
  {
    id: ServiceCategoryEnum.Analytics,
    label: 'Analytics',
  },
  {
    id: ServiceCategoryEnum.Connectivity,
    label: 'Connectivity',
  },
  {
    id: ServiceCategoryEnum.Ticketing,
    label: 'Ticketing System',
  },
];

export const CATEGORY = {
  [ServiceCategoryEnum.CRM]: 'CRM',
  [ServiceCategoryEnum.Analytics]: 'Analytics',
  [ServiceCategoryEnum.Connectivity]: 'Connectivity',
  [ServiceCategoryEnum.Ticketing]: 'Ticketing System',
};

export const SERVICES: Array<ServiceType> = [
  {
    id: 'servicenow',
    name: 'ServiceNow',
    logo: servicenowSrc,
    owner: 'XYTE',
    price: '$12 / month',
    shortDescription: `ServiceNow is a cloud-based IT service management platform that streamlines IT operations. It can be used to quickly connect XYTE’s cloud platform with devices, enabling businesses to monitor and manage their device data.`,
    description: `ServiceNow is a cloud-based IT service management platform that helps organizations manage their IT infrastructure and operations. It helps organizations automate processes, streamline services, and gain greater visibility into their IT environment. ServiceNow can be used to cloudify device connections with XYTE platform by automating processes and streamlining services across connected devices. This makes it easier for organizations to manage their data and services in the cloud. Additionally, ServiceNow can be used to monitor, manage, and analyze data from connected devices, allowing businesses to make informed decisions.`,
    category: ServiceCategoryEnum.Ticketing,
  },
  {
    id: 'salesforce',
    name: 'Salesforce',
    logo: salesforceSrc,
    owner: 'XYTE',
    price: '$12 / month',
    shortDescription: `Salesforce is a cloud-based customer relationship management (CRM) platform used by businesses to manage their customer relationships. With Salesforce, businesses can easily integrate XYTE’s cloud platform to capture and analyze device data to track customer usage of their products and services.`,
    description: `Salesforce is a cloud-based customer relationship management (CRM) platform that helps organizations manage their customer relationships and data. It helps organizations manage customer data, automate processes, and gain greater visibility into their customer relationships. Salesforce can be used to cloudify device connections with XYTE platform by allowing businesses to manage their data and processes in the cloud. This makes it easier for organizations to access their data from any device with an internet connection. Additionally, Salesforce can be used to monitor, manage, and analyze data from connected devices, allowing businesses to make informed decisions.`,
    category: ServiceCategoryEnum.CRM,
  },
  {
    id: 'msdynamics',
    name: 'MS Dynamics',
    logo: msdynamicsSrc,
    owner: 'XYTE',
    price: '$12 / month',
    shortDescription: `Microsoft Dynamics is an enterprise resource planning (ERP) and customer relationship management (CRM) platform used by businesses to manage their operations. With Dynamics, businesses can easily integrate XYTE’s cloud platform with their devices, allowing them to track usage and analyze device data.`,
    description: `Microsoft Dynamics is a cloud-based enterprise resource planning (ERP) software that helps organizations manage their business processes. It helps organizations manage customer relationships, inventory, supply chain, finance, and much more. MS Dynamics can be used to cloudify device connections with XYTE platform by allowing businesses to manage their data and processes in the cloud. This makes it easier for organizations to access their data from any device with an internet connection. Additionally, MS Dynamics can be used to monitor, manage, and analyze data from connected devices, allowing businesses to make informed decisions.`,
    category: ServiceCategoryEnum.CRM,
  },
  {
    id: 'jira',
    name: 'Jira',
    logo: jiraSrc,
    owner: 'XYTE',
    price: '$12 / month',
    shortDescription: `Jira is a popular project management platform used by businesses to manage their projects. Jira can be used to quickly connect XYTE’s cloud platform with devices, enabling businesses to monitor and manage their device data.`,
    description: `Jira is a cloud-based project management platform that helps organizations manage their projects and tasks. It helps organizations manage and track tasks, assign tasks to team members, and gain greater visibility into their project progress. Jira can be used to cloudify device connections with XYTE platform by allowing businesses to manage their data and processes in the cloud. This makes it easier for organizations to access their data from any device with an internet connection. Additionally, Jira can be used to monitor, manage, and analyze data from connected devices, allowing businesses to make informed decisions.`,
    category: ServiceCategoryEnum.Ticketing,
  },
  {
    id: 'hubspot',
    name: 'HubSpot',
    logo: hubspotSrc,
    owner: 'XYTE',
    price: '$12 / month',
    shortDescription: `HubSpot is a cloud-based customer relationship management (CRM) platform used by businesses to manage their customer relationships. With HubSpot, businesses can easily integrate XYTE’s cloud platform to capture and analyze device data to track customer usage of their products and services.`,
    description: `HubSpot is a cloud-based customer relationship management (CRM) platform that helps organizations manage their customer relationships and data. It helps organizations manage customer data, automate processes, and gain greater visibility into their customer relationships. HubSpot can be used to cloudify device connections with XYTE platform by allowing businesses to manage their data and processes in the cloud. This makes it easier for organizations to access their data from any device with an internet connection. Additionally, HubSpot can be used to monitor, manage, and analyze data from connected devices, allowing businesses to make informed decisions.`,
    category: ServiceCategoryEnum.CRM,
  },
  {
    id: 'zoho',
    name: 'Zoho',
    logo: zohoSrc,
    owner: 'XYTE',
    price: '$12 / month',
    shortDescription: `Zoho is an enterprise resource planning (ERP) and customer relationship management (CRM) platform used by businesses to manage their operations. With Zoho, businesses can easily integrate XYTE’s cloud platform with their devices, allowing them to track usage and analyze device data.`,
    description: `Zoho is a suite of cloud-based business applications that helps organizations manage their business processes. It includes applications for customer relationship management (CRM), project management, finance, and more. Zoho can be used to cloudify device connections with XYTE platform by allowing businesses to manage their data and processes in the cloud. This makes it easier for organizations to access their data from any device with an internet connection. Additionally, Zoho can be used to monitor, manage, and analyze data from connected devices, allowing businesses to make informed decisions.`,
    category: ServiceCategoryEnum.CRM,
  },
  {
    id: 'oracle',
    name: 'Oracle',
    logo: oracleSrc,
    owner: 'XYTE',
    price: '$12 / month',
    shortDescription: `Oracle provides enterprise resource planning (ERP) and analytics software to help businesses manage their operations. With Oracle, businesses can easily integrate XYTE’s cloud platform with devices to collect and analyze device data. This allows businesses to make informed decisions and improve their operations.`,
    description: `Oracle is a cloud-based enterprise resource planning (ERP) software that helps organizations manage their business processes. It helps organizations manage customer relationships, inventory, supply chain, finance, and much more. Oracle can be used to cloudify device connections with XYTE platform by allowing businesses to manage their data and processes in the cloud. This makes it easier for organizations to access their data from any device with an internet connection. Additionally, Oracle can be used to monitor, manage, and analyze data from connected devices, allowing businesses to make informed decisions.`,
    category: ServiceCategoryEnum.CRM,
  },
  {
    id: 'freshservice',
    name: 'FreshService',
    logo: freshServiceSrc,
    owner: 'XYTE',
    price: '$12 / month',
    shortDescription: `FreshService is a cloud-based ITSM software that can integrate with various cloud IoT providers to manage and monitor connected devices and provide centralized management and incident resolution.`,
    description: `FreshService is a cloud-based IT service management (ITSM) software that can integrate with various cloud IoT providers to manage and monitor connected devices. The integration allows FreshService to collect data from IoT devices, create tickets and incidents based on device status, and perform remote troubleshooting and maintenance. This allows for centralized management of IoT devices and streamlined incident resolution. The specific steps for integrating FreshService with a specific IoT provider will vary depending on the provider's API and FreshService's integration capabilities.`,
    category: ServiceCategoryEnum.CRM,
  },
  {
    id: 'connectwise',
    name: 'ConnectWise',
    logo: connectWiseSrc,
    owner: 'XYTE',
    price: '$12 / month',
    shortDescription: `ConnectWise is a professional services automation software that can integrate with cloud IoT providers to automate tasks such as device provisioning, monitoring, and maintenance. It helps to streamline the management of IoT devices and improve efficiency, but the specific integration points will depend on the IoT provider's features.`,
    description: `ConnectWise is a professional services automation (PSA) software that can integrate with various cloud IoT providers. The integration allows for the automation of certain tasks, such as device provisioning, monitoring, and maintenance. This can help streamline the management of IoT devices and improve efficiency. The specific details of how ConnectWise integrates with a particular cloud IoT provider will depend on the provider and the specific features they offer. Some possible integration points may include APIs for device management, integration with monitoring and alerting tools, and support for automation scripts.`,
    category: ServiceCategoryEnum.Ticketing,
  },
  {
    id: 'googlechat',
    name: 'Google Chat',
    logo: googleChatSrc,
    owner: 'XYTE',
    price: '$12 / month',
    shortDescription:
      'Google Chat integration with a cloud IoT provider allows users to control and receive updates from IoT devices through the Google Chat platform using a bridge or gateway to connect the devices and handle tasks like authentication and encryption.',
    description:
      'Google Chat integration with a cloud IoT provider allows users to interact with their IoT devices and receive updates from them via the Google Chat platform. This can include sending commands to control the devices, receiving real-time sensor data, and setting up notifications for certain events. The integration is typically accomplished through the use of a bridge or gateway that connects the IoT devices to the Google Chat platform. This bridge may also handle tasks such as device authentication, data encryption, and message routing.',
    category: ServiceCategoryEnum.Connectivity,
  },
  {
    id: 'microsoftteams',
    name: 'Microsoft Teams',
    logo: microsoftTeamsRoomsSrc,
    owner: 'XYTE',
    price: '$12 / month',
    shortDescription:
      'Microsoft Teams can be integrated with a cloud IoT provider to enable real-time communication, collaboration, data sharing and device management among team members working on IoT projects. This allows for seamless communication and collaboration among team members, regardless of location.',
    description:
      'Microsoft Teams is a collaboration platform that allows users to communicate and collaborate in real-time. It can be integrated with a cloud IoT provider to allow for seamless communication and collaboration among team members working on IoT projects. The integration allows team members to share data and information from IoT devices in real-time, as well as collaborate on the development and deployment of IoT solutions. This can include features such as real-time data visualization and analytics, device management, and remote control capabilities. Additionally, the integration allows for easy communication and collaboration among team members, regardless of location, making it a powerful tool for distributed IoT teams.',
    category: ServiceCategoryEnum.Connectivity,
  },
  {
    id: 'slack',
    name: 'Slack',
    logo: slackSrc,
    owner: 'XYTE',
    price: '$12 / month',
    shortDescription: `Slack integration with a cloud IoT provider enables messaging and control of IoT devices through a Slack channel using the provider's and Slack's APIs for real-time communication and monitoring.`,
    description: `Slack integration with a cloud IoT provider allows for the sending and receiving of messages between the IoT devices and a Slack channel. This integration can be set up using the cloud IoT provider's API and Slack's API. Once the integration is set up, IoT devices can send messages to a designated Slack channel, and users can send commands to the IoT devices through the Slack channel. This allows for real-time communication and control of IoT devices, as well as easy monitoring and logging of IoT device activity.`,
    category: ServiceCategoryEnum.Connectivity,
  },
  {
    id: 'webexteams',
    name: 'WebEx Teams',
    logo: webExTeamsSrc,
    owner: 'XYTE',
    price: '$12 / month',
    shortDescription:
      'WebEx Teams integration with a cloud IoT provider allows users to control and communicate with IoT devices through the WebEx Teams platform using APIs, allowing teams to view sensor data, control device settings, and receive alerts in real-time communication.',
    description:
      'WebEx Teams is a collaboration platform that allows users to communicate and collaborate in real-time through text, voice, and video. Integration with a cloud IoT provider would allow users to connect and control IoT devices through the WebEx Teams platform. This would allow team members to view sensor data, control device settings, and receive alerts from IoT devices, all within the context of their ongoing team communication. The integration would likely involve using APIs provided by the IoT provider to connect to and control the devices, as well as integrating the sensor data and device controls into the WebEx Teams user interface.',
    category: ServiceCategoryEnum.Connectivity,
  },
  {
    id: 'zoomrooms',
    name: 'Zoom Rooms',
    logo: zoomRoomsSrc,
    owner: 'XYTE',
    price: '$12 / month',
    shortDescription:
      'Zoom Rooms integration with a cloud IoT provider allows users to control and manage connected devices in a meeting room through the Zoom Rooms interface, and use device data to enhance the meeting experience, such as optimize audio and video quality.',
    description:
      'Zoom Rooms is a software-based conference room solution that can be integrated with a cloud IoT provider to control and manage connected devices in a meeting room, such as cameras, microphones, and displays. This integration allows users to control these devices through the Zoom Rooms interface, and to use device data to enhance the meeting experience. For example, an integrated IoT-enabled camera can automatically pan and zoom to keep all meeting participants in frame, or an IoT-enabled microphone can automatically adjust its sensitivity based on the ambient noise level in the room. Additionally, data from these devices can be used to improve the overall performance of the Zoom Rooms system, such as to optimize audio and video quality.',
    category: ServiceCategoryEnum.Connectivity,
  },
  {
    id: 'microsoftteamsrooms',
    name: 'MS Teams Rooms',
    logo: microsoftTeamsRoomsSrc,
    owner: 'XYTE',
    price: '$12 / month',
    shortDescription:
      'Microsoft Teams Rooms is a platform for video meetings and conference calls that can be integrated with IoT cloud providers to connect IoT devices and provide enhanced collaboration and communication capabilities. Additionally, sensor data from IoT devices can be integrated to provide real-time insights and analytics.',
    description:
      'Microsoft Teams Rooms is a platform that allows users to schedule and join video meetings and conference calls using a variety of devices, including desktops, laptops, and conference room systems. It can be integrated with IoT (Internet of Things) cloud providers to provide additional functionality and connectivity options for users. For example, IoT-enabled devices such as cameras, microphones, and speakers can be connected to the Teams Rooms platform, allowing for enhanced collaboration and communication capabilities. Additionally, sensor data from IoT devices can be integrated with the Teams Rooms platform to provide real-time insights and analytics. Overall, the integration of Teams Rooms with IoT cloud providers can help organizations improve the efficiency and effectiveness of their meetings and collaboration efforts',
    category: ServiceCategoryEnum.Connectivity,
  },
  {
    id: 'brightsign',
    name: 'BrightSign',
    logo: brightSignSrc,
    owner: 'XYTE',
    price: '$12 / month',
    shortDescription:
      "BrightSign is a digital signage platform that allows for remote access and control of multimedia content via integration with a cloud IoT provider. This is done using BrightSign's built-in networking and APIs. Specific integration steps depend on the cloud IoT provider.",
    description:
      "BrightSign is a digital signage platform that allows users to create and manage multimedia content for display on digital signs and displays. Integration with a cloud IoT provider would allow users to remotely access and control their digital signage content through the cloud, as well as receive analytics and other data about the performance of their signs. This can be done through the use of BrightSign's built-in networking capabilities and APIs, which allow for easy integration with other systems and platforms. The specific steps and requirements for integrating BrightSign with a particular cloud IoT provider would depend on the specific provider and their API.",
    category: ServiceCategoryEnum.Connectivity,
  },
  {
    id: 'crestronxio',
    name: 'Crestron XiO',
    logo: crestronXiOSrc,
    owner: 'XYTE',
    price: '$12 / month',
    shortDescription: `Crestron XiO is a cloud-based platform that integrates Crestron devices with IoT providers for remote monitoring, control, and data collection. The integration process involves connecting to the provider's cloud platform and using their API.`,
    description:
      "Crestron XiO is a cloud-based platform that allows for integration of Crestron devices and systems with various internet of things (IoT) providers. This integration allows for remote monitoring and control of the devices and systems, as well as the ability to collect and analyze data from them. The process for integrating with a specific IoT provider will vary, but typically involves configuring the Crestron device or system to connect to the provider's cloud platform and then using the provider's API to access and control the device or system.",
    category: ServiceCategoryEnum.Connectivity,
  },
  {
    id: 'qscreflect',
    name: 'QSC Reflect',
    logo: qscReflectSrc,
    owner: 'XYTE',
    price: '$12 / month',
    shortDescription:
      'QSC Reflect is a software that allows for integration of IoT devices with cloud providers by collecting and analyzing data from the devices and triggering actions in the cloud. It can connect to multiple cloud platforms such as AWS, Azure, and Google Cloud.',
    description:
      'QSC Reflect is a software platform that enables integration of various IoT devices and sensors with cloud-based IoT providers. It allows users to collect and analyze data from IoT devices, and then use that data to trigger actions or events in the cloud. Reflect can be used to connect IoT devices to a variety of cloud platforms, including AWS IoT, Microsoft Azure IoT, and Google Cloud IoT. The integration process typically involves configuring the IoT device to send data to Reflect, and then configuring Reflect to forward that data to the desired cloud platform. Once the integration is complete, users can access and analyze the data from the cloud, and use it to trigger actions or events in other systems.',
    category: ServiceCategoryEnum.Connectivity,
  },
];
