import { isUndefined, isNil } from 'lodash/fp';
import { useSelector } from 'react-redux';

import { getIsPending } from '../selectors/ui';

export const useIsPending = (type: string | Array<string>): boolean =>
  useSelector(getIsPending(type));

export enum DataState {
  Initial = 'initial',
  Pending = 'pending',
  Fetched = 'fetched',
}

export type UseDataWithState = {
  actionsTypes: string | Array<string>;
  data: any;
};

export const useDataWithState = ({
  actionsTypes,
  data,
}: UseDataWithState): [any, DataState] => {
  const isPending = useIsPending(actionsTypes);
  const isInitial = isUndefined(data);

  const isEmpty = !isInitial && !isPending && isNil(data);

  let dataState;

  if (isPending) {
    dataState = DataState.Pending;
  } else if (!isEmpty) {
    dataState = DataState.Fetched;
  } else {
    dataState = DataState.Initial;
  }

  return [data, dataState];
};
