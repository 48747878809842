import { Button, Group, Stack, Text, Title } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { InferType, object } from 'yup';

import { useErrorByActionName, useIsPending } from '@portals/redux';
import { changePasswordToken } from '@portals/redux/actions/auth';
import { FADE_IN_OUT } from '@portals/ui';

import { useAppConfig } from '../../context';
import { FormWrapper } from './common';
import {
  PasswordInputWithRequirements,
  yupPasswordConfirmValidator,
  yupPasswordValidator,
} from './PasswordInputWithRequirements';

const schema = object({
  password: yupPasswordValidator,
  confirm: yupPasswordConfirmValidator('password'),
});

export function ChangePassword() {
  const dispatch = useDispatch();
  const params = useParams<{ token: string }>();

  const { tenantType } = useAppConfig();
  const serverError = useErrorByActionName('changePasswordToken');
  const isPending = useIsPending('changePasswordToken');

  const form = useForm<InferType<typeof schema>>({
    initialValues: {
      password: '',
      confirm: '',
    },
    validate: yupResolver(schema),
  });

  const onSubmit = (values: typeof form.values) => {
    dispatch(changePasswordToken(params.token, values.password, tenantType));
  };

  return (
    <FormWrapper id="change-password">
      <Stack>
        <Title order={1} mb="lg" className="auth-page-title">
          Change Password
        </Title>

        <form noValidate onSubmit={form.onSubmit(onSubmit)}>
          <Stack>
            <PasswordInputWithRequirements
              value={form.values.password}
              onChange={(e) => form.setFieldValue('password', e.target.value)}
              error={form.errors.password}
            />

            <PasswordInputWithRequirements
              label="Confirm password"
              placeholder="The same password again"
              value={form.values.confirm}
              onChange={(e) => form.setFieldValue('confirm', e.target.value)}
              error={form.errors.confirm}
            />

            <Button type="submit" loading={isPending} mt="lg">
              Update
            </Button>
          </Stack>
        </form>

        <AnimatePresence>
          {serverError ? (
            <motion.div {...FADE_IN_OUT}>
              <Text color="red" className="auth-page-error">
                {serverError}
              </Text>
            </motion.div>
          ) : null}
        </AnimatePresence>

        <Text color="dimmed" size="sm" align="center">
          <Group spacing={6}>
            Return to
            <Link to="/auth/sign-in">Sign-In</Link>
          </Group>
        </Text>
      </Stack>
    </FormWrapper>
  );
}
