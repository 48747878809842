import { Button, Modal, PasswordInput, Stack } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import React from 'react';
import { useDispatch } from 'react-redux';
import { object, string } from 'yup';

import { ModalBody, ModalFooter } from '@portals/core';
import { useIsPending } from '@portals/redux';
import { changePassword } from '@portals/redux/actions/auth';

import { ModalProps } from '../components/Modals';
import {
  PasswordInputWithRequirements,
  yupPasswordConfirmValidator,
  yupPasswordValidator,
} from '../pages/auth/PasswordInputWithRequirements';

const schema = object({
  old_password: string().required(),
  new_password: yupPasswordValidator,
  confirm: yupPasswordConfirmValidator('new_password'),
});

export function ChangePasswordModal({ closeMe }: ModalProps) {
  const dispatch = useDispatch();
  const isPending = useIsPending('changePassword');

  const form = useForm({
    initialValues: {
      old_password: '',
      new_password: '',
      confirm: '',
    },
    validate: yupResolver(schema),
  });

  const onSubmit = (values: typeof form.values) => {
    dispatch(changePassword(values, closeMe));
  };

  return (
    <Modal
      opened
      onClose={closeMe}
      closeOnEscape={false}
      title="Change Password"
    >
      <form noValidate onSubmit={form.onSubmit(onSubmit)}>
        <ModalBody>
          <Stack>
            <PasswordInput
              required
              data-autofocus
              label="Old password"
              placeholder="Old password"
              {...form.getInputProps('old_password')}
            />

            <PasswordInputWithRequirements
              label="New Password"
              value={form.values.new_password}
              onChange={(e) =>
                form.setFieldValue('new_password', e.target.value)
              }
              error={form.errors.new_password}
            />

            <PasswordInputWithRequirements
              label="Confirm Password"
              value={form.values.confirm}
              onChange={(e) => form.setFieldValue('confirm', e.target.value)}
              error={form.errors.confirm}
            />
          </Stack>
        </ModalBody>

        <ModalFooter position="right">
          <Button variant="default" onClick={closeMe}>
            Cancel
          </Button>
          <Button type="submit" loading={isPending}>
            Change
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
}
