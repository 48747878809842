import { CloseButton, createStyles, Group } from '@mantine/core';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import ReactDOM from 'react-dom';
import { useLocation } from 'react-router-dom';

import { useCurrentUser, useHubspotConversationsToken } from '@portals/api/ui';

interface HubspotConversationsContextType {
  isEnabled: boolean;
  showWidget: () => void;
  hideWidget: () => void;
  toggleWidget: () => void;
  isOpen: boolean;
  unreadMessagesCount: number;
  removeWidget: () => void;
}

const HubspotConversationsContext =
  createContext<HubspotConversationsContextType | null>(null);

const HUBSPOT_INLINE_EMBED_ELEMENT_ID =
  'hubspot-conversations-inline-embed-selector';

interface HubspotConversationsProviderProps {
  children: React.ReactNode;
}

export function HubspotConversationsProvider({
  children,
}: HubspotConversationsProviderProps) {
  const location = useLocation();

  const [isReady, setIsReady] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [unreadMessagesCount, setUnreadMessagesCount] = useState(0);

  const { classes } = useStyles(isOpen);

  // Widget is enabled only for partners portal and organizations portal that are not under a custom domain.
  const isEnabled = window.location.host.includes('.xyte.io');

  const currentUser = useCurrentUser();
  const hubspotToken = useHubspotConversationsToken(isEnabled);

  const hideWidget = useCallback(() => {
    setIsOpen(false);
  }, []);

  const showWidget = useCallback(() => {
    if (!isEnabled || !isReady) return;

    try {
      window.HubSpotConversations?.widget?.load?.();
      window.HubSpotConversations?.widget?.open?.();

      setIsOpen(true);
    } catch (error) {
      console.error(error);
    }
  }, [isEnabled, isReady]);

  const toggleWidget = useCallback(() => {
    if (isOpen) {
      hideWidget();
    } else {
      showWidget();
    }
  }, [hideWidget, isOpen, showWidget]);

  const removeWidget = useCallback(() => {
    try {
      window.HubSpotConversations?.widget?.remove?.();
    } catch (error) {
      console.error(error);
    }
  }, []);

  const onConversationsReady = useCallback(() => {
    setIsReady(true);
  }, []);

  useEffect(
    function init() {
      if (!isEnabled) {
        // Reset Hubspot SDK if we don't want to enable it
        window.HubSpotConversations = undefined as any;
        return;
      }

      if (window.HubSpotConversations) {
        onConversationsReady();
      } else {
        window.hsConversationsOnReady = [onConversationsReady];
      }
    },
    [isEnabled, onConversationsReady]
  );

  useEffect(
    function updateSettingsAndLoadWidget() {
      if (!isEnabled) return;

      if (
        hubspotToken.isFetched &&
        hubspotToken?.data &&
        currentUser.data?.email
      ) {
        try {
          window.hsConversationsSettings = {
            ...window.hsConversationsSettings,
            identificationEmail: currentUser.data.email,
            identificationToken: hubspotToken.data.token,
          };
          window.HubSpotConversations?.widget?.load?.();
        } catch (error) {
          console.error(error);
        }
      }
    },
    [
      hubspotToken.data,
      hubspotToken.isFetched,
      isEnabled,
      currentUser.data?.email,
    ]
  );

  useEffect(
    function addEventListeners() {
      if (!isEnabled || !isReady) return;

      function listener(payload: { unreadCount: number }) {
        setUnreadMessagesCount(payload.unreadCount);
      }

      try {
        window.HubSpotConversations?.on(
          'unreadConversationCountChanged',
          listener
        );
      } catch (error) {
        console.error(error);
      }

      return () => {
        try {
          window.HubSpotConversations?.off(
            'unreadConversationCountChanged',
            listener
          );
        } catch (error) {
          console.error(error);
        }
      };
    },
    [isEnabled, isReady]
  );

  useEffect(
    function refreshConversationsOnRouteChange() {
      if (!isEnabled || !isReady || !currentUser.data?.email) return;

      try {
        window.HubSpotConversations?.widget?.refresh?.();
      } catch (error) {
        console.error(error);
      }
    },
    [isEnabled, isReady, location.pathname, currentUser.data?.email]
  );

  return (
    <HubspotConversationsContext.Provider
      value={{
        isEnabled,
        isOpen,
        showWidget,
        hideWidget,
        toggleWidget,
        unreadMessagesCount,
        removeWidget,
      }}
    >
      {children}

      {ReactDOM.createPortal(
        <div className={classes.chatWidgetContainer}>
          <Group position="right" p={4}>
            <CloseButton size="lg" onClick={hideWidget} />
          </Group>

          <div id={HUBSPOT_INLINE_EMBED_ELEMENT_ID} />
        </div>,
        document.body
      )}
    </HubspotConversationsContext.Provider>
  );
}

const useStyles = createStyles((theme, isOpen: boolean) => ({
  chatWidgetContainer: {
    overflow: 'hidden',
    zIndex: 2147483647, // this is the max possible value
    position: 'fixed',
    top: 75,
    right: theme.spacing.xl,
    display: isOpen ? 'grid' : 'none',
    gridTemplateRows: 'max-content 1fr',
    height: 500,
    width: 376,
    borderRadius: theme.radius.md,
    backgroundColor: theme.white,
    boxShadow: '0 5px 20px rgb(0 0 0 / 10%)',

    [`#${HUBSPOT_INLINE_EMBED_ELEMENT_ID}`]: {
      width: '100%',
      height: '100%',
    },

    '#hubspot-conversations-inline-parent': {
      width: '100%',
      height: '100%',
    },

    '#hubspot-conversations-inline-iframe': {
      width: '100%',
      height: '100%',
      border: 'none',
    },
  },
}));

export function useHubspotConversations() {
  const context = useContext(HubspotConversationsContext);

  if (context === null) {
    throw new Error(
      'useHubspotConversations must be used within HubspotConversationsProvider'
    );
  }

  return context;
}

declare global {
  interface Window {
    hsConversationsSettings?: Record<string, any>;
    hsConversationsOnReady?: Array<() => void>;
    HubSpotConversations?: {
      on?: any;
      off?: any;
      widget?: {
        status?: () => { loaded: boolean; pending: boolean };
        load?: (params?: { widgetOpen: boolean }) => void;
        remove?: () => void;
        open?: () => void;
        close?: () => void;
        refresh?: (openToNewThread?: boolean) => void;
      };
    };
  }
}
