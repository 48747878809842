import { AnimateSharedLayout } from 'framer-motion';
import { forEach, startsWith } from 'lodash/fp';
import React, { FC, ReactNode, useMemo } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';

import { useAuth } from '@portals/redux';
import {
  CommonFeatureFlagsType,
  CommonPortalCapabilities,
  CommonPricingPlanFeatures,
} from '@portals/types';
import { ScrollToTop } from '@portals/ui';

import { AutoTenantSwitcher } from './AutoTenantSwitcher';
import { authRoutes } from './routes.constants';
import { getChildRoutes } from './routes.utils';
import ErrorBoundary from '../components/ErrorBoundary';
import Modals from '../components/Modals';
import { usePermissionAccess } from '../components/permission-access/use-permission-access';
import { useAppConfig } from '../context';
import { useNetworkStatus } from '../hooks/network-status';
import {
  useCommonClassification,
  useCommonFeatureFlags,
  useCommonPortalCapabilities,
  useCommonPricingPlanFeatures,
} from '../hooks/portal-config';
import { useHasSupportSeat } from '../hooks/support-seats';
import AuthLayout from '../layouts/Auth';
import { DashboardLayout } from '../layouts/Dashboard';
import { ExternalStoreLayout } from '../layouts/ExternalStoreLayout';
import OnboardingLayout from '../layouts/Onboarding';
import { Page404 } from '../pages/auth/Page404';
import Referral from '../pages/auth/Referral';
import {
  RouteOverlayLocationState,
  RouteOverlayWrapper,
} from '../route-overlays';

enum LayoutTypeEnum {
  Auth = 'Auth',
  ExternalStore = 'ExternalStore',
  Dashboard = 'Dashboard',
  Onboarding = 'Onboarding',
}

function AdjustedRoutes<
  TFeatureFlags extends CommonFeatureFlagsType,
  TPortalCapabilities extends CommonPortalCapabilities,
  TPricingPlanFeatures extends CommonPricingPlanFeatures
>() {
  const location = useLocation<RouteOverlayLocationState>();
  const { extraLayout, routes } = useAppConfig<
    TFeatureFlags,
    TPortalCapabilities,
    TPricingPlanFeatures
  >();
  const featureFlags = useCommonFeatureFlags() as TFeatureFlags;
  const portalCapabilities =
    useCommonPortalCapabilities() as TPortalCapabilities;
  const pricingPlanFeatures =
    useCommonPricingPlanFeatures() as TPricingPlanFeatures;
  const { canView, canEdit, isAdmin } = usePermissionAccess();
  const hasSupportSeat = useHasSupportSeat();
  const authentication = useAuth();

  const classification = useCommonClassification();

  const [auth, dashboard, shop = null, onboarding = null] = useMemo(() => {
    const appRoutes = [
      getChildRoutes<TFeatureFlags, TPortalCapabilities, TPricingPlanFeatures>({
        routes: routes?.auth || authRoutes,
        featureFlags,
        portalCapabilities,
        pricingPlanFeatures,
        canView,
        canEdit,
        isAdmin,
        hasSupportSeat,
        classification,
      }),
    ];

    const dashboardRoutes: ReactNode[] = [];

    forEach((dashboardRoute) => {
      if (
        dashboardRoute.canAccessRoute &&
        !dashboardRoute.canAccessRoute({
          featureFlags,
          portalCapabilities,
          pricingPlanFeatures,
          canView,
          canEdit,
          isAdmin,
          hasSupportSeat,
          classification,
        })
      ) {
        return;
      }

      dashboardRoutes.push(
        getChildRoutes<
          TFeatureFlags,
          TPortalCapabilities,
          TPricingPlanFeatures
        >({
          routes: dashboardRoute.childRoutes,
          featureFlags,
          portalCapabilities,
          pricingPlanFeatures,
          canView,
          canEdit,
          isAdmin,
          hasSupportSeat,
          classification,
        })
      );
    }, routes?.dashboard);

    if (routes?.routeModals) {
      forEach((modalRoute) => {
        if (
          modalRoute.canAccessRoute &&
          !modalRoute.canAccessRoute({
            featureFlags,
            portalCapabilities,
            pricingPlanFeatures,
            canView,
            canEdit,
            isAdmin,
            hasSupportSeat,
            classification,
          })
        ) {
          return;
        }

        dashboardRoutes.push(
          getChildRoutes<
            TFeatureFlags,
            TPortalCapabilities,
            TPricingPlanFeatures
          >({
            routes: [modalRoute],
            featureFlags,
            portalCapabilities,
            pricingPlanFeatures,
            canView,
            canEdit,
            isAdmin,
            hasSupportSeat,
            classification,
          })
        );
      }, routes.routeModals);
    }

    if (routes?.routePanels) {
      forEach((panelRoute) => {
        if (
          panelRoute.canAccessRoute &&
          !panelRoute.canAccessRoute({
            featureFlags,
            portalCapabilities,
            pricingPlanFeatures,
            canView,
            canEdit,
            isAdmin,
            hasSupportSeat,
            classification,
          })
        ) {
          return;
        }

        dashboardRoutes.push(
          getChildRoutes<
            TFeatureFlags,
            TPortalCapabilities,
            TPricingPlanFeatures
          >({
            routes: [panelRoute],
            featureFlags,
            portalCapabilities,
            pricingPlanFeatures,
            canView,
            canEdit,
            isAdmin,
            hasSupportSeat,
            classification,
          })
        );
      }, routes.routePanels);
    }

    appRoutes.push([dashboardRoutes]);

    if (routes?.externalStore) {
      appRoutes.push(
        getChildRoutes<
          TFeatureFlags,
          TPortalCapabilities,
          TPricingPlanFeatures
        >({
          routes: routes.externalStore,
          featureFlags,
          portalCapabilities,
          pricingPlanFeatures,
          canView,
          canEdit,
          isAdmin,
          hasSupportSeat,
          classification,
        })
      );
    } else {
      // TODO: Refactor to const { auth, dashboard, ... } = useMemo();
      appRoutes.push(null);
    }

    if (routes?.onboarding) {
      appRoutes.push(
        getChildRoutes<
          TFeatureFlags,
          TPortalCapabilities,
          TPricingPlanFeatures
        >({
          routes: routes.onboarding,
          featureFlags,
          portalCapabilities,
          pricingPlanFeatures,
          canView,
          canEdit,
          isAdmin,
          hasSupportSeat,
          classification,
        })
      );
    }

    return appRoutes;
  }, [
    routes,
    featureFlags,
    portalCapabilities,
    pricingPlanFeatures,
    canView,
    canEdit,
    isAdmin,
    hasSupportSeat,
    classification,
  ]);

  const layoutType = useMemo(() => {
    if (startsWith('/auth', location.pathname)) {
      return LayoutTypeEnum.Auth;
    } else if (!authentication && startsWith('/store', location.pathname)) {
      return LayoutTypeEnum.ExternalStore;
    } else if (startsWith('/onboarding', location.pathname)) {
      return LayoutTypeEnum.Onboarding;
    } else {
      return LayoutTypeEnum.Dashboard;
    }
  }, [authentication, location.pathname]);

  const adjustedRoutes = useMemo(() => {
    let Layout: FC;
    let adjustedRoutes: ReactNode[];

    if (layoutType === LayoutTypeEnum.Auth) {
      Layout = extraLayout?.authLayout || AuthLayout;
      adjustedRoutes = auth;
    } else if (layoutType === LayoutTypeEnum.ExternalStore) {
      Layout = extraLayout?.externalStoreLayout || ExternalStoreLayout;
      adjustedRoutes = shop;
    } else if (layoutType === LayoutTypeEnum.Onboarding) {
      Layout = extraLayout?.onboardingLayout || OnboardingLayout;
      adjustedRoutes = onboarding;
    } else {
      Layout = extraLayout?.dashboardLayout || DashboardLayout;
      adjustedRoutes = dashboard;
    }

    // When 'routeOverlay' is defined, it means there's a route-modal/route-panel rendered on top of the
    // current view. The modal/panel itself is rendered OUTSIDE the <Switch> router, (in
    // <RouteOverlayWrapper>), while the Switch router is displaying a "background" route which
    // will be visible when the modal/panel is closed
    const routeOverlay = location?.state?.routeOverlay;

    return (
      <Layout>
        <ErrorBoundary>
          <Switch location={routeOverlay?.backgroundPath || location}>
            {adjustedRoutes}

            <Route render={() => <Page404 />} />
          </Switch>
        </ErrorBoundary>
      </Layout>
    );
  }, [layoutType, location, extraLayout, auth, shop, onboarding, dashboard]);

  return (
    <ScrollToTop>
      <AnimateSharedLayout>{adjustedRoutes}</AnimateSharedLayout>
    </ScrollToTop>
  );
}

export function Routes<
  TFeatureFlags extends CommonFeatureFlagsType,
  TPortalCapabilities extends CommonPortalCapabilities,
  TPricingPlanFeatures extends CommonPricingPlanFeatures
>() {
  useNetworkStatus();

  return (
    <>
      <AutoTenantSwitcher />

      <Referral />

      <AdjustedRoutes<
        TFeatureFlags,
        TPortalCapabilities,
        TPricingPlanFeatures
      > />

      <Modals />

      <RouteOverlayWrapper overlayType="modal" />
      <RouteOverlayWrapper overlayType="panel" />
    </>
  );
}
