import {
  Button,
  Checkbox,
  Group,
  Input,
  LoadingOverlay,
  Stack,
  Text,
  TextInput,
  Title,
} from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import { noop } from 'lodash/fp';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { boolean, object, string } from 'yup';

import {
  useCreateOrganization,
  UseCreateOrganizationParams,
} from '@portals/api/organizations';
import { useSetRoute } from '@portals/redux';
import { signedIn } from '@portals/redux/actions/auth';

import { FormWrapper } from './common';
import {
  PasswordInputWithRequirements,
  yupPasswordValidator,
} from './PasswordInputWithRequirements';
import { TermsAndPolicy } from '../../components/TermsAndPolicy';
import { useCommonPortalConfig } from '../../hooks/portal-config';

interface SignUpProps {
  onError?: (error: string) => void;
}

const schemaWithOrgName = object({
  org: string().required('Organization name is required'),
  name: string().required('Full name is required'),
  email: string()
    .email('Must be a valid email address')
    .required('Email is required'),
  password: yupPasswordValidator,
  accepted_terms_and_conditions: boolean()
    .oneOf(
      [true],
      'Please read and agree to our terms and conditions and privacy policy by checking the box'
    )
    .required(),
});

const schemaWithoutOrgName = object({
  name: string().required('Full name is required'),
  email: string()
    .email('Must be a valid email address')
    .required('Email is required'),
  password: yupPasswordValidator,
  accepted_terms_and_conditions: boolean()
    .oneOf(
      [true],
      'Please read and agree to our terms and conditions and privacy policy by checking the box'
    )
    .required(),
});

export function SignUpForm({ onError = noop }: SignUpProps) {
  const dispatch = useDispatch();
  const isReferral = !!localStorage.getItem('referral');

  const setRoute = useSetRoute();
  const portalConfig = useCommonPortalConfig();
  const createOrganization = useCreateOrganization();

  const isB2C = portalConfig.data?.b2c_view;

  const form = useForm<Omit<UseCreateOrganizationParams, 'referral'>>({
    initialValues: isB2C
      ? {
          name: '',
          email: '',
          password: '',
          accepted_terms_and_conditions: false,
        }
      : {
          org: '',
          name: '',
          email: '',
          password: '',
          accepted_terms_and_conditions: false,
        },
    validate: yupResolver(isB2C ? schemaWithoutOrgName : schemaWithOrgName),
  });

  const onSubmit = async (values: typeof form.values) => {
    const referral = localStorage.getItem('referral');

    try {
      const auth = await createOrganization.mutateAsync({
        ...values,
        referral,
      });

      const afterAuthPath = localStorage.getItem('afterAuthPath');

      if (afterAuthPath) {
        localStorage.removeItem('afterAuthPath');
      }

      dispatch(signedIn(auth));
      dispatch(setRoute(afterAuthPath || '/'));
    } catch (error) {
      console.log(error);

      onError(error?.error);
    }
  };

  useEffect(() => {
    if (!isReferral && portalConfig.data?.signup !== true) {
      setRoute('/auth/sign-in');
    }
  }, [isReferral, setRoute, portalConfig.data?.signup]);

  return (
    <FormWrapper id="sign-up">
      <Stack spacing="lg" className="sign-up-container">
        <Group position="apart" align="baseline">
          <Title order={1} className="auth-page-title">
            Sign-Up
          </Title>

          <Text color="dimmed" size="sm" align="center">
            <Group spacing={6}>
              Already have an account?
              <Link className="sign-up-link-sign-in" to="/auth/sign-in">
                Sign in
              </Link>
            </Group>
          </Text>
        </Group>

        <form onSubmit={form.onSubmit(onSubmit)} noValidate>
          <Stack spacing="md">
            {!portalConfig.data?.b2c_view ? (
              <TextInput
                required
                label="Organization Name"
                placeholder="Name your organization"
                {...form.getInputProps('org')}
              />
            ) : null}

            <TextInput
              required
              label="Full Name"
              placeholder="Your full name"
              autoFocus={portalConfig.data?.b2c_view}
              {...form.getInputProps('name')}
            />

            <TextInput
              required
              label="Email"
              type="email"
              placeholder="Company email address"
              {...form.getInputProps('email')}
            />

            <PasswordInputWithRequirements
              error={form.errors.password}
              value={form.values.password}
              onChange={(e) => form.setFieldValue('password', e.target.value)}
            />

            <Checkbox
              required
              label={<TermsAndPolicy />}
              {...form.getInputProps('accepted_terms_and_conditions')}
            />

            {createOrganization.isError ? (
              <Input.Error>{createOrganization.error?.error}</Input.Error>
            ) : null}
          </Stack>

          <Button
            fullWidth
            mt="xl"
            type="submit"
            loading={createOrganization.isLoading}
          >
            Sign Up
          </Button>
        </form>
      </Stack>
    </FormWrapper>
  );
}

export function SignUp(props: SignUpProps) {
  const portalConfig = useCommonPortalConfig();

  if (!portalConfig.isFetched) return <LoadingOverlay visible />;

  return <SignUpForm {...props} />;
}
