import { useDispatch } from 'react-redux';

import { useOpenModal } from '@portals/redux';
import { closeModal } from '@portals/redux/actions/modal';

import { useAsyncConfirmation } from './async-confirmation';
import { ConfirmationModalProps } from '../modals';

export const useConfirmationModal = () => {
  const dispatch = useDispatch();
  const openModal = useOpenModal();

  const { asyncConfirmationCheck, onResolve, toggleOpen } =
    useAsyncConfirmation({
      shouldConfirm: true,
      onOpen: ({
        description,
        title,
        confirmationLabel,
        cancelLabel,
        confirmButtonProps,
        cancelButtonProps,
      }) => {
        openModal<ConfirmationModalProps['data']>('ConfirmationModal', {
          onApprove: () => {
            onResolve(true);
            dispatch(closeModal('ConfirmationModal'));
            toggleOpen();
          },
          onCancel: () => {
            onResolve(false);
            dispatch(closeModal('ConfirmationModal'));
            toggleOpen();
          },
          description,
          title,
          confirmationLabel,
          cancelLabel,
          confirmButtonProps,
          cancelButtonProps,
        });
      },
    });

  return asyncConfirmationCheck;
};
