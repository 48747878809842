import { Box, createStyles, Group, Menu, Text } from '@mantine/core';
import { noop } from 'lodash/fp';
import React, { FC } from 'react';

import { useOrganizationConfig } from '@portals/api/organizations';
import { TenantSwitch, UserSettings } from '@portals/framework';
import { ReactComponent as ArrowDown } from '@portals/icons/linear/arrow-down.svg';
import { ReactComponent as MenuIcon } from '@portals/icons/linear/menu.svg';

interface DashboardHeaderProps {
  onOpen: () => void;
}

export const DashboardHeader: FC<DashboardHeaderProps> = ({ onOpen }) => {
  const { classes } = useStyles();
  const organization = useOrganizationConfig();

  return (
    <Box className={classes.container}>
      <Box onClick={onOpen}>
        <MenuIcon />
      </Box>

      <Box>
        <Menu width={300}>
          <Menu.Target>
            <Group>
              <Text size="lg" weight="600">
                {organization?.name}
              </Text>

              <ArrowDown style={{ width: 17, height: 17 }} />
            </Group>
          </Menu.Target>
          <Menu.Dropdown>
            <TenantSwitch
              toggleTenantSwitch={noop}
              toggleTenantMenu={noop}
              isStandalone={true}
            />
          </Menu.Dropdown>
        </Menu>
      </Box>

      <Box>
        <UserSettings />
      </Box>
    </Box>
  );
};

const useStyles = createStyles((theme) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: 'min-content max-content min-content',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: `0 ${theme.spacing.md}`,
    height: 60,
    boxShadow: `0 16px 24px rgba(0, 0, 0, 0.025), 0 2px 6px rgba(0, 0, 0, 0.025), 0 0 1px rgba(0, 0, 0, 0.025)`,
    borderBottom: `1px solid ${theme.colors.gray[2]}`,

    '.user-settings-container': {
      marginLeft: 0,

      '>div': {
        borderLeft: 'none',
        paddingLeft: 0,

        '.user-name': {
          margin: 0,
        },
      },
    },
  },
}));
