import { createStyles, Group, Stack, StackProps, Text } from '@mantine/core';
import { useElementSize } from '@mantine/hooks';
import { motion } from 'framer-motion';
import { isUndefined } from 'lodash';
import React, { useCallback, useMemo } from 'react';

import { FORMATTERS } from '../../../utils/formatters';
import { NumberFormatType, ScaleType } from '../../widgets.types';
import { BarIndicatorSegmentType } from '../form';

export interface BarIndicatorWidgetProps {
  title: string;
  value: number;
  unit?: string | null;
  min: number;
  max: number;
  scaleType?: ScaleType;
  numOfDecimals?: number;
  numberFormat?: NumberFormatType;
  segments: Array<BarIndicatorSegmentType>;
  stackProps?: StackProps;
}

const BAR_WIDTH = 5;
const BAR_HEIGHT = 30;
const GAP_WIDTH = 8;

export function BarIndicatorWidget({
  title,
  value,
  unit,
  segments,
  min,
  max,
  scaleType = 'linear',
  numberFormat = 'none',
  numOfDecimals = 1,
  stackProps = {},
}: BarIndicatorWidgetProps) {
  const { classes, theme } = useStyles();
  const { ref, width } = useElementSize();

  const bars = useMemo(() => {
    if (!width) return [];

    const BAR_WIDTHWithGap = BAR_WIDTH + GAP_WIDTH;
    let barsCount = Math.floor(width / BAR_WIDTHWithGap);
    const remainingSpace = width - barsCount * BAR_WIDTHWithGap;

    if (remainingSpace >= BAR_WIDTH) {
      barsCount += 1;
    }

    const getColor = (valuePercentage: number) => {
      for (const segment of segments) {
        if (valuePercentage <= segment.max) return segment.color;
      }
      return 'gray.3';
    };

    const valuePercentage = Math.min(((value - min) / (max - min)) * 100, 100);
    let adjustedValuePercentage;

    if (scaleType === 'log') {
      const minValue = Math.max(min, 1);
      const maxValue = Math.max(max, 1);
      const logMin = Math.log10(minValue);
      const logMax = Math.log10(maxValue);
      const logValue = Math.log10(value);

      adjustedValuePercentage = ((logValue - logMin) / (logMax - logMin)) * 100;
    } else {
      adjustedValuePercentage = valuePercentage;
    }

    const barColor = getColor(valuePercentage);

    const splitIndex = Math.round((adjustedValuePercentage / 100) * barsCount);

    return new Array(barsCount).fill(null).map((_, index) => {
      return (
        <motion.div
          key={index}
          style={{
            height: BAR_HEIGHT,
            width: BAR_WIDTH,
            flexShrink: 0,
            borderRadius: 32,
          }}
          animate={{
            backgroundColor:
              index < splitIndex
                ? theme.fn.themeColor(barColor)
                : theme.fn.themeColor('gray.3'),
          }}
          transition={{ duration: 0.15 }}
        />
      );
    });
  }, [width, value, min, max, scaleType, segments, theme.fn]);

  const formatter = useCallback(
    (value: number | undefined) => {
      if (isUndefined(value)) return 'N/A';

      const adjustedValue = FORMATTERS[numberFormat](value, numOfDecimals);

      if (isUndefined(adjustedValue)) return 'N/A';

      return unit ? `${adjustedValue}${unit}` : adjustedValue;
    },
    [numberFormat, numOfDecimals, unit]
  );

  return (
    <Stack
      className={classes.container}
      p="xl"
      h="100%"
      w="100%"
      bg="white"
      justify="center"
      spacing="xl"
      {...stackProps}
    >
      <Group w="100%" position="apart" align="start">
        <Text
          size="md"
          data-testid="dashboard-bar-indicator-widget-name"
          color="gray.5"
        >
          {title}
        </Text>

        <Text color="gray.8" sx={{ fontSize: 28 }}>
          {formatter(value)}
        </Text>
      </Group>

      <Group w="100%" px={2} ref={ref} spacing={GAP_WIDTH}>
        {bars}
      </Group>
    </Stack>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    borderRadius: theme.radius.lg,
  },
}));
