import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { toastrError, toastrSuccess } from '@portals/redux/actions/toastr';
import { DeviceType } from '@portals/types';

import { fetchApiRequest, useRequestOptions } from '../../utils';
import { globalQueryKeys } from '../global-query-keys';
import { spacesQueryKeys } from '../spaces/spaces.constants';
import { QR_CODE_API_URL } from './qr-code.constants';

export function useClaimDeviceByQrCode() {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const { url, options } = useRequestOptions({
    url: QR_CODE_API_URL,
    method: 'POST',
  });

  return useMutation({
    mutationFn: async ({
      qr,
      space_id,
    }: {
      qr: string;
      space_id?: string;
    }): Promise<DeviceType> => {
      return fetchApiRequest(url, {
        ...options,
        body: JSON.stringify({ qr, space_id }),
      });
    },
    onSuccess: async (device: DeviceType) => {
      await queryClient.invalidateQueries(globalQueryKeys.devices);

      setTimeout(() => {
        queryClient.invalidateQueries(spacesQueryKeys.base);
      }, 2000);

      dispatch(toastrSuccess('Device claimed successfully'));

      return device;
    },
    onError: ({ error }) => {
      dispatch(toastrError(error));
    },
  });
}
