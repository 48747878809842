import { createStyles } from '@mantine/core';
import React from 'react';

import { ReactComponent as Danger } from '@portals/icons/bold/danger.svg';
import { IncidentPriorityLevel } from '@portals/types';
import { getIncidentPriorityLevelColor } from '@portals/utils';

interface PriorityIconProps {
  priorityLevel: IncidentPriorityLevel;
}

export function PriorityIcon({ priorityLevel }: PriorityIconProps) {
  const { classes, theme } = useStyles(priorityLevel);

  return (
    <div className={classes.priority}>
      <Danger color={theme.white} width={16} height={16} />
    </div>
  );
}

const useStyles = createStyles(
  (theme, priorityLevel: IncidentPriorityLevel) => ({
    priority: {
      display: 'grid',
      placeContent: 'center',
      width: 36,
      height: 36,
      borderRadius: '50%',
      backgroundColor: theme.fn.themeColor(
        getIncidentPriorityLevelColor(priorityLevel),
        4
      ),
    },
  })
);
