import { omit, set } from 'lodash/fp';

import { StateType } from '@portals/types';

import {
  END_NETWORK,
  SIGN_IN_FAILED,
  SIGNED_IN,
  SIGNED_OUT,
  START_NETWORK,
  SWITCH_TENANT,
  SET_PARTNER_CONFIG,
  ADD_NOTIFICATION,
  HIDE_NOTIFICATION,
  REMOVE_NOTIFICATION,
  RESET_AUTH_ERROR,
  UPDATE_AUTH_TENANT_NAME,
} from '../constants';

const initialState: StateType['ui'] = {
  auth: JSON.parse(localStorage.getItem('auth')),
  notifications: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SIGNED_IN:
      // Save auth data to local storage
      localStorage.setItem('auth', JSON.stringify(action.payload));

      return {
        ...state,
        auth: action.payload,
        authError: null,
      };

    case SIGNED_OUT:
      localStorage.setItem('auth', null);

      return {
        ...state,
        auth: null,
        authError: null,
      };

    case SIGN_IN_FAILED:
      return {
        ...state,
        auth: null,
        authError: action.payload.errors && action.payload.errors[0],
      };

    case RESET_AUTH_ERROR:
      return {
        ...state,
        authError: null,
      };

    case UPDATE_AUTH_TENANT_NAME:
      const { tenantName } = action.payload;
      const updatedAuth = set('tenant.name', tenantName, state.auth);
      localStorage.setItem('auth', JSON.stringify(updatedAuth));

      return set('auth.tenant.name', tenantName, state);

    case SWITCH_TENANT: {
      const updated = { ...state.auth, tenant: action.payload };

      localStorage.setItem('auth', JSON.stringify(updated));
      localStorage.setItem('last_tenant_id', JSON.stringify(action.payload.id));

      return { ...state, auth: updated };
    }

    case START_NETWORK:
      return {
        ...state,
        network: { ...state.network, [action.payload || 'global']: true },
      };

    case END_NETWORK:
      return {
        ...state,
        network: omit(action.payload || 'global', state.network),
      };

    case SET_PARTNER_CONFIG:
      return { ...state, partnerConfig: action.payload };

    case ADD_NOTIFICATION:
      return state.notifications.some((item) => item.id === action.payload.id)
        ? state
        : { ...state, notifications: [...state.notifications, action.payload] };

    case REMOVE_NOTIFICATION:
      return {
        ...state,
        notifications: state.notifications.filter(
          (item) => item.id !== action.payload
        ),
      };

    case HIDE_NOTIFICATION:
      return {
        ...state,
        notifications: state.notifications.map((item) =>
          item.id === action.payload ? { ...item, visible: false } : item
        ),
      };

    default:
      return state;
  }
};

export default reducer;
