import {
  Box,
  Button,
  createStyles,
  Group,
  LoadingOverlay,
  Modal,
} from '@mantine/core';
import { find, noop } from 'lodash/fp';
import React, { FC, useCallback, useState } from 'react';

import {
  SpaceType,
  useMoveDevice,
  useSpaces,
} from '@portals/api/organizations';
import { ModalProps } from '@portals/framework';
import { DeviceType } from '@portals/types';

import { SpaceTree } from '../components/common/SpaceTree/SpaceTree';

interface MoveDeviceProps {
  device: DeviceType;
}

export const MoveDevice: FC<ModalProps<MoveDeviceProps>> = ({
  closeMe,
  data,
}) => {
  const { classes } = useStyles();
  const { device } = data;
  const moveDevice = useMoveDevice();
  const spaces = useSpaces();

  const [selectedSpace, setSelectedSpace] = useState<SpaceType>(null);

  const onSelect = (spaceId: SpaceType['id']) => {
    if (spaceId === selectedSpace?.id) {
      setSelectedSpace(null);

      return;
    }

    const space = find({ id: spaceId }, spaces.data);

    if (space) setSelectedSpace(space);
  };

  const onSubmit = useCallback(async () => {
    await moveDevice.mutateAsync({
      device_id: device.id,
      space_id: selectedSpace.id,
    });

    closeMe();
  }, [closeMe, device?.id, moveDevice, selectedSpace?.id]);

  return (
    <Modal opened title="Move device" onClose={closeMe} size="xl">
      <LoadingOverlay visible={moveDevice.isLoading} />

      <Box className={classes.container}>
        <Box className={classes.tree}>
          <SpaceTree
            spaces={spaces.data}
            draggable={false}
            openModal={noop}
            handleSelected={onSelect}
            selected={selectedSpace}
          />
        </Box>
      </Box>

      <Group grow>
        <Button disabled={!selectedSpace} size="md" onClick={onSubmit}>
          {!selectedSpace
            ? 'Select a space to continue'
            : `Move to "${selectedSpace.name}"`}
        </Button>
      </Group>
    </Modal>
  );
};

const useStyles = createStyles((theme) => ({
  container: {
    display: 'grid',
    gridTemplateRows: '1fr min-content',
    gap: theme.spacing.md,
  },
  tree: {
    height: '70vh',
    border: `1px solid ${theme.colors.gray[3]}`,

    '.tree-container': {
      gridTemplateRows: '0 70px 1fr',

      '.tree-header': {
        gridTemplateColumns: '1fr',

        '.tree-actions': {
          display: 'none',
        },

        input: {
          minHeight: 45,
        },
      },

      '.rc-tree-list': {
        '.rst_toggleButtonIcon': {
          width: 20,
          height: 20,
        },

        '.rc-tree-treenode': {
          height: '40px !important',

          '.inline-space-name-input': {
            fontSize: `${theme.fontSizes.md} !important`,
          },

          '.rc-tree-iconEle': {
            width: 20,
            height: 20,

            svg: {
              width: '20px !important',
              height: '20px !important',
            },
          },

          '.rc-tree-title': {
            paddingLeft: theme.spacing.md,
            fontSize: theme.fontSizes.md,

            '.context-menu-toggle': {
              display: 'none',
            },
          },
        },
      },
    },
  },
}));
