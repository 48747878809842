import {
  ActionIcon,
  CloseButton,
  createStyles,
  Group,
  Stack,
  Title,
  Tooltip,
} from '@mantine/core';
import { filter, includes, lowerCase } from 'lodash/fp';
import React, { useMemo, useRef, useState } from 'react';
import { useKey } from 'react-use';

import { ReactComponent as Setting2 } from '@portals/icons/linear/setting-2.svg';
import { useOnClickOutside } from '@portals/utils';

import ColumnOption from './ColumnOption';
import SearchInput from '../SearchInput';

type ColumnsSettingsProps = {
  columns: Array<Record<string, any>>;
  onColumnToggle: (column: string) => void;
};

export function ColumnsSettings({ columns }: ColumnsSettingsProps) {
  const { classes } = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const filteredOptions = useMemo(
    () =>
      filter(
        ({ Header, isAction }) =>
          !isAction && includes(lowerCase(searchTerm), lowerCase(Header)),
        columns
      ),
    [columns, searchTerm]
  );

  const tooltipContentRef = useRef(null);
  useOnClickOutside(tooltipContentRef, () => setIsOpen(false), isOpen);

  useKey(
    'Escape',
    () => {
      if (isOpen) {
        setIsOpen(false);
      }
    },
    {},
    [isOpen]
  );

  return (
    <Tooltip
      opened={isOpen}
      withinPortal
      withArrow
      arrowSize={10}
      position="left-start"
      classNames={{
        tooltip: classes.tooltip,
      }}
      label={
        <Stack sx={{ width: 280 }} p="lg" ref={tooltipContentRef}>
          <Group position="apart" mb="xs">
            <Title order={5} weight={600} color="gray.9">
              Customize Columns
            </Title>

            <CloseButton
              onClick={() => setIsOpen(false)}
              data-testid="columns-settings-close-btn"
            />
          </Group>

          <SearchInput
            placeholder="Search column..."
            mb="sm"
            value={searchTerm}
            onChange={(event) => setSearchTerm(event.target.value)}
            onClear={() => setSearchTerm('')}
            sx={{ width: '100%' }}
            data-testid="columns-settings-search-input"
          />

          <Stack>
            {filteredOptions.map((column) => (
              <ColumnOption key={column.id} option={column} />
            ))}
          </Stack>
        </Stack>
      }
    >
      <Tooltip label="Customize columns" withinPortal>
        <ActionIcon
          data-testid="columns-settings-toggle-btn"
          onClick={() => setIsOpen(true)}
          color={isOpen ? 'primary' : 'blue_gray'}
        >
          <Setting2 />
        </ActionIcon>
      </Tooltip>
    </Tooltip>
  );
}

const useStyles = createStyles((theme) => ({
  tooltip: {
    padding: 0,
    pointerEvents: 'all',
    background: 'white',
    boxShadow: theme.shadows.lg,
  },
}));
