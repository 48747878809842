import { captureException, withScope } from '@sentry/react';
import React, { FC } from 'react';
import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

export const queryClient = new QueryClient({
  /*
   * We capture exceptions for every query and mutation and use
   * scope.setFingerprint to define, what fingerprint the scope has,
   * that is what the exception will be grouped by. For now,
   * we use the mutationKey or the queryHash for this. Before using the
   * queryHash we strip away all numbers, so that we don’t get one issue per
   * query parameter combination.
   */
  mutationCache: new MutationCache({
    onError: (err, _variables, _context, mutation) => {
      withScope((scope) => {
        scope.setContext('mutation', {
          mutationId: mutation.mutationId,
          variables: mutation.state.variables,
        });
        if (mutation.options.mutationKey) {
          scope.setFingerprint(
            // Duplicate to prevent modification
            Array.from(mutation.options.mutationKey) as string[]
          );
        }
        captureException(err);
      });
    },
  }),
  queryCache: new QueryCache({
    onError: (err, query) => {
      withScope((scope) => {
        scope.setContext('query', { queryHash: query.queryHash });
        scope.setFingerprint([query.queryHash.replace(/[0-9]/g, '0')]);
        captureException(err);
      });
    },
  }),

  defaultOptions: {
    queries: {
      staleTime: Infinity,
      cacheTime: Infinity,
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

const QueryClientWrapper: FC<{ children }> = ({ children }) => (
  <QueryClientProvider client={queryClient}>
    {children}

    {process.env.NODE_ENV === 'development' ? (
      <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
    ) : null}
  </QueryClientProvider>
);

export default QueryClientWrapper;
