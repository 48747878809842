import { MantineColor } from '@mantine/core';
import { capitalize } from 'lodash/fp';

import { DeviceStatusType } from '@portals/types';

export function getDeviceStatusColor(
  deviceStatus: DeviceStatusType | undefined
): {
  baseColor: MantineColor;
  bg: MantineColor;
  indicator: MantineColor;
} {
  switch (deviceStatus) {
    case 'online':
      return {
        baseColor: 'teal',
        bg: 'teal.0',
        indicator: 'teal_accent.4',
      };

    case 'offline':
      return {
        baseColor: 'gray',
        bg: 'gray.1',
        indicator: 'gray.4',
      };

    case 'error':
      return {
        baseColor: 'red',
        bg: 'red.0',
        indicator: 'red.4',
      };

    case 'unavailable':
    default:
      return {
        baseColor: 'amber',
        bg: 'amber.0',
        indicator: 'amber.7',
      };
  }
}

export function getDeviceStatusText(
  deviceStatus: DeviceStatusType | undefined
) {
  return deviceStatus ? capitalize(deviceStatus) : 'N/A';
}

export function getDeviceName(deviceName: string | null | undefined) {
  return deviceName || 'Unknown';
}
