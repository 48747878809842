import {
  Button,
  Group,
  LoadingOverlay,
  Modal,
  Stack,
  TextInput,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import React from 'react';
import { UseMutationResult } from 'react-query';

import { ModalProps } from '../components/Modals';

export interface CreateApiKeyProps
  extends ModalProps<{
    onCreate: UseMutationResult<any, unknown, { name: string }>;
  }> {}

interface FormValues {
  name: string;
}

export function CreateApiKey({ data, closeMe }: CreateApiKeyProps) {
  const form = useForm<FormValues>({
    initialValues: {
      name: '',
    },
  });

  const { onCreate } = data;

  const onSubmit = async ({ name }: FormValues) => {
    try {
      await onCreate.mutateAsync({ name });

      closeMe();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Modal opened onClose={closeMe} title="Create New API Key">
      <form onSubmit={form.onSubmit(onSubmit)}>
        <Stack>
          <LoadingOverlay visible={onCreate.isLoading} />

          <TextInput
            label="API Key Name"
            required
            autoFocus={true}
            {...form.getInputProps('name')}
          />

          <Group position="right">
            <Button variant="default" onClick={closeMe}>
              Cancel
            </Button>

            <Button
              type="submit"
              disabled={!form.values.name || onCreate.isLoading}
            >
              Create
            </Button>
          </Group>
        </Stack>
      </form>
    </Modal>
  );
}
