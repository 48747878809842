import { find, get } from 'lodash/fp';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { InfoTable } from '@portals/table';
import { ShortenedString } from '@portals/ui';
import { getStyledThemeColor, timeAgo } from '@portals/utils';

import { DeviceName } from './DeviceName';
import IncidentTracking from './IncidentTracking';
import HDBaseT from './Widgets/HDBaseT';
import MSolutions from './Widgets/MSolutions';

const GeneralView = (props) => {
  const { device, model, devices, spaces, setDeviceDetails } = props;
  const img = get('user_settings.media.img', model);
  const parent = device.parent && devices && devices[device.parent];

  return (
    <Container className="device-info-container p-3">
      {img ? (
        <div className="model-image-wrapper">
          <img src={img} className="model-image" alt="Model" />
        </div>
      ) : null}

      <InfoTable.Table>
        {parent ? (
          <InfoTable.Row
            label="Parent"
            value={
              <div className="d-flex align-items-center">
                <Link
                  to={`/overview/${parent.space_id}/devices/${parent.id}`}
                  className="mr-1"
                >
                  {parent.name || 'Unknown'}
                </Link>

                {spaces && (
                  <>
                    <span className="mr-1">{' in '}</span>

                    <Link to={`/overview/${parent.space_id}`}>
                      {find({ id: parent.space_id }, spaces)?.name || 'Unknown'}
                    </Link>
                  </>
                )}
              </div>
            }
          />
        ) : null}

        {device.parent && !parent ? (
          <InfoTable.Row label="Parent" value={device.parent} />
        ) : null}

        <InfoTable.Row
          label="Name"
          value={
            <DeviceName device={device} setDeviceDetails={setDeviceDetails} />
          }
        />

        <InfoTable.Row label="Vendor" value={device.partner.vendor} />
        <InfoTable.Row label="Model" value={device.partner.model} />

        {device.partner.sub_model ? (
          <InfoTable.Row label="Sub Model" value={device.partner.sub_model} />
        ) : null}

        <InfoTable.Row label="Type" value={device.type} />
        <InfoTable.Row
          label="Last Seen"
          value={
            device.last_seen ? timeAgo(Date.parse(device.last_seen)) : 'Never'
          }
        />
        <InfoTable.Row
          label="Firmware Version"
          value={device.firmware.version}
        />
        <InfoTable.Row label="MAC Address" value={device.partner.mac} />
        <InfoTable.Row label="Serial Number" value={device.partner.sn} />
        <InfoTable.Row label="Cloud ID" value={device.partner.cloud_id} />

        {device.driver === 'xio' ? (
          <InfoTable.Row
            label="XiO Cloud"
            value={
              <a
                href={`https://portal.crestron.io/customer/(list:groups//details:device/${device?.details['device-cid']}/status)`}
                rel="noopener noreferrer"
                target="_blank"
              >
                Device in XiO portal
              </a>
            }
          />
        ) : null}

        <InfoTable.Row
          label="Config"
          value={
            !device.config || !get('config.version', device)
              ? 'Unknown'
              : device.config_version === device.config.version
              ? 'Up to date'
              : `${
                  device.config.version - device.config_version
                } versions behind`
          }
        />

        <InfoTable.Row label="UUID" value={device.id} />

        {device.connection_info ? (
          <>
            <InfoTable.Row
              label="Access Key"
              value={
                <ShortenedString
                  str={device.connection_info.access_key}
                  size={10}
                />
              }
            />
            <InfoTable.Row
              label="Assigned server"
              value={device.connection_info.hub_url}
            />
            <InfoTable.Row
              label="Assigned static cert server"
              value={device.connection_info.hub_url_static_cert}
            />
            <InfoTable.Row
              label="Assigned mqtt server"
              value={device.connection_info.mqtt_hub_url}
            />
          </>
        ) : null}

        <InfoTable.Row label="Driver" value={device.driver} />
        <InfoTable.Row
          label="Incident Tracking"
          value={
            <IncidentTracking
              device={device}
              setDeviceDetails={setDeviceDetails}
            />
          }
        />

        {device.model_settings['HDBaseT'] && <HDBaseT {...props} />}

        {device.model_settings['MSolutions'] && <MSolutions {...props} />}
      </InfoTable.Table>
    </Container>
  );
};

const Container = styled.div`
  .model-image-wrapper {
    overflow: hidden;
    border-radius: 8px;
    border: 2px solid ${getStyledThemeColor('gray200')};
    height: 250px;
    width: 400px;
    margin-bottom: 20px;
    display: grid;
    align-items: center;
    justify-content: center;
    background-color: ${getStyledThemeColor('gray100')};

    .model-image {
      max-height: 100%;
      max-width: 100%;
      aspect-ratio: 2;
    }
  }
`;

GeneralView.propTypes = {
  device: PropTypes.object.isRequired,
  model: PropTypes.object,
  devices: PropTypes.object,
  spaces: PropTypes.object,
  setDeviceDetails: PropTypes.func,
};

export default GeneralView;
