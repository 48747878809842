import { Box, Button, Group, Overlay, Stack, Text } from '@mantine/core';
import React, { FC } from 'react';
import { Spinner } from 'reactstrap';

import { NoDataIndicationType } from '@portals/types';

import noDataSrc from '../assets/no-data.svg';
import noFiltersSrc from '../assets/no-filters-results.svg';
import noSearchResultsSrc from '../assets/no-search-results.svg';
import { useTableInstance } from '../context';

const DataStateContainer: FC = ({ children }) => (
  <Stack
    className="data-state-container"
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translateX(-50%) translateY(-50%)',
    }}
  >
    {children}
  </Stack>
);

export const NoDataState: FC<NoDataIndicationType> = ({
  assetSrc = noDataSrc,
  title = 'No data',
  subtitle,
  actions = null,
}) => (
  <DataStateContainer>
    <Box mb={55}>
      <img src={assetSrc} />
    </Box>

    <Text size="sm" weight={600} align="center" data-testid="no-data-title">
      {title}
    </Text>

    {subtitle ? (
      <Text size="xs" align="center" color="gray.5">
        {subtitle}
      </Text>
    ) : null}

    {actions}
  </DataStateContainer>
);

export const NoSearchResultsState = () => {
  const { state } = useTableInstance();

  return (
    <DataStateContainer>
      <Box mb={55}>
        <img src={noSearchResultsSrc} />
      </Box>

      <Text size="sm" weight={600} align="center">
        No results found
      </Text>

      <Stack spacing={0}>
        <Group spacing={5} position="center">
          <Text size="xs" color="dimmed" align="center">
            We didn't find any matches for
          </Text>
          <Text
            weight={600}
            size="xs"
            color="dark"
            align="center"
            sx={{
              wordBreak: 'break-word',
            }}
          >
            "{state.globalFilter}"
          </Text>
        </Group>
        <Text size="xs" color="dimmed" align="center">
          Try using different keywords
        </Text>
      </Stack>
    </DataStateContainer>
  );
};

export const LoadingState = () => (
  <DataStateContainer>
    <Overlay>
      <Spinner color="primary" />
    </Overlay>
  </DataStateContainer>
);

export const NoFiltersResultsState = () => {
  const instance = useTableInstance();

  return (
    <DataStateContainer>
      <Box mb={55}>
        <img src={noFiltersSrc} />
      </Box>

      <Text size="sm" weight={600} align="center">
        Your filters produced no results
      </Text>

      <Text size="xs" color="dimmed" align="center">
        Try adjusting or clearing your filters to display better results
      </Text>

      <Button
        variant="subtle"
        size="xs"
        onClick={() => instance.setAllFilters([])}
      >
        Clear filters
      </Button>
    </DataStateContainer>
  );
};
