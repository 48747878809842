import { Group, SegmentedControl, Stack, Text } from '@mantine/core';
import { get } from 'lodash/fp';
import React, { FC, ReactNode, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';

import { setRoute } from '@portals/redux/actions/routing';
import { ComponentRendererType, TabType } from '@portals/types';

type ButtonTabsProps = {
  tabs: Array<TabType>;
  children?: (selectedTab: TabType, currUrl: string) => ReactNode;
  urlParam: string;
  urlBase: string;
  titleWrap: ComponentRendererType<any>;
};

type ButtonsTabsConnectedActions = {
  setRoute: (path: string) => void;
};

const ButtonTabs: FC<
  RouteComponentProps & ButtonTabsProps & ButtonsTabsConnectedActions
> = ({ match, tabs, children, urlParam, urlBase, setRoute, titleWrap }) => {
  const defaultId = get([0, 'id'], tabs);
  const [stateSelectedId, setStateSelectedId] = useState(defaultId);
  const selectedId = urlParam
    ? match.params[urlParam] || defaultId
    : stateSelectedId;
  const selectedTab = tabs.find(({ id }) => id === selectedId);
  const SelectedComponent = selectedTab && selectedTab.Component;
  const currUrl = `${urlBase}/${selectedId}`;

  const setSelectedId = (id) =>
    urlParam ? setRoute(`${urlBase}/${id}`) : setStateSelectedId(id);

  const labelRenderer = (tab: TabType) => {
    const title = titleWrap ? React.createElement(titleWrap, tab) : tab.title;

    return (
      <Group align="center" position="center" spacing="sm" noWrap px="lg">
        <Text inherit>{title}</Text>

        {tab.badge || null}
      </Group>
    );
  };

  return (
    <Stack>
      <SegmentedControl
        styles={{ root: { width: 'fit-content' } }}
        data={tabs.map((tab) => ({
          value: tab.id,
          label: labelRenderer(tab),
        }))}
        value={selectedId}
        onChange={setSelectedId}
      />

      {selectedTab &&
        (children ? (
          children(selectedTab, currUrl)
        ) : (
          <SelectedComponent id={selectedId} urlBase={currUrl} />
        ))}
    </Stack>
  );
};

const ConnectedButtonTabs = connect<null, ButtonsTabsConnectedActions>(null, {
  setRoute,
})(ButtonTabs);

export default withRouter(ConnectedButtonTabs);
