import { capitalize, map } from 'lodash/fp';
import React, { FC, useCallback, useMemo } from 'react';
import styled from 'styled-components';

import { DeviceType } from '@portals/api/organizations';
import { ReactComponent as ArrowDown } from '@portals/icons/linear/arrow-down.svg';
import { getStyledThemeColor } from '@portals/utils';

import DeviceCard from './DeviceCard';
import { useDevicesFiltersContext } from './devices-list.context';
import { DevicesWrapper } from './devices-list.hooks';
import { GroupTypeEnum } from './devices-list.types';

type DevicesGroupProps = {
  devices: Array<DeviceType>;
  groupType: string;
  groupName: string;
  isOpen: boolean;
  onDeviceSelect: (device: DeviceType) => void;
};

const DevicesGroup: FC<DevicesGroupProps> = ({
  devices,
  isOpen,
  groupName,
  groupType,
  onDeviceSelect,
}) => {
  const { collapsedGroups } = useDevicesFiltersContext();

  const formattedGroupName = useMemo(() => {
    if (groupType === GroupTypeEnum.Model) {
      return groupName;
    } else if (groupName === 'null') {
      return 'Unknown';
    } else {
      return capitalize(groupName);
    }
  }, [groupName, groupType]);

  const devicesList = useMemo(
    () =>
      map(
        (device) => (
          <DeviceCard
            key={device.id}
            device={device}
            handleSelected={() => onDeviceSelect(device)}
          />
        ),
        devices
      ),
    [devices, onDeviceSelect]
  );

  const onToggle = useCallback(
    () =>
      collapsedGroups.setValue((curr: Record<string, boolean>) => ({
        ...curr,
        [groupName]: !isOpen,
      })),
    [collapsedGroups, groupName, isOpen]
  );

  return (
    <Container
      key={groupName}
      isOpen={isOpen}
      className="devices-group-container"
    >
      <GroupLabel className="devices-group-label">
        <div>
          <span className="group-name">{groupType}</span>
          {formattedGroupName}
        </div>

        <div className="group-toggle">
          <ArrowDown onClick={onToggle} />
        </div>
      </GroupLabel>

      {isOpen ? <DevicesWrapper>{devicesList}</DevicesWrapper> : null}
    </Container>
  );
};

const Container = styled.div<{ isOpen: boolean }>`
  margin-top: -10px;
  margin-bottom: ${({ isOpen }) => (isOpen ? 35 : 15)}px;

  .group-toggle {
    cursor: pointer;
    transform: translateY(-5px);
    color: ${getStyledThemeColor('gray600')};

    svg {
      transform: rotate(${({ isOpen }) => (isOpen ? 0 : 180)}deg);
      transition: transform 0.15s ease-in-out;
    }
  }
`;

const GroupLabel = styled.div`
  margin-bottom: 10px;
  font-size: 15px;
  font-weight: bold;
  color: ${getStyledThemeColor('dark')};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 22px 10px;
  background-color: ${getStyledThemeColor('gray150')};
  letter-spacing: 0.1em;
  width: calc(100% + 40px);
  transform: translateX(-20px);
  position: relative;

  .group-name {
    color: ${getStyledThemeColor('gray500')};
    font-size: 9px;
    position: absolute;
    text-transform: uppercase;
    top: 10px;
    left: 22px;
  }
`;

export default DevicesGroup;
