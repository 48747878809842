import React from 'react';
import styled from 'styled-components';

import { getStyledThemeColor } from '@portals/utils';

const ToS = ({ label }) => (
  <Container className="tos-container">
    {`By ${label} you agree to our `}
    <a
      href="https://xyte.io/terms-of-service"
      target="_blank"
      rel="noopener noreferrer"
    >
      Terms of Use
    </a>{' '}
    and{' '}
    <a
      href="https://xyte.io/privacy-policy"
      target="_blank"
      rel="noopener noreferrer"
    >
      Privacy Policy
    </a>
  </Container>
);

const Container = styled.div`
  color: ${getStyledThemeColor('gray600')};
  font-size: 16px;

  a {
    text-transform: none;
    color: ${getStyledThemeColor('dark')};
    font-weight: 600;
  }
`;

export default ToS;
