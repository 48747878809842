import { createStyles, Stack, StackProps, Text } from '@mantine/core';
import { isUndefined } from 'lodash/fp';
import React from 'react';

export interface ValueWidgetProps {
  title: string;
  value: string | number;
  unit?: string | null;
  stackProps?: StackProps;
}

export function ValueWidget({
  title,
  value,
  unit,
  stackProps = {},
}: ValueWidgetProps) {
  const { classes } = useStyles();

  const getValue = () => {
    if (isUndefined(value)) return 'N/A';

    if (!unit) return value;

    return `${value} ${unit}`;
  };

  return (
    <Stack
      className={classes.container}
      p="xl"
      h="100%"
      w="100%"
      bg="white"
      justify="center"
      spacing={4}
      {...stackProps}
    >
      <Text
        size="md"
        data-testid="dashboard-value-widget-name"
        color="gray.5"
        truncate
      >
        {title}
      </Text>

      <Text size={28} color="gray.8" truncate>
        {getValue()}
      </Text>
    </Stack>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    borderRadius: theme.radius.lg,
  },
}));
