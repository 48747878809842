import { Box, createStyles, SimpleGrid, Text } from '@mantine/core';
import { size } from 'lodash/fp';
import React, { FC } from 'react';

import { VerticalScrollBar } from '@portals/scrollbar';
import { EmptyState } from '@portals/table';

import { CATEGORY } from './integrations.constants';
import { ServicesGroupType } from './integrations.types';
import { ServiceCard } from './ServiceCard';

interface ServicesListProps {
  servicesGroups: Array<ServicesGroupType>;
  toggleSelectedService: (serviceId: string) => void;
}

export const ServicesList: FC<ServicesListProps> = ({
  servicesGroups = [],
  toggleSelectedService,
}) => {
  const { classes } = useStyles();

  return (
    <Box className={classes.container}>
      {size(servicesGroups) ? (
        <VerticalScrollBar
          renderThumbVertical={() => <div />}
          renderView={(props) => <Box className={classes.list} {...props} />}
        >
          {servicesGroups.map(({ category, services = [] }) => (
            <Box key={category} className={classes.category}>
              <Text className={classes.categoryTitle}>
                {CATEGORY[category]}
              </Text>

              <SimpleGrid cols={3}>
                {services.map((service) => (
                  <ServiceCard
                    key={service.id}
                    {...service}
                    onClick={() => toggleSelectedService(service.id)}
                  />
                ))}
              </SimpleGrid>
            </Box>
          ))}
        </VerticalScrollBar>
      ) : (
        <EmptyState label="No services" />
      )}
    </Box>
  );
};

const useStyles = createStyles((theme) => ({
  container: {
    display: 'grid',
    gridTemplateRows: '1fr',
  },
  list: {
    padding: `${theme.spacing.xl} 30px`,
    width: '100%',
    backgroundColor: theme.colors.gray[0],
    display: 'grid',
    gridTemplateRows: 'min-content 1fr',
    gap: theme.spacing.md,
  },
  category: {
    display: 'grid',
    gridTemplateRows: 'min-content 1fr',
    gap: theme.spacing.md,
    height: 'fit-content',
    marginBottom: theme.spacing.md,
  },
  categoryTitle: {
    color: theme.colors.blue_gray[8],
    fontSize: theme.fontSizes.lg,
    marginBottom: theme.spacing.md,
  },
  footer: {
    borderTop: `1px solid ${theme.colors.gray[2]}`,
    padding: `0 ${theme.spacing.xl}`,
  },
}));
