import { lazy, LazyExoticComponent } from 'react';

import { FeatureNotificationEnum } from '@portals/api/ui';

export const FEATURES_MAP: Record<
  FeatureNotificationEnum,
  LazyExoticComponent<any>
> = {
  [FeatureNotificationEnum.NewOrganizationDashboard]: lazy(() =>
    import('./features-modals/NewOrganizationDashboard').then((module) => ({
      default: module.NewOrganizationDashboard,
    }))
  ),
  [FeatureNotificationEnum.NewSettingsDesign]: lazy(() =>
    import('./features-modals/NewSettingsDesign').then((module) => ({
      default: module.NewSettingsDesign,
    }))
  ),
  [FeatureNotificationEnum.NewPartnerToPartner]: lazy(() =>
    import('./features-modals/NewPartnerToPartner').then((module) => ({
      default: module.NewPartnerToPartner,
    }))
  ),
};
