import { Stack } from '@mantine/core';
import { FormikProps } from 'formik';
import React, { FC } from 'react';

import { AutoFormikProps } from '@portals/types';

import FieldWrapper from './FieldWrapper';

const Body: FC<AutoFormikProps & FormikProps<any>> = (props) => {
  const { fields, title, subTitle, errors } = props;

  return (
    <>
      {title && <h3 className={subTitle ? '' : 'mb-3'}>{title}</h3>}

      {subTitle && <h5 className="mb-3">{subTitle}</h5>}

      <Stack spacing="md">
        {fields.map((field) => (
          <FieldWrapper key={field.name} field={field} {...props} />
        ))}
      </Stack>

      {errors.server && (
        <p data-testid="error-text" className="text-danger mt-2">
          {errors.server}
        </p>
      )}
    </>
  );
};

export default Body;
