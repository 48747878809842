import { createStyles } from '@mantine/core';
import { Identifier, XYCoord } from 'dnd-core';
import { motion } from 'framer-motion';
import React, { PropsWithChildren, useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';

interface SortableDndListProps {
  dndTypeIdentifier: Identifier;
  itemIndex: number;
  itemUniqueId: string | number;
  setCursorToGrabWhenHover?: boolean;
  onMove: (dragIndex: number, hoverIndex: number) => void;
}

export function SortableDndItem({
  dndTypeIdentifier,
  itemIndex,
  itemUniqueId,
  setCursorToGrabWhenHover = true,
  onMove,
  children,
}: PropsWithChildren<SortableDndListProps>) {
  const { classes, cx } = useStyles();
  const ref = useRef<HTMLDivElement>(null);

  const [{ handlerId }, drop] = useDrop<
    { index: number; id: string },
    void,
    { handlerId: Identifier | null }
  >({
    accept: dndTypeIdentifier,
    collect: (monitor) => {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover: (item, monitor) => {
      if (!ref.current) {
        return;
      }

      const dragIndex = item.index;
      const hoverIndex = itemIndex;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      onMove(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [, drag] = useDrag({
    type: dndTypeIdentifier,
    item: () => ({ index: itemIndex, id: itemUniqueId }),
  });

  drag(drop(ref));

  return (
    <motion.div
      layout="position"
      transition={{ duration: 0.1 }}
      className={cx({ [classes.cursorGrab]: setCursorToGrabWhenHover })}
    >
      <div ref={ref} data-handler-id={handlerId}>
        {children}
      </div>
    </motion.div>
  );
}

const useStyles = createStyles(() => ({
  cursorGrab: {
    cursor: 'grab',
  },
}));
