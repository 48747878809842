import { ActionIcon, createStyles, Group, Text, Tooltip } from '@mantine/core';
import { last } from 'lodash/fp';
import React from 'react';

import { ReactComponent as Eye } from '@portals/icons/linear/eye.svg';
import { ReactComponent as RecoveryConvert } from '@portals/icons/linear/recovery-convert.svg';
import { extractFilenamePrefixedWithUuidV4 } from '@portals/utils';

import { FileTypeIcon } from '../../../index';

interface UploadedFileRowProps {
  fileUrl: string;
  onDeleteFile: (fileUrl: string) => void;
}

export function UploadedFileRow({
  fileUrl,
  onDeleteFile,
}: UploadedFileRowProps) {
  const { classes, theme } = useStyles();
  const remoteFilename = last(fileUrl.split('/')) as string;
  const filename = extractFilenamePrefixedWithUuidV4(remoteFilename);

  return (
    <Group spacing="xl" className={classes.container}>
      <FileTypeIcon width={40} fileUrl={fileUrl} />

      <Text className={classes.filename}>{filename}</Text>

      <Group className={classes.actions}>
        <Tooltip label="View document">
          <ActionIcon onClick={() => window.open(fileUrl, '_blank')}>
            <Eye color={theme.colors.gray[6]} />
          </ActionIcon>
        </Tooltip>

        <Tooltip label="Upload new document">
          <ActionIcon onClick={() => onDeleteFile(fileUrl)}>
            <RecoveryConvert color={theme.colors.gray[6]} />
          </ActionIcon>
        </Tooltip>
      </Group>
    </Group>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    padding: theme.spacing.xl,
    border: `1px solid ${theme.colors.gray[3]}`,
    borderRadius: theme.radius.md,
  },
  filename: {
    fontSize: theme.fontSizes.sm,
    fontWeight: 700,
    color: theme.colors.gray[9],
  },
  actions: {
    marginLeft: 'auto',
  },
}));
