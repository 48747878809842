import { Anchor, Stack, Text, useMantineTheme } from '@mantine/core';
import { Dropzone, DropzoneProps } from '@mantine/dropzone';
import React from 'react';

import { ReactComponent as Upload } from '@portals/icons/linear/upload.svg';

interface UploadDropzoneProps {
  dropzoneProps: Omit<DropzoneProps, 'children' | 'loading' | 'onDrop'>;
  onDrop: DropzoneProps['onDrop'];
}

export function UploadDropzone({ dropzoneProps, onDrop }: UploadDropzoneProps) {
  const theme = useMantineTheme();

  return (
    <Dropzone
      onDrop={onDrop}
      multiple={false}
      styles={dropzoneStyles}
      {...dropzoneProps}
    >
      <Stack
        spacing="xs"
        align="center"
        justify="center"
        sx={{ height: '100%' }}
      >
        <Upload width={62} height={53} color={theme.colors.blue_gray[2]} />

        <Text size="sm" sx={{ color: theme.colors.blue_gray[4] }}>
          Drag & drop files here
        </Text>

        <Text size="xs" inline sx={{ color: theme.colors.blue_gray[2] }}>
          or
        </Text>

        <Anchor size="sm">Upload from computer</Anchor>
      </Stack>
    </Dropzone>
  );
}

const dropzoneStyles: DropzoneProps['styles'] = () => ({
  inner: {
    height: '100%',
  },
});
